/**
 * @name Content
 * @description
 */
import {useEffect, useState} from 'react'
import styled from 'styled-components'
import {motion, useMotionValue} from 'framer-motion'

export default function Contents() {
  // hooks
  const [isBool, setIsBool] = useState(false)
  //*--------------------------------------------------*
  const setToggle = (mode: boolean) => {
    setIsBool(mode)
  }
  //*--------------------------------------------------*
  return (
    <Content>
      <div className="wrap">
        <button
          onClick={() => {
            setToggle(true)
          }}>
          True
        </button>
        <button
          onClick={() => {
            setToggle(false)
          }}>
          False
        </button>
        <Motion
          transition={{duration: 0.5}}
          animate={{
            x: isBool ? 0 : 300,
            scale: isBool ? 1 : 0.5,
            backgroundColor: isBool ? 'blue' : 'red',
          }}
        />
      </div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  // styles
  button {
    margin-right: 50px;
    padding: 10px 20px;
    color: white;
    background: black;
    /* border: 0; */
  }
`
const Motion = styled(motion.div)`
  width: 100px;
  height: 100px;
  border-radius: 200px;
  background: red;
  overflow: 'hidden';
`
