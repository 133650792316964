import styled from 'styled-components';
import { COLOR } from 'styles';
import typography from '@/styles/typography';

export const Header = styled.div`
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  align-items: center;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SummaryWrapper = styled.div`
  min-width: 400px;
`;

export const ModelInfoWrapper = styled.div`
  min-width: 680px;
`;

export const InstalledInfoWrapper = styled.div`
  min-width: 680px;
`;

export const RoamingInfoWrapper = styled.div`
  min-width: 680px;
`;

export const RoamingRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const RoamingHeader = styled.div`
  flex: 1;
  padding: 14px 8px;
  background-color: ${COLOR.BLUE8};
  border-bottom: 1px solid ${COLOR.BLUE_GRAY9};
  text-align: center;
  ${typography.headline6};
  color: ${COLOR.GRAY1};
`;
export const RoamingCell = styled.div`
  padding: 16px 8px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  flex: 1;
  text-align: center;
  ${typography.body4};
  color: ${COLOR.BLACK3};
`;

export const ChannelIdDescription = styled.div`
  display: flex;
  align-items: center;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%), ${COLOR.YELLOW2};
  border-radius: 4px;
  padding: 4px 8px;
  gap: 2px;
`;
export const ChannelIdDescriptionText = styled.span`
  ${typography.caption1};
  color: ${COLOR.YELLOW2};
`;
