/**
 * @name 정상,장애뱃지반환
 * @description
 */
import Badge from '@/components/atoms/Badge';

type Props = {
  type: string
  onClick?: () => void
}
export default function ChargeStatus({type}: Props) {
  // hooks
  const isActive = type === 'ACTIVE' ? true : false
  //*--------------------------------------------------*
  return <Badge mode={`${isActive ? 'Primary' : 'Danger'}`} label={`${isActive ? '정상' : '장애'}`} />
}
/********************************************************
[사용법]
    <ChargeStatus type={item?.model?.charge_type} />
*********************************************************/
