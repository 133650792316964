import React, { useEffect } from 'react';
import styled from 'styled-components';
import { layout } from 'styles';
import { SESSION_STORAGE } from 'lib';
import { useAppDispatch } from 'model';
import { Gnb, Top } from 'components';
import Footer from './footer';
import { authAction } from '@/model/modules';
import { setLocations, setCountries } from '@/model/modules/location';

type Props = {
  empty?: boolean; // 레이아웃만유지됨(GNB,footer)
  children?: React.ReactNode | JSX.Element[] | undefined;
  [key: string]: any;
};

/**
 * @name App
 */
export default function App({ children, empty }: Props) {
  const dispatch = useAppDispatch();

  // refreshLogin
  async function refreshLogin() {
    const businessCode = await sessionStorage.getItem(SESSION_STORAGE.BUSINESS_CODE);
    const cpoSeq = await sessionStorage.getItem(SESSION_STORAGE.CPO_SEQ);
    const locationList = await sessionStorage.getItem(SESSION_STORAGE.LOCATION);
    const countryList = await sessionStorage.getItem(SESSION_STORAGE.COUNTRIES);
    if (cpoSeq && businessCode) {
      dispatch(authAction.setRefreshUser({ businessCode, cpoSeq }));
    }
    if (locationList) {
      dispatch(setLocations(JSON.parse(locationList)));
    }
    if (countryList) {
      dispatch(setCountries(JSON.parse(countryList)));
    }
    // const res = await signIn({ payload, dispatch, type: 'refresh' });
    // log(res);
  }
  // useEffect
  useEffect(() => {
    refreshLogin();
  }, []);

  return (
    <Content>
      {/* main */}
      <main className={layout.main}>
        <div className="gnb">
          {/* gnb */}
          <aside className={`${layout.gnb}`}>
            <Gnb />
          </aside>
        </div>
        {/* container, children */}
        <article className={layout.container_page}>
          {/* top */}
          <div className="top">
            <Top mode="page" />
          </div>
          {/* <!--children영역--> */}
          <main className={empty ? layout.container_empty : layout.container}>
            {children}
            {/* 메인대시보드,충전단가관리 */}
            {empty && (
              <footer>
                <Footer />
              </footer>
            )}
          </main>
          {/* footer */}
          {!empty && (
            <footer>
              <Footer />
            </footer>
          )}
        </article>
      </main>
    </Content>
  );
}
const Content = styled.div`
  /* GNB */
  .top {
    position: relative;
    height: 72px;
    z-index: 1;
  }
  /* GNB */
  .gnb {
    width: 72px;
  }
  article > footer,
  article > main > footer {
    margin-left: 24px;
    margin-right: 30px;
    margin-bottom: 20px;
  }
`;
