/**
 * @name 컬러색상
 * @description
 */

/*= ==================================================================[컬러값] */
export const COLOR = {
  TRANSPARENT: 'TRANSPARENT',
  SIGN_IN_BG: '#F8F7FA',
  /** ------------------------------------[BLACK,WHITE] */
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  /** ------------------------------------[BLACK] */
  BLACK1: '#080808',
  BLACK2: '#202020',
  BLACK3: '#373737',
  /** ------------------------------------[GRAY] */
  GRAY1: '#5F5F5F',
  GRAY2: '#8A8A8A',
  GRAY3: '#B2B2B2',
  GRAY4: '#D8D8D8',
  GRAY5: '#E9E9E9',
  GRAY6: '#F4F4F4',
  /** ------------------------------------[BLUEGRAY] */
  BLUE_GRAY1: '#363849',
  BLUE_GRAY2: '#474A62',
  BLUE_GRAY3: '#525678',
  BLUE_GRAY4: '#677196',
  BLUE_GRAY5: '#7D86A9',
  BLUE_GRAY6: '#959CB8',
  BLUE_GRAY7: '#A9AEC6',
  BLUE_GRAY8: '#CBCEE0',
  BLUE_GRAY9: '#E5E7F3',
  /** ------------------------------------[BLUE] */
  BLUE0: '#0F3FB7',
  BLUE1: '#1146CB',
  BLUE2: '#1850DD',
  BLUE3: '#2B64F5',
  BLUE4: '#4073F4',
  BLUE5: '#6690FB',
  BLUE6: '#82A5ED',
  BLUE7: '#D6E2FB',
  BLUE8: '#E9F0FE',
  BLUE9: '#F7FAFF',
  /** ------------------------------------[YELLOW] */
  YELLOW1: '#FDC061',
  YELLOW2: '#F6AE3F',
  YELLOW3: '#EBA12F',
  /** ------------------------------------[RED] */
  RED1: '#FF6D6E',
  RED2: '#FF5B5C',
  RED3: '#F44142',
  RED4: '#F2BDBD',
  RED5: '#FFEAEA',
  /** ------------------------------------[GREEN] */
  GREEN1: '#6CD5AF',
  GREEN2: '#40C997',
  GREEN3: '#1EBB82',
} as const;

/*= ==================================================================[테마설정] */
