import { useAppDispatch } from 'model';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SESSION_STORAGE, objectToQueryString, useContext } from 'lib';
import dayjs from 'dayjs';
import RemoteHistoryListPresenter from './RemoteHistoryList.presenter';
import { actions } from '@/model/modules/remote';
import { Paging, RemoteHistoryListRequest } from '@/lib/apis/run/type';
import { getRemoteExcelFile, getRemoteHistories } from '@/lib/apis/run';
import { IRemoteHistoryFilter } from '@/components/organisms/Filters/RemoteHistoryFilter/RemoteHistoryFilter.type';

export default function RemoteHistoryListContainer() {
  const { cpo_seq } = useContext();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(Number(page));
  const searchQueryString = searchParams.get('search') || '';
  const [searchKeyword, setSearchKeyword] = useState(searchQueryString);
  const [appliedSearchKeyword, setAppliedSearchKeyword] = useState(searchQueryString);

  // 상세검색
  const [detailSearchTerms, setDetailSearchTerms] = useState<IRemoteHistoryFilter>({
    stationName: '',
    chargepointName: '',
    startTime: '',
    endTime: '',
    action: [],
    result: [],
  });

  const fetchList = async (payload?: RemoteHistoryListRequest) => {
    let termsPayload: RemoteHistoryListRequest = {
      size: itemsPerPage,
      page: currentPage,
      cpo_seq,
    };

    if (searchKeyword) {
      termsPayload = { ...termsPayload, station_name: appliedSearchKeyword };
    }
    // 상세검색?
    if (detailSearchTerms.chargepointName) {
      termsPayload = { ...termsPayload, chargepoint_name: detailSearchTerms.chargepointName };
    }
    if (detailSearchTerms.startTime) {
      termsPayload = { ...termsPayload, start_time: detailSearchTerms.startTime };
    }
    if (detailSearchTerms.endTime) {
      termsPayload = { ...termsPayload, end_time: detailSearchTerms.endTime };
    }
    if (detailSearchTerms.result.length > 0) {
      termsPayload = { ...termsPayload, result: [...detailSearchTerms.result] };
    }
    if (detailSearchTerms.action.length > 0) {
      termsPayload = { ...termsPayload, action: [...detailSearchTerms.action] };
    }

    if (cpo_seq) {
      const { isSuccess, data, meta } = await getRemoteHistories(termsPayload);
      if (isSuccess)
        dispatch(
          actions.setList({
            list: data,
            totalCount: meta.paging?.total_count ?? 0,
          }),
        );
    }
  };

  const queryParamsURL = ({
    page,
    search,
    filterVisible,
    chargepointName,
    startTime,
    endTime,
    result,
    action,
  }: {
    page?: number;
    search?: string;
    filterVisible?: boolean;
    chargepointName?: string;
    startTime?: string;
    endTime?: string;
    result?: string[];
    action?: string[];
  }) => {
    const searchKeyword = search ?? appliedSearchKeyword;
    const isFilterVisible = filterVisible ? 'y' : 'n';
    const queryParams = [];

    if (searchKeyword) {
      queryParams.push(`stationName=${encodeURIComponent(searchKeyword)}`);
    }
    if (chargepointName) {
      queryParams.push(`chargepointName=${encodeURIComponent(chargepointName)}`);
    }
    if (startTime) {
      queryParams.push(`startTime=${encodeURIComponent(startTime)}`);
    }
    if (endTime) {
      queryParams.push(`endTime=${encodeURIComponent(endTime)}`);
    }
    if (result) {
      queryParams.push(`result=${encodeURIComponent(result.toString())}`);
    }
    if (action) {
      queryParams.push(`action=${encodeURIComponent(action.toString())}`);
    }
    queryParams.push(`filter=${encodeURIComponent(isFilterVisible)}`);
    queryParams.push(`page=${page ?? currentPage}`);
    const queryString = queryParams.join('&');

    return queryString;
  };

  const handlSetDetailSearchTerms = (terms: IRemoteHistoryFilter) => {
    setDetailSearchTerms(terms);
  };

  const pageHandler = (page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      navigate(`?${queryParamsURL({ page })}`);
      fetchList({ size: itemsPerPage, page: currentPage, cpo_seq });
    }
  };

  const handleExcelDownload = async () => {
    let termsPayload: RemoteHistoryListRequest = {
      size: itemsPerPage,
      page: currentPage,
      cpo_seq,
    };

    if (searchKeyword) {
      termsPayload = { ...termsPayload, station_name: appliedSearchKeyword };
    }
    if (detailSearchTerms.chargepointName) {
      termsPayload = { ...termsPayload, chargepoint_name: detailSearchTerms.chargepointName };
    }
    if (detailSearchTerms.startTime) {
      termsPayload = { ...termsPayload, start_time: detailSearchTerms.startTime };
    }
    if (detailSearchTerms.endTime) {
      termsPayload = { ...termsPayload, end_time: detailSearchTerms.endTime };
    }
    if (detailSearchTerms.result.length > 0) {
      termsPayload = { ...termsPayload, result: [...detailSearchTerms.result] };
    }
    if (detailSearchTerms.action.length > 0) {
      termsPayload = { ...termsPayload, action: [...detailSearchTerms.action] };
    }
    if (cpo_seq) {
      await getRemoteExcelFile({
        payload: termsPayload,
        fileName: `원격제어이력목록_${dayjs().format('YYYY_MM_DD')}.xlsx`,
      });
    }
  };

  const handleSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const handleSearch = () => {
    setAppliedSearchKeyword(searchKeyword);

    fetchList({
      size: itemsPerPage,
      page: currentPage,
      cpo_seq,
    });
  };

  const handleFilterButton = (value: boolean) => {
    dispatch(actions.setSearchPayload({ isOpen: value }));
  };

  useEffect(() => {
    let termsPayload: RemoteHistoryListRequest = {
      size: itemsPerPage,
      page: currentPage,
      cpo_seq,
    };

    if (searchKeyword) {
      termsPayload = { ...termsPayload, station_name: appliedSearchKeyword };
    }
    if (detailSearchTerms.chargepointName) {
      termsPayload = { ...termsPayload, chargepoint_name: detailSearchTerms.chargepointName };
    }
    if (detailSearchTerms.startTime) {
      termsPayload = { ...termsPayload, start_time: detailSearchTerms.startTime };
    }
    if (detailSearchTerms.endTime) {
      termsPayload = { ...termsPayload, end_time: detailSearchTerms.endTime };
    }
    if (detailSearchTerms.result.length > 0) {
      termsPayload = { ...termsPayload, result: [...detailSearchTerms.result] };
    }
    if (detailSearchTerms.action.length > 0) {
      termsPayload = { ...termsPayload, action: [...detailSearchTerms.action] };
    }
    sessionStorage.setItem(SESSION_STORAGE.FIlTER_TERMS.REMOTE_HISTORY, JSON.stringify(termsPayload));

    fetchList(termsPayload);
  }, [detailSearchTerms, currentPage]);

  return (
    <RemoteHistoryListPresenter
      itemsPerPage={itemsPerPage}
      currentPage={currentPage}
      pageHandler={pageHandler}
      handleExcelDownload={handleExcelDownload}
      searchKeyword={searchKeyword}
      handleSearch={handleSearch}
      handleSearchKeyword={handleSearchKeyword}
      detailSearchTerms={detailSearchTerms}
      handleSetDetailSearchTerms={handlSetDetailSearchTerms}
      handleFilterOpen={handleFilterButton}
    />
  );
}
