import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'model';
import { Cell, Header, Row, Wrapper } from './VendorModelInfo.style';
import { IConnector, IModelCps } from '@/types/infra';
import Badge from '@/components/atoms/Badge';

function VendorModelInfo({ tabSelected }: { tabSelected?: boolean }) {
  const { t } = useTranslation('common');
  const { view } = useAppSelector(state => state.vendor);
  return (
    <Wrapper $tabSelected={tabSelected}>
      <Row>
        <Header>No.</Header>
        <Header>{t('forms.model.model')}</Header>
        <Header>{t('forms.model.type')}</Header>
        <Header>{t('forms.model.simultaneousCharging')}</Header>
        <Header>{`${t('common.maximumOutput')}\n${t('common.unit.brackets.kWh')}`}</Header>
        <Header>{t('forms.model.typeOfConnectors')}</Header>
      </Row>
      {view.model_cps.length > 0 ? (
        view.model_cps.map((ele: IModelCps, index: number) => (
          <Row key={ele.seq}>
            <Cell>{index + 1}</Cell>
            <Cell>{ele.name}</Cell>
            <Cell>
              <Badge
                mode={ele.charger_type === 'FAST' ? 'Fast' : 'Standard'}
                label={ele.charger_type === 'FAST' ? '급속' : '완속'}
              />
            </Cell>
            <Cell>
              <Badge
                mode={ele.is_concurrency ? 'Available' : 'Unavailable'}
                label={ele.is_concurrency ? '가능' : '불가'}
              />
            </Cell>
            <Cell>{ele.max_power_kw}</Cell>
            <Cell>
              {ele.model_connectors.map((connector: IConnector, connectorIndex: number) => {
                if (connectorIndex > 0) {
                  return ` / ${t(`common.connector.${connector.types}`)}`;
                }
                return t(`common.connector.${connector.types}`);
              })}
            </Cell>
          </Row>
        ))
      ) : (
        <Row>
          <Cell>{t('forms.charger.noInformation')}</Cell>
        </Row>
      )}
    </Wrapper>
  );
}

export default VendorModelInfo;
