/**
 * @name 충전기(등록/삭제/추가)
 * @description
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChargepoint, IChargePointModelDetail, ICharger, IStation } from '@/types/infra';
import { SCREEN_MODE } from '@/types/mode';

export const sliceName = 'device'; // 변경필요

// 팝업 _popup
export const POPUP = {
  REGISTER_CANCEL: 'REGISTER_CANCEL', // 등록취소
  REGISTER_SUCCESS: 'REGISTER_SUCCESS', // 등록성공
  REGISTER_FAIL: 'REGISTER_FAIL', // 등록실패
  SELECT_STATION: 'SELECT_STATION', // 충전소선택하기
  DELETE_STATION: 'DELETE_STATION', // DELETE_STATION
};
// 이벤트 _event
export const EVENT = {
  // submit
  SUBMIT: 'SUBMIT',
  UPDATE: 'UPDATE',
  // 레이어팝업
  POPUP_SHOW: 'POPUP_SHOW',
  POPUP_HIDE: 'POPUP_HIDE',
  POPUP_SET: 'POPUP_SET',
  // CRUD
  SUBMIT_CREATE: 'SUBMIT_CREATE', // 등록하기
  FETCH_LIST: 'FETCH_LIST', // 리스트데이터요청
  FETCH_VIEW: 'FETCH_VIEW', // 상세데이터요청
  DELETE: 'DELETE', // 삭제하기
  MODIFY: 'MODIFY', // 수정하기
};
/** @defineType */
type StateType = {
  mode: string;
  duplicatedCheck: boolean;
  payload?: any;
  selectedStation: IStation | null;
  selectedModel: IChargePointModelDetail | null;
  list: ICharger[];
  totalCount: number;
  view: IChargepoint;
  [key: string]: any;
};
/** @initialState */
const initialState: StateType = {
  mode: SCREEN_MODE.LIST,
  duplicatedCheck: true,
  payload: {
    station_seq: -1,
    // model_seq: 1,
    // heartbeat_interval: 5,
    // country_code: 'GH',
    // setup_date: '2023-01-11 11:38:04',
    // made_date: '2023-01-11 11:38:04',
    // name: '만희집앞충전기',
  },
  selectedStation: null,
  selectedModel: null,
  list: [],
  totalCount: 0,
  view: {
    activated_at: '',
    channel_id: '',
    code: '',
    connectors: [],
    heartbeat_interval: null,
    installed_at: '',
    is_concurrency: null,
    is_roaming: null,
    model: {
      charger_type: 'FAST',
      connector_count: null,
      created_at: undefined,
      created_by: undefined,
      efficiencies: null,
      firmware_version: undefined,
      ipx_level: undefined,
      is_concurrency: null,
      last_modified_at: undefined,
      last_modified_by: undefined,
      max_ampare: null,
      max_power_kw: null,
      max_voltage: null,
      name: '',
      pg: '',
      seq: '',
      status: '',
      suspended_at: '',
      trouble_version: '',
      model_connectors: [],
      vendor: null,
    },
    name: '',
    password: '',
    produced_at: '',
    roaming_chargepoint_id1: '',
    roaming_chargepoint_id2: '',
    roaming_station_id: '',
    seq: '',
    socket_id: '',
    station: {
      kr_location: {
        name: '',
      },
      address: '',
      address_detail: '',
      b_code: '',
      chargepoint_count: null,
      code: '',
      country_code2: '',
      created_at: '',
      created_by: null,
      description: '',
      is_default: null,
      last_chargepoint_number: null,
      last_modified_at: '',
      last_modified_by: null,
      latitude: '',
      longitude: '',
      memo: '',
      name: '',
      old_code: '',
      phone: '',
      pic_large: '',
      pic_medium: '',
      pic_small: '',
      post_code: '',
      seq: '',
      status: '',
      tariff_type_applied_at: '',
      tariff_types: '',
      timezone: '',
      station_type: null,
    },
    status: '',
    suspended_at: '',
    device_status_items: [],
  },
  search_payload: {
    isOpen: false,
  }, // ---------------------검색조건 및 상세검색조건
};
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // 초기화
    setInit(state: StateType) {
      state.mode = SCREEN_MODE.LIST;
    },
    setDuplicatedCheck: (state: StateType, action: PayloadAction<boolean>) => {
      state.duplicatedCheck = action.payload;
    },
    // 모드변경
    setMode(state: StateType, action: PayloadAction<SCREEN_MODE>) {
      state.mode = action.payload;
    },
    // Payload 업데이트
    setPayload(state: StateType, action: PayloadAction<any>) {
      state.payload = Object.assign(state.payload, action.payload);
    },
    initView: (state: StateType) => {
      state.view = initialState.view;
    },
    initPayload: (state: StateType) => {
      state.payload = initialState.payload;
    },
    // fetch 리스트
    setChargerList(state: StateType, action: PayloadAction<{ list: ICharger[]; totalCount: number }>) {
      state.list = action.payload.list;
      state.totalCount = action.payload.totalCount;
    },
    // fetch view
    setView(state: StateType, action: PayloadAction<IChargepoint>) {
      state.view = action.payload;
    },
    // fetch view
    setSelectStation(state: StateType, action: PayloadAction<IStation | null>) {
      state.selectedStation = action.payload;
    },
    setSelectModel(state: StateType, action: PayloadAction<IChargePointModelDetail | null>) {
      state.selectedModel = action.payload;
    },
    // 검색및 상세검색조건 업데이트
    setSearchPayload(state: StateType, action: PayloadAction<any>) {
      state.search_payload = Object.assign(state.search_payload, action.payload);
    },
  },
});

export default slice.reducer;
export const nameSpace = sliceName;
export const { actions } = slice;
