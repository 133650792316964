/**
 * @name Content
 * @description
 */
import { useState } from 'react';
import styled from 'styled-components';
import { debug, H2, Input, Textarea } from 'lib';

export default function Contents() {
  // hooks
  const [text, setText] = useState(' ');
  //*--------------------------------------------------*
  return (
    <Content>
      <div className="wrap">
        <section>
          <H2>input</H2>
          <Input
            value={text}
            setValue={setText}
            style={{ marginBottom: 30 }}
            className="input"
            placeholder="입력해주세요."
          />
        </section>
        <section>
          <H2>textarea</H2>
          <Textarea
            style={{ marginBottom: 30, resize: 'none' }}
            className="textarea"
            value={text}
            setValue={setText}
            placeholder="입력해주세요."
            onChange={str => {
              // console.log(str)
            }}
          />
        </section>
        <section>
          <H2>debug</H2>
          {debug(text)}
        </section>
      </div>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  .wrap {
    display: flex;
    flex-direction: row;
  }
  // styles
  section {
    margin-right: 30px;
  }
`;
