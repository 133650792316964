import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MENU } from '@/pages/router';
import { IMenu, ISitemap } from '@/types/menu';
import { icons } from '@/assets/images';

/**
 * @name GlobalNavigation
 * @description
 */
export const sliceName = 'gnb'; // 변경필요
type StateType = {
  location: any[];
  sitemap: ISitemap[];
  footer: { name: string; path: string; classnames: string }[];
  menu: IMenu[];
  navi: {
    depth1: number | null;
    depth2: number | null;
  };
  [key: string]: any;
};

/** @initialState */
const initialState: StateType = {
  location: [
    { label: '홈', path: MENU.DASHBOARD },
    { label: '대시보드', path: MENU.DASHBOARD },
  ],
  navi: {
    depth1: null,
    depth2: null,
  },
  menu: [],
  sitemap: [
    {
      name: '대시보드',
      path: MENU.DASHBOARD,
      imageSrc: {
        default: icons.menu.dashboard.default.image,
        activated: icons.menu.dashboard.activated.image,
        disabled: icons.menu.dashboard.disabled.image,
      },
    },
    {
      name: '인프라',
      path: MENU.INFRA.INFRA,
      imageSrc: {
        default: icons.menu.infra.default.image,
        activated: icons.menu.infra.activated.image,
        disabled: icons.menu.infra.disabled.image,
      },
    },
    { name: '충전소 관리', path: MENU.INFRA.STATION_MANAGEMENT },
    { name: '충전기 관리', path: MENU.INFRA.CHARGER_MANAGEMENT },
    { name: '제조사 관리', path: MENU.INFRA.VENDOR_MANAGEMENT },
    { name: '모델 관리', path: MENU.INFRA.MODEL_MANAGEMENT },
    { name: '장애코드 등록', path: MENU.INFRA.ERRORCODE_MANAGE },
    { name: '펌웨어 등록', path: MENU.FIRMWARE_MANAGE },
    {
      name: '충전 단가',
      path: MENU.RATE.RATE,
      imageSrc: {
        default: icons.menu.chargingPrice.default.image,
        activated: icons.menu.chargingPrice.activated.image,
        disabled: icons.menu.chargingPrice.disabled.image,
      },
    },
    { name: '충전 단가 관리', path: MENU.RATE.CHARGING_RATE_MANAGEMENT },
    { name: '충전소별 단가 관리', path: MENU.CHARGING_STATION_PRICE },
    {
      name: '매출',
      path: MENU.SALES.SALES,
      imageSrc: {
        default: icons.menu.sales.default.image,
        activated: icons.menu.sales.activated.image,
        disabled: icons.menu.sales.disabled.image,
      },
    },
    { name: '충전 내역', path: MENU.SALES.CHARGING_STATUS },
    { name: '결제 내역', path: MENU.SALES.PAYMENT_STATUS },
    { name: '정산 현황', path: MENU.CALCULATE_STATUS },
    {
      name: '충전기 운영',
      path: MENU.OPERATION.OPERATION,
      imageSrc: {
        default: icons.menu.report.default.image,
        activated: icons.menu.report.activated.image,
        disabled: icons.menu.report.disabled.image,
      },
    },
    { name: '충전기 현황/제어', path: MENU.OPERATION.CHARGEPOINT_OPERATION },
    { name: '장애 이력', path: MENU.OPERATION.FAULT_HISTORY },
    { name: '장애 신고 이력', path: MENU.OPERATION.TROUBLE_REPORTS },
    { name: '원격제어 이력', path: MENU.OPERATION.REMOTE_HISTORY },
    {
      name: '계정',
      path: MENU.ADMIN.ADMIN,
      imageSrc: {
        default: icons.menu.profileSetting.default.image,
        activated: icons.menu.profileSetting.activated.image,
        disabled: icons.menu.profileSetting.disabled.image,
      },
    },
    { name: '계정별 권한 관리', path: MENU.ADMIN.ACCOUNT_MANAGEMENT },
    { name: '시스템 설정', path: MENU.SETTING },
  ],
  footer: [
    { name: '고객센터', classnames: 'gnb8', path: MENU.POLICY_CUSTOMER },
    { name: '로그아웃', classnames: 'gnb9', path: MENU.AUTH.SIGN_OUT },
  ],
};

/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // 네비게이션초기화
    setInit(state: StateType) {
      state.navi = Object.assign(state.navi, { depth1: null, depth2: null });
    },
    // 메뉴관리
    setMenu: (state: StateType, action: PayloadAction<IMenu[]>) => {
      state.menu = action.payload;
    },
    // 네비게이션변경
    setNavi(state: StateType, action: PayloadAction<any>) {
      state.navi = Object.assign(state.navi, action.payload);
      // 초기화
      state.location = state.location.slice(0, 1);
      const { depth1 } = action.payload;
      const main = state.sitemap[depth1];
      state.location[1] = { label: main.name, path: main.path };
      // depth2
      if (action.payload?.depth2_name !== undefined) {
        const { depth2_name, depth2_path } = action.payload;
        state.location[2] = { label: depth2_name, path: depth2_path };
      } else {
      }
    },
  },
});

// export
export const EVENT = {
  // submit
};
export default slice.reducer;
export const nameSpace = sliceName;
export const { actions } = slice;
