/**
 * @name 고객센터
 * @description
 */
import {App} from 'components'
// contents
import {Header} from './common'
import {Template} from './contents'

export default function Presenter() {
  // hooks
  return (
    <App>
      {/* 헤더 */}
      <Header />
      {/* 컨텐츠 */}
      <Template />
    </App>
  )
}
