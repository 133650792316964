import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'model';
import ChargingStatusDetailPresenter from './ChargingStatusDetail.presenter';
import { MENU } from '@/pages/router';
import { actions } from '@/model/modules/chargingStatus';
import { getTransactionDetail } from '@/lib/apis/sales';

export default function ChargingStatusDetailContainer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const searchKeyword = searchParams.get('search') || '';
  const locationKeyword = searchParams.get('location') || '';
  const filterYn = searchParams.get('filter') || '';

  const fetchData = async () => {
    if (id) {
      const { isSuccess, data } = await getTransactionDetail(id);
      if (isSuccess) {
        dispatch(actions.setView(data));
      }
    }
  };

  const queryParamsURL = () => {
    const queryParams = [];

    if (searchKeyword) {
      queryParams.push(`search=${encodeURIComponent(searchKeyword)}`);
    }
    if (locationKeyword) {
      queryParams.push(`location=${encodeURIComponent(locationKeyword)}`);
    }
    if (filterYn) {
      queryParams.push(`filter=${encodeURIComponent(filterYn)}`);
    }
    queryParams.push(`page=${page}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const handleBackToList = () => {
    navigate(`${MENU.SALES.CHARGING_STATUS}?${queryParamsURL()}`);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return <ChargingStatusDetailPresenter handleBackToList={handleBackToList} />;
}
