/**
 * @name Search
 * @description
 */
import styled from 'styled-components'
import {CheckboxGroup, ListKeyComponent, RadioGroup} from 'components/form'
import {Events} from 'lib'
import {useAppDispatch} from 'model'
import {Store, _event, _action} from '../..'

export default function Contents() {
  // hooks
  const dispatch = useAppDispatch()
  const {search_payload} = Store()
  //*--------------------------------------------------*
  return (
    <Content>
      {/* --------- */}
      <ListKeyComponent
        title="충전기 구분"
        component={
          <RadioGroup
            info={[
              {label: '급속', value: 'FAST', checked: false},
              {label: '완속', value: 'SLOW', checked: false},
            ]}
            onValue={data => {
              const _payload = {charger_type: data[0]}
              Events.emit(_event.FETCH_LIST, Object.assign({}, search_payload, _payload))
              dispatch(_action.setSearchPayload(_payload))
            }}
          />
        }
      />
      <ListKeyComponent
        title="동시 충전"
        component={
          <CheckboxGroup
            info={[
              {label: '동시충전', value: true, checked: false},
              {label: '단독충전', value: false, checked: false},
            ]}
            onValue={data => {
              const _payload = {is_concurrency: data}
              Events.emit(_event.FETCH_LIST, Object.assign({}, search_payload, _payload))
              dispatch(_action.setSearchPayload(_payload))
            }}
          />
        }
      />
      <ListKeyComponent
        title="커넥터 타입"
        component={
          <CheckboxGroup
            info={[
              {label: 'DC콤보', value: 'CCS1', checked: false},
              {label: 'AC상', value: '3PHASE', checked: false},
              {label: 'GBT', value: 'GBT', checked: false},
              {label: 'AC완속', value: 'J1772', checked: false},
            ]}
            onValue={data => {
              const _payload = {connector_type: data}
              Events.emit(_event.FETCH_LIST, Object.assign({}, search_payload, _payload))
              dispatch(_action.setSearchPayload(_payload))
            }}
          />
        }
      />
      <ListKeyComponent
        title="장애 여부"
        component={
          <CheckboxGroup
            info={[
              {label: '장애', value: 'Y', checked: false},
              {label: '정상', value: 'N', checked: false},
            ]}
            onValue={data => {
              const _payload = {is_error: data}
              Events.emit(_event.FETCH_LIST, Object.assign({}, search_payload, _payload))
              dispatch(_action.setSearchPayload(_payload))
            }}
          />
        }
      />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div``
