import React from 'react';
import { ImageButton } from 'lib';
import { icon_delete_gray, icon_edit_gray } from 'assets/images';
import styled from 'styled-components';

type Props = {
  onModify?: () => void;
  onDelete?: () => void;
};
/**
 * @name 수정,삭제버튼그룹
 * @description
 */
export default function IconButtonsModifyDel({ onModify, onDelete }: Props) {
  return (
    <Content>
      <ImageButton source={icon_edit_gray} className="icon" onClick={() => !!onModify && onModify()} />
      <ImageButton source={icon_delete_gray} className="icon" onClick={() => !!onDelete && onDelete()} />
    </Content>
  );
}
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  button.icon {
    width: 24px;
    height: 24px;
    margin-left: 4px;
    margin-right: 4px;
    > img {
      width: 24px;
      height: 24px;
    }
  }
`;
