import { API_HOST } from '@/config/index';
import api from '@/lib/apis/api';
import { objectToQueryString } from '@/lib/index';
import { IResponse } from '../api/type';
import { IChart, IDashBoardTrouble, ReqGetChart } from './type';

/**
 * @param type  1 : 시간 , 2 : 일 , 3 : 월
 * start_date , end_date도 받을 수 있게 되어 있습니다
 */
export const getChart = async (payload: ReqGetChart): Promise<IResponse<IChart>> => {
  const param = objectToQueryString(payload);
  const url = `${API_HOST}/dashboard/chart?${param}`;
  return api.get({ url });
};
/**
 * page , size가 optional로 들어가서 안넣었습니다
 */
export const getTroubles = async (): Promise<IResponse<IDashBoardTrouble>> => {
  const url = `${API_HOST}/dashboard/trouble`;
  return api.get({ url });
};

export const getTotal = async (payload: ReqGetChart) => {
  const param = objectToQueryString(payload);
  const url = `${API_HOST}/dashboard/total?${param}`;
  return api.get({ url });
};

export const getUses = async (payload: ReqGetChart) => {
  const param = objectToQueryString(payload);
  const url = `${API_HOST}/dashboard/uses?${param}`;
  return api.get({ url });
};
