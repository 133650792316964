import React from 'react';
import { useTranslation } from 'react-i18next';
import { InnerCellContainer, TD, TR, Text, ButtonText } from './OpenInfoRow.style';
import typography from '@/styles/typography';
import { IOpens } from '@/types/infra';
import { Image } from '@/lib/index';
import { icon_radio_off, icon_radio_on } from '@/assets/images';
import { DropDownTimes, TextInput } from '@/components/form';
import { RadioButton } from './OpenInfoRow.style';

function OpenInfoRow({
  editMode,
  item,
  onChangeValue,
}: {
  editMode: boolean;
  item: IOpens;
  onChangeValue?: (editKey: string, value: string | boolean) => void;
}) {
  const { t } = useTranslation('common');
  const setImage = (mode: boolean | null) => {
    // if (editMode) return <Image src={icon_radio_disabled} style={{ width: 18, height: 18 }} />;
    if (mode) return <Image src={icon_radio_on} style={{ width: 18, height: 18 }} />;
    if (!mode) return <Image src={icon_radio_off} style={{ width: 18, height: 18 }} />;
    return null;
  };

  return (
    <TR>
      <TD $customFont={typography.body2} $dayCode={item.day_code}>
        {t(`common.day.${item.day_code}`)}
      </TD>
      <TD $customFont={typography.body5}>
        <InnerCellContainer>
          <RadioButton
            onClick={() => {
              if (onChangeValue) {
                onChangeValue('is_off', false);
              }
            }}>
            {setImage(!item.is_off)}
            <ButtonText>{t('forms.station.run')}</ButtonText>
          </RadioButton>
          <RadioButton
            onClick={() => {
              if (onChangeValue) {
                onChangeValue('is_off', true);
              }
            }}>
            {setImage(item.is_off)}
            <ButtonText>{t('forms.station.notRun')}</ButtonText>
          </RadioButton>
        </InnerCellContainer>
      </TD>
      <TD>
        <DropDownTimes
          disabled={!editMode}
          value={item.open_time}
          onChange={(data: { name: string; value: string }) => {
            if (onChangeValue) {
              onChangeValue('open_time', data?.value);
            }
          }}
        />
      </TD>
      <TD>
        <DropDownTimes
          disabled={!editMode}
          value={item.close_time}
          onChange={(data: { name: string; value: string }) => {
            if (onChangeValue) {
              onChangeValue('close_time', data?.value);
            }
          }}
        />
      </TD>
      <TD>
        <InnerCellContainer>
          <TextInput
            disabled={!editMode}
            defaultValue={item.memo}
            placeholder={t('forms.station.opensMemoPlaceholder')}
            maxLength={25}
            onValue={value => {
              if (onChangeValue) {
                onChangeValue('memo', value);
              }
            }}
          />
          <Text>
            {item.memo.length}/25{t('common.unit.character')}
          </Text>
        </InnerCellContainer>
      </TD>
    </TR>
  );
}

export default OpenInfoRow;
