import React, { useEffect } from 'react';
import { Events, H2, Image, Text, useRoutes } from 'lib';
import { useAppDispatch, useAppSelector } from 'model';
import styled from 'styled-components';
import { COLOR } from 'styles';
import {
  SubmitConfirm,
  TextFiledLabel,
  TextInput,
  PopupCreate,
  TextInputPassword,
  PopupContainer,
  SubmitButton,
} from 'components/form';
import { useTranslation } from 'react-i18next';
import { actions } from '@/model/modules/auth';
import AuthScreenLayout from '@/components/layout/auth';
import { auth_screen_forgot_password } from '@/assets/images';
import { MENU } from '@/pages/router';
import { _event, _mode } from '@/pages/auth/sign-in';

/**
 * @name 새 비밀번호 설정
 */
export default function ResetPassword({
  onChangeHandler,
  popupVisible,
  setPopupVisible,
  navigate,
  message,
  validEmail,
  onSubmit,
  success,
  title,
}: {
  onChangeHandler: (k: string, value: string) => void;
  popupVisible: boolean;
  setPopupVisible: (flag: boolean) => void;
  navigate: (str: string) => void;
  message: string;
  validEmail: string;
  onSubmit: () => void;
  success: boolean;
  title: string;
}) {
  const { t } = useTranslation('common');

  const closePopup = () => {
    setPopupVisible(false);
  };

  return (
    <AuthScreenLayout visual={<Image src={auth_screen_forgot_password} />}>
      <Content>
        <div className="wrap">
          <H2 style={{ marginBottom: 20 }} className="center">
            {t('pages.auth.setNewPassword')}
          </H2>
          <TextFiledLabel label={t('pages.auth.email')} />
          <TextInput
            disabled
            style={{ marginBottom: 16, color: COLOR.GRAY2 }}
            placeholder={t('pages.auth.emailPlaceholder')}
            defaultValue={validEmail}
          />
          <TextFiledLabel label={t('pages.auth.newPassword')} />
          <TextInputPassword
            style={{ marginBottom: 16, color: COLOR.GRAY2 }}
            placeholder={t('pages.auth.setPasswordPlaceholder')}
            onValue={str => {
              onChangeHandler('pw', str);
            }}
          />
          <Text color={COLOR.GRAY3} className="caption1" style={{ display: 'block', marginBottom: 16 }}>
            {t('pages.auth.passwordRule')}
          </Text>
          <TextFiledLabel label={t('pages.auth.newPassword2')} />
          <TextInputPassword
            style={{ marginBottom: 16, color: COLOR.GRAY2 }}
            placeholder={t('pages.auth.newPassword2Placeholder')}
            onValue={str => {
              onChangeHandler('confirm', str);
            }}
          />
          <footer>
            <SubmitConfirm
              cancelLabel={t('pages.auth.updateNextTime')}
              onCancel={() => {
                navigate(MENU.AUTH.SIGN_IN);
              }}
              confirmLabel={t('pages.auth.updatePassword')}
              onConfirm={() => {
                onSubmit();
              }}
            />
          </footer>
        </div>
      </Content>
      <PopupContainer visible={popupVisible} onClose={closePopup}>
        <PopupCreate
          warning={success ? false : true}
          title={success ? title : ''}
          message={message}
          footer={
            <SubmitButton warning={success ? false : true} label={t('common.buttons.submit')} onConfirm={closePopup} />
          }
        />
      </PopupContainer>
    </AuthScreenLayout>
  );
}
const Content = styled.div`
  width: 320px;
  /* agree */
  .agree {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .extra {
      color: ${COLOR.BLUE4};
    }
  }
  /* footer */
  footer {
    button {
      margin: 0;
      &:first-child {
        margin-right: 8px;
      }
    }
  }
`;
