/**
 * @name 제조사검색
 * @description
 */
import {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {Image, Row, useAxios, useClickOutside, useContext, uuid} from 'lib'
import {useAppSelector} from 'model'
import {COLOR} from 'styles'
import {icon_arrow_down_black} from 'assets/images'
import {API_HOST} from 'config'
//contents

type Props = {
  style?: React.CSSProperties //----------------- StyleSheet
  onChange?: (value: boolean) => void
  value?: string
  [key: string]: any
}
export default function DropDownVendor({onChange, style, value}: Props) {
  // hooks
  const {cpo_seq} = useContext()
  const [info, setInfo] = useState(null) as any
  const {get} = useAxios()
  const eleRef = useRef(null)
  const [search, setSearch] = useState(value ?? null)
  const [visible, setVisible] = useState(false)
  const {countries} = useAppSelector(state => state.context)
  //*--------------------------------------------------*
  // outside click
  useClickOutside(eleRef, () => {
    setVisible(false)
  })
  // 공통fetch
  async function fetch_vendors() {
    const res = await get(`${API_HOST}/vendors/business/${cpo_seq}/lists`)
    if (res?.isSuccess) {
      // console.log(res)
      setInfo(res?.data)
      // dispatch(contextAction.setCountries(_countries.data))
    }
    return true
  }
  useEffect(() => {
    //*------- 국가코드로드
    fetch_vendors()
  }, [])
  //*--------------------------------------------------*
  return (
    <Content ref={eleRef} style={style}>
      <button className="select" onClick={() => setVisible(!visible)}>
        <label className="selected">{search ?? `제조사를 선택해 주세요.`}</label>
        <Image src={icon_arrow_down_black} style={{width: 20, height: 20}} />
      </button>
      {/* 레이어팝업 */}
      {visible && (
        <section className="popup">
          {!!info &&
            info?.map((item: any) => {
              return (
                <button
                  className="item"
                  key={uuid()}
                  onClick={() => {
                    !!onChange && onChange(item)
                    setSearch(item?.name)
                    setVisible(false)
                  }}>
                  {item?.name}
                </button>
              )
            })}
        </section>
      )}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  position: relative;
  /* 레이어팝업 */
  button.select {
    display: flex;
    width: 100%;
    height: 40px;
    padding: 0 16px;
    border: 1px solid ${COLOR.GRAY4};
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    label.selected {
      color: ${COLOR.GRAY2};
    }
  }
  /* 레이어팝업 */
  section.popup {
    position: absolute;
    z-index: 10;
    background-color: ${COLOR.WHITE};
    width: 100%;
    height: 300px;
    box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.07);
    border-radius: 6px;
    overflow-y: scroll;

    button.item {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
      height: 40px;
      padding: 0 16px;
      color: ${COLOR.BLACK1};
      &:hover {
        background-color: ${COLOR.GRAY6};
      }
    }
  }
`
/********************************************************
  <DropDownVendor
    onChange={async (item: any) => {
      // vendorSeq
      const res = await get(`${API}/trouble-code/collective/business/${cpo_seq}/vendor/${item?.seq}`)
      // 장애코드등록
      if (res?.isSuccess) {
        dispatch(_action.setTroubleCode(res?.data?.model_cps))
        dispatch(_action.setPayload({vendorSeq: item?.seq}))
      }
    }}
  />
*********************************************************/
