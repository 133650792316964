/**
 * @name 폼입력
 * @description
 */
import {useEffect, useState} from 'react'
import styled from 'styled-components'

import {Button, Row, Tab, addComma, debug, objectToQueryString, useAxios, useContext, uuid} from 'lib'
import {ListTable, TextFiledLabel} from 'components/form'
import {API_HOST} from 'config'
import {Paging} from 'types'
import {MemberType} from 'components'
import {COLOR} from 'styles'

export default function Contents() {
  // hooks
  const {cpo_seq} = useContext()
  const [info, setInfo] = useState([]) as any
  const [selected, setSelected] = useState(0)
  const {get} = useAxios()
  //*--------------------------------------------------*
  async function fetch(payload?: Paging) {
    const temp = Object.assign({}, payload, {per: 10})
    const _payload = objectToQueryString(temp)
    const res = await get(`${API_HOST}/business/${cpo_seq}/tariff/seasons?${_payload}`)
    if (res?.isSuccess) {
      setInfo(res?.data)
    }
  }
  useEffect(() => {
    fetch()
  }, [])
  //*--------------------------------------------------*
  return (
    <Content className="inc_form" style={{width: 674}}>
      <Row className="auto tab_wrap">
        <button
          className={`${selected === 0 ? 'tab active' : 'tab'}`}
          onClick={() => {
            setSelected(0)
          }}>
          봄
        </button>
        <button
          className={`${selected === 1 ? 'tab active' : 'tab'}`}
          onClick={() => {
            setSelected(1)
          }}>
          여름
        </button>
        <button
          className={`${selected === 2 ? 'tab active' : 'tab'}`}
          onClick={() => {
            setSelected(2)
          }}>
          가을
        </button>
        <button
          className={`${selected === 3 ? 'tab active' : 'tab'}`}
          onClick={() => {
            setSelected(3)
          }}>
          겨울
        </button>
      </Row>
      {/* {debug(info[0]?.season_hours)} */}
      <TextFiledLabel required label="개별단가" />
      <ListTable
        thead={
          <thead>
            <tr>
              <th className="inner">적용시간</th>
              <th className="inner">급속단가(원/kWh)</th>
              <th className="inner">완속단가(원/kWh)</th>
            </tr>
          </thead>
        }>
        <tbody>
          {!!info &&
            info[selected]?.season_hours.map((item: any) => {
              return (
                <tr key={uuid()}>
                  <td>
                    {item?.start_time} ~ {item?.end_time}
                  </td>
                  <td>{addComma(item?.season_hour_amounts[0]?.amount)}원</td>
                  <td>{addComma(item?.season_hour_amounts[1]?.amount)}원</td>
                </tr>
              )
            })}
        </tbody>
      </ListTable>
      <div className="auto" style={{width: 400, marginTop: 40}}>
        <Button full label="수정하기" />
      </div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.main`
  .tab_wrap {
    justify-content: center;
    .tab {
      width: 66px;
      height: 40px;
      border-radius: 8px;
      margin: 0 4px;
      color: ${COLOR.GRAY4};
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.3px;
      border: 1px solid ${COLOR.GRAY4};
      &.active {
        color: ${COLOR.WHITE};
        background: ${COLOR.BLUE3};
      }
    }
    /* width: 400px; */
  }
`
