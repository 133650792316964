import { API_HOST } from '@/config/index';
import api from '@/lib/apis/api';
import { objectToQueryString } from '@/lib/index';
import { ChargePointModelsRequest, ChargePointRequest, Paging } from './type';
import { ReqChargerRegister, ReqEditVendor, ReqModel, ReqStationRegister, ReqVendor } from '@/types/infra';

export const getStations = async ({ payload, cpo_seq }: { payload?: Paging; cpo_seq: string }) => {
  const temp = { ...payload, size: 10, cpo_seq };
  const param = objectToQueryString(temp);
  const url = `${API_HOST}/stations?${param}`;
  return api.get({ url });
};

export const getLocations = async () => {
  const url = `${API_HOST}/stations/info/locations`;
  return api.get({ url });
};

export const getStation = async ({ stationSeq }: { stationSeq: string }) => {
  const url = `${API_HOST}/stations/${stationSeq}`;
  return api.get({ url });
};

export const getCheckNameDuplicated = async ({ name, cpo_seq }: { name: string; cpo_seq: string }) => {
  const url = `${API_HOST}/stations/${name}/is_name_duplicated?cpo_seq=${cpo_seq}`;
  return api.get({ url });
};

export const getStationTypes = async () => {
  const url = `${API_HOST}/stations/info/types`;
  return api.get({ url });
};

export const registerStation = async (payload: ReqStationRegister) => {
  const url = `${API_HOST}/stations`;

  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    if (key === 'station_open') {
      const stringifyResult = JSON.stringify(payload.station_open);
      formData.append('station_open', stringifyResult);
    } else if (payload[key as keyof ReqStationRegister] instanceof File) {
      formData.append(key, payload[key as keyof ReqStationRegister] as File);
    } else {
      formData.append(key, payload[key as keyof ReqStationRegister] as string);
    }
  });
  return api.post({ url, headers: { 'Content-Type': 'multipart/form-data' }, payload: formData });
};

export const modifyStation = async ({ seq, payload }: { seq: string; payload: ReqStationRegister }) => {
  const url = `${API_HOST}/stations/${seq}`;
  const formData = new FormData();
  const updateKeys = [
    'supplier_seq',
    'location_seq',
    'name',
    'station_type_group',
    'station_type_detail',
    'post_code',
    'address',
    'address_detail',
    'latitude',
    'longitude',
    'phone',
    'station_open',
  ];
  Object.keys(payload).forEach(key => {
    if (key === 'station_open') {
      const stringifyResult = JSON.stringify(payload.station_open);
      formData.append('station_open', stringifyResult);
    } else if (payload[key as keyof ReqStationRegister] instanceof File) {
      formData.append(key, payload[key as keyof ReqStationRegister] as File);
    } else if (payload[key as keyof ReqStationRegister] !== undefined && updateKeys.includes(key)) {
      formData.append(key, payload[key as keyof ReqStationRegister] as string);
    }
  });
  return api.patch({
    url,
    headers: { 'Content-Type': 'multipart/form-data' },
    payload: formData,
  });
};

export const removeStation = async (seq: string) => {
  const url = `${API_HOST}/stations/${seq}`;
  return api.delete({ url });
};

export const getExcelFile = async ({
  payload,
  cpo_seq,
  fileName,
}: {
  payload?: Paging;
  cpo_seq: string;
  fileName: string;
}) => {
  const temp = { ...payload, size: 10, cpo_seq };
  const param = objectToQueryString(temp);
  const url = `${API_HOST}/stations/excel?${param}`;
  return api.get({ url, responseType: 'blob', fileName });
};

export const getChargers = async (payload: ChargePointRequest) => {
  const param = objectToQueryString({ ...payload });
  const url = `${API_HOST}/chargepoints?${param}`;
  return api.get({ url });
};

export const getCharger = async (seq: string, cpo_seq: string) => {
  const url = `${API_HOST}/chargepoints/${seq}?cpo_seq=${cpo_seq}`;
  return api.get({ url });
};

export const getVendors = async (paging?: Paging) => {
  const param = objectToQueryString({ ...paging });
  const url = `${API_HOST}/vendors?${param}`;
  return api.get({ url });
};

export const getVendorSeq = async (seq: string) => {
  const url = `${API_HOST}/vendors/seq/${seq}`;
  return api.get({ url });
};

export const getModels = async (payload?: ChargePointModelsRequest) => {
  const param = objectToQueryString({ ...payload });
  const url = `${API_HOST}/chargepoint-models?${param}`;
  return api.get({ url });
};

export const getModel = async (seq: string) => {
  const url = `${API_HOST}/chargepoint-models/${seq}`;
  return api.get({ url });
};

export const getCheckChargepointNameDuplicated = async ({
  name,
  station_seq,
}: {
  name: string;
  station_seq: number;
}) => {
  const url = `${API_HOST}/chargepoints/names?name=${name}&station_seq=${station_seq}`;
  return api.get({ url });
};

export const registerCharger = async (payload: ReqChargerRegister) => {
  const url = `${API_HOST}/chargepoints`;
  return api.post({ url, payload });
};

export const patchCharger = async ({ payload, seq }: { payload: ReqChargerRegister; seq: string }) => {
  const url = `${API_HOST}/chargepoints/${seq}`;
  return api.patch({ url, payload });
};

export const getCountries = async () => {
  const url = `${API_HOST}/commons/countries?view_type=list`;
  return api.get({ url });
};

export const getChargepointsExcelFile = async (payload: ChargePointRequest, fileName: string) => {
  const param = objectToQueryString({ ...payload });
  const url = `${API_HOST}/chargepoints/list/excel?${param}`;
  return api.get({ url, responseType: 'blob', fileName });
};

export const removeCharger = async (seq: string) => {
  const url = `${API_HOST}/chargepoints/${seq}`;
  return api.delete({ url });
};

export const getCheckVendorNameDuplicated = async ({ name, cpo_seq }: { name: string; cpo_seq: string }) => {
  const url = `${API_HOST}/vendors/names?name=${name}&cpo_seq=${cpo_seq}`;
  return api.get({ url });
};
export const getCheckVendorIdDuplicated = async ({ vendor_id, cpo_seq }: { vendor_id: string; cpo_seq: string }) => {
  const url = `${API_HOST}/vendors/ids?vendor_id=${vendor_id}&cpo_seq=${cpo_seq}`;
  return api.get({ url });
};

export const getModelCpsName = async (name: string) => {
  const url = `${API_HOST}/chargepoint-models/duplicated/name?name=${name}`;
  return api.get({ url }); // return type boolean false = 등록할 수 없음
};

export const registerVendor = async (payload: ReqVendor) => {
  const url = `${API_HOST}/vendors`;
  return api.post({ url, payload });
};

export const editVendor = async (seq: string, payload: ReqEditVendor) => {
  const url = `${API_HOST}/vendors/${seq}`;
  return api.put({ url, payload }); // return type boolean
};

export const removeVendor = async (seq: string, cpo_seq: string) => {
  const url = `${API_HOST}/vendors/${seq}?cpo_seq=${cpo_seq}`;
  return api.delete({ url });
};

export const registerModel = async (payload: ReqModel) => {
  const url = `${API_HOST}/chargepoint-models`;
  return api.post({ url, payload });
};

export const editModel = async (payload: ReqModel, seq: string) => {
  const url = `${API_HOST}/chargepoint-models/${seq}`;
  return api.put({ url, payload });
};

export const removeModel = async (seq: string) => {
  const url = `${API_HOST}/chargepoint-models/${seq}`;
  return api.delete({ url });
};

export const getVendorsExcelFile = async (paging: Paging, fileName: string) => {
  const param = objectToQueryString({ ...paging });
  const url = `${API_HOST}/vendors/download/excel?${param}`;
  return api.get({ url, responseType: 'blob', fileName });
};

export const getModelsExcelFile = async (paging: Paging, fileName: string) => {
  const param = objectToQueryString({ ...paging });
  const url = `${API_HOST}/chargepoint-models/download/excel?${param}`;
  return api.get({ url, responseType: 'blob', fileName });
};

export const getErrorCodeList = async (paging: Paging) => {
  const param = objectToQueryString({ ...paging });
  const url = `${API_HOST}/trouble-code/cpo?${param}`;
  return api.get({ url });
};

export const getErrorCodeExcelList = async (search: string) => {
  const url = `${API_HOST}/trouble-code/cpo/excel?search=${search}`;
  return api.get({ url });
};
