/**
 * @name 급속,완속뱃지반환
 * @description
 */
import Badge from '@/components/atoms/Badge';

type Props = {
  type: string
  onClick?: () => void
}
export default function ChargeType({type}: Props) {
  // hooks
  const isFast = type === 'FAST' ? true : false
  //*--------------------------------------------------*
  return <Badge mode={`${isFast ? 'Secondary1' : 'Warning1'}`} label={`${isFast ? '급속' : '완속'}`} />
}
/********************************************************
[사용법]
    <ChargeType type={item?.model?.charge_type} />
*********************************************************/
