import React from 'react';
import { Div, Events, H3, Row } from 'lib';
import { useAppDispatch } from 'model';
import { MENU } from '@/pages/router';
import { ListTitleSearch, ButtonAddDownload, ButtonsModifyDel, BackButton } from 'components';
import { icon_signal_white } from 'assets/images';
import { useTranslation } from 'react-i18next';
import { Store, _action, _event, _mode } from '../../index';
import Search from './search';
import FilterButton from '@/components/atoms/Buttons/FilterButton';
/**
 * @name Header
 * @description
 */
export default function Contents() {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const { mode, list, view, search_payload } = Store();
  const setContents = () => {
    switch (mode) {
      case _mode.DEFAULT: // ---------------------------기본
      case _mode.LIST: // ------------------------------리스트
        return (
          <div style={{ width: '100%' }}>
            <Div style={{ width: '100%' }}>
              <Row>
                <ListTitleSearch
                  title={t('pages.operation.status')}
                  total={list?.meta?.paging?.total_count ?? 0}
                  placeholder={t('pages.operation.searchPlaceholder')}
                  onChange={str => {
                    // 빈값을때 리스트를 다시 불러온다.
                    if (str === '') Events.emit(_event.FETCH_LIST, { search: str });
                  }}
                  onSubmit={str => {
                    Events.emit(_event.FETCH_LIST, { search: str });
                  }}
                />
                <FilterButton
                  style={{ marginLeft: 8 }}
                  onChange={(bool: boolean) => {
                    dispatch(_action.setSearchPayload({ isOpen: bool }));
                    // console.log(bool)
                  }}
                />
              </Row>
              <ButtonAddDownload
                icon={icon_signal_white}
                addLabel={t('common.buttons.multiControl')}
                addRoute={`${MENU.DEVICE_STATUS}/mctrl`}
                onDownload={() => {
                  alert('download11');
                }}
              />
            </Div>
            {/* 검색상세화면 */}
            {search_payload.isOpen && <Search />}
          </div>
        );
      case _mode.VIEW: // ------------------------------상세
        return (
          <Div style={{ width: '100%' }}>
            <BackButton label={view?.data?.name} />
            <ButtonsModifyDel
              onModify={() => {
                dispatch(_action.setMode(_mode.MODIFY));
              }}
              onDelete={() => {
                // Events.emit(_event.POPUP_SHOW, _popup.STATION_REMOVE, {title: 'test'})
              }}
            />
          </Div>
        );
      case _mode.MODIFY: // ----------------------------수정
        return <BackButton label={view?.data?.name} />;
      case _mode.MULTI_CONTROLLER: // --------------------------등록
        return <BackButton label={t('common.buttons.runRemoteControl')} />;
      default:
        return <div>{t('common.none')}</div>;
    }
  };
  return <header className="inc_header">{setContents()}</header>;
}
