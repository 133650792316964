import styled from 'styled-components';
import { StatusImagesPath } from '@/components/atoms/Buttons/Buttons.types';

export const ImageBox = styled.div<{ $default: any }>`
  background-image: url('${props => props.$default}');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  width: 24px;
  height: 24px;
`;

export const Button = styled.button<StatusImagesPath>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
    }

    &:hover ${ImageBox} {
      background-image: url('${props => props.$hover}');
    }
  }
  &:focus {
  }
  &:active {
  }
  &:focus ${ImageBox} {
    background-image: url('${props => props.$focus}');
  }
  &:active ${ImageBox} {
    background-image: url('${props => props.$focus}');
  }
`;
