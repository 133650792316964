import React from 'react';
import { useTranslation } from 'react-i18next';
import { App, BackButton, ButtonsModifyDel } from 'components';
import styled from 'styled-components';
import { useAppSelector } from '@/model/index';
import { COLOR } from '@/styles/index';
import { PopupContainer, PopupCreate, SubmitCancel } from '@/components/form';
import ModelRegisterForm from '@/components/organisms/Forms/ModelRegisterForm';

/**
 * @name 모델관리 상세
 */
export default function ModelDetailPresenter({
  onEdit,
  deletePopupVisible,
  handleDeletePopup,
  handleBackToList,
  onDeleteConfirm,
}: {
  onEdit: () => void;
  deletePopupVisible: boolean;
  handleDeletePopup: (value: boolean) => void;
  handleBackToList: () => void;
  onDeleteConfirm: () => void;
}) {
  const { t } = useTranslation('common');
  const { view } = useAppSelector(state => state.model);

  return (
    <App>
      <HeaderWrapper>
        <BackButton label={view.name} onClick={handleBackToList} />
        <ButtonsModifyDel onModify={onEdit} onDelete={() => handleDeletePopup(true)} />
      </HeaderWrapper>
      <Wrapper>
        <main>
          <Content>
            <ModelRegisterForm />
          </Content>
        </main>
      </Wrapper>
      <PopupContainer onClose={() => handleDeletePopup(false)} visible={deletePopupVisible}>
        <PopupCreate
          warning
          title={t('common.toast.removeVendor')}
          message={t('common.toast.askRemoveVendor')}
          footer={
            <SubmitCancel
              confirmLabel={t('common.buttons.removeAction')}
              onCancel={() => handleDeletePopup(false)}
              onConfirm={onDeleteConfirm}
            />
          }
        />
      </PopupContainer>
    </App>
  );
}

const HeaderWrapper = styled.div`
  // styles
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  align-items: center;
  justify-content: space-between;
`;
const Wrapper = styled.div`
  header {
    width: 674px;
    margin: 24px auto;
  }
  main {
    width: 900px;
    margin: 24px auto;
  }
`;
const Content = styled.div`
  padding-bottom: 30px;
`;
