import React from 'react';
import styled from 'styled-components';
import { App } from 'components';
import DashboardFilterControl from '@/components/organisms/Cards/DashboardFilterControl';
import HourlySalesChart from '@/components/organisms/Cards/HourlySalesChart';
import TotalSales from '@/components/organisms/Cards/TotalSales';
// import ActiveUsers from '@/components/organisms/Cards/ActiveUsers';
import ChargerUsageCountStats from '@/components/organisms/Cards/ChargerUsageCountStats';
import FaultCountStats from '@/components/organisms/Cards/FaultCountStats';
import PeakSalesTimeStats from '@/components/organisms/Cards/PeakSalesTimeStats';
import SalesByStationCategory from '@/components/organisms/Cards/SalesByStationCategory';
import RealTimeFaultStatus from '@/components/organisms/Cards/RealTimeFaultStatus';
// import TopChargingStations from '@/components/organisms/Cards/TopChargingStations/TopChargingStations';

/**
 * @name 대시보드
 */
export default function DashboardPresenter() {
  return (
    <App empty>
      <Content>
        <BodyWrapper>
          {/* {debug(period)} */}
          {/* {debug(widget?.type?.src_data?.data)} */}
          {/* 통계유형,선택날짜,충전소선택 */}
          <DashboardFilterControl />
          {/* 총매출 */}
          <HourlySalesChart />
          {/* 매출총액,이용자수,이용획수,등 */}
          <WidgetContainer>
            <WidgetContainerInnerWrapper>
              <TotalSales />
              {/* <ActiveUsers /> */}
              <ChargerUsageCountStats />
              <FaultCountStats />
              {/* <PeakSalesTimeStats /> */}
              {/* <SalesByStationCategory /> */}
            </WidgetContainerInnerWrapper>
          </WidgetContainer>
        </BodyWrapper>
        <Aside>
          <RealTimeFaultStatus />
          {/* <TopChargingStations /> */}
        </Aside>
      </Content>
    </App>
  );
}
const Content = styled.div`
  display: flex;
  margin-top: 16px;
  padding: 0 24px;
  flex-direction: row;
`;

const BodyWrapper = styled.div`
  flex: 1;
`;

const Aside = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  @media all and (min-width: 0) and (max-width: 900px) {
    display: none;
  }
`;

const WidgetContainer = styled.div`
  width: 100%;
  > .title {
    display: block;
    margin-left: 24px;
    margin-bottom: 16px;
  }
`;

const WidgetContainerInnerWrapper = styled.div`
  display: grid;
  /* margin: 0 24px; */
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 24px;
  @media all and (min-width: 0) and (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
