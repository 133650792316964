/**
 * @name Container
 * @description
 */
import {useCallback, useEffect} from 'react'
import {API_HOST} from 'config'
import {useAppDispatch} from 'model'
import {Events, objectToQueryString, useAxios, useContext, useRoutes} from 'lib'
import {api_fail} from 'pages/common'
import {Paging} from 'types'
import {_action, _event, _mode, _popup} from '.'
// contents
import Presenter from './presenter'
import { useLocation } from 'react-router';

export default function Container() {
  // const
  // hooks
  const {params, cpo_seq} = useContext()
  const { state } = useLocation();
  const {get, form} = useAxios()
  const {navigate} = useRoutes()
  const dispatch = useAppDispatch()
  //*--------------------------------------------------*
  const onInit = useCallback(() => {
    dispatch(_action.setInit())
    fetch_list({
      // page_num: 1,
    })
  }, [dispatch])

  // 생성하기
  async function onCreate(payload: any) {
    const res = await form(`${API_HOST}/stations`, payload)
    if (res?.isSuccess) {
      onInit()
      fetch_list()
      dispatch(_action.setInit())
      Events.emit(_event.POPUP_SHOW, _popup.REGISTER_SUCCESS)
    } else {
      Events.emit(_event.POPUP_SHOW, _popup.REGISTER_FAIL)
    }
  }
  // 리스트보기
  async function fetch_list(payload?: Paging) {
    const temp = Object.assign({}, payload, {per: 10})
    const _payload = objectToQueryString(temp)
    const res = await get(`${API_HOST}/business/${cpo_seq}/tariff/stations?${_payload}`)
    if (res?.isSuccess) {
      dispatch(_action.setList(res))
    }
  }
  // 상세보기
  async function fetch_view(seq: number) {
    const res = await get(`${API_HOST}/business/${cpo_seq}/tariff/stations/${seq}`)
    if (res?.isSuccess) {
      // dispatch(_action.setView(res))
      dispatch(_action.setMode(_mode.VIEW))
    } else {
      api_fail(res)
    }
  }

  //*--------------------------------------------------*
  useEffect(() => {
    if (params?.id !== undefined) {
      // 생성페이지
      if (params?.id === 'add') {
        // dispatch(_action.setView(null)) // 등록페이지, 상세페이지 초기화
        dispatch(_action.setMode(_mode.REGISTER))
        return
      }
      fetch_view(params?.id) // 상세페이지
    } else {
      onInit()
    }
  }, [params, state])

  // Events
  useEffect(() => {
    // addListener
    Events.addListener(_event.FETCH_LIST, fetch_list)
    Events.addListener(_event.FETCH_VIEW, fetch_view)
    Events.addListener(_event.SUBMIT_CREATE, onCreate)
    return () => {
      // removeListener
      Events.removeListener(_event.FETCH_LIST, fetch_list)
      Events.removeListener(_event.FETCH_VIEW, fetch_view)
      Events.removeListener(_event.SUBMIT_CREATE, onCreate)
    }
  }, [])

  return <Presenter />
}
