/**
 * @name Input
 * @description 입력창
 */
import React from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'

type Props = {
  children?: React.ReactNode //--------------------Children
  label?: string //------------------------------- Text
  style?: React.CSSProperties //------------------ StyleSheet
  placeholder?: string //------------------------- placeholder
  onSubmit?: (str: string) => void //------------- onSubmit
  setValue?: (str: string) => void //------------- setValue
  onChange?: (event: string) => void //----------- onChange Event
  [key: string]: any
}
export default function Input({children, onChange, setValue, onSubmit, label, ...props}: Props) {
  return (
    <Element
      {...props}
      onChange={event => {
        !!setValue && setValue(event.target.value)
        !!onChange && onChange(event.target.value)
      }}
      onKeyUp={event => {
        // ENTER키적용
        if (event.key === 'Enter') {
          !!onSubmit && onSubmit(event.currentTarget.value)
        }
      }}
    />
  )
}
//*--------------------------------------------------*
const Element = styled.input`
  /* input클래스적용시 기본스타일작동 */
  &.input {
    display: flex;
    min-width: 300px;
    width: 100%;
    height: 40px;
    padding: 0 16px;
    border-radius: 8px;
    border: 1px solid ${COLOR.GRAY4};
    outline: 0;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.1px;
    /* 마우스호버 */
    &:hover {
      border-color: ${COLOR.GRAY1};
    }
    /* 포커스인 */
    &:focus {
      border-color: ${COLOR.BLUE3};
      outline: 0;
      outline-color: ${COLOR.BLUE3};
    }
    /* 비활성화 */
    &:disabled {
      color: ${COLOR.GRAY3};
      background-color: ${COLOR.GRAY6};
    }
    /* 유효성검사 */
    &.invalid {
      border-color: ${COLOR.RED1} !important;
    }
    /* 플레이스홀더 */
    &::placeholder {
      color: ${COLOR.GRAY2};
    }
  }
`
/********************************************************
[사용법]
<Input
  className="input invalid" 
  disabled
  defaultValue={search}
  setValue={setSearch}
  onChange={str => { console.log(str) }}
  placeholder="충전소 ID 또는 이름을 검색해주세요."
  onSubmit={onSubmit}
/>

<input
  disabled
  type="text"
  name="patternValue"
  value={inputVal}
  onKeyDown={event => {
    const {key} = event.nativeEvent
    console.log(key)
  }}
  onFocus={({target}) => {
    console.log(target.value)
  }}
  onBlur={({target}) => {
    console.log(target.value)
  }}
  onChange={event => {
    const {value} = event.target
    // const number = /^[0-9]*$/ //------------------------ 숫자만입력
    const engNum = /^[a-zA-Z0-9]*$/ //------------------ 영문,숫자만입력
    // const email = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+$/ //------ 이메일
    // const phone = /^\d{2,3}-\d{3,4}-\d{4}$/ //---------- 전화번호
    console.log(engNum.test(value))
    return engNum.test(value) && setInputVal(value)
  }}
/>
*********************************************************/
