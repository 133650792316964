import { SESSION_STORAGE } from 'lib';
import { authAction, dashboardAction, gnbAction } from 'model/modules';
import { CPO, ISignInType, ResLogin, SessionStorageUserInfo } from './type';
import { gnbMenu, login, stations } from './api';
import { IResponse } from '@/lib/apis/api/type';
import { IMenu } from '@/types/menu';
import { getCountries, getLocations } from '@/lib/apis/infra';
import { setLocations, setCountries } from '@/model/modules/location';
import { ICountry, ILocation } from '@/types/location';

/**
 * @name 로그인 실행
 */
export async function signIn({ payload, dispatch, type }: ISignInType) {
  try {
    const loginResponse: IResponse = await login(payload);
    console.log('login:::::', loginResponse);
    if (!loginResponse?.isSuccess) {
      return { status: 'fail', message: '로그인실패', code: loginResponse.error.errors?.[0]?.code ?? 'none' };
    }
    // 정보입력이 안됐을 경우
    if (loginResponse.data.role.code === 'CPO' && loginResponse.data.admin_cpo_list.length === 0) {
      return { status: 'info', message: 'cpo 정보 입력하는 화면으로 이동' };
    }

    const locationResponse: IResponse<ILocation[]> = await getLocations();
    if (locationResponse.isSuccess) {
      const { data } = locationResponse;
      setSessionLocations(data);
      dispatch(setLocations(data));
    }

    const countriesResponse: IResponse<ICountry[]> = await getCountries();
    if (countriesResponse.isSuccess) {
      const { data } = countriesResponse;
      setSessionCountries(data);
      dispatch(setCountries(data));
    }

    const { data }: { data: ResLogin } = loginResponse;
    if (data?.admin_cpo_list?.length === 0 && data?.roles?.code === 'CPO') {
      dispatch(authAction.setUser(data));
      return { status: 'info', message: 'cpo 정보 입력하는 화면으로 이동' };
    }
    // 비즈니스정보
    const business: CPO = data?.admin_cpo_list?.[0]?.cpo;
    dispatch(authAction.setUser(data));

    // 로그인 성공 이후 로직
    const sessions = await signInComplete({
      token: data.tokens.access_token,
      cpo_seq: business?.seq,
      business_code: business?.code,
      email: data.email,
    });

    if (!sessions) {
      return { status: 'fail', message: '세션스토리지에 저장실패입니다.' };
    }

    const token = sessionStorage.getItem(SESSION_STORAGE.TOKEN);
    if (!token) {
      return { status: 'fail', message: '세션스토리지에 토큰정보가 없습니다.' };
    }

    // GNB 메뉴설정
    // user.role.code === "ADMIN" 경우 cpo를 생성하지 않기 떄문에 admin_seq로 찾음
    // 서버에서도 cpo_seq로 사용하고 있지 않습니다.
    const gnbResponse = await gnbMenu(business?.code === '' ? 'code' : business?.code);
    if (gnbResponse?.isSuccess) {
      setUserMenu(gnbResponse.data);
      dispatch(gnbAction.setMenu(gnbResponse.data));
    }

    // 지역별충전소목록
    const stationsResponse = await stations();
    if (stationsResponse?.isSuccess) {
      dispatch(dashboardAction.setFilterStations(stationsResponse?.data));
    }

    // 충전소지도정보사전취득 (통계유형및, 날짜조건에 맞춰서 랜더링 필요)
    // TODO 2024 02 21 stations 테이블에 위도,경도 컬럼이 존재함. 위의 stations API에서 한번에 처리할 수 있을 것으로 보임
    // TODO 작업 후 삭제하자
    // const map = await ajax(`${API}/dashboards/stations/map?business_code=${business?.code}`);
    // if (map?.isSuccess) {
    //   payload?.dispatch(dashboardAction.setMapStations(map?.data));
    // }
    if (type === 'refresh') return { status: 'ok', mode: process.env.NODE_ENV, message: 'REFRESH 정상' };
    return {
      status: 'ok',
      message: '정상.',
      mode: process.env.NODE_ENV,
    };
  } catch (error) {
    console.log(error);
    if (typeof error === 'object') {
      const response = error as unknown as IResponse;
      const code = 'none';
      return { status: 'fail', message: '로그인 실패', code };
    }
    return { status: 'fail', message: '로그인 실패' };
  }
}

/**
 * @name 로그인성공이후로직
 * @description 세션스토리지에 로그인정보를 저장한다.
 */
export async function signInComplete(payload: SessionStorageUserInfo) {
  console.log(
    `%csessionStorage settings`,
    `display:block;width:100%;padding:2px 5px;font-size:12px;background-color:tomato;color:black;`,
  );
  // 세션스토리지설정
  sessionStorage.setItem(SESSION_STORAGE.EMAIL, payload.email);
  sessionStorage.setItem(SESSION_STORAGE.CPO_SEQ, `${payload.cpo_seq}`);
  sessionStorage.setItem(SESSION_STORAGE.BUSINESS_CODE, payload.business_code);
  if (payload.token) {
    sessionStorage.setItem(SESSION_STORAGE.TOKEN, payload.token);
  }
  return true;
}

export function setUserMenu(menu: IMenu[]) {
  sessionStorage.setItem(SESSION_STORAGE.USER_MENU, JSON.stringify(menu));
}

const setSessionLocations = (locations: ILocation[]) => {
  sessionStorage.setItem(SESSION_STORAGE.LOCATION, JSON.stringify(locations));
};

const setSessionCountries = (countries: ICountry[]) => {
  sessionStorage.setItem(SESSION_STORAGE.COUNTRIES, JSON.stringify(countries));
};

/**
 * @name 로그아웃성공이후로직
 * @description 세션스토리지에 초기화한다.
 */
export function signOut(payload?: any) {
  sessionStorage.clear();
}
