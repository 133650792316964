/**
 * @name Content
 * @description
 */
import {Button, Events} from 'lib'
import styled from 'styled-components'
import axios from 'axios'
import {API_HOST} from 'config'

export default function Contents() {
  // hooks
  async function fetch() {
    const api = `${API_HOST}/auth/login`
    // const url = 'http://211.49.126.109:9999/api/v1/user/login'
    const res = await axios({
      method: 'get',
      url: api,
      headers: {
        'Content-Type': 'application/json',
        withCredentialsZ: true,
      },
    })
    // console.log(res?.data)
  }
  //*--------------------------------------------------*
  return (
    <Content>
      <div className="wrap">
        <h1>axios</h1>
        <Button label="axios" onClick={() => fetch()} />
      </div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /* <!--wrap--> */
  .wrap {
  }
`
