import React, { useEffect } from 'react';
import { App, BackButton, ButtonsModifyDel } from 'components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'model';
import { Button } from 'lib';
import { COLOR } from '@/styles/index';
import typography from '@/styles/typography';
import { PopupContainer, PopupCreate, SubmitCancel, TextFiledGroup, TextInput } from '@/components/form';
import { displayPrice } from '@/lib/util/displayData';
import Badge from '@/components/atoms/Badge';
import { SCREEN_MODE } from '@/types/mode';
import { actions } from '@/model/modules/price';

export default function ChargingRateRoamingDetailPresenter({
  handleBackToList,
  handleToUpdate,
  handleDeletePopup,
  deletePopupVisible,
  onDeleteConfirm,
}: {
  handleBackToList: () => void;
  handleToUpdate: () => void;
  handleDeletePopup: (value: boolean) => void;
  deletePopupVisible: boolean;
  onDeleteConfirm: () => void;
}) {
  const { t } = useTranslation('common');
  const { view, mode, payload, roamingPayload, roamingView } = useAppSelector(state => state.price);
  const isModify = mode === SCREEN_MODE.MODIFY;
  const dispatch = useAppDispatch();
  // useEffect(() => {
  //   console.log(view);
  // }, []);

  return (
    <App>
      <HeaderWrapper>
        <BackButton label={t('pages.price.roamingPrice.roamingDetail')} onClick={handleBackToList} />
        <ButtonsModifyDel onModify={handleToUpdate} onDelete={() => handleDeletePopup(true)} />
      </HeaderWrapper>
      <ContentWrapper>
        <Content>
          <TextFiledGroup
            label={t('pages.price.roamingPrice.roamingDetail')}
            component={
              <>
                <Row>
                  <TableHeader>{t('pages.price.roamingPrice.roamingCode')}</TableHeader>
                  <TableHeader>{t('pages.price.roamingPrice.roamingCompanyName')}</TableHeader>
                  <TableHeader>{t('pages.price.roamingPrice.startKW')}</TableHeader>
                  <TableHeader>{t('pages.price.roamingPrice.endKW')}</TableHeader>
                  <TableHeader>
                    {t('pages.price.roamingPrice.price')}
                    {t('common.unit.brackets.priceWonBykWh')}
                  </TableHeader>
                </Row>
                <Row>
                  {/* TODO Roaming roamingView에 있는 값으로 세팅해주자. */}
                  <Cell>{roamingPayload.roaming_code}</Cell>
                  <Cell>{roamingPayload.roaming_name}</Cell>
                  <Cell>{roamingPayload.start_kw}</Cell>
                  <Cell>{roamingPayload.end_kw}</Cell>
                  <Cell>{roamingPayload.price}</Cell>
                </Row>
              </>
            }
          />
        </Content>
      </ContentWrapper>
      <PopupContainer onClose={() => handleDeletePopup(false)} visible={deletePopupVisible}>
        <PopupCreate
          warning
          title={t('common.toast.removeRoaming')}
          message={t('common.toast.askRemoveRoaming')}
          footer={
            <SubmitCancel
              confirmLabel={t('common.buttons.removeAction')}
              onCancel={() => handleDeletePopup(false)}
              onConfirm={onDeleteConfirm}
            />
          }
        />
      </PopupContainer>
    </App>
  );
}

const HeaderWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  align-items: center;
  justify-content: space-between;
`;

export const ContentWrapper = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 680px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const TableHeader = styled.div`
  flex: 1;
  padding: 14px 8px;
  background-color: ${COLOR.BLUE8};
  border-bottom: 1px solid ${COLOR.BLUE_GRAY9};
  text-align: center;
  ${typography.headline6};
  color: ${COLOR.GRAY1};
`;
export const Cell = styled.div`
  padding: 16px 8px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  flex: 1;
  text-align: center;
  ${typography.body4};
  color: ${COLOR.BLACK3};
  display: flex;
  align-items: center;
  justify-content: center;
`;
