import { H3, Row, Text, Button } from 'lib';
import { App } from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { COLOR } from '@/styles/index';
import { icon_edit_white, icon_plus_white } from '@/assets/images';
import { PopupContainer, PopupCreate, SubmitCancel, TextFiledSearch } from '@/components/form';
import DownloadButton from '@/components/atoms/Buttons/DownloadButton';
import ErrorCodeTable from '@/components/organisms/Tables/ErrorCodeTable';
import { useAppSelector } from '@/model/index';

export default function ErrorCodeListPresenter({
  itemsPerPage,
  currentPage,
  pageHandler,
  handleExcelDownload,
  handleSearchKeyword,
  handleSearch,
  searchKeyword,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  handleExcelDownload: () => void;
  handleSearchKeyword: (keyword: string) => void;
  handleSearch: () => void;
  searchKeyword: string;
}) {
  const { t } = useTranslation('common');
  const { totalCount } = useAppSelector(state => state.errorcode);
  return (
    <App>
      <Header style={{ width: '100%' }}>
        <HeaderFuncWrapper>
          <Row>
            <div style={{ marginRight: 20 }}>
              <H3 color={COLOR.BLACK3}>{t('forms.history.failedCodeStatus')}</H3>
              <Text color={COLOR.BLACK3} className="body6">
                {`${totalCount}${t('common.unit.cases')}`}
              </Text>
            </div>
            <div className="search" style={{ marginLeft: 20 }}>
              <TextFiledSearch
                defaultValue={searchKeyword}
                placeholder={t('forms.history.searchByManufacturer')}
                onChange={str => {
                  handleSearchKeyword(str);
                }}
                onSubmit={handleSearch}
              />
            </div>
          </Row>
          <ButtonWrapper>
            <Button
              label={t('forms.history.uploadFailedHistory')}
              style={{ marginRight: 16, backgroundColor: 'white', color: COLOR.BLACK3 }}
            />
            <Button
              icon={icon_plus_white}
              label={t('forms.charger.registerErrorCode')}
              style={{ marginRight: 16 }}
              // onClick={onRegister}
            />
            <DownloadButton onClick={handleExcelDownload} />
          </ButtonWrapper>
        </HeaderFuncWrapper>
      </Header>
      <ErrorCodeTable itemsPerPage={itemsPerPage} currentPage={currentPage} pageHandler={pageHandler} />
    </App>
  );
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${COLOR.GRAY5};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderFuncWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 16px 16px 16px 24px;
  align-items: center;
  justify-content: space-between;
`;
const FilterWrapper = styled.div`
  padding: 8px 32px;
`;
