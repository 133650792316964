import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableHead, TH, TR, Wrapper } from './OpensTable.style';
import { RootState, useAppDispatch, useAppSelector } from '@/model/index';
import { DayCode, IOpens } from '@/types/infra';
import OpenInfoRow from '@/components/molecules/RadioRows/OpenInfo';
import { actions } from '@/model/modules/station';

const defaultOpensData = {
  MON: { day_code: 'MON', is_off: false, open_time: '00:00:00', close_time: '24:00:00', memo: '' },
  TUE: { day_code: 'TUE', is_off: false, open_time: '00:00:00', close_time: '24:00:00', memo: '' },
  WED: { day_code: 'WED', is_off: false, open_time: '00:00:00', close_time: '24:00:00', memo: '' },
  THU: { day_code: 'THU', is_off: false, open_time: '00:00:00', close_time: '24:00:00', memo: '' },
  FRI: { day_code: 'FRI', is_off: false, open_time: '00:00:00', close_time: '24:00:00', memo: '' },
  SAT: { day_code: 'SAT', is_off: false, open_time: '00:00:00', close_time: '24:00:00', memo: '' },
  SUN: { day_code: 'SUN', is_off: false, open_time: '00:00:00', close_time: '24:00:00', memo: '' },
};
function OpensTable({ isModify }: { isModify: boolean }) {
  const { t } = useTranslation('common');
  const station = useAppSelector((state: RootState) => state.station);
  const view = useAppSelector((state: RootState) => state.station.view);
  const [opensInfo, setOpensInfo] = useState(defaultOpensData);
  const dispatch = useAppDispatch();

  const onChangeInfo = (dayCode: DayCode, editKey: string, value: string | boolean) => {
    const newOpensInfo = {
      ...opensInfo,
      [dayCode]: {
        ...opensInfo[dayCode],
        [editKey]: value,
      },
    };
    setOpensInfo(newOpensInfo);
    dispatch(
      actions.setPayloadStationOpen({
        ...station.payload.opens,
        ...newOpensInfo,
      }),
    );
  };

  useEffect(() => {
    view.opens.map((element: IOpens) =>
      setOpensInfo(prevState => ({
        ...prevState,
        [element.day_code]: element,
      })),
    );
  }, [view]);

  return (
    <Wrapper>
      <Table>
        <colgroup>
          <col style={{ width: 50 }} />
          <col style={{ minWidth: 180 }} />
          <col style={{ minWidth: 100 }} />
          <col style={{ minWidth: 100 }} />
          <col style={{ minWidth: 380 }} />
        </colgroup>
        <TableHead>
          <TR>
            <TH>{t('forms.station.day')}</TH>
            <TH>{t('forms.station.operationStatus')}</TH>
            <TH>{t('forms.station.startTime')}</TH>
            <TH>{t('forms.station.endTime')}</TH>
            <TH>{t('forms.station.additionalOperationTimeInformation')}</TH>
          </TR>
        </TableHead>
        <TableBody>
          <OpenInfoRow
            editMode={isModify}
            item={opensInfo.MON}
            onChangeValue={(editKey, value) => onChangeInfo('MON', editKey, value)}
          />
          <OpenInfoRow
            editMode={isModify}
            item={opensInfo.TUE}
            onChangeValue={(editKey, value) => onChangeInfo('TUE', editKey, value)}
          />
          <OpenInfoRow
            editMode={isModify}
            item={opensInfo.WED}
            onChangeValue={(editKey, value) => onChangeInfo('WED', editKey, value)}
          />
          <OpenInfoRow
            editMode={isModify}
            item={opensInfo.THU}
            onChangeValue={(editKey, value) => onChangeInfo('THU', editKey, value)}
          />
          <OpenInfoRow
            editMode={isModify}
            item={opensInfo.FRI}
            onChangeValue={(editKey, value) => onChangeInfo('FRI', editKey, value)}
          />
          <OpenInfoRow
            editMode={isModify}
            item={opensInfo.SAT}
            onChangeValue={(editKey, value) => onChangeInfo('SAT', editKey, value)}
          />
          <OpenInfoRow
            editMode={isModify}
            item={opensInfo.SUN}
            onChangeValue={(editKey, value) => onChangeInfo('SUN', editKey, value)}
          />
        </TableBody>
      </Table>
    </Wrapper>
  );
}

export default OpensTable;
