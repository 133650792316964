/**
 * @name 충전기기본정보
 * @description
 * @todo 장애이력
 */
import styled from 'styled-components'
import {H5, debug} from 'lib'
import {COLOR} from 'styles'
import {Store} from '../..'
import {formatDay} from 'pages/common'

export default function Contents() {
  // const
  const {view} = Store()

  //*--------------------------------------------------*
  return (
    <Content>
      <div style={{marginBottom: 5}}>
        <H5 color={COLOR.GRAY1}>충전기 기본정보</H5>
      </div>
      <article>
        <dl>
          <dt>장애 고유번호</dt>
          <dd>{view?.data?.chargepoint_code}</dd>
        </dl>
        <dl>
          <dt>장애발생 충전소</dt>
          <dd>{view?.data?.chargepoint?.station?.name}</dd>
        </dl>
        <dl>
          <dt>장애발생 충전기</dt>
          <dd>{view?.data?.chargepoint?.name}</dd>
        </dl>
        <dl>
          <dt>장애 대상</dt>
          <dd>{view?.data?.chargepoint_code}</dd>
        </dl>
        <dl>
          <dt>장애 대상 ID</dt>
          <dd>{view?.data?.chargepoint?.code}</dd>
        </dl>
        <dl>
          <dt>OCPP 장애명</dt>
          <dd>{view?.data?.remote?.seq}</dd>
        </dl>
        <dl>
          <dt>제조사 장애코드</dt>
          <dd>{view?.data?.remote?.types}</dd>
        </dl>
        <dl>
          <dt>장애 분류</dt>
          <dd>{view?.data?.remote?.types}</dd>
        </dl>
        <dl>
          <dt>제조사 장애명</dt>
          <dd>{view?.data?.remote?.types}</dd>
        </dl>
        <dl>
          <dt>설명</dt>
          <dd>{view?.data?.status + ''}</dd>
        </dl>
        <dl>
          <dt>조치방법</dt>
          <dd>{view?.data?.stop_reason + ''}</dd>
        </dl>
        <dl>
          <dt>Level</dt>
          <dd>{view?.data?.connector?.types}</dd>
        </dl>
      </article>
      {/* {debug(view?.data)} */}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  dl {
    display: flex;
    flex-direction: row;
    margin-bottom:2px;
    border-bottom:1px solid ${COLOR.GRAY5};
    /* common */
    .right {
      justify-content: flex-end;
      align-items: flex-end;
    }
    dt,
    dd {
      display: flex;
      /* border: 1px solid ${COLOR.GRAY3}; */
      /* margin-top: -1px; */
      /* margin-left: -1px; */
      padding-top:14px;
      padding-bottom:14px;
    }
    dt {
      width:140px;
      padding-left:20px;
      justify-content: flex-start
      align-items: center;
      font-size: 14px;
      background-color: ${COLOR.BLUE8};
    }
    dd {
      width:100%;
      align-items: center;
      justify-content: center;
    }
  }
`
