/**
 * @name 폼입력
 * @description
 */
import { ChangeEvent } from 'react';
import { useAppDispatch } from 'model';
import { ListKeyValue, TextFiledLabel } from 'components/form';
import { Store, _action } from '../../index';
import { DropDown } from 'lib';

export default function Contents() {
  // hooks
  const { view } = Store();
  const dispatch = useAppDispatch();
  const info = [
    { label: '고정단가 (전 회원 공통)', value: 'num1' },
    { label: '계시별단가 (회원) + 고정단가 (비회원)', value: 'num1' },
    { label: '개별단가 (전 회원 공통)', value: 'num1' },
  ];
  //*--------------------------------------------------*
  // onChangeHandler
  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(_action.setPayload({ [name]: value }));
  };
  //*--------------------------------------------------*
  return (
    <main className="inc_form" style={{ width: 674 }}>
      <div style={{ marginBottom: 40 }}>
        <TextFiledLabel label="충전소 정보" />
        <ListKeyValue title="충전소명" value={view?.data?.station?.name} />
        <ListKeyValue title="충전소 ID" value={view?.data?.station?.code} />
        <ListKeyValue title="보유충전기 수" value={view?.data?.station?.chargepoint_count} />
      </div>
      <div>
        <TextFiledLabel label="적용단가 정보" />
        <ListKeyValue
          title="단가유형"
          component={
            <DropDown
              info={info}
              onChange={data => {
                // console.log(data)
              }}
            />
          }
        />
        <ListKeyValue title="적용 기간" value={'상시적용'} />
      </div>
    </main>
  );
}
