/**
 * @name Content
 * @description
 */
import { useState } from 'react';
import styled from 'styled-components';
import { CheckBox } from 'components';
import { Accordion, DropDown, H1, H2, List, Tab, debug, uuid } from 'lib';
import { COLOR } from 'styles';
import { ListRenderItem } from 'types';

export default function Contents() {
  // hooks
  const ui_info = [
    { label: '라벨1', value: 'num1' },
    { label: '라벨2', value: 'num2' },
    { label: '라벨3', value: 'num3' },
  ];
  const info = [
    {
      title: '라벨1',
      desc: '지난해 12억 4000만 원까지 가격이 치솟았던 경기 인덕원역 일대의 한 아파트가 지난달 4억 2000만 원에 실거래 등재됐다. 이번 거래는 공인중개사를 거치지 않은 직거래로 절세를 목적으로 한 특수 거래일 가능성이 높은 것으로 추정된다. 부동산 가격 하락세가 가팔라지며 수도권 각지에서는 직전 실거래가 대비 수억 원 낮은 직거래가 다수 발생하고 있다.',
    },
    { title: '라벨2', desc: '지난해 12억 4000만 원까지 가격이 치솟았던 경기 인덕원역 일대의 한 아파트가 지난' },
    { title: '라벨3', desc: '지난해 12억 4000만 원까지 가격이 치솟았던 경기 인덕원역 일대의 한 아파트가 지난' },
  ];
  const checkList = [0, 1, 2, 3];
  const [inputVal, setInputVal] = useState<string>('');
  const [select, setSelect] = useState(0);
  const [visible, setVisible] = useState(true);
  //*--------------------------------------------------*

  //*--------------------------------------------------*
  return (
    <Content>
      <H1>UI</H1>
      <div className="wrap">
        {/* 아코디언 */}
        <Accordion info={info} style={{ marginBottom: 30 }} />
        <DropDown
          style={{ marginBottom: 30 }}
          info={ui_info}
          onChange={data => {
            // console.log(data)
          }}
          // layerStyle={{ top: 50 }}
        />
        <H1>LIST</H1>
        <List
          info={checkList}
          renderItem={({ item, index }) => (
            <CheckBox
              style={{ margin: 10 }}
              onChange={() => {
                setSelect(index);
              }}
              value={select === index ? true : false}>
              체크박스 및 uuid {uuid(2)}
            </CheckBox>
          )}
        />
        <H1>탭</H1>
        <Tab selected={2} style={{ marginBottom: 30 }} info={ui_info} onChange={({ index }) => {}} />
        <input type="range" min="1" max="100" onChange={event => {}} />
      </div>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  // styles
  select {
    flex: 1;
    margin-top: 30px;
    padding: 10px 20px;
    align-items: center;
    justify-content: center;
    self-align: center;
    border: 0;
    background-color: ${COLOR.GRAY1};
    &:hover {
      background-color: ${COLOR.GRAY3};
    }
  }
`;
