/**
 * @name Content
 * @description
 */
import {icon_arrow_black_down} from 'assets/images'
import {Button, Events, ImageButton, Row, useToast} from 'lib'
import {GUIDE_EVENT} from 'model/modules'
import styled from 'styled-components'

export default function Contents() {
  // hooks
  const {toast, show} = useToast({time: 1000, message: '확인되었습니다.'})
  //*--------------------------------------------------*
  return (
    <Content>
      <div className="wrap">
        <Row style={{marginBottom: 30}}>
          <section>
            <Button
              label="토스트팝업"
              onClick={() => {
                show()
              }}
            />
            {toast()}
          </section>
          <Button
            label="레이어팝업"
            style={{marginRight: 20}}
            onClick={() => {
              Events.emit(GUIDE_EVENT.POPUP_SET, {title: 'TEST', content: 'TEST'})
            }}
          />
        </Row>
      </div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /* <!--wrap--> */
  section {
    margin-right: 20px;
  }
  .wrap {
  }
`
