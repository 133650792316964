import styled, { css } from 'styled-components';
import { COLOR } from '@/styles/index';
import { FontType } from '@/types/font';

export const InputComponent = styled.input<{ $customFont?: FontType }>`
  ${({ $customFont }) =>
    $customFont &&
    css`
      font-family: ${$customFont.fontFamily};
      letter-spacing: ${$customFont.letterSpacing};
      font-size: ${$customFont.fontSize};
      line-height: ${$customFont.lineHeight};
      font-weight: ${$customFont.fontWeight};
    `};
  // &.input {
  //   display: flex;
  //   min-width: 300px;
  //   width: 100%;
  //   height: 40px;
  //   padding: 0 16px;
  //   border-radius: 8px;
  //   border: 1px solid ${COLOR.GRAY4};
  //   outline: 0;
  //   align-items: center;
  //   justify-content: center;
  //   font-size: 15px;
  //   line-height: 24px;
  //   letter-spacing: -0.1px;
  //   /* 마우스호버 */
  //   &:hover {
  //     border-color: ${COLOR.GRAY1};
  //   }
  //   /* 포커스인 */
  //   &:focus {
  //     border-color: ${COLOR.BLUE3};
  //     outline: 0;
  //     outline-color: ${COLOR.BLUE3};
  //   }
  //   /* 비활성화 */
  //   &:disabled {
  //     color: ${COLOR.GRAY3};
  //     background-color: ${COLOR.GRAY6};
  //   }
  //   /* 유효성검사 */
  //   &.invalid {
  //     border-color: ${COLOR.RED1} !important;
  //   }
  //   /* 플레이스홀더 */
  //   &::placeholder {
  //     color: ${COLOR.GRAY2};
  //   }
  // }
`;
