import React from 'react';
import { CheckboxGroup, DropDownLocations } from 'components/form';
import { useTranslation } from 'react-i18next';
// import Checkbox from '@/components/atoms/Checkbox';
import { SESSION_STORAGE } from 'lib';
import { DeleteTerms, FilterRow, FilterTitle, LimitDescription, Terms, Wrapper } from './ChargerFilter.style';
import { IChargerFilter } from '@/components/organisms/Filters/ChargerFilter/ChargerFilter.type';
import InputSearch from '@/components/molecules/InputSearch';
import SearchKeywordButton from '@/components/atoms/Buttons/SearchKeywordButton';

export default function ChargerFilter({
  detailSearchTerms,
  handleSetDetailSearchTerms,
}: {
  detailSearchTerms: IChargerFilter;
  handleSetDetailSearchTerms: (terms: IChargerFilter) => void;
}) {
  const { t } = useTranslation('common');

  const handleRoaming = (value: boolean[]) => {
    sessionStorage.setItem(
      SESSION_STORAGE.FIlTER_TERMS.CHARGER,
      JSON.stringify({ ...detailSearchTerms, roamingME: value }),
    );
    handleSetDetailSearchTerms({ ...detailSearchTerms, roamingME: value });
  };
  const handleLocationTerms = ({ name }: { name: string; value: number }) => {
    handleSetDetailSearchTerms({ ...detailSearchTerms, location: name });
  };

  const handleChargerTypeTerms = (value: string[]) => {
    handleSetDetailSearchTerms({ ...detailSearchTerms, chargerType: value });
  };

  const handleConcurrencyTerms = (value: boolean[]) => {
    handleSetDetailSearchTerms({ ...detailSearchTerms, isConcurrency: value });
  };

  const handleConnectorTypeTerms = (value: string[]) => {
    handleSetDetailSearchTerms({ ...detailSearchTerms, connectorType: value });
  };

  const handleKeywordTerms = (value: string) => {
    const currentValues = detailSearchTerms.manufacturerKeyword;
    const index = currentValues.indexOf(value);
    if (index > -1) {
      handleSetDetailSearchTerms({
        ...detailSearchTerms,
        manufacturerKeyword: currentValues.filter((_, i) => i !== index),
      });
    } else {
      handleSetDetailSearchTerms({ ...detailSearchTerms, manufacturerKeyword: [...currentValues, value] });
    }
  };

  const handleInitKeywordTerms = () => {
    handleSetDetailSearchTerms({
      ...detailSearchTerms,
      manufacturerKeyword: [],
    });
  };

  const handleRemoveKeyword = (value: string) => {
    const currentValues = detailSearchTerms.manufacturerKeyword;
    const index = currentValues.indexOf(value);
    handleSetDetailSearchTerms({
      ...detailSearchTerms,
      manufacturerKeyword: currentValues.filter((_, i) => i !== index),
    });
  };
  return (
    <Wrapper>
      <FilterRow>
        <FilterTitle>{t('pages.infra.chargerManage.search.roamingME')}</FilterTitle>
        <Terms>
          <CheckboxGroup
            info={[
              { label: t('common.available'), value: true, checked: false },
              { label: t('common.unavailable'), value: false, checked: false },
            ]}
            onValue={data => handleRoaming(data)}
          />
          {/* <SingleCheckbox> */}
          {/*  <Checkbox */}
          {/*    id="isRoaming" */}
          {/*    isCheckedFromParent={detailSearchTerms.roamingME} */}
          {/*    onClick={() => handleRoaming(!detailSearchTerms.roamingME)} */}
          {/*  /> */}
          {/* </SingleCheckbox> */}
        </Terms>
      </FilterRow>
      <FilterRow>
        <FilterTitle>{t('pages.infra.chargerManage.search.locationOfStation')}</FilterTitle>
        <Terms>
          <DropDownLocations value={detailSearchTerms.location} onChange={location => handleLocationTerms(location)} />
        </Terms>
      </FilterRow>
      <FilterRow>
        <FilterTitle>{t('pages.infra.chargerManage.search.chargerType')}</FilterTitle>
        <Terms>
          <CheckboxGroup
            info={[
              { label: t('common.rapid'), value: 'FAST', checked: false },
              { label: t('common.standard'), value: 'SLOW', checked: false },
            ]}
            onValue={data => handleChargerTypeTerms(data)}
          />
        </Terms>
      </FilterRow>
      <FilterRow>
        <FilterTitle>{t('pages.infra.chargerManage.search.concurrency')}</FilterTitle>
        <Terms>
          <CheckboxGroup
            info={[
              { label: t('common.available'), value: true, checked: false },
              { label: t('common.unavailable'), value: false, checked: false },
            ]}
            onValue={data => handleConcurrencyTerms(data)}
          />
        </Terms>
      </FilterRow>
      <FilterRow>
        <FilterTitle>{t('pages.infra.chargerManage.search.connectorsType')}</FilterTitle>
        <Terms>
          <CheckboxGroup
            info={[
              { label: t('common.connector.CCS1'), value: ['CCS1', 'CCS2'], checked: false },
              { label: t('common.connector.3PHASE'), value: '3PHASE', checked: false },
              { label: t('common.connector.GBT'), value: 'GBT', checked: false },
              { label: t('common.connector.J1772'), value: 'J1772', checked: false },
            ]}
            onValue={data => handleConnectorTypeTerms(data)}
          />
        </Terms>
      </FilterRow>
      <FilterRow $borderNone>
        <FilterTitle>{t('pages.infra.chargerManage.search.searchByManufacturer')}</FilterTitle>
        <Terms>
          <InputSearch
            onSubmit={value => handleKeywordTerms(value)}
            disabled={detailSearchTerms.manufacturerKeyword.length > 4}
            placeholder={t('pages.infra.chargerManage.search.searchByManufacturerPlaceholder')}
          />
        </Terms>
      </FilterRow>
      {detailSearchTerms.manufacturerKeyword.length > 0 && (
        <FilterRow $borderNone>
          <FilterTitle>
            {t('pages.infra.chargerManage.search.multipleKeyword')}
            <DeleteTerms onClick={handleInitKeywordTerms}>
              {t('pages.infra.chargerManage.search.removeAll')}
            </DeleteTerms>
            <LimitDescription>{t('pages.infra.chargerManage.search.limit5')}</LimitDescription>
          </FilterTitle>
          <Terms>
            <SearchKeywordButton
              keywords={detailSearchTerms.manufacturerKeyword}
              onRemoveKeyword={keyword => handleRemoveKeyword(keyword)}
            />
          </Terms>
        </FilterRow>
      )}
    </Wrapper>
  );
}
