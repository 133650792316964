/**
 * @name 캘린더
 * @description
 */
import {debug, H1, useCalendar, uuid} from 'lib'
import {COLOR} from 'styles'
import styled from 'styled-components'
import {GrFormNext, GrFormPrevious} from 'react-icons/gr'
import {useState} from 'react'

export default function Contents() {
  // hooks
  const [startRange, setStartRange] = useState<any>(null) // ------시작일
  const [endRange, endRangeRange] = useState<any>(null) // --------종료일
  const week = ['일', '월', '화', '수', '목', '금', '토']
  const {calendar, selectYear, selectMonth, prevMonth, nextMonth} = useCalendar()
  //*--------------------------------------------------*
  // 오늘표시
  const checkToday = (item: any) => {
    if (selectYear === new Date().getFullYear() && selectMonth === new Date().getMonth() + 1 && item === new Date().getDate()) {
      return 'today'
    } else {
      return ''
    }
  }
  // 구간표시
  const checkRange = (item: any) => {
    if (!startRange || !endRange) return ''
    if (startRange?.month <= selectMonth && selectMonth <= endRange?.month && startRange?.day <= item && item <= endRange?.day) {
      return 'range'
    } else {
      return ''
    }
  }
  //renderCalendar
  const renderCalendar = () => {
    return calendar.map((list: any, index: number) => {
      return (
        <dl key={uuid()}>
          {list.map((item: any) => {
            return (
              <button
                key={uuid()}
                // 오늘표시
                className={`${checkToday(item)} ${checkRange(item)}`}
                onClick={() => {
                  // 시작일,종료일이 모두있으면 초기화
                  if (!!startRange && !!endRange) {
                    // 시작일이 종료일보다 크면 시작일,종료일을 바꿔준다.
                    setStartRange(null)
                    endRangeRange(null)
                  } else if (!startRange) {
                    setStartRange({year: selectYear, month: selectMonth, day: item})
                  } else if (!endRange) {
                    // 시작일이 종료일보다 크면 시작일,종료일을 바꿔준다.
                    if (startRange.day > item) {
                      setStartRange({year: selectYear, month: selectMonth, day: item})
                      endRangeRange(startRange)
                      return
                    }
                    endRangeRange({year: selectYear, month: selectMonth, day: item})
                  }
                }}>
                {item}
              </button>
            )
          })}
        </dl>
      )
    })
  }
  //*--------------------------------------------------*
  return (
    <Content>
      <div className="wrap">
        {/* 년도표시 */}
        <div className="top">
          <p>{selectYear}</p>
        </div>
        {/* 헤더 */}
        <header>
          <button className="control" onClick={prevMonth}>
            <GrFormPrevious />
          </button>
          <H1>{selectMonth}월</H1>
          <button className="control" onClick={nextMonth}>
            <GrFormNext />
          </button>
        </header>
        <dl className="week">
          {week.map(list => {
            return <dt key={uuid()}>{list}</dt>
          })}
        </dl>
        {renderCalendar()}
        {/* <footer>
          <p>
            <span>시작일{JSON.stringify(startRange)}</span>
          </p>
          <p>
            <span>종료일{JSON.stringify(endRange)}</span>
          </p>
        </footer> */}
      </div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  .wrap {
    width: 300px;
    /* height: 150px; */
  }
  /* <!--상단--> */
  .top {
    display: flex;
    font-size: 12px;
    justify-content: center;
  }
  /* <!--헤더--> */
  header {
    display: flex;
    width: 50%;
    margin: 10px auto;
    align-items: center;
    justify-content: space-between;
    /* 이전,다음 */
    .control {
      width: 30px;
      height: 30px;
      &:hover {
        color: red;
      }
    }
  }
  // styles
  dl {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    //common
    dt,
    button {
      display: flex;
      flex: 1;
      width: 100%;
      width: 48px;
      height: 38px;
      align-items: center;
      justify-content: center;
      /* 일요일 */
      &:nth-child(1) {
        color: red;
      }
      /* 토요일 */
      &:nth-child(7) {
        color: blue;
      }
    }
    dt {
      font-size: 14px;
      color: ${COLOR.GRAY3};
    }
    button {
      font-size: 14px;
      //오늘표시
      &.today {
        color: ${COLOR.WHITE};
        border-radius: 50%;
        background: ${COLOR.BLACK};
      }
      // 기간설정
      &.range {
        background: ${COLOR.BLUE5};
      }
      &:hover {
        background: ${COLOR.GRAY1};
      }
    }
  }
`
/********************************************************
[사용법]
https://day.js.org/en/


*********************************************************/
