import { API_HOST } from '@/config/index';
import api from '@/lib/apis/api';

import { IRemoteDetails, Paging, RemoteHistoryListRequest } from './type';
import { objectToQueryString } from '@/lib/index';
import { IResponse } from '../api/type';
import { RemoteDetail } from '@/types/operation';

export const getRemoteHistories = async (payload: RemoteHistoryListRequest) => {
  const param = objectToQueryString({ ...payload });

  const url = `${API_HOST}/remotes?${param}`;
  return api.get({ url });
};

export const getRemoteHistory = async (seq: string): Promise<IResponse<RemoteDetail>> => {
  const url = `${API_HOST}/remotes/${seq}`;
  return api.get({ url });
};

export const getRemoteExcelFile = async ({
  payload,
  fileName,
}: {
  payload: Paging;

  fileName: string;
}) => {
  const temp = { ...payload };
  const param = objectToQueryString(temp);
  const url = `${API_HOST}/remotes/download/excel?${param}`;
  return api.get({ url, responseType: 'blob', fileName });
};
