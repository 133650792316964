/**
 * @name 폼입력
 * @description
 */
import styled from 'styled-components'
import {H1} from 'lib'
import {COLOR} from 'styles'

export default function Contents() {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      <section>
        <H1>여러분을 환영합니다.</H1>
        <p>
          크로커스 EV 서비스 및 제품(이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 약관은 다양한 크로커스 EV 서비스의 이용과 관련하여 크로커스 EV 서비스를 제공하는 크로커스 EV 주식회사(이하 ‘크로커스
          EV’)와 이를 이용하는 크로커스 EV 서비스 회원(이하 ‘회원’) 또는 비회원과의 관계를 설명하며, 아울러 여러분의 크로커스 EV 서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고 있습니다.
          크로커스 EV 서비스를 이용하시거나 크로커스 EV 서비스 회원으로 가입하실 경우 여러분은 본 약관 및 관련 운영 정책을 확인하거나 동의하게 되므로, 잠시 시간을 내시어 주의 깊게 살펴봐 주시기
          바랍니다.
        </p>
      </section>
      <section>
        <H1>여러분을 환영합니다.</H1>
        <p>
          크로커스 EV 서비스 및 제품(이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 약관은 다양한 크로커스 EV 서비스의 이용과 관련하여 크로커스 EV 서비스를 제공하는 크로커스 EV 주식회사(이하 ‘크로커스
          EV’)와 이를 이용하는 크로커스 EV 서비스 회원(이하 ‘회원’) 또는 비회원과의 관계를 설명하며, 아울러 여러분의 크로커스 EV 서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고 있습니다.
          크로커스 EV 서비스를 이용하시거나 크로커스 EV 서비스 회원으로 가입하실 경우 여러분은 본 약관 및 관련 운영 정책을 확인하거나 동의하게 되므로, 잠시 시간을 내시어 주의 깊게 살펴봐 주시기
          바랍니다.
        </p>
      </section>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  section {
    margin-bottom: 40px;
  }
  h1 {
    color: ${COLOR.GRAY1};
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.6px;
  }
  p {
    color: ${COLOR.GRAY2};
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.45px;
  }
`
