/**
 * @name 캘린더
 * @description
 */
import React, { useEffect, useRef, useState } from 'react';
import { COLOR } from 'styles';
import styled, { css } from 'styled-components';
import { useClickOutside } from 'lib';
import { Calendar } from 'components/element';
import { ICalendar } from '@/types/calendar';
import typography from '@/styles/typography';

type Props = {
  style?: React.CSSProperties;
  popupStyle?: React.CSSProperties;
  onChange?: (value: ICalendar) => void;
  disabled?: boolean;
  value?: string;
  placeholder?: string;
};

export default function TextFiledCalendar({ onChange, style, disabled, popupStyle, value, placeholder }: Props) {
  const eleRef = useRef(null);
  const [select, setSelect] = useState(value);
  const [visible, setVisible] = useState(false);

  useClickOutside(eleRef, () => {
    setVisible(false);
  });

  useEffect(() => {
    setSelect(value);
  }, [value]);

  return (
    <Content ref={eleRef} style={style}>
      <button
        type="button"
        className="select"
        onClick={() => {
          if (disabled) return;
          setVisible(!visible);
        }}>
        <Text $isSelected={select !== ''}>{select || placeholder}</Text>
      </button>
      {visible && (
        <section className="popup" style={popupStyle}>
          <Calendar
            onChange={(value: ICalendar) => {
              setSelect(`${value.year}.${value.month}.${value.date}`);
              setVisible(false);
              if (onChange) {
                onChange(value);
              }
            }}
          />
        </section>
      )}
    </Content>
  );
}

const Content = styled.div`
  position: relative;
  width: 100%;
  button.select {
    display: flex;
    width: 100%;
    height: 40px;
    padding: 0 16px;
    border: 1px solid ${COLOR.GRAY4};
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
  }
  section.popup {
    position: absolute;
    bottom: 40px;
    z-index: 10;
    background-color: ${COLOR.WHITE};
    width: 100%;
    max-width: 394px;
    padding: 16px 8px;
    box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.07);
    border-radius: 8px;
  }
`;

const Text = styled.span<{ $isSelected: boolean }>`
  ${typography.body4};
  ${({ $isSelected }) =>
    $isSelected
      ? css`
          color: ${COLOR.BLACK1};
        `
      : css`
          color: ${COLOR.GRAY2};
        `}
`;
