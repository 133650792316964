import React, { useEffect, useState } from 'react';
import { Button, addComma, objectToQueryString, useAxios, useContext, uuid } from 'lib';
import { ListTable, TextFiledLabel } from 'components/form';
import { API_HOST } from 'config';
import { Paging } from 'types';
import { MemberType } from 'components';
import { useTranslation } from 'react-i18next';
/**
 * @name 폼입력
 * @description
 */
export default function Contents() {
  const { t } = useTranslation('common');
  const { params, cpo_seq } = useContext();
  const [info, setInfo] = useState([]);
  const { get } = useAxios();
  async function fetch(payload?: Paging) {
    const temp = { ...payload, per: 10 };
    const _payload = objectToQueryString(temp);
    const res = await get(`${API_HOST}/business/${cpo_seq}/tariff/stations/${params?.id}/fixed?${_payload}`);
    if (res?.isSuccess) {
      setInfo(res?.data);
    }
  }
  useEffect(() => {
    fetch();
  }, []);
  return (
    <main className="inc_form" style={{ width: 674 }}>
      <TextFiledLabel required label={t('pages.price.chargingStationPrice.each.title')} />
      <ListTable
        thead={
          <thead>
            <tr>
              <th className="inner">{t('forms.price.memberType')}</th>
              <th className="inner">
                {t('forms.price.rapid')}
                {t('common.unit.brackets.priceWonBykWh')}
              </th>
              <th className="inner">
                {t('forms.price.standard')}
                {t('common.unit.brackets.priceWonBykWh')}
              </th>
            </tr>
          </thead>
        }>
        <tbody>
          {!!info &&
            info?.map((item: any) => {
              return (
                <tr key={uuid()}>
                  <td>
                    <MemberType type={item?.is_user} />
                  </td>
                  <td>{addComma(item?.fast_amount)}</td>
                  <td>{addComma(item?.slow_amount)}</td>
                </tr>
              );
            })}
        </tbody>
      </ListTable>
      <div className="auto" style={{ width: 400, marginTop: 40 }}>
        <Button full label={t('common.buttons.updateAction')} />
      </div>
    </main>
  );
}
