import styled, { css } from 'styled-components';
import { FontType } from '@/types/font';
import { COLOR } from '@/styles/index';
import typography from '@/styles/typography';

export const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const Table = styled.table`
  width: 100%;
`;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody``;
export const TR = styled.tr`
  border-bottom: 1px solid ${COLOR.GRAY5};
  &:hover {
    background-color: ${COLOR.GRAY6};
  }
`;

export const InnerTR = styled.tr`
  &:hover {
    background-color: ${COLOR.GRAY6};
  }
`;

export const InnerTD = styled.td<{ $index?: number; $customFont?: FontType; color?: string }>`
  padding: 4px;
  color: ${props => props.color ?? COLOR.BLACK2};
  vertical-align: middle;
  ${({ $customFont }) =>
    $customFont &&
    css`
      font-family: ${$customFont.fontFamily};
      letter-spacing: ${$customFont.letterSpacing};
      font-size: ${$customFont.fontSize};
      line-height: ${$customFont.lineHeight};
      font-weight: ${$customFont.fontWeight};
    `};
`;

export const TH = styled.th`
  text-align: left;
  padding: 4px 8px;
  background-color: ${COLOR.BLUE8};
  ${typography.headline6};
  color: ${COLOR.GRAY1};
`;

export const TD = styled.td<{ $index?: number; $customFont?: FontType; color?: string }>`
  padding: 8px;
  height: 56px;
  color: ${props => props.color ?? COLOR.BLACK2};
  vertical-align: middle;
  ${({ $customFont }) =>
    $customFont &&
    css`
      font-family: ${$customFont.fontFamily};
      letter-spacing: ${$customFont.letterSpacing};
      font-size: ${$customFont.fontSize};
      line-height: ${$customFont.lineHeight};
      font-weight: ${$customFont.fontWeight};
    `};
`;

export const InnerCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  height: 40px;
`;

export const InnerCellContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-height: 56px;
`;

export const InnerCellTextButton = styled.span`
  cursor: pointer;
  color: ${COLOR.BLUE4};
  &:hover {
    color: ${COLOR.BLUE1};
  }
  &:active {
    color: ${COLOR.BLUE6};
  }
`;

export const NoSearchResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 117px 0;
`;
export const NoSearchResultTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const NoResultTextWrapper = styled.div``;
export const SearchKeyword = styled.span`
  color: ${COLOR.BLUE4};
  ${typography.body1};
`;
export const NoResultText = styled.span`
  color: ${COLOR.GRAY1};
  ${typography.body1};
`;

export const NoResultDescription = styled.p`
  color: ${COLOR.GRAY2};
  ${typography.body3};
`;
