/**
 * @name Modify
 * @description
 */
import styled from 'styled-components'
// contents
import {Template} from '..'

export default function Contents() {
  // const
  //*--------------------------------------------------*
  return (
    <Content>
      <Template />
    </Content>
  )
}

//*--------------------------------------------------*
const Content = styled.div``
