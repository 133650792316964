/**
 * @name 대시보드
 * @description
 */
import styled from 'styled-components'
import {App} from 'components'
// contents
import {Aside, GoogleMap} from './contents'
import {Popup} from './common'

export default function Presenter() {
  return (
    <App empty>
      <Content>
        {/* 지도 */}
        <GoogleMap />
        {/* 랭킹 */}
        <Aside />
        <Popup />
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  position: relative;
`
