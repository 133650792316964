import React, { useState } from 'react';
import { Image } from 'lib';
import styled from 'styled-components';
import { icon_checkbox_off, icon_checkbox_on } from '@/assets/images';
import { COLOR } from '@/styles/index';
import typography from '@/styles/typography';

export default function Checkbox({
  label,
  id,
  isCheckedFromParent,
  onClick,
  textFirst = true,
}: {
  label?: string;
  id: string;
  isCheckedFromParent?: boolean;
  onClick?: () => void;
  textFirst?: boolean;
}) {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <Wrapper>
      <Label htmlFor={id}>
        {textFirst && label && label}
        <input
          id={id}
          type="checkbox"
          style={{ display: 'none' }}
          onClick={() => {
            if (onClick) {
              onClick();
            } else {
              setIsChecked(!isChecked);
            }
          }}
        />
        <Image
          src={isCheckedFromParent ?? isChecked ? icon_checkbox_on : icon_checkbox_off}
          style={{ width: 18, height: 18 }}
        />
        {!textFirst && label && label}
      </Label>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${COLOR.GRAY1};
  ${typography.headline5};
  cursor: pointer;
`;
