import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'model';
import dayjs from 'dayjs';
import CalculateListPresenter from './CalculateList.presenter';
import { getCalculates, getExcelFile } from '@/lib/apis/sales';
import { actions } from '@/model/modules/sales';

export default function CalculateListContainer() {
  const { cpo_seq } = useAppSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const itemsPerPage = 10;
  const selectYearQueryString = searchParams.get('year') || '';
  const selectMonthQueryString = searchParams.get('month') || '';

  const [currentPage, setCurrentPage] = useState(page || '1');
  const [selectMonth, setSelectMonth] = useState<string>(selectMonthQueryString);
  const [selectYear, setSelectYear] = useState<string>(selectYearQueryString);

  const pageHandler = (p: number) => {
    const page = String(p);
    if (page !== currentPage) {
      setCurrentPage(page);
      navigate(`?${queryParamsURL({ page })}`);
    }
  };

  const fetchList = async (payload: { size: number; page: string; cpo_seq: string; year: string; month: string }) => {
    const getCalcualtesResponse = await getCalculates(payload);
    if (getCalcualtesResponse.isSuccess) {
      dispatch(
        actions.setList({
          list: getCalcualtesResponse.data,
          totalCount: getCalcualtesResponse.meta.paging?.total_count ?? 0,
        }),
      );
    }
  };

  const queryParamsURL = (args: { page?: string; year?: string; month?: string } = {}) => {
    const { page, year, month } = args;
    const yearValue = year ?? selectYear;
    const monthValue = month ?? selectMonth;
    const queryParams = [];
    if (yearValue) {
      queryParams.push(`year=${encodeURIComponent(yearValue)}`);
    }
    if (monthValue) {
      queryParams.push(`month=${encodeURIComponent(monthValue)}`);
    }
    queryParams.push(`page=${page ?? currentPage}`);
    queryParams.push(`size=${itemsPerPage}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const handleSelectYear = (val: { label: string; value: number }) => {
    const year = String(val.value);
    setSelectYear(year);
    navigate(`?${queryParamsURL({ year })}`);
    fetchList({ size: itemsPerPage, page: currentPage, cpo_seq, year, month: selectMonth });
  };
  const handleSelectMonth = (val: { label: string; value: number }) => {
    const month = String(val.value);
    setSelectMonth(month);
    navigate(`?${queryParamsURL({ month })}`);
    fetchList({ size: itemsPerPage, page: currentPage, cpo_seq, year: selectYear, month });
  };

  const handleExcelDownload = async () => {
    const payload = { cpo_seq, year: selectYear, month: selectMonth };
    if (cpo_seq) {
      await getExcelFile({ payload, fileName: `정산현황목록_${dayjs().format('YYYY_MM_DD')}.xlsx` });
    }
  };

  useEffect(() => {
    const payload = {
      size: itemsPerPage,
      page: currentPage,
      cpo_seq,
      year: selectYear,
      month: selectMonth,
    };
    fetchList(payload);
  }, []);

  return (
    <CalculateListPresenter
      itemsPerPage={itemsPerPage}
      currentPage={+currentPage}
      pageHandler={pageHandler}
      queryString={queryParamsURL()}
      handleExcelDownload={handleExcelDownload}
      handleSelectYear={handleSelectYear}
      handleSelectMonth={handleSelectMonth}
      selectYear={selectYear}
      selectMonth={selectMonth}
    />
  );
}
