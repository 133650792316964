/**
 * @name 폼입력
 * @description
 */
import styled from 'styled-components'
import {H1} from 'lib'
import {COLOR} from 'styles'

export default function Contents() {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      <section>
        <H1>여러분을 환영합니다.</H1>
        <p>여러분을</p>
      </section>
      <section>
        <H1>여러분을 환영합니다.</H1>
        <p>바랍니다.</p>
      </section>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  section {
    margin-bottom: 40px;
  }
  h1 {
    color: ${COLOR.GRAY1};
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.6px;
  }
  p {
    color: ${COLOR.GRAY2};
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.45px;
  }
`
