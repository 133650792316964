/**
 * @name 커넥터
 * @description
 */
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Image, useClickOutside, uuid } from 'lib';
import { COLOR } from 'styles';
import { icon_arrow_down_black } from 'assets/images';
// contents

type Props = {
  style?: React.CSSProperties; // ---------------------- StyleSheet
  popupStyle?: React.CSSProperties; // ----------------- PopupStyleSheet
  onChange?: (value: { name: string; value: string }) => void;
  defaultValue?: string;
  disabled?: boolean;
};
export default function DropDownConnectors({ onChange, style, popupStyle, defaultValue, disabled }: Props) {
  const info = [
    { name: 'CCS1 (DC콤보1)', value: 'CCS1' },
    { name: 'CCS2 (DC콤보2)', value: 'CCS2' },
    { name: 'CHADEMO (DC차데모)', value: 'CHADEMO' },
    { name: '3PHASE (AC상)', value: '3PHASE' },
    { name: 'GBT (GBT)', value: 'GBT' },
    { name: 'J1772 (AC완속)', value: 'J1772' },
    { name: 'ETC', value: 'ETC' },
  ];
  const eleRef = useRef(null);
  const [selected, setSelected] = useState<string | null>(null);
  const [visible, setVisible] = useState(false);

  // outside click
  useClickOutside(eleRef, () => {
    setVisible(false);
  });

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Content ref={eleRef} style={style} $disabled={disabled}>
      <SelectButton
        $disabled={disabled}
        type="button"
        onClick={() => {
          if (disabled) return;
          setVisible(!visible);
        }}>
        <SelectedTypeText $disabled={disabled} className="selected">
          {selected}
        </SelectedTypeText>
        <Image src={icon_arrow_down_black} style={{ width: 20, height: 20 }} />
      </SelectButton>
      {/* 레이어팝업 */}
      {visible && (
        <section className="popup" style={popupStyle}>
          {info?.map((item: { name: string; value: string }) => {
            return (
              <button
                type="button"
                className="item"
                key={uuid()}
                onClick={() => {
                  if (onChange) {
                    onChange(item);
                  }
                  setSelected(item.name);
                  setVisible(false);
                }}>
                {item?.name}
              </button>
            );
          })}
        </section>
      )}
    </Content>
  );
}

const SelectButton = styled.button<{ $disabled?: boolean }>`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  border: 1px solid ${COLOR.GRAY4};
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  span.selected {
    color: ${COLOR.GRAY2};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: default;
    `}
`;

const SelectedTypeText = styled.span<{ $disabled?: boolean }>`
  color: ${COLOR.GRAY2};
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${COLOR.GRAY3};
    `}
`;

const Content = styled.div<{ $disabled?: boolean }>`
  position: relative;
  width: 100%;
  /* 레이어팝업 */
  section.popup {
    position: absolute;
    z-index: 10;
    background-color: ${COLOR.WHITE};
    width: 100%;
    height: 300px;
    box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.07);
    border-radius: 6px;
    overflow-y: scroll;

    button.item {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
      height: 40px;
      padding: 0 16px;
      color: ${COLOR.BLACK1};
      &:hover {
        background-color: ${COLOR.GRAY6};
      }
    }
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${COLOR.GRAY6};
    `}
`;
