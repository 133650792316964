/**
 * @name List
 * @description
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { Events, Table, debug, uuid } from 'lib';
import { ListRenderItem } from 'types';
import { TariffType } from 'components';
import { Pagination } from 'components/form';
import { MENU } from '@/pages/router';
import { formatDay } from 'pages/common';
import { Store, _event } from '../../index';
import Badge from '@/components/atoms/Badge';

export default function Contents() {
  // hooks
  const { list } = Store();
  //*--------------------------------------------------*
  return (
    <div className="table_list">
      {/* 테이블 */}
      <Table
        info={list?.data}
        renderHeader={() => {
          return (
            <React.Fragment>
              <thead>
                <tr key={uuid()}>
                  <th className="inner">No.</th>
                  <th className="inner l">충전소명</th>
                  <th className="inner l">충전소ID </th>
                  <th className="inner">회원 적용단가</th>
                  <th className="inner">비회원 적용단가</th>
                  <th className="inner">단가 적용일시</th>
                </tr>
              </thead>
              <colgroup>
                <col style={{ width: 50 }} />
                <col style={{ width: '*' }} />
                <col style={{ width: 120 }} />
                <col style={{ width: 150 }} />
                <col style={{ width: 150 }} />
                <col style={{ width: 200 }} />
              </colgroup>
            </React.Fragment>
          );
        }}
        renderItem={({ item }: ListRenderItem) => {
          return (
            <tr key={uuid()}>
              <td>{item?.seq}</td>
              <td className="l">
                <Link to={`${MENU.CHARGING_STATION_PRICE}/${item?.seq}`}>{item?.name}</Link>
              </td>
              <td className="l">
                <Link to={`${MENU.CHARGING_STATION_PRICE}/${item?.seq}`}>{item?.code}</Link>
              </td>
              <td className="c">
                <Badge
                  mode="Secondary1"
                  label="수정필요"
                  // type={item?.member_tariff_type}
                />
              </td>
              <td className="c">
                <Badge
                  mode="Secondary1"
                  label="수정필요"
                  // type={item?.non_member_tariff_type}
                />
              </td>
              <td>{formatDay(item?.tariff_type_applied_at ?? new Date())}</td>
            </tr>
          );
        }}
      />
      {/* 페이징 */}
      <Pagination
        style={{ marginTop: 30 }}
        info={list?.meta?.paging}
        onChange={(num: any) => {
          Events.emit(_event.FETCH_LIST, { page_num: num });
        }}
      />
    </div>
  );
}
