import React, { useEffect } from 'react';
import styled from 'styled-components';
import { COLOR } from 'styles';
import { Events, H3, objectToQueryString, useAxios, useContext } from 'lib';
import dayjs from 'dayjs';
import { API_HOST } from 'config';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/model/index';

/**
 * @name 타이틀및토글버튼
 * @description
 */
export default function Contents() {
  const { t } = useTranslation('common');
  const { period } = useAppSelector(state => state.dashboard);

  // 포멧체크
  const setFormat = (value: string) => {
    return dayjs(value).format('YYYY.MM.DD');
  };

  // 설정기간체크
  const check_tz_start_date = () => {
    if (!period?.tz_start_date) return '';
    const _calc_type = period?.calc_type;
    // 실시간통계
    if (_calc_type === 'REALTIME') return setFormat(period?.tz_start_date);
    // 종료날짜가없을때
    if (period?.tz_end_date === null) return setFormat(period?.tz_start_date);
    return `${setFormat(period?.tz_start_date)} ~ ${period?.tz_end_date !== null && setFormat(period?.tz_end_date)} / ${
      period?.diff
    }일`;
  };

  return (
    <Content>
      <div className="title">
        <H3 color={COLOR.GRAY1}>{t('pages.dashboard.hourlySales')}</H3>
        <p className="desc body6">{t('pages.dashboard.hourlySalesDescription')}</p>
      </div>
      <div className="control">
        <section className="caption2 setup">
          {t('pages.dashboard.setPeriod')} {check_tz_start_date()}
        </section>
        {period.calc_type !== 'REALTIME' && (
          <section className="caption2 compare">
            {`${t('pages.dashboard.comparePeriod')} `}
            {!!period?.diff &&
              `: ${setFormat(period?.tz_compare_start_date)} ~ ${setFormat(period?.tz_compare_end_date)} / ${
                period?.diff
              }${t('common.unit.day')}`}
          </section>
        )}
      </div>
    </Content>
  );
}
const Content = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 17px;
  height: 74px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  p.desc {
    color: ${COLOR.BLACK3};
    font-size: 13px;
    letter-spacing: -0.2px;
  }
  /* control */
  .control {
    display: flex;
    flex-direction: column;
    section {
      display: flex;
      align-items: center;
      align-self: flex-end;
      padding: 5px 10px;
      border-radius: 4px;
      color: ${COLOR.WHITE};
      align-items: center;
      background-color: ${COLOR.BLUE3};
      &.setup {
        margin-bottom: 4px;
      }
      &.compare {
        background-color: ${COLOR.YELLOW2};
      }
    }
  }
`;
