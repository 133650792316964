/**
 * @name List
 * @description 가이드페이지
 */
import styled from 'styled-components'
//contents
import Status from './status'

export default function Contents() {
  // const
  //*--------------------------------------------------*
  return (
    <Content>
      <main>
        <Status />
      </main>
    </Content>
  )
}

//*--------------------------------------------------*
const Content = styled.div`
  main {
    width: 674px;
    margin: 40px auto 100px;
  }
`
