// 위도, 경도 표시
export const displayCoordinate = (latitude: string, longitude: string) => {
  return `${parseFloat(latitude).toFixed(4)}, ${parseFloat(longitude).toFixed(4)}`;
};

// 단위 표시
export const displayUnits = (value: string | number, unit?: string) => {
  return value ? `${value}${unit}` : '-';
};

// 데이터 없는 경우 표시
export const displayNull = (value: string | number) => {
  return value || '-';
};

// 소숫점 아래에 숫자가 있는 경우 소숫점 두번째 자리까지 표시, 소숫점 아래 없으면 정수 리턴
export const displayPrice = (value: string) => {
  if (value) {
    const numericValue = parseFloat(value);
    const parts = value.split('.');

    if (parts.length < 2 || parseFloat(parts[1]) === 0) {
      return numericValue.toString();
    }
    return numericValue.toFixed(2);
  } else {
    return '';
  }
};
