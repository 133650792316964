/**
 * @name List
 * @description
 */
import {useState} from 'react'
import styled from 'styled-components'
import {Row, Tab, Text, addComma, debug, uuid} from 'lib'
import {COLOR} from 'styles'
// contents
import Summary from '../template/summary'
import Each from '../template/each'
import Fixed from '../template/fixed'
import Timely from '../template/timely'
import {Store} from '../..'
import {MemberType} from 'components'

export default function Contents() {
  // const
  const {view} = Store()
  const tabMenu = [{label: '간단정보 및 단가설정  '}, {label: '개별단가'}, {label: '고정단가'}, {label: '계시별단가'}]
  const [selected, setSelected] = useState(0)
  //*--------------------------------------------------*
  // setContents
  const setContents = () => {
    switch (selected) {
      case 0:
        return <Summary />
      case 1:
        return <Each />
      case 2:
        return <Fixed />
      case 3:
        return <Timely />
    }
  }
  //*--------------------------------------------------*
  return (
    <Content>
      {/* {debug(view?.data?.current_tariff)} */}
      <div className="top">
        {!!view &&
          view?.data?.current_tariff?.map((item: any, index: number) => {
            return (
              <section className="section" key={uuid()}>
                <MemberType type={item?.is_user} />
                <Row style={{marginTop: 30, justifyContent: 'center'}}>
                  <div style={{marginRight: 10}}>
                    <p className="body3">급속단가</p>
                    <p className="price">
                      <b>{addComma(item?.fast_amount)}</b>
                      원/kwh
                    </p>
                  </div>
                  <div style={{marginLeft: 10}}>
                    <p className="body3">완속단가</p>
                    <p className="price">
                      <b>{addComma(item?.fast_amount)}</b>
                      원/kwh
                    </p>
                  </div>
                </Row>
              </section>
            )
          })}
      </div>
      <header className="auto">
        <Tab
          selected={selected}
          style={{marginBottom: 32}}
          info={tabMenu}
          onChange={({index}) => {
            setSelected(index)
          }}
        />
      </header>
      <main>{setContents()}</main>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 920px;
    height: 180px;
    margin: 24px auto;
    /* padding: 30px 30px; */
    border: 1px solid ${COLOR.GRAY5};
    border-radius: 8px;
    /* 구분 */
    section.section {
      text-align: center;
      width: 200px;
      margin: 20px 20px;
      padding: 37px 0;
      .body3 {
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.45px;
      }
      p.price {
        font-size: 13px;
        line-height: 24px;
        letter-spacing: -0.45px;
        color: ${COLOR.GRAY2};
        b {
          display: inline-block;
          margin-right: 2px;
          color: ${COLOR.BLUE3};
          font-weight: bold;
        }
      }
    }
  }
  header {
    width: 674px;
    margin: 24px auto;
  }
  main {
    width: 674px;
    margin: 24px auto;
    padding-bottom: 30px;
  }
`
