import React from 'react';
import { InnerCellContainer, TD, TR, RadioButton } from './AuthorityByMenu.style';
import typography from '@/styles/typography';
import { Image } from '@/lib/index';
import { icon_radio_disabled, icon_radio_off, icon_radio_on } from '@/assets/images';
import { IMenu, IMenuItems } from '@/types/menu';

function AuthorityByMenu({
  categoryMenu,
  menuItem,
  editMode,
  isChecked,
  onChangeValue,
}: {
  categoryMenu?: IMenu;
  menuItem: IMenuItems;
  editMode: boolean;
  isChecked: boolean;
  onChangeValue?: (menuSeq: number, value: boolean) => void;
}) {
  const setImage = (mode: boolean | null) => {
    if (!editMode && !mode) return <Image src={icon_radio_disabled} style={{ width: 18, height: 18 }} />;
    if (mode) return <Image src={icon_radio_on} style={{ width: 18, height: 18 }} />;
    if (!mode) return <Image src={icon_radio_off} style={{ width: 18, height: 18 }} />;
    return null;
  };

  return (
    <TR>
      <TD $customFont={typography.body2}>
        {categoryMenu?.name ? `${categoryMenu.name} > ${menuItem.name}` : menuItem.name}
      </TD>
      <TD $customFont={typography.body5}>
        <InnerCellContainer>
          <RadioButton
            $editMode={editMode}
            onClick={() => {
              if (editMode) {
                if (onChangeValue) {
                  onChangeValue(menuItem.seq, true);
                }
              }
            }}>
            {setImage(isChecked)}
          </RadioButton>
        </InnerCellContainer>
      </TD>
      <TD $customFont={typography.body5}>
        <InnerCellContainer>
          <RadioButton
            $editMode={editMode}
            onClick={() => {
              if (editMode) {
                if (onChangeValue) {
                  onChangeValue(menuItem.seq, false);
                }
              }
            }}>
            {setImage(!isChecked)}
          </RadioButton>
        </InnerCellContainer>
      </TD>
    </TR>
  );
}

export default AuthorityByMenu;
