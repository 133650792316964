import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { Text } from 'lib';
import typography from '@/styles/typography';
import { COLOR } from '@/styles/index';
import { Table, TableBody, TableHead, TD, TH, TR, Wrapper } from './CalculateDetailSummaryTable.style';
import { useAppSelector } from '@/model/index';
import { ICalculate } from '@/lib/apis/sales/type';

export default function CalculateDetailSummaryTable() {
  const { t } = useTranslation('common');
  const {
    list: { summary: list },
  } = useAppSelector(state => state.sales);

  const summary = list?.[0] as ICalculate;
  return (
    <Wrapper>
      <Text color={COLOR.BLACK3} className="body6">
        {`${t('pages.sales.calculateStatusDetail.summaryTitle')}`}
      </Text>
      <Table>
        <colgroup>
          <col style={{ minWidth: 61 }} />
          <col style={{ minWidth: 61 }} />
          <col style={{ minWidth: 61 }} />
          <col style={{ minWidth: 61 }} />
          <col style={{ minWidth: 61 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 138 }} />
        </colgroup>
        <TableHead>
          <TR>
            <TH>{t('pages.sales.calculateStatus.target.year')}</TH>
            <TH>{t('pages.sales.calculateStatus.target.month')}</TH>
            <TH>{t('pages.sales.calculateStatus.target.stations')}</TH>
            <TH>{t('pages.sales.calculateStatus.target.chargers')}</TH>
            <TH>{t('pages.sales.calculateStatus.total.chargeAmount')}</TH>
            <TH>{t('pages.sales.calculateStatus.total.chargePrice')}</TH>
          </TR>
        </TableHead>
        <TableBody>
          {list && (
            <TR key={nanoid()}>
              <TD $customFont={typography.body5}>
                {summary.year}
                {t('common.unit.year')}
              </TD>
              <TD $customFont={typography.body5}>
                {summary.month}
                {t('common.unit.month')}
              </TD>
              <TD $customFont={typography.body4}>{summary.stations}</TD>
              <TD $customFont={typography.body4}>{summary.chargepoints}</TD>
              <TD $customFont={typography.body5}>{summary.kw}</TD>
              <TD $customFont={typography.body5}>{summary.amount}</TD>
            </TR>
          )}
        </TableBody>
      </Table>
    </Wrapper>
  );
}
