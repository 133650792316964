import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLOR, layout } from 'styles';
import { ResponsiveLine, ResponsiveLineCanvas } from '@nivo/line';
import { useTranslation } from 'react-i18next';
import Header from './header';
import { useAppDispatch, useAppSelector } from '@/model/index';
import { getChart } from '@/lib/apis/dashborad';
import { actions } from '@/model/modules/dashboard';
import { Chart } from '@/lib/apis/dashborad/type';
import { addComma } from '@/lib/index';
import {
  ChartTooltipWrapper,
  Rectangle,
  UnitText,
  XAxisText,
  XAxisTextContainer,
  YAxisText,
  YAxisTextContainer,
} from '@/components/organisms/Cards/HourlySalesChart/HourlySalesChart.styles';

/**
 * @name 총매출
 * @description
 * @todo canvas , ResponsiveLine,ResponsiveLineCanvas
 */
export default function HourlySalesChart() {
  const { lineGraph } = useAppSelector(state => state.dashboard);
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const [chartData, setChartData] = useState([]);
  const fetchData = async () => {
    const { isSuccess, data } = await getChart({ type: 1 });
    if (isSuccess) {
      dispatch(actions.setLineGraph(data));
    }
  };

  const setLineChartData = () => {
    const dd = lineGraph.chart.map((item: Chart) => {
      return { x: item.datetime, y: parseFloat(item.total) };
    });

    setChartData(dd);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setLineChartData();
  }, [lineGraph]);
  // hooks
  const info = [
    {
      id: '시간',
      data: chartData,
    },
  ];
  return (
    <Content className={layout.panel}>
      {/* 시간대 별 매출 */}
      <Header />
      <div style={{ width: '100%', height: 390 }}>
        <ResponsiveLine
          data={info}
          margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            // stacked: true,
            // reverse: false,
          }}
          // 기본설정
          // gridYValues={6} // 세로축라인횟수
          enableGridX
          enableGridY
          yFormat=" >-.2f"
          // curve="natural"
          enableArea
          areaOpacity={0.1}
          areaBaselineValue={0}
          useMesh
          pointSize={7}
          colors={[COLOR.BLUE3]}
          pointColor={COLOR.BLUE2}
          pointBorderWidth={1}
          pointBorderColor={COLOR.BLUE5}
          pointLabelYOffset={-12}
          tooltip={({ point }) => {
            return (
              <ChartTooltipWrapper>
                <XAxisTextContainer>
                  <Rectangle $serieColor={point.serieColor} />
                  <XAxisText $serieColor={point.serieColor}>{`${point.data.xFormatted}시`}</XAxisText>
                </XAxisTextContainer>
                <YAxisTextContainer>
                  <YAxisText>{addComma(point.data.yFormatted)}</YAxisText>
                  <UnitText>{t('common.currency.KRW')}</UnitText>
                </YAxisTextContainer>
              </ChartTooltipWrapper>
            );
          }}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </Content>
  );
}

const Content = styled.div`
  width: 100%;
  height: 461px;
  margin-bottom: 24px;
`;
