import React from 'react';
import styled from 'styled-components';
import Wave from 'react-wavify';
import { CSS } from 'types';

type Props = {
  style?: CSS;
};
/**
 * @name 매출총액
 * @description
 */
export default function TotalSalesItemYellow({ style }: Props) {
  return (
    <Content style={style}>
      <div className="wrap">
        <Wave fill="url(#gradient10)" options={{ height: 45, amplitude: 15, speed: 0.25, points: 4 }}>
          <defs>
            <linearGradient id="gradient10" gradientTransform="rotate(40)">
              <stop offset="0%" stopColor="#F6AE3F" />
              <stop offset="100%" stopColor="#F6AE3F" />
            </linearGradient>
          </defs>
        </Wave>
      </div>
      <div className="wrap">
        <Wave fill="url(#gradient11)" options={{ height: 50, amplitude: 12, speed: 0.35, points: 2 }}>
          <defs>
            <linearGradient id="gradient11" gradientTransform="rotate(30)">
              <stop offset="10%" stopColor="#F6AE3F" />
              <stop offset="100%" stopColor="#FDC061" />
            </linearGradient>
          </defs>
        </Wave>
      </div>
      <div className="wrap">
        <Wave fill="url(#gradient12)" options={{ height: 60, amplitude: 17, speed: 0.4, points: 5 }}>
          <defs>
            <linearGradient id="gradient12" gradientTransform="rotate(0)">
              <stop offset="0%" stopColor="#F6AE3F" />
              <stop offset="100%" stopColor="#FFFF00" />
            </linearGradient>
          </defs>
        </Wave>
      </div>
    </Content>
  );
}

const Content = styled.div`
  position: relative;
  margin-top: 24px;
  /* margin-bottom: 25px; */
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  border-radius: 200px;
  background: #f4f4f4;
  .wrap {
    position: absolute;
    top: 150;
    left: 0;
  }
  footer {
    align-items: center;
  }
`;
