/**
 * @name 커넥터
 * @description
 */
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { COLOR } from 'styles';
import { Image, makeArray, useClickOutside, uuid } from 'lib';
import { icon_arrow_down_black, icon_arrow_up_black } from 'assets/images';

type Props = {
  style?: React.CSSProperties; //---------------------- StyleSheet
  popupStyle?: React.CSSProperties; //----------------- PopupStyleSheet
  onChange?: (value: any) => void;
  disabled?: boolean; //------------------------------- disabled
  value?: string;
  [key: string]: any;
};
export default function DropDownTimes({ onChange, style, disabled, popupStyle, value }: Props) {
  // hooks
  // hooks
  const year = makeArray(20, { label: 'name', value: new Date().getFullYear() }).map((item: any, index) => {
    const _val = item?.value - index;
    return { label: _val + '', value: _val };
  });
  const eleRef = useRef(null);
  const [title, setTitle] = useState(value);
  const [visible, setVisible] = useState(false);
  //*--------------------------------------------------*
  // outside click
  useClickOutside(eleRef, () => {
    setVisible(false);
  });
  //*--------------------------------------------------*
  return (
    <Content ref={eleRef} style={style}>
      <button
        className="select"
        onClick={() => {
          // 비활성화면 종료
          if (disabled) return;
          setVisible(!visible);
        }}>
        <label className="selected">{title || `전체 연도`}</label>
        <Image src={visible ? icon_arrow_up_black : icon_arrow_down_black} style={{ width: 20, height: 20 }} />
      </button>
      {/* 레이어팝업 */}
      {visible && (
        <section className="popup" style={popupStyle}>
          {year?.map((item: any) => {
            return (
              <button
                className={`item ${item?.label === title ? 'active' : ''}`}
                key={uuid()}
                onClick={() => {
                  !!onChange && onChange(item);
                  setTitle(item?.label);
                  setVisible(false);
                }}>
                {item?.label}
              </button>
            );
          })}
        </section>
      )}
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  position: relative;
  width: 100%;
  min-width: 120px;
  max-width: 140px;
  /* 레이어팝업 */
  button.select {
    display: flex;
    width: 100%;
    height: 40px;
    padding: 0 16px;
    border: 1px solid ${COLOR.GRAY4};
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    color: ${COLOR.GRAY2};
    label.selected {
      color: ${COLOR.GRAY2};
    }
  }
  /* 레이어팝업 */
  section.popup {
    position: absolute;
    z-index: 1;
    background-color: ${COLOR.WHITE};
    width: 100%;
    height: 240px;
    box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.07);
    border-radius: 8px;
    overflow-y: scroll;
    button.item {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
      height: 40px;
      padding: 0 16px;
      color: ${COLOR.BLACK1};
      &.active {
        background-color: ${COLOR.BLUE7};
      }
      &:hover {
        background-color: ${COLOR.GRAY6};
      }
      &:active {
        background-color: ${COLOR.BLUE_GRAY9};
      }
    }
  }
`;
/********************************************************

<DropDownTimes
  value={payload?.station_open[index]?.open_time}
  onChange={(data: any) => {
    onChangeHandler({key: 'open_time', index, value: data?.value})
  }}
/>
*********************************************************/
