/**
 * @name TextButton
 * @description 버튼
 */
import React, {CSSProperties} from 'react'
import styled from 'styled-components'

type Props = {
  label: string
  className?: string //----------------------- 클래스네임
  buttonStyle?: CSSProperties //-------------- 스타일시트
  style?: CSSProperties //-------------------- 스타일시트
  onClick?: (event: React.MouseEvent<HTMLElement>) => void //------------------------ onClick 이벤트
}
export default function TextButton({label = 'TEXT_BUTTON', className, buttonStyle = {}, style = {}, onClick}: Props) {
  // hooks
  const _style = Object.assign({}, !!style && style)
  //*--------------------------------------------------*
  return (
    <Element
      style={buttonStyle}
      className={className}
      onClick={event => {
        onClick && onClick(event)
      }}>
      <label style={_style}>{label}</label>
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.button`
  label {
    cursor: pointer;
  }
`

/********************************************************
[사용법]
<ImageButton
  buttonStyle={{display: 'block', border: '1px solid red'}}
  style={{backgroundColor: 'red'}}
  source={icon_alert}
  onClick={() => {
    alert('test')
  }}
/>
*********************************************************/
