import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'model';
import ChargerDetailPresenter from './ChargerDetail.presenter';
import { getCharger, removeCharger } from '@/lib/apis/infra';
import { IResponse } from '@/lib/apis/api/type';
import { IChargepoint } from '@/types/infra';
import { MENU } from '@/pages/router';
import { actions } from '@/model/modules/device';
import { SCREEN_MODE } from '@/types/mode';

export default function ChargerDetailContainer() {
  const { cpo_seq } = useAppSelector(state => state.auth);
  const { id } = useParams();
  const [deletePopup, setDeletePopup] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const searchKeyword = searchParams.get('search') || '';
  const locationKeyword = searchParams.get('location') || '';
  const filterYn = searchParams.get('filter') || '';

  const fetchChargerData = async () => {
    if (id && cpo_seq) {
      const chargerResponse: IResponse<IChargepoint> = await getCharger(id, cpo_seq);
      if (chargerResponse.isSuccess) {
        dispatch(actions.setView(chargerResponse.data));
      }
    }
  };

  const queryParamsURL = () => {
    const queryParams = [];

    if (searchKeyword) {
      queryParams.push(`search=${encodeURIComponent(searchKeyword)}`);
    }
    if (locationKeyword) {
      queryParams.push(`location=${encodeURIComponent(locationKeyword)}`);
    }
    if (filterYn) {
      queryParams.push(`filter=${encodeURIComponent(filterYn)}`);
    }
    queryParams.push(`page=${page}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const handleEdit = () => {
    dispatch(actions.setMode(SCREEN_MODE.MODIFY));
    navigate(`${MENU.INFRA.CHARGER_MANAGEMENT_UPDATE}/${id}?${queryParamsURL()}`);
  };
  const handleDeletePopup = (value: boolean) => {
    setDeletePopup(value);
  };
  const handleDeleteConfirm = async () => {
    try {
      if (id) {
        const removeResponse = await removeCharger(id);
        if (removeResponse.isSuccess) {
          handleDeletePopup(false);
          dispatch(actions.initView());
          navigate(MENU.INFRA.CHARGER_MANAGEMENT);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleBackToList = () => {
    navigate(`${MENU.INFRA.CHARGER_MANAGEMENT}?${queryParamsURL()}`);
  };

  useEffect(() => {
    fetchChargerData();
  }, [id, cpo_seq]);

  return (
    <ChargerDetailPresenter
      onEdit={handleEdit}
      handleDeletePopup={handleDeletePopup}
      deletePopupVisible={deletePopup}
      onDeleteConfirm={handleDeleteConfirm}
      handleBackToList={handleBackToList}
    />
  );
}
