/**
 * @name 캘린더
 * @description
 */
import { Button, debug, H1, useCalendar, useContext, uuid } from 'lib';
import { COLOR } from 'styles';
import styled from 'styled-components';
import { MENU } from '@/pages/router';
import { useNavigate } from 'react-router-dom';

export default function Contents() {
  // hooks
  const { navigate } = useContext();
  const navigation = useNavigate();
  //*--------------------------------------------------*

  //*--------------------------------------------------*
  return (
    <Content>
      <div className="wrap">
        <Button
          onClick={() => {
            const payload = {
              title: '타이틀',
              data: ['1', '2', '3'],
            };
            navigate(MENU.TEST, { state: payload });
          }}>
          TEST 이동
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          onClick={() => {
            const payload = {
              title: '타이틀',
              data: ['1', '2', '3'],
            };
            navigate(`${MENU.TEST}/TEST_CODE`, { state: payload });
          }}>
          useParams 이동
        </Button>
      </div>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  .wrap {
    width: 300px;
    /* height: 150px; */
  }
`;
/********************************************************
[사용법]

<Button
  onClick={() => {
    const payload = {
      title: '타이틀',
      data: ['1', '2', '3'],
    }
    navigate(MENU.TEST, {state: payload})
  }}>
  TEST 이동
</Button>

<Button
  onClick={() => {
    navigate(`${MENU.TEST}/TEST_CODE`, {state: payload})
  }}>
  useParams 이동
</Button>
*********************************************************/
