import React from 'react';
import { Text } from 'lib';
import styled from 'styled-components';
import { COLOR } from 'styles';
import typography from '@/styles/typography';

/**
 * @name ListKeyValue
 * @description @key값을, 예약어라 title로 변경
 */
type Props = {
  style?: React.CSSProperties;
  keyStyle?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
  title?: string;
  left?: boolean;
  leftValue?: boolean;
  component?: React.ReactNode;
  value?: string | number;
  children?: React.ReactNode;
};
export default function ListKeyValue({
  style,
  keyStyle,
  left,
  leftValue,
  valueStyle,
  title,
  value,
  component,
  children,
}: Props) {
  return (
    <Element style={style}>
      <dt className={left ? 'left' : ''} style={keyStyle}>
        {title ?? 'KEY'}
      </dt>
      <ContentsWrapper>
        <dd style={valueStyle} className={leftValue ? 'left' : ''}>
          {!!component && component}
          {value}
        </dd>
        {children}
      </ContentsWrapper>
    </Element>
  );
}
const ContentsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 8px 14px;
`;

const Element = styled.dl`
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  .right {
    justify-content: flex-end;
    align-items: flex-end;
  }
  dt {
    ${typography.headline6};
    display: flex;
    width: 200px;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${COLOR.GRAY1};
    background-color: ${COLOR.BLUE8};
    white-space: pre-line;
    &.left {
      justify-content: flex-start;
    }
  }
  dd {
    ${typography.headline6};
    display: flex;
    width: 100%;
    color: ${COLOR.GRAY1};
    align-items: center;
    justify-content: center;
    white-space: pre-line;
    text-align: center;
    &.left {
      justify-content: flex-start;
    }
  }
`;
/** ******************************************************
[사용법]

 <ListKeyValue leftValue title="보유충전기 수" value={view?.data?.station?.chargepoint_count} component={<Button />} />


  <ListKeyValue
    title="단가유형"
    component={
      <DropDown
        info={info}
        onChange={data => {
          console.log(data)
        }}
      />
    }
  />

******************************************************** */
