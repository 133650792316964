/**
 * @name Container
 */
import {useEffect} from 'react'
import {Events, useAxios} from 'lib'
import {GUIDE_EVENT} from 'model/modules'
import Presenter from './presenter'

export default function Container() {
  // hooks
  const url = 'http://211.49.126.109:9999/api/v1/user/login'
  const data = {
    email: 'DC',
    password: '1q2w3e4',
  }

  const {post} = useAxios()
  // const {loading, get} = useAxios()
  // const dispatch = useAppDispatch()
  // const {data} = useAppSelector(state => state.guide)

  //*--------------------------------------------------*
  async function onSubmit() {
    const res = await post(url, data)
    // const res = await axios(config)
  }
  //*--------------------------------------------------*
  useEffect(() => {
    // addListener
    Events.addListener(GUIDE_EVENT.SUBMIT, onSubmit)
    return () => {
      // removeListener
      Events.removeListener(GUIDE_EVENT.SUBMIT, onSubmit)
    }
  }, [])

  return <Presenter />
}
