import React from 'react';
import { App } from 'components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/model/index';
import {
  BodyContainer,
  ButtonWrapper,
  Container,
  HeaderContainer,
  SubmitContainer,
} from './AccountManagementRegister.styles';
import { COLOR } from '@/styles/index';
import { Button, Div, H3, ImageButton, Row, Text } from '@/lib/index';
import { icon_arrow_prev_black } from '@/assets/images';
import { MENU } from '@/pages/router';
import { SCREEN_MODE } from '@/types/mode';
import AccountAuthorityForm from '@/components/organisms/Forms/AccountAuthorityForm';
import AccountRegisterForm from '@/components/organisms/Forms/AccountRegisterForm';
import { PopupContainer, PopupCreate, SubmitCancel, SubmitConfirm } from '@/components/form';

// @name 계정관리 등록
export default function AccountManagementRegisterPresenter({
  popupInviteVisible,
  handleInvitePopup,
  popupSuccessVisible,
  handleDone,
  popupCancelVisible,
  handleCloseCancelPopup,
  popupFailedVisible,
  handleCloseFailedPopup,
  onCancelPopup,
  requiredFieldsWarning,
}: {
  popupInviteVisible: boolean;
  handleInvitePopup: { on: () => void; close: () => void; confirm: () => void };
  popupSuccessVisible: boolean;
  handleDone: () => void;
  popupCancelVisible: boolean;
  handleCloseCancelPopup: () => void;
  popupFailedVisible: boolean;
  handleCloseFailedPopup: () => void;
  onCancelPopup: () => void;
  requiredFieldsWarning: {
    email: boolean;
    roleCode: boolean;
    businessNumber: boolean;
    requireDuplicateCheck: boolean;
    operatorCompanyName: boolean;
  };
}) {
  const { mode, invitePayload } = useAppSelector(state => state.admin);
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const isModify = mode === SCREEN_MODE.MODIFY;

  const handleNavigateToList = () => {
    navigate(MENU.ADMIN.ACCOUNT_MANAGEMENT);
  };

  return (
    <App>
      <HeaderContainer>
        <Row>
          <ImageButton
            onClick={() => handleNavigateToList()}
            source={icon_arrow_prev_black}
            style={{ width: 24, height: 24, marginRight: 15 }}
          />
          <H3 color={COLOR.BLACK3}>{t('pages.auth.inviteUser')}</H3>
        </Row>
      </HeaderContainer>
      <BodyContainer>
        <Container>
          <AccountRegisterForm requiredFieldsWarning={requiredFieldsWarning} />
          <AccountAuthorityForm />
          {mode !== SCREEN_MODE.VIEW && (
            <SubmitContainer>
              <Text className="center body3">{t('pages.admin.account.register.inviteDescription')}</Text>
              <ButtonWrapper>
                <Div>
                  <Button
                    full
                    label={t('common.buttons.cancel')}
                    color={COLOR.GRAY2}
                    borderColor={COLOR.GRAY2}
                    bg="transparent"
                    onClick={onCancelPopup}
                  />
                  <div style={{ width: 32 }} />
                  <Button full label={t('common.buttons.inviteAction')} onClick={handleInvitePopup.on} />
                </Div>
              </ButtonWrapper>
            </SubmitContainer>
          )}
        </Container>
      </BodyContainer>
      <PopupContainer visible={popupSuccessVisible} onClose={handleDone}>
        <PopupCreate title={t('common.toast.inviteSuccess')} onConfirm={handleDone} />
      </PopupContainer>
      <PopupContainer visible={popupInviteVisible}>
        <PopupCreate
          title={t('common.toast.inviteConfirmTitle')}
          message={invitePayload.invite_email}
          description={t('common.toast.inviteDesc')}
          footer={
            <SubmitConfirm
              confirmLabel={t('common.buttons.submit')}
              onCancel={handleInvitePopup.close}
              onConfirm={handleInvitePopup.confirm}
            />
          }
        />
      </PopupContainer>
      <PopupContainer visible={popupCancelVisible}>
        <PopupCreate
          warning
          title={isModify ? t('common.toast.cancelUpdateAccount') : t('common.toast.cancelRegisterAccount')}
          message={isModify ? t('common.toast.askCancel') : t('common.toast.askCancelWithoutSave')}
          footer={
            <SubmitCancel
              confirmLabel={
                isModify ? t('common.buttons.cancelUpdateAction') : t('common.buttons.cancelRegisterAction')
              }
              onCancel={handleCloseCancelPopup}
              onConfirm={handleDone}
            />
          }
        />
      </PopupContainer>
      <PopupContainer visible={popupFailedVisible} onClose={handleCloseFailedPopup}>
        <PopupCreate
          warning
          title={isModify ? t('common.toast.failedUpdateAccountTitle') : t('common.toast.failedRegisterAccountTitle')}
          message={
            isModify ? t('common.toast.failedUpdateAccountMessage') : t('common.toast.failedRegisterAccountMessage')
          }
          onConfirm={handleCloseFailedPopup}
        />
      </PopupContainer>
    </App>
  );
}
