import React, { ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';
import { TextFiledGroup } from 'components/form';
import { COLOR } from 'styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/model/index';
import { Table, TableBody, TableHead, TH, TR } from './AccountAuthorityForm.styles';
import { IMenu, IMenuItems } from '@/types/menu';
import AuthorityByMenu from '@/components/molecules/RadioRows/AuthorityByMenu';
import { SCREEN_MODE } from '@/types/mode';
import { actions } from '@/model/modules/admin';
import { IAssignMenu } from '@/types/admin';
import { AdminPermission } from '@/pages/common';

export default function AccountAuthorityForm() {
  const { permissions, mode, invitePayload, accountDetail } = useAppSelector(state => state.admin);
  const { menu } = useAppSelector(state => state.gnb);
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const isModify = mode === SCREEN_MODE.MODIFY;
  const isRegister = mode === SCREEN_MODE.REGISTER;

  // 메뉴 권한 체크
  const onChangeInfo = (menuSeq: number, value: boolean, adminPermissionKey?: number) => {
    const assignValue = value ? 1 : 0;
    const updatedAssignMenu = invitePayload.assign_menu.map((menuItem: IAssignMenu) => {
      if (menuItem.menu_seq === menuSeq) {
        return {
          ...menuItem,
          is_read: assignValue,
          is_create: assignValue,
          is_update: assignValue,
          is_delete: assignValue,
        };
      }
      return menuItem;
    });

    // 부모 메뉴에 대한 권한 설정
    const parentSeq = menu.find((category: IMenu) => category.menu_items.some(item => item.seq === menuSeq))?.seq;

    if (parentSeq) {
      const childItems = menu.find((category: IMenu) => category.seq === parentSeq)?.menu_items;
      const childSeqs = childItems?.map((item: IMenuItems) => item.seq) || [];

      const childrenStatuses = updatedAssignMenu.filter((item: IAssignMenu) => childSeqs.includes(item.menu_seq));

      const anyChildOn = childrenStatuses.some((item: IAssignMenu) => item.is_read === 1);
      const allChildrenOff = childrenStatuses.every((item: IAssignMenu) => item.is_read === 0);

      updatedAssignMenu.forEach((item: IAssignMenu, index: number) => {
        if (item.menu_seq === parentSeq) {
          let newValues = {
            is_read: item.is_read,
            is_create: item.is_create,
            is_update: item.is_update,
            is_delete: item.is_delete,
          };

          if (anyChildOn) {
            newValues = { is_read: 1, is_create: 1, is_update: 1, is_delete: 1 };
          } else if (allChildrenOff) {
            newValues = { is_read: 0, is_create: 0, is_update: 0, is_delete: 0 };
          }

          updatedAssignMenu[index] = {
            ...item,
            ...newValues,
          };
        }
      });
    }

    dispatch(
      actions.setInvitePayload({
        ...invitePayload,
        assign_menu: updatedAssignMenu,
      }),
    );
  };

  const createPermissionObject = (menu_seq: number, adminMenuSeq?: number) => {
    return { menu_seq, is_read: 0, is_create: 0, is_update: 0, is_delete: 0, menu_admin_permissions_seq: adminMenuSeq };
  };

  const createAssignPermissionObject = (menu_seq: number, adminMenuSeq?: number) => {
    return { menu_seq, is_read: 1, is_create: 1, is_update: 1, is_delete: 1, menu_admin_permissions_seq: adminMenuSeq };
  };

  // 관리자 초대용 메뉴 권한 초기화 (등록)
  const initInvitePayload = () => {
    const permissionsInit = menu.reduce(
      (acc: IAssignMenu[], categoryMenu: IMenu) => {
        acc.push(createPermissionObject(categoryMenu.seq));
        categoryMenu.menu_items.forEach((menuItem: IMenuItems) => {
          acc.push(createPermissionObject(menuItem.seq));
        });
        return acc;
      },
      [createAssignPermissionObject(1)],
    );
    permissionsInit.sort((a: IAssignMenu, b: IAssignMenu) => a.menu_seq - b.menu_seq);
    dispatch(
      actions.setInvitePayload({
        ...invitePayload,
        assign_menu: permissionsInit,
      }),
    );
  };

  // 관리자 초대용 메뉴 권한 초기화 (수정)
  const initModifyInvitePayload = () => {
    const permissionsInit = menu.reduce(
      (acc: IAssignMenu[], categoryMenu: IMenu) => {
        acc.push(createPermissionObject(categoryMenu.seq));
        categoryMenu.menu_items.forEach((menuItem: IMenuItems) => {
          acc.push(createPermissionObject(menuItem.seq));
        });
        return acc;
      },
      [createPermissionObject(1)],
    );
    const alreadyAssignedMenu = permissionsInit.map((item: IAssignMenu) => {
      const findItem = accountDetail.admin_permissions.find(
        (permissionMenu: AdminPermission) => permissionMenu.menu.seq === item.menu_seq,
      );
      if (findItem.is_read) {
        return createAssignPermissionObject(findItem.menu.seq, findItem.seq);
      }
      return createPermissionObject(findItem.menu.seq, findItem.seq);
    });

    alreadyAssignedMenu.sort((a: IAssignMenu, b: IAssignMenu) => a.menu_seq - b.menu_seq);
    dispatch(
      actions.setInvitePayload({
        ...invitePayload,
        assign_menu: alreadyAssignedMenu,
      }),
    );
  };

  useEffect(() => {
    if (isRegister) initInvitePayload();
    if (isModify) initModifyInvitePayload();
  }, []);

  return (
    <Content>
      <TextFiledGroup
        label={t('pages.admin.account.detail.authorityByMenu')}
        component={
          <Table>
            <TableHead>
              <TR>
                <TH>{t('pages.admin.account.detail.menuName')}</TH>
                <TH>{t('pages.admin.account.detail.all')}</TH>
                {/* <TH>{t('pages.admin.account.detail.write')}</TH> */}
                {/* <TH>{t('pages.admin.account.detail.read')}</TH> */}
                <TH>{t('pages.admin.account.detail.none')}</TH>
              </TR>
            </TableHead>
            <TableBody>
              <AuthorityByMenu
                menuItem={{
                  seq: 1,
                  name: '대시보드',
                }}
                isChecked
                editMode={false}
                onChangeValue={(editKey, value) => onChangeInfo(editKey, value)}
              />
              {menu.map((categoryMenu: IMenu) => {
                return categoryMenu.menu_items.map((menuItem: IMenuItems) => {
                  let isChecked = false;
                  if (isModify || isRegister) {
                    const matchingPermission = invitePayload.assign_menu.find(
                      (permission: IAssignMenu) => permission.menu_seq === menuItem.seq,
                    );
                    isChecked = matchingPermission ? matchingPermission.is_read === 1 : false;
                  } else {
                    const matchingPermission = accountDetail.admin_permissions.find(
                      (permission: AdminPermission) => permission.menu.seq === menuItem.seq,
                    );
                    if (matchingPermission) {
                      isChecked = matchingPermission.is_read;
                    } else {
                      isChecked = false;
                    }
                  }
                  return (
                    <AuthorityByMenu
                      key={menuItem.seq}
                      categoryMenu={categoryMenu}
                      menuItem={menuItem}
                      isChecked={isChecked}
                      editMode={isModify || isRegister}
                      onChangeValue={(editKey, value) => onChangeInfo(editKey, value)}
                    />
                  );
                });
              })}
            </TableBody>
          </Table>
        }
      />
    </Content>
  );
}
const Content = styled.div`
  width: 776px;
  margin: 24px auto 32px;
  table {
    table-layout: fixed;
    width: 100%;
    td {
      /* width: 20%; */
    }
    td.name {
      border-right: 1px solid ${COLOR.GRAY5};
    }
  }
`;
