import React from 'react';
import { App } from 'components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { COLOR } from '@/styles/index';
import typography from '@/styles/typography';
import FixedRateTable from '@/components/organisms/Tables/FixedRateTable';

export default function ChargingRateListPresenter({
  itemsPerPage,
  currentPage,
  pageHandler,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
}) {
  const { t } = useTranslation('common');
  return (
    <App empty>
      <Wrapper>
        <Header>
          <HeaderText>{t('forms.price.status')}</HeaderText>
        </Header>
        <FixedRateTable
          /*  계시별 단가 필요한 경우 charging-rate-management/backup 디렉토리 하위 파일 참고 */
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          pageHandler={pageHandler}
        />
      </Wrapper>
    </App>
  );
}

const Wrapper = styled.div`
  padding: 18px 24px 30px 24px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  padding: 13px 24px;
  border-radius: 8px;
  background-color: ${COLOR.WHITE};
  border-bottom: 1px solid ${COLOR.GRAY5};

  align-items: center;
  justify-content: space-between;
`;

const HeaderText = styled.span`
  ${typography.headline3};
  color: ${COLOR.BLACK3};
`;
