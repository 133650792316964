import { App, BackButton, ButtonsModifyDel } from 'components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button } from 'lib';
import Badge from '@/components/atoms/Badge';
import {
  Header,
  ListWrapper,
  SummaryWrapper,
  TableTitle,
  styleListKeyValue,
  Wrapper,
  RowHeader,
  Cell,
  CustomGap,
  Row,
} from './TroubleReportsDetail.styles';
import { ListKeyValue } from '@/components/form';
import { icon_edit_white } from '@/assets/images';
import { TroubleReport } from '@/lib/apis/operation/trouble-reports/type';

export default function TroubleReportsDetailPresenter({
  handleBackToList,
  troubleReport,
  onEdit,
}: {
  handleBackToList: () => void;
  troubleReport: TroubleReport | null;
  onEdit: () => void;
}) {
  const { t } = useTranslation('common');
  if (!troubleReport) {
    return null;
  }

  return (
    <App>
      <Header style={{ width: '100%' }}>
        <BackButton label={t('pages.operation.troubleReports.detail.title')} onClick={handleBackToList} />
        <Row>
          <Button
            icon={icon_edit_white}
            label="수정"
            style={{ marginRight: 16 }}
            onClick={() => {
              onEdit();
            }}
          />
        </Row>
      </Header>
      <Wrapper>
        <SummaryWrapper>
          <TableTitle>{t('pages.operation.troubleReports.detail.dataTitle')}</TableTitle>
          <ListWrapper>
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.title')}
              value={troubleReport.title}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.userMemo')}
              value={troubleReport.user_memo || '-'}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.adminMemo')}
              value={troubleReport.admin_memo || '-'}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.status')}
              value={troubleReport.status}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.remoteData')}
              value={troubleReport.remote_data ? JSON.stringify(troubleReport.remote_data, null, 2) : '-'}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.createdAt')}
              value={dayjs(troubleReport.created_at).format('YYYY.MM.DD HH:mm:ss')}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.roleCode')}
              value={troubleReport.role_code}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.chargepoint.code')}
              value={troubleReport.chargepoint.code}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.chargepoint.station.name')}
              value={troubleReport.chargepoint.station.name}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.chargepoint.model.name')}
              value={troubleReport.chargepoint.model.name}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.chargepoint.model.chargerType')}
              value={troubleReport.chargepoint.model.charger_type}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.chargepoint.model.vendor.name')}
              value={troubleReport.chargepoint.model.vendor.name}
              valueStyle={styleListKeyValue}
            />
          </ListWrapper>
        </SummaryWrapper>
        <CustomGap />
        {/* <SummaryWrapper>
          <TableTitle>{t('pages.operation.troubleReports.detail.result')}</TableTitle>
          <Row>
            <RowHeader>{t('pages.operation.troubleReports.detail.targetDevice')}</RowHeader>
            <RowHeader>{t('pages.operation.troubleReports.detail.targetDeviceID')}</RowHeader>
            <RowHeader>{t('pages.operation.troubleReports.detail.result')}</RowHeader>
            <RowHeader>{t('pages.operation.troubleReports.detail.chargepointBelongsTo')}</RowHeader>
            <RowHeader>{t('pages.operation.troubleReports.detail.stationBelongsTo')}</RowHeader>
          </Row>
          <Row>
            <Cell>
              {troubleReport.connector_number === 0
                ? t('pages.operation.troubleReports.detail.charger')
                : t('pages.operation.troubleReports.detail.connector')}
            </Cell>
            <Cell>{troubleReport.chargepoint.code}</Cell>
            <Cell>{renderActionResult(troubleReport.response_type)}</Cell>
            <Cell>{troubleReport.chargepoint.name}</Cell>
            <Cell>{troubleReport.chargepoint.station.name}</Cell>
          </Row>
        </SummaryWrapper> */}
      </Wrapper>
    </App>
  );
}
