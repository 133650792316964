import React from 'react';
import styled from 'styled-components';
import { H5, ListDescription, uuid } from 'lib';
import { COLOR } from 'styles';
import { useTranslation } from 'react-i18next';

/**
 * @name 보유 충전기 및 적용된 충전 단가
 * @description
 */
export default function Contents() {
  const { t } = useTranslation('common');
  const info = [
    { key: '보유 충전기 수', value: '333,999 대' },
    { key: '충전단가 기준', value: '고정단가' },
    { key: '충전단가', value: '회원 : 221,300원 비회원 : 34,330원 로밍회원 : 55,310원' },
  ];

  return (
    <Content>
      <H5 color={COLOR.GRAY1}>{t('pages.deviceStatus.connectors.title')}</H5>
      <ListDescription
        info={info}
        renderItem={({ item }) => {
          return (
            <dl key={uuid()}>
              <dt>{item?.key}</dt>
              <dd>{item?.value}</dd>
            </dl>
          );
        }}
      />
    </Content>
  );
}

const Content = styled.div``;
