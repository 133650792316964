/**
 * @name Const
 * @description
 */

// 세션스토리지
export const SESSION_STORAGE = {
  TOKEN: 'EV_TOKEN',
  EMAIL: 'EV_EMAIL',
  PASSWORD: 'EV_PASSWORD',
  CPO_SEQ: 'CPO_SEQ',
  BUSINESS_CODE: 'EV_BUSINESS_CODE',
  USER_MENU: 'USER_MENU',
  LOCATION: 'LOCATION',
  COUNTRIES: 'COUNTRIES',
  FIlTER_TERMS: {
    CHARGER: 'CHARGER_FILTER_TERMS',
    REMOTE_HISTORY: 'REMOTE_HISTORY_FILTER_TERMS',
  },
};
