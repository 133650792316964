import styled from 'styled-components';
import { COLOR } from '@/styles/index';
import typography from '@/styles/typography';

export const ChartTooltipWrapper = styled.div`
  padding: 12px;
  background-color: ${COLOR.WHITE};
  border-radius: 4px;
  box-shadow: 0px 6px 10px 0px rgba(6, 25, 56, 0.07);
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const XAxisTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Rectangle = styled.div<{ $serieColor: string }>`
  width: 8px;
  height: 8px;
  background-color: ${({ $serieColor }) => $serieColor};
`;

export const XAxisText = styled.span<{ $serieColor: string }>`
  color: ${({ $serieColor }) => $serieColor};
  ${typography.caption2};
`;

export const YAxisTextContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 2px;
`;

export const YAxisText = styled.span`
  color: ${COLOR.BLACK3};
  ${typography.body3};
`;
export const UnitText = styled.span`
  ${typography.caption2};
  color: ${COLOR.GRAY2};
`;
