/**
 * @name 충전단가관리
 * @description
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetTariffRes } from '@/lib/apis/tariff/type';
// 모드변경 _mode
export const MODE = {
  DEFAULT: 'DEFAULT',
  LIST: 'LIST',
  VIEW: 'VIEW',
  MODIFY: 'MODIFY',
  REGISTER: 'REGISTER',
  EMPTY: 'EMPTY',
  // ------충전단가관리
  FIXED_REGISTER: 'FIXED_REGISTER', // -------------고정단가등록
  TIMELY_REGISTER: 'TIMELY_REGISTER', // -----------계시별단가등록
  FIXED_MODIFY: 'FIXED_MODIFY', // -----------------고정단가수정
  TIMELY_MODIFY: 'TIMELY_MODIFY', // ---------------계시별단가수정
  // ------충전소별단가관리
};
// 팝업 _popup
export const POPUP = {
  REGISTER_SUCCESS: 'REGISTER_SUCCESS', // ----------- 등록성공
  REGISTER_FAIL: 'REGISTER_FAIL', // ----------------- 등록실패
  CANCEL_CONFIRM: 'CANCEL_CONFIRM', // --------------- 취소확인
  DELETE_CONFIRM: 'DELETE_CONFIRM', // --------------- 삭제확인
};
// 이벤트 _event
export const EVENT = {
  // submit
  SUBMIT: 'SUBMIT',
  SUBMIT_FIXED: 'SUBMIT_FIXED',
  SUBMIT_TIMELY: 'SUBMIT_TIMELY',
  UPDATE: 'UPDATE',
  // 레이어팝업
  POPUP_SHOW: 'POPUP_SHOW',
  POPUP_HIDE: 'POPUP_HIDE',
  POPUP_SET: 'POPUP_SET',
  // CRUD
  SUBMIT_CREATE: 'SUBMIT_CREATE', // 등록하기
  FETCH_LIST: 'FETCH_LIST', // 리스트데이터요청
  TOGGLE: 'TOGGLE', // 계시별단가,활성화,비활성화
  FETCH_VIEW: 'FETCH_VIEW', // 상세데이터요청
  DELETE: 'DELETE', // 삭제하기
  MODIFY: 'MODIFY', // 수정하기
};
//* --------------------------------------------------*
/** @defineType */
type StateType = {
  mode: string;
  payload: {};
  payload_fixed: any[]; // 고정단가
  data: {};
  list: GetTariffRes[];
  roamingList: GetTariffRes[]; // TODO Roaming 리스트 타입 설정
  roamingView: any; // TODO Roaming View 타입 설정
  roamingPayload: {
    roaming_code: string;
    roaming_name: string;
    start_kw: string;
    end_kw: string;
    price: string;
  }; // TODO Roaming Payload 타입 설정
  view: {
    nonMembers: GetTariffRes | undefined;
    members: GetTariffRes | undefined;
    roamingMembers: GetTariffRes | undefined;
  };
  search_payload: {
    isOpen: boolean;
  }; // ---------------------검색조건 및 상세검색조건
  [key: string]: any;
};
//* --------------------------------------------------*
export const sliceName = 'price'; // 변경필요
/** @initialState */
const initialState: StateType = {
  mode: 'DEFAULT',
  payload: {},
  payload_fixed: [
    {
      is_user: true, // 회원 여부
      fast_amount: '0', // 급속 충전 단가
      slow_amount: '0', // 완속 충전 단가
      currency: 'KRW', // 단가 화폐 단위
      day_code: 'ALL', // 예: ALL, SUN, MON, TUE, WED, THU, FRI, SAT
    },
    {
      is_user: false,
      fast_amount: 0,
      slow_amount: 0,
      currency: 'KRW',
      day_code: 'ALL', // 예: ALL, SUN, MON, TUE, WED, THU, FRI, SAT
    },
  ],
  data: {},
  list: [],
  roamingList: [],
  roamingView: '', // TODO Roaming View 초기값 설정
  roamingPayload: {
    roaming_code: '',
    roaming_name: '',
    start_kw: '',
    end_kw: '',
    price: '',
  }, // TODO Roaming View 초기값 설정
  view: {
    nonMembers: undefined,
    members: undefined,
    roamingMembers: undefined,
  },
  search_payload: {
    isOpen: false,
  }, // ---------------------검색조건 및 상세검색조건
};
//* --------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //* --------------------------------------------------*
    // 초기화
    setInit(state: StateType) {
      // state.mode = 'DEFAULT'
      Object.assign(state, initialState);
    },
    // setMode
    setMode(state: StateType, action: PayloadAction<any>) {
      state.mode = action.payload;
    },
    // fetch 리스트
    setList(state: StateType, action: PayloadAction<GetTariffRes[]>) {
      state.list = Object.assign(state.list, action.payload);
    },
    // TODO Roaming 리스트 타입 설정
    setRoamingList(state: StateType, action: PayloadAction<any>) {
      state.roamingList = action.payload;
      state.totalCount = action.payload.totalCount;
    },
    // fetch view
    setView(
      state: StateType,
      action: PayloadAction<{
        nonMembers: GetTariffRes | undefined;
        members: GetTariffRes | undefined;
        roamingMembers: GetTariffRes | undefined;
      }>,
    ) {
      state.view = action.payload;
    },
    // TODO Roaming View 타입 설정
    setRoamingView(state: StateType, action: PayloadAction<any>) {
      state.roamingView = action.payload;
    },
    initView(state: StateType) {
      state.view = initialState.view;
    },
    initPayload(state: StateType) {
      state.payload = initialState.payload;
    },
    initRoamingView(state: StateType) {
      state.roamingView = initialState.roamingView;
    },
    initRoamingPayload(state: StateType) {
      state.roamingPayload = initialState.roamingPayload;
    },
    // create Payload업데이트
    setPayload(state: StateType, action: PayloadAction<any>) {
      state.payload = Object.assign(state.payload, action.payload);
    },
    // TODO Roaming payload 타입 설정
    setRoamingPayload(state: StateType, action: PayloadAction<any>) {
      state.roamingPayload = action.payload;
    },
    // 고정단가업데이트
    setPayloadFixed(state: StateType, action: PayloadAction<any>) {
      state.payload_fixed = Object.assign(state.payload_fixed, action.payload);
    },
    // 검색및 상세검색조건 업데이트
    setSearchPayload(state: StateType, action: PayloadAction<any>) {
      state.search_payload = Object.assign(state.search_payload, action.payload);
    },
  },
});
//* --------------------------------------------------*
export default slice.reducer;
export const nameSpace = sliceName;
export const { actions } = slice;
