import React, { useState } from 'react';
import styled from 'styled-components';
import { icon_station_black } from 'assets/images';
import { TextFiledSearch } from 'components/form';
import { COLOR } from 'styles';

import { useAppDispatch, useAppSelector } from 'model';
import { uuid } from 'lib';
import InputStationItem from './input-station-list';
import InputButton from './input-button';
import Panel from './panel';

type Props = {
  value?: string;
};

/**
 * @name 충전소선택
 * @description
 */
export default function Contents({ value }: Props) {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const { filter_stations } = useAppSelector(state => state.dashboard);

  return (
    <Content>
      <InputButton
        icon={icon_station_black}
        value="충전소선택"
        onClick={() => {
          setVisible(true);
        }}
      />
      {/* 레이어팝업 */}
      <Panel
        visible={visible}
        isContent={false}
        setVisible={setVisible}
        title="확인하실 충전소를 선택해 주세요. "
        desc="선택하신 충전소 그룹의 현황을 확인하실 수 있습니다.  ">
        {/* 검색 */}
        <TextFiledSearch style={{ marginTop: 16, marginBottom: 16 }} placeholder="충전소명으로 검색하세요" />
        {/* 리스트 */}
        <section className="station">
          {!!filter_stations &&
            filter_stations.map((item: any, index: number) => {
              return <InputStationItem key={uuid()} item={item} index={index} onChange={data => {}} />;
            })}
        </section>
      </Panel>
    </Content>
  );
}

const Content = styled.div`
  // styles
  position: relative;

  /* station */
  .station {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 400px;
    overflow-y: auto;
    margin-top: 16px;
    padding: 16px;
    border-radius: 8px;
    color: ${COLOR.BLACK1};
    background-color: ${COLOR.WHITE};
  }
`;
