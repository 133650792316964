/**
 * @name Container
 */
import {useEffect} from 'react'
import {useAppDispatch} from 'model'
import Presenter from './presenter'
import {Events, objectToQueryString, useAxios, useContext} from 'lib'
import {API_HOST} from 'config'
import {_action} from '.'

export default function Container() {
  // hooks
  const {get} = useAxios()
  const {business_code} = useContext()
  const dispatch = useAppDispatch()

  //*--------------------------------------------------*
  // onInit
  async function onInit() {
    await onFilter()
  }
  // 통계유형,시작날짜,종료날짜,충전소선택
  async function onFilter(payload?: any) {
    const temp = Object.assign({}, payload, {business_code: business_code})
    const _payload = objectToQueryString(temp)
    // 충전소매출랭킹
    const sale = await get(`${API_HOST}/dashboards/rank/stations/sale?${_payload}`)
    if (sale?.isSuccess) {
      dispatch(_action.setRank(sale))
    }
  }

  //*--------------------------------------------------*
  useEffect(() => {
    onInit()
    // addListener
    Events.addListener('init', onInit)
    return () => {
      // removeListener
      Events.removeListener('init', onInit)
    }
  }, [])

  return <Presenter />
}
