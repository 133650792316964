import React from 'react';
import styled from 'styled-components';
import { CSS } from 'types';
import { H5, Text } from 'lib';
import { COLOR } from 'styles';
import Image from '../../lib/core/image/image';
import { icons } from '@/assets/images';
import typography from '@/styles/typography';

type Props = {
  label?: string;
  required?: boolean;
  style?: CSS;
  desc?: string;
  disabled?: boolean;
  component?: React.ReactNode; // ------------------ children
  validCheckResult?: boolean | 'none';
  warningText?: string;
  displayWarning?: boolean;
  warningText2?: string;
  displayWarning2?: boolean;
};
export default function TextFiledGroup({
  label,
  required,
  style,
  disabled,
  component,
  desc,
  validCheckResult,
  warningText,
  displayWarning,
  warningText2,
  displayWarning2,
}: Props) {
  const validCheck = () => {
    if (validCheckResult === 'none' || validCheckResult === undefined) {
      return COLOR.GRAY3;
    }
    return validCheckResult ? COLOR.GREEN2 : COLOR.RED2;
  };

  const imageRender = () => {
    if (validCheckResult !== undefined && validCheckResult !== 'none') {
      return validCheckResult ? (
        <Image src={icons.validationSuccess.image.default} style={{ width: 14, height: 14 }} />
      ) : (
        <Image src={icons.validationFails.image.default} style={{ width: 14, height: 14 }} />
      );
    }
    return null;
  };

  return (
    <Element style={style}>
      <dt>
        <H5>{label ?? '텍스트라벨명'}</H5>
        {required && (
          <H5 className="required" style={{ color: 'red' }}>
            *
          </H5>
        )}
      </dt>
      {!!component && <ChildrenWrapper>{component}</ChildrenWrapper>}
      {!!desc && !disabled && (
        <DescriptionContainer>
          {imageRender()}
          <Text color={validCheck()} className="caption1">
            {desc}
          </Text>
        </DescriptionContainer>
      )}
      {displayWarning && warningText && (
        <WarningContainer>
          <Image src={icons.warningTriangle.image.default} style={{ width: 16, height: 16 }} />
          <WarningText>{warningText}</WarningText>
        </WarningContainer>
      )}
      {displayWarning2 && warningText2 && (
        <WarningContainer>
          <Image src={icons.warningTriangle.image.default} style={{ width: 16, height: 16 }} />
          <WarningText>{warningText2}</WarningText>
        </WarningContainer>
      )}
    </Element>
  );
}
const Element = styled.dl`
  margin-bottom: 32px;
  dt {
    display: flex;
    margin-bottom: 4px;
    flex-direction: row;
    align-items: flex-start;
    color: ${COLOR.GRAY1};
    //required
    .required {
      color: ${COLOR.RED3};
    }
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 4px;
  align-items: center;
`;

const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 12px 14px;
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%), ${COLOR.RED2};
  margin-top: 4px;
`;

const WarningText = styled.span`
  ${typography.caption1};
  color: ${COLOR.RED1};
`;

const ChildrenWrapper = styled.div``;
/** ******************************************************
[사용법]
      <TextFiledGroup
        label="충전소명"
        required
        desc="충전소 명은 25자 내의 한글, 영문, 숫자, -,_ 만 입력 가능합니다."
        component={
          <Div className="gap">
            <TextInput disabled={disabled} style={{width: '77%'}} placeholder="충전소 명을 입력해 주세요." defaultValue={''} name="name" onChange={onChangeHandler} />
            <Button label="중복확인" disabled={disabled} />
          </Div>
        }
      />
******************************************************** */
