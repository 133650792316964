/**
 * @name index
 * @description
 */

import {useAppSelector} from 'model'
import {actions, MODE, EVENT, POPUP} from 'model/modules/_template'
export {default} from './container'
//*--------------------------------------------------*
/**
 * @name config
 * @description Redux Action, Mode, Event, Popup
 */
export const Store = () => useAppSelector(state => state.template)
export const _action = actions
export const _mode = MODE
export const _event = EVENT
export const _popup = POPUP

/********************************************************
[사용법]
export const Store = () => useAppSelector(state => state.template) // 스토어
export const _action = templateAction // Redux Action
export const _mode = TEMPLATE_MODE // 모드
export const _event = TEMPLATE_EVENT //이벤트 Events
export const _popup = TEMPLATE_POPUP // 팝업타입
*********************************************************/
