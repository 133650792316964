import React, { FormEventHandler, HTMLInputTypeAttribute, InputHTMLAttributes, useState } from 'react';
import styled, { css } from 'styled-components';
import { COLOR } from 'styles';
import { ValidFunc } from '@/types/validation';

type Props = {
  className?: string; // --------------------------- 클래스네임
  children?: React.ReactNode; // --------------------Children
  label?: string; // ------------------------------- Text
  style?: React.CSSProperties; // ------------------ StyleSheet
  placeholder?: string; // ------------------------- placeholder
  disabled?: boolean; // --------------------------- disabled
  onSubmit?: (str: string) => void; // ------------- onSubmit
  setValue?: (str: string) => void; // ------------- setValue
  onChange?: (obj: any) => void; // ---------------- onChange Event
  onValue?: (obj: any) => void; // ---------------- onChange Event
  onBlur?: (obj: any) => void; // ------------------ onBlur Event
  [key: string]: any;
  validFunc?: ValidFunc;
  maxLength?: number;
  parentValidResult?: (result: boolean) => void;
  type?: HTMLInputTypeAttribute;
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;
  pattern?: string;
  onInput?: FormEventHandler<HTMLInputElement>;
};

/**
 * @name TextInput
 * @description 입력창
 */
export default function TextInput({
  disabled,
  children,
  className,
  onValue,
  onChange,
  setValue,
  onBlur,
  onSubmit,
  label,
  validFunc,
  maxLength,
  parentValidResult,
  type,
  inputMode,
  pattern,
  onInput,
  ...props
}: Props) {
  const [isValid, setIsValid] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!isTouched) setIsTouched(true);
    if (validFunc !== undefined) {
      const validCheckResult = validFunc(value);
      setIsValid(validCheckResult);
      if (parentValidResult) {
        parentValidResult(validCheckResult);
      }
    }
    if (onChange) {
      onChange(value);
    }
    if (setValue) {
      setValue(value);
    }
    if (onValue) {
      onValue(value);
    }
  };

  return (
    <InputElement
      {...props}
      type={type}
      disabled={disabled}
      className={`${className ?? ''}`}
      onBlur={event => {
        if (onBlur) {
          onBlur(event);
        }
      }}
      onChange={event => {
        handleInputChange(event);
      }}
      onKeyUp={event => {
        if (!disabled && event.key === 'Enter' && onSubmit) {
          onSubmit(event.currentTarget.value);
        }
      }}
      $isValid={isValid}
      $isTouched={isTouched}
      $isExistValidFunc={validFunc !== undefined}
      maxLength={maxLength}
      min={0}
      inputMode={inputMode}
      pattern={pattern}
      // onInput={onInput}
      onInput={e => {
        /**
         * type="number"와 maxlength가 들어갈 경우 여기서 잡아야 됩니다.
         * onInput을 사용하는 곳이 없어서 이렇게 사용합니다.
         */
        const input = e.currentTarget;
        if (maxLength && input.value.length > Number(maxLength)) {
          input.value = input.value.slice(0, Number(maxLength));
        }
      }}
    />
  );
}

const determineBorderStyle = ({
  $isValid,
  $isTouched,
  $isExistValidFunc,
}: {
  $isValid?: boolean;
  $isTouched?: boolean;
  $isExistValidFunc?: boolean;
}) => {
  if ($isExistValidFunc) {
    if ($isTouched) {
      return $isValid
        ? css`
            border: 1px solid ${COLOR.GREEN3};
          `
        : css`
            border: 1px solid ${COLOR.RED3};
          `;
    }
  }
  return css`
    border: 1px solid ${COLOR.GRAY4};
  `;
};

const InputElement = styled.input<{ $isValid?: boolean; $isTouched?: boolean; $isExistValidFunc?: boolean }>`
  /* input클래스적용시 기본스타일작동 */
  display: flex;
  min-width: 180px;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  border-radius: 8px;
  color: ${COLOR.BLACK1};
  outline: 0;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.1px;

  ${props =>
    determineBorderStyle({
      $isValid: props.$isValid,
      $isTouched: props.$isTouched,
      $isExistValidFunc: props.$isExistValidFunc,
    })}

  /* 마우스호버 */
  &:hover {
    border-color: ${COLOR.GRAY1};
  }
  /* 포커스인 */
  &:focus {
    border-color: ${COLOR.BLUE3};
    outline: 0;
    outline-color: ${COLOR.BLUE3};
  }
  /* 비활성화 */
  &:disabled {
    border: 0;
    color: ${COLOR.GRAY3};
    background-color: ${COLOR.GRAY6};
  }
  /* 유효성검사 */
  &.invalid {
    border-color: ${COLOR.RED1} !important;
  }
  /* 플레이스홀더 */
  &::placeholder {
    color: ${COLOR.GRAY3};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
/** ******************************************************
[사용법]

<input
  disabled
  type="text"
  name="patternValue"
  value={inputVal}
  onKeyDown={event => {
    const {key} = event.nativeEvent
    console.log(key)
  }}
  onFocus={({target}) => {
    console.log(target.value)
  }}
  onBlur={({target}) => {
    console.log(target.value)
  }}
  onChange={event => {
    const {value} = event.target
    // const number = /^[0-9]*$/ //------------------------ 숫자만입력
    const engNum = /^[a-zA-Z0-9]*$/ //------------------ 영문,숫자만입력
    // const email = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+$/ //------ 이메일
    // const phone = /^\d{2,3}-\d{3,4}-\d{4}$/ //---------- 전화번호
    console.log(engNum.test(value))
    return engNum.test(value) && setInputVal(value)
  }}
/>
******************************************************** */
