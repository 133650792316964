import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TroubleReport } from '@/lib/apis/operation/trouble-reports/type';
import { getTroubleReportById } from '@/lib/apis/operation/trouble-reports';
import TroubleReportsDetailPresenter from './TroubleReportsDetail.presenter';
import { MENU } from '@/pages/router';

export default function TroubleReportsDetailContainer() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [troubleReport, setTroubleReport] = useState<TroubleReport | null>(null);

  const handleEdit = () => {
    navigate(`${MENU.OPERATION.TROUBLE_REPORTS}/${id}/edit`);
  };
  const fetchData = async () => {
    if (id) {
      const { data } = await getTroubleReportById(+id);
      setTroubleReport(data);
    }
  };

  const handleBackToList = () => {
    navigate(MENU.OPERATION.TROUBLE_REPORTS);
  };
  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <TroubleReportsDetailPresenter
      troubleReport={troubleReport}
      onEdit={handleEdit}
      handleBackToList={handleBackToList}
    />
  );
}
