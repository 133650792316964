import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import VendorDetailPresenter from './VendorDetail.presenter';
import { actions } from '@/model/modules/vendor';
import { SCREEN_MODE } from '@/types/mode';
import { MENU } from '@/pages/router';
import { useAppDispatch, useAppSelector } from '@/model/index';
import { getVendorSeq, removeVendor } from '@/lib/apis/infra';
import { IResponse } from '@/lib/apis/api/type';
import { IVendorDetail } from '@/types/infra';

export default function VendorDetailContainer() {
  const { cpo_seq } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [deletePopup, setDeletePopup] = useState(false);

  const onInit = () => {
    dispatch(actions.setMode(SCREEN_MODE.VIEW));
    fetchVendorDetail();
  };

  const fetchVendorDetail = async () => {
    if (id) {
      const vendorDetailResponse: IResponse<IVendorDetail> = await getVendorSeq(id);
      if (vendorDetailResponse.isSuccess) {
        dispatch(actions.setView(vendorDetailResponse.data));
      }
    }
  };

  const handleDeletePopup = (value: boolean) => {
    setDeletePopup(value);
  };

  const handleBackToList = () => {
    navigate(`${MENU.INFRA.VENDOR_MANAGEMENT}`);
  };

  const onEdit = () => {
    dispatch(actions.setMode(SCREEN_MODE.MODIFY));
    navigate(`${MENU.INFRA.VENDOR_MANAGEMENT_UPDATE}/${id}`);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (id) {
        const removeResponse = await removeVendor(id, cpo_seq);
        if (removeResponse.isSuccess) {
          handleDeletePopup(false);
          dispatch(actions.initView());
          navigate(MENU.INFRA.VENDOR_MANAGEMENT);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    onInit();
  }, [id]);

  return (
    <VendorDetailPresenter
      onEdit={onEdit}
      handleDeletePopup={handleDeletePopup}
      handleBackToList={handleBackToList}
      deletePopupVisible={deletePopup}
      onDeleteConfirm={handleDeleteConfirm}
    />
  );
}
