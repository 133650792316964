/**
 * @name List
 * @description 가이드페이지
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { Div, Events, Table, debug, useContext, uuid } from 'lib';
import { ListRenderItem } from 'types';
import { IconButtonsModifyDel, SortButton } from 'components';
import { Pagination } from 'components/form';
import { MENU } from '@/pages/router';
import { formatDay } from 'pages/common';
import { Store, _event, _mode } from '../../index';

export default function Contents() {
  // hooks
  const { cpo_seq, user } = useContext();
  const { list } = Store();
  const [sort, setSort] = React.useState({}) as any;
  //*--------------------------------------------------*
  return (
    <div className="table_list">
      {/* 테이블 */}
      <Table
        info={list?.data}
        renderHeader={() => {
          return (
            <React.Fragment>
              <thead>
                <tr key={uuid()}>
                  <th className="inner">No.</th>
                  <th className="inner l">
                    <Div>
                      <label>이름</label>
                      <SortButton
                        value={sort?.name}
                        onChange={data => {
                          setSort(Object.assign({}, sort, { name: data }));
                          Events.emit(_event.FETCH_LIST, {
                            sort_element: 'name',
                            sort_type: data,
                          });
                        }}
                      />
                    </Div>
                  </th>
                  <th className="inner l">ID</th>
                  <th className="inner l">Type</th>
                  <th className="inner l">지역</th>
                  <th className="inner l">주소</th>
                  <th className="inner l">
                    <label>등록일시</label>
                  </th>
                  <th className="inner">충전기 수</th>
                  <th className="inner">수정/삭제</th>
                </tr>
              </thead>
              <colgroup>
                <col style={{ width: 50 }} />
                <col style={{ width: '*' }} />
                <col style={{ width: 100 }} />
                <col style={{ width: 150 }} />
                <col style={{ width: 100 }} />
                <col style={{ width: 100 }} />
                <col style={{ width: 100 }} />
                <col style={{ width: 100 }} />
                <col style={{ width: 100 }} />
                {/* <col span={2} /> 필요없는 col합치기 */}
              </colgroup>
            </React.Fragment>
          );
        }}
        renderItem={({ item, index }: ListRenderItem) => {
          return (
            <tr key={uuid()}>
              <td>{item?.seq}</td>
              <td className="l">
                <Link to={`${MENU.TEMPLATE}/${item?.seq}`}>{item?.name}</Link>
              </td>
              <td className="l">
                <Link to={`${MENU.TEMPLATE}/${item?.seq}`}>{item?.code}</Link>
              </td>
              <td className="l">
                {item?.station_type_group} {`>`} {item?.station_type_detail}
              </td>
              <td className="l">{item?.address_01}</td>
              <td className="l">{`${item?.address_01} > ${item?.address_02}`}</td>
              <td>{formatDay(item?.created_at)}</td>
              <td>{item?.chargepoint_count}</td>
              <td>
                <IconButtonsModifyDel
                  onModify={() => {
                    Events.emit(_event.MODIFY, { mode: _mode.MODIFY, seq: item?.seq });
                  }}
                  onDelete={() => {
                    Events.emit(_event.DELETE, { seq: item?.seq });
                  }}
                />
              </td>
            </tr>
          );
        }}
      />
      {/* 페이징 */}
      <Pagination
        style={{ marginTop: 30 }}
        info={list?.meta?.paging}
        onChange={(num: any) => {
          Events.emit(_event.FETCH_LIST, { page_num: num });
        }}
      />
    </div>
  );
}
