import styled, { css } from 'styled-components';
import { COLOR } from '@/styles/index';
import typography from '@/styles/typography';
import { FontType } from '@/types/font';

export const RadioButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  gap: 8px;
`;

export const InnerCellContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
export const TR = styled.tr`
  border-bottom: 1px solid ${COLOR.GRAY5};
`;

export const TD = styled.td<{ $index?: number; $customFont?: FontType; color?: string; $dayCode?: string }>`
  padding: 8px;
  height: 56px;
  color: ${props => props.color ?? COLOR.BLACK2};
  vertical-align: middle;
  ${({ $customFont }) =>
    $customFont &&
    css`
      font-family: ${$customFont.fontFamily};
      letter-spacing: ${$customFont.letterSpacing};
      font-size: ${$customFont.fontSize};
      line-height: ${$customFont.lineHeight};
      font-weight: ${$customFont.fontWeight};
    `};

  ${({ $dayCode }) => {
    if ($dayCode === 'SAT') {
      return css`
        color: ${COLOR.BLUE4};
      `;
    }
    if ($dayCode === 'SUN') {
      return css`
        color: ${COLOR.RED2};
      `;
    }
    return css``;
  }}
`;

export const ButtonText = styled.span`
  ${typography.body4};
`;

export const Text = styled.span`
  ${typography.caption1};
  min-width: 48px;
  color: ${COLOR.GRAY3};
`;
