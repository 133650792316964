import { Button, Row } from 'lib';
import { icon_delete_red, icon_edit_white } from 'assets/images';
import { COLOR } from 'styles';

type Props = {
  modifyLabel?: string;
  deleteLabel?: string;
  onModify?: () => void;
  onDelete?: () => void;
};
export default function ButtonsModifyDel({ modifyLabel, deleteLabel, onModify, onDelete }: Props) {
  return (
    <Row>
      <Button
        icon={icon_edit_white}
        label={modifyLabel ?? '수정'}
        style={{ marginRight: 16 }}
        onClick={() => {
          if (onModify) onModify();
        }}
      />
      <Button
        icon={icon_delete_red}
        label={deleteLabel ?? '삭제'}
        color={COLOR.RED2}
        borderColor={COLOR.RED2}
        bg="transparent"
        onClick={() => {
          if (onDelete) onDelete();
        }}
      />
    </Row>
  );
}
