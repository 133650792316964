import React from 'react';
import { useTranslation } from 'react-i18next';
import { addComma, calculateChargeDurationInMinutes, formatDateTime, Image } from 'lib';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableHead,
  TD,
  TH,
  TR,
  Wrapper,
  NoResultDescription,
  NoResultText,
  NoResultTextWrapper,
  NoSearchResultTextWrapper,
  NoSearchResultWrapper,
  InnerCellContainer,
} from './FaultHistoryTable.style';
import Pagination from '@/components/organisms/Paginations/Pagination';
import { useAppSelector } from '@/model/index';
import typography from '@/styles/typography';
import { icons } from '@/assets/images';
import Badge from '@/components/atoms/Badge';
import { TariffUserType } from '@/lib/apis/tariff/type';
import { COLOR } from '@/styles/index';
import { MENU } from '@/pages/router';
import { ITrouble } from '@/types/trouble';
import { InnerTD } from '../ChargepointTable/ChargepointTable.style';
import { BadgeMode, BadgeType } from '@/components/atoms/Badge/Badge.type';
import { DeviceStatus } from '@/lib/apis/dashborad/type';

export default function FaultHistoryTable({
  itemsPerPage,
  currentPage,
  pageHandler,
  queryString,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  queryString?: string;
}) {
  const { t } = useTranslation('common');
  const { list, totalCount } = useAppSelector(state => state.operationTrouble);
  const navigate = useNavigate();

  const renderBadgeByMemberType = (type: TariffUserType) => {
    if (type === TariffUserType.NONE) {
      return <Badge mode="TariffNone" label={t('forms.price.member.none')} />;
    }
    if (type === TariffUserType.USER) {
      return <Badge mode="TariffUser" label={t('forms.price.member.user')} />;
    }
    return <Badge mode="TariffRoaming" label={t('forms.price.member.roaming')} />;
  };

  const getDeviceStatus = (status: DeviceStatus) => {
    const obj = {
      AVAILABLE: {
        mode: BadgeMode.Available,
        label: '사용가능',
      },
      PREPARING: {
        mode: BadgeMode.Unavailable,
        label: '사용불가능',
      },
      CHARGING: {
        mode: BadgeMode.Unavailable,
        label: '사용불가능',
      },
      SUSPENDED_EVSE: {
        mode: BadgeMode.Unavailable,
        label: '사용불가능',
      },
      SUSPENDED_EV: {
        mode: BadgeMode.Unavailable,
        label: '사용불가능',
      },
      FINISHING: {
        mode: BadgeMode.Available,
        label: '사용가능',
      },
      RESERVED: {
        mode: BadgeMode.Unavailable,
        label: '사용불가능',
      },
      UNAVAILABLE: {
        mode: BadgeMode.Unavailable,
        label: '사용불가능',
      },
      FAULTED: {
        mode: BadgeMode.Unavailable,
        label: '사용불가능',
      },
      COMMUNICATION_FAULT: {
        mode: BadgeMode.Unavailable,
        label: '사용불가능',
      },
    };
    const result = obj[status] ?? {
      mode: BadgeMode.Unavailable,
      label: '사용불가능',
    };
    return result;
  };

  const handleNavigateToDetail = (seq: number) => {
    // navigate(`${MENU.SALES.CHARGING_STATUS}/${seq}?${queryString}`);
    navigate(`${MENU.SALES.CHARGING_STATUS}/${seq}`);
  };

  return (
    <Wrapper>
      <Table>
        <colgroup>
          <col style={{ width: 46 }} />
          <col style={{ minWidth: 180 }} />
          <col style={{ minWidth: 150 }} />
          <col style={{ minWidth: 120 }} />
          <col style={{ minWidth: 120 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 130 }} />
          <col style={{ minWidth: 100 }} />
          <col style={{ minWidth: 100 }} />
          <col style={{ minWidth: 172 }} />
          <col style={{ minWidth: 90 }} />
        </colgroup>
        <TableHead>
          <TR>
            <TH>No.</TH>
            <TH>{t('pages.operation.fault.list.table.chargerName')}</TH>
            <TH>{t('pages.operation.fault.list.table.faultDevice')}</TH>
            <TH>{t('pages.operation.fault.list.table.id')}</TH>
            <TH>{t('pages.operation.fault.list.table.status')}</TH>
            <TH>{t('pages.operation.fault.list.table.level')}</TH>
            <TH>{t('pages.operation.fault.list.table.ocppFaultName')}</TH>
            <TH>{t('pages.operation.fault.list.table.vendorFaultCode')}</TH>
            <TH>{t('pages.operation.fault.list.table.faultType')}</TH>
            <TH>{t('pages.operation.fault.list.table.vendorFaultName')}</TH>
            <TH>{t('pages.operation.fault.list.table.date')}</TH>
          </TR>
        </TableHead>
        <TableBody>
          {list?.length > 0 &&
            list.map((item: ITrouble, index: number) => (
              <TR key={item.seq}>
                <TD $customFont={typography.body4}>
                  {totalCount - (currentPage - 1) * itemsPerPage - index}
                  {/* {itemsPerPage * (currentPage - 1) + (index + 1)} */}
                </TD>
                <TD $customFont={typography.body5}>{item.chargepoints[0].name}</TD>
                <TD $customFont={typography.body5} color={COLOR.BLUE4}>
                  {item.chargepoints[0].connectors.map(connector => {
                    if (`${connector.connector_number}` === `${item.connector_number}`) {
                      return t(`common.connector.${connector.types}`);
                    }
                    return '';
                  })}
                </TD>
                <TD $customFont={typography.body5}>{item.vendor_id}</TD>
                {item.chargepoints[0].device_status_items?.length > 0 ? (
                  <InnerTD $customFont={typography.body4}>
                    <InnerCellContainer>
                      <Badge
                        mode={
                          getDeviceStatus(
                            item.chargepoints[0].device_status_items.find(
                              device => device.connector_number === item.connector_number,
                            )?.status as DeviceStatus,
                          ).mode as unknown as BadgeType
                        }
                        label={
                          getDeviceStatus(
                            item.chargepoints[0].device_status_items.find(
                              device => device.connector_number === item.connector_number,
                            )?.status as DeviceStatus,
                          ).label
                        }
                      />
                    </InnerCellContainer>
                  </InnerTD>
                ) : (
                  <TD $customFont={typography.body5}>-</TD>
                )}
                <TD $customFont={typography.body5}>{item.error_level}</TD>
                <TD $customFont={typography.body5}>{item.ocpp_error_code}</TD>
                <TD $customFont={typography.body5}>{item.vendor_error_code}</TD>
                <TD $customFont={typography.body5}>-</TD>
                <TD $customFont={typography.body5}>{item.vendor_error_info}</TD>
                <TD $customFont={typography.body5}>{formatDateTime(item.created_at)}</TD>
              </TR>
            ))}
        </TableBody>
      </Table>
      {list.length > 0 && (
        <Pagination
          listLength={totalCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onClick={pageHandler}
        />
      )}
      {list.length === 0 && (
        <NoSearchResultWrapper>
          <Image src={icons.noSalesData.image.default} style={{ width: 192, height: 192 }} />
          <NoSearchResultTextWrapper>
            <NoResultTextWrapper>
              <NoResultText>{t('pages.operation.fault.list.description.noData')}</NoResultText>
            </NoResultTextWrapper>
            <NoResultDescription />
          </NoSearchResultTextWrapper>
        </NoSearchResultWrapper>
      )}
    </Wrapper>
  );
}
