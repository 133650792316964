import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStation, IStationDetail, IStationPayload } from '@/types/infra';
import { SCREEN_MODE } from '@/types/mode';

/**
 * @name 충전소(등록/삭제/추가)
 * @description
 */
export const sliceName = 'station'; // 변경필요
// 충전소팝업타입
export const POPUP = {
  STATION_REMOVE: 'STATION_REMOVE', // 충전소삭제
  STATION_MODIFY_CANCEL: 'STATION_MODIFY_CANCEL', // 충전수정취소
  STATION_MODIFY_SUCCESS: 'STATION_MODIFY_SUCCESS', // 충전소수정완료
  STATION_MODIFY_FAIL: 'STATION_MODIFY_FAIL', // 충전소수정실패
  STATION_ADDRESS_SEARCH: 'STATION_ADDRESS_SEARCH', // 주소검색
  STATION_ADDRESS_NOT_SELECTED: 'STATION_ADDRESS_NOT_SELECTED', // 주소미선택
  STATION_RESISTER_CANCEL: 'STATION_RESISTER_CANCEL', // 충전소등록취소
  STATION_RESISTER_SUCCESS: 'STATION_RESISTER_SUCCESS', // 충전소등록성공
  STATION_RESISTER_FAIL: 'STATION_RESISTER_FAIL', // 충전소등록실패
};
// export
export const EVENT = {
  POPUP_SHOW: 'POPUP_SHOW', // 팝업보이기
  POPUP_HIDE: 'POPUP_HIDE', // 팝업숨기기
  SUBMIT_CREATE: 'SUBMIT_CREATE', // 등록하기
  FETCH_LIST: 'FETCH_LIST', // 리스트데이터요청
  FETCH_VIEW: 'FETCH_VIEW', // 상세데이터요청
  FETCH_MODIFY: 'FETCH_MODIFY', // 업데이트
  DELETE: 'DELETE', // 충전소삭제
};
/** @defineType */
type StateType = {
  mode: string;
  duplicatedCheck: boolean;
  payload: IStationPayload;
  list: IStation[];
  totalCount: number;
  view: IStationDetail;
  [key: string]: any;
};
/** @initialState */
const initialState: StateType = {
  mode: SCREEN_MODE.EMPTY,
  duplicatedCheck: true,
  payload: {
    cpo_seq: '',
    station_type_group: null,
    station_type_detail: null,
    name: '',
    latitude: null,
    longitude: null,
    post_code: '',
    supplier_seq: '1',
    phone: '',
    address: '',
    address_detail: '',
    station_open: {
      MON: { day_code: 'MON', is_off: false, open_time: '00:00:00', close_time: '24:00:00', memo: '' },
      TUE: { day_code: 'TUE', is_off: false, open_time: '00:00:00', close_time: '24:00:00', memo: '' },
      WED: { day_code: 'WED', is_off: false, open_time: '00:00:00', close_time: '24:00:00', memo: '' },
      THU: { day_code: 'THU', is_off: false, open_time: '00:00:00', close_time: '24:00:00', memo: '' },
      FRI: { day_code: 'FRI', is_off: false, open_time: '00:00:00', close_time: '24:00:00', memo: '' },
      SAT: { day_code: 'SAT', is_off: false, open_time: '00:00:00', close_time: '24:00:00', memo: '' },
      SUN: { day_code: 'SUN', is_off: false, open_time: '00:00:00', close_time: '24:00:00', memo: '' },
    },
    image: '', // 기존 이미지인 경우 로직을 타지 않습니다
  },
  list: [],
  totalCount: 0,
  view: {
    seq: '',
    code: '',
    name: '',
    country_code2: '',
    timezone: '',
    pic_large: '',
    pic_medium: '',
    pic_small: '',
    post_code: '',
    address: '',
    address_detail: '',
    latitude: '',
    longitude: '',
    chargepoint_count: null,
    phone: '',
    description: '',
    memo: '',
    created_at: '',
    created_by: null,
    last_modified_at: '',
    last_modified_by: null,
    station_type: {
      type_detail: '',
      type_group: '',
    },
    kr_location: null,
    opens: [],
  },
};

/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // 초기화
    setInit(state: StateType) {
      state.mode = SCREEN_MODE.EMPTY;
      // state.payload = {}
    },
    setDuplicatedCheck: (state: StateType, action: PayloadAction<boolean>) => {
      state.duplicatedCheck = action.payload;
    },
    // 모드변경
    setStationMode(state: StateType, action: PayloadAction<SCREEN_MODE>) {
      state.mode = action.payload;
    },
    initPayload: (state: StateType) => {
      state.payload = initialState.payload;
    },
    // Payload 업데이트
    setStationPayload(state: StateType, action: PayloadAction<IStationPayload>) {
      state.payload = action.payload;
    },
    // Payload 업데이트
    setPayloadStationOpen(state: StateType, action: PayloadAction<IStationPayload>) {
      state.payload.station_open = {
        ...state.payload.station_open,
        ...action.payload,
      };
    },
    // fetch 리스트
    setStationList(state: StateType, action: PayloadAction<{ list: IStation[]; totalCount: number }>) {
      state.list = action.payload.list;
      state.totalCount = action.payload.totalCount;
    },
    initView: (state: StateType) => {
      state.view = initialState.view;
    },
    // fetch view
    setView(state: StateType, action: PayloadAction<IStationDetail>) {
      state.view = action.payload;
    },
  },
});

export default slice.reducer;
export const nameSpace = sliceName;
export const { actions } = slice;
