import { Dayjs } from 'lib/events';
/**
 *
 * @param 타임존변경
 */
export const setTimezone = (str: string | number | any) => {
  Dayjs.tz.setDefault('America/Toronto');
  return Dayjs.tz(str, 'America/Toronto').format();
};

export const formatDate = (date: string) => {
  if (date) {
    return Dayjs(date).format('YYYY.MM.DD');
  }
  return '-';
};

export const formatDateTime = (date: string | undefined) => {
  if (date) {
    return Dayjs(date).format('YYYY.MM.DD HH:mm:ss');
  }
  return '-';
};

export const calculateChargeDurationInMinutes = (startTime: string, endTime: string) => {
  if (startTime && endTime) {
    const chargeStartTime = Dayjs(startTime);
    const chargeEndTime = Dayjs(endTime);
    const chargeDurationMinutes = chargeEndTime.diff(chargeStartTime, 'minute');
    return chargeDurationMinutes.toString();
  }
  return '-';
};
