import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
// import logger from 'redux-logger'
import {
  dashboardReducer,
  contextReducer,
  guideReducer,
  gnbReducer,
  stationReducer,
  authReducer,
  templateReducer,
  deviceReducer,
  vendorReducer,
  modelReducer,
  errorcodeReducer,
  firmwareReducer,
  salesReducer,
  priceReducer,
  runReducer,
  remoteReducer,
} from './modules';
import locationReducer from './modules/location';
import chargingStatusReducer from '@/model/modules/chargingStatus';
import operationTroubleReducer from '@/model/modules/operationTrouble';
import chargepointReducer from '@/model/modules/chargepoint';
import adminReducer from '@/model/modules/admin';
import troubleReportsReducer from '@/model/modules/troubleReports';

const store = configureStore({
  reducer: {
    template: templateReducer,
    guide: guideReducer,
    run: runReducer, // --------------------------------충전기운영
    sales: salesReducer, // ----------------------------매출관리
    price: priceReducer, // ----------------------------충전단가관리
    auth: authReducer, // ------------------------------Auth
    station: stationReducer, // ------------------------충전소
    device: deviceReducer, // --------------------------충전기
    vendor: vendorReducer, // --------------------------제조사
    model: modelReducer, // ----------------------------모델
    errorcode: errorcodeReducer, // ----------------------장애코드관리
    firmware: firmwareReducer, // ----------------------펌웨어
    gnb: gnbReducer, // --------------------------------GNB
    dashboard: dashboardReducer, // --------------------대시보드
    context: contextReducer, // ------------------------컨텍스트
    location: locationReducer,
    chargepoint: chargepointReducer, // ----------------충전기 현황
    chargingStatus: chargingStatusReducer,
    operationTrouble: operationTroubleReducer,
    remote: remoteReducer,
    admin: adminReducer,
    troubleReports: troubleReportsReducer,
    // chargepoint:
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
  // middleware: getDefaultMiddleware => [...getDefaultMiddleware(), logger],
  devTools: process.env.NODE_ENV === 'development', // 개발자도구 설정
});

export default store;
// TODO 대부분의 리듀서가 any 타입으로 정의되어있어 단계별 마이그레이션이 필요함
// 현재 너무 많은 코드를 건드려야하기 때문에 리듀서의 타입이 어느정도 정리되었을때 RootState의 타입을 정의하는 것으로 한다.
// export type RootState = ReturnType<typeof rootReducer>;
export type RootState = any;
export type AppDispatch = any;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
