import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SCREEN_MODE } from '@/types/mode';
import { IChargepoint, ICharger } from '@/types/infra';

type StateType = {
  mode: string;
  view: IChargepoint;
  // payload: ReqModel;
  data: {};
  // search_payload: {
  //   isOpen: boolean;
  // }; // ---------------------검색조건 및 상세검색조건
  totalCount: number;
  list: ICharger[];
  [key: string]: any;
};

export const sliceName = 'chargepoint';
const initialState: StateType = {
  mode: SCREEN_MODE.EMPTY,
  view: {
    activated_at: '',
    channel_id: '',
    code: '',
    connectors: [],
    heartbeat_interval: null,
    installed_at: '',
    is_concurrency: null,
    is_roaming: null,
    device_status_items: [],
    model: {
      charger_type: 'FAST',
      connector_count: null,
      created_at: undefined,
      created_by: undefined,
      efficiencies: null,
      firmware_version: undefined,
      ipx_level: undefined,
      is_concurrency: null,
      last_modified_at: undefined,
      last_modified_by: undefined,
      max_ampare: null,
      max_power_kw: null,
      max_voltage: null,
      name: '',
      pg: '',
      seq: '',
      status: '',
      suspended_at: '',
      trouble_version: '',
      model_connectors: [],
      vendor: {
        seq: '',
        cpo_seq: '',
        vendor_id: '',
        ocpp_vendor_id: '',
        name: '',
        country_code2: '',
        country_code_2: '',
        post_code: '',
        address: '',
        address_detail: '',
        phone: '',
        status: '',
        created_at: '',
        created_by: '',
        suspended_at: '',
        last_modified_at: '',
        last_modified_by: '',
        fax: '',
        model_count: '',
      },
    },
    name: '',
    password: '',
    produced_at: '',
    roaming_chargepoint_id1: '',
    roaming_chargepoint_id2: '',
    roaming_station_id: '',
    seq: '',
    socket_id: '',
    station: {
      kr_location: {
        name: '',
      },
      address: '',
      address_detail: '',
      b_code: '',
      chargepoint_count: null,
      code: '',
      country_code2: '',
      created_at: '',
      created_by: null,
      description: '',
      is_default: null,
      last_chargepoint_number: null,
      last_modified_at: '',
      last_modified_by: null,
      latitude: '',
      longitude: '',
      memo: '',
      name: '',
      old_code: '',
      phone: '',
      pic_large: '',
      pic_medium: '',
      pic_small: '',
      post_code: '',
      seq: '',
      status: '',
      tariff_type_applied_at: '',
      tariff_types: '',
      timezone: '',
      station_type: null,
    },
    status: '',
    suspended_at: '',
  },
  data: {},
  list: [],
  totalCount: 0,
};

const chargepointSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setList: (state: StateType, action: PayloadAction<any>) => {
      state.list = action.payload.list;
      state.totalCount = action.payload.totalCount;
    },
    setView: (state: StateType, action: PayloadAction<any>) => {
      state.view = action.payload;
    },
  },
});

export const { actions } = chargepointSlice;
export default chargepointSlice.reducer;
