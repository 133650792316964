import React, { useEffect } from 'react';
import { COLOR, layout } from 'styles';
import styled from 'styled-components';
import { Button, Events, H5 } from 'lib';
import { icon_computer } from 'assets/images';
import { useAppDispatch, useAppSelector } from 'model';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import InputType from './input-type';
import InputDate from './input-date';
import InputStation from './input-station';
import { actions } from '@/model/modules/dashboard';

/**
 * @name Filter(통계유형,선택날짜,충전소선택)
 * @description
 */
export default function DashboardFilterControl() {
  const { period } = useAppSelector(state => state.dashboard);
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');

  useEffect(() => {
    // 날짜차이계산
    const d1 = dayjs(period?.tz_start_date);
    const d2 = dayjs(period?.tz_end_date);
    const diff = d2.diff(d1, 'day');
    if (diff < 0) {
      alert(t('common.toast.selectEndDateAgain'));
      return;
    }
    // 비교
    const compare_d2 = dayjs(period?.tz_start_date).add(-1, 'day').format('YYYYMMDD');
    const compare_d1 = dayjs(period?.tz_start_date)
      .add(diff * -1, 'day')
      .format('YYYYMMDD');
    dispatch(actions.setPeriod({ diff, tz_compare_start_date: compare_d1, tz_compare_end_date: compare_d2 }));
  }, [period?.tz_start_date, period?.tz_end_date]);

  return (
    <Content className={layout.panel}>
      <div className="wrapper">
        <section className="type">
          <H5>{t('pages.dashboard.filterController.statisticsType')}</H5>
          <InputType />
        </section>
        <section>
          <H5>
            {period?.calc_type === 'REALTIME'
              ? t('pages.dashboard.filterController.select')
              : t('pages.dashboard.filterController.start')}
            {` ${t('pages.dashboard.filterController.date')}`}
          </H5>
          <InputDate
            placeholder={
              period?.calc_type === 'REALTIME'
                ? t('pages.dashboard.filterController.selectedDate')
                : t('pages.dashboard.filterController.startedDate')
            }
            value={period?.tz_start_date}
            onChange={(data: any) => {
              dispatch(actions.setPeriod({ tz_start_date: data?.value }));
            }}
          />
        </section>
        {period?.calc_type !== 'REALTIME' && (
          <section>
            <H5>{t('pages.dashboard.filterController.endDate')}</H5>
            <InputDate
              placeholder={t('pages.dashboard.filterController.endDate')}
              value={period?.tz_end_date}
              onChange={(data: any) => {
                dispatch(actions.setPeriod({ tz_end_date: data?.value }));
              }}
            />
          </section>
        )}
        <section className="station">
          <H5>{t('pages.dashboard.filterController.selectStation')}</H5>
          <InputStation />
        </section>
        <section className="button">
          <Button
            onClick={() => {
              // 유효성체크
              if (period?.tz_start_date === null) {
                alert(t('common.toast.dateSelectionNeeded'));
                return;
              }
              // 사용되지않는값 제거
              const temp = { ...period };
              if (temp?.calc_type === 'REALTIME') {
                delete temp.tz_end_date;
                delete temp.tz_compare_start_date;
                delete temp.tz_compare_end_date;
              }
              delete temp.diff;
              // Events.emit(_event.SUBMIT_FILTER, temp);
            }}
            iconStyle={{ width: 20, height: 20, marginRight: 10 }}
            icon={icon_computer}
            label={t('pages.dashboard.filterController.displayResult')}
            style={{ whiteSpace: 'nowrap' }}
          />
        </section>
      </div>
      {/* 레이어팝업 */}
    </Content>
  );
}

const Content = styled.div`
  display: flex;
  width: 100%;
  height: 116px;
  margin-bottom: 16px;
  padding: 24px;
  align-items: center;
  justify-content: center;
  /* wrap */
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    /* section */
    > section {
      display: flex;
      flex: 1;
      margin-right: 16px;
      flex-direction: column;
      justify-content: flex-end;
      /* align-items: center; */
    }
    // 선택날짜
    > section:first-child {
      flex-basis: 186px;
      flex-grow: 0;
      flex-shrink: 0;
    }
    // 선택날짜
    > section:nth-child(2) {
    }
    > section:last-child {
      margin-right: 0;
    }
    // 충전소선택
    > section.station {
      /* flex-basis: 213px;
      flex-grow: 0;
      flex-shrink: 0; */
    }
    // 결과보기
    > section.button {
      flex-basis: 117px;
      flex-grow: 0;
      flex-shrink: 0;
    }
    > section > h5 {
      display: block;
      margin-bottom: 4px;
      color: ${COLOR.GRAY1};
    }
  }
`;
