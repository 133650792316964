import React, { useEffect, useState } from 'react';
import { icon_close_black, icon_upload, icons } from 'assets/images';
import { Button, H5, Image, Text, ImageButton } from 'lib';
import { FileUploader } from 'react-drag-drop-files';
import styled, { css } from 'styled-components';
import { COLOR } from 'styles';
import { useTranslation } from 'react-i18next';
import { RootState, useAppSelector } from 'model';
import typography from '@/styles/typography';
import { SCREEN_MODE } from '@/types/mode';

type Props = {
  types?: string[]; // ---------------------------------File type
  onChange?: (e: File | null) => void; // -------------------onChange
  style?: React.CSSProperties; // ------------------ StyleSheet
  title?: string; // --------------------------------Title
  message?: string; // ------------------------------Message
};
/**
 * @name FormFileUploader
 */
export default function FormFileUploader({ style, onChange, types, title, message }: Props) {
  const {
    station: { mode, view },
  } = useAppSelector((state: RootState) => state);
  const isModify = mode === SCREEN_MODE.MODIFY;
  const { t } = useTranslation('common');
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [draggingState, setDraggingState] = useState(false);
  const [imageUploadErrorState, setImageUploadErrorState] = useState('');

  const handleFileChange = (file: File) => {
    setImageUploadErrorState('');
    setFile(file);
    setIsLoaded(true);
    if (onChange) {
      onChange(file);
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const removeImage = () => {
    setFile(null);
    setIsLoaded(false);
    setImagePreviewUrl('');
    if (onChange) {
      onChange(null);
    }
  };

  useEffect(() => {
    if (isModify && view.pic_large !== '' && view.pic_large !== null) {
      setImagePreviewUrl(`${view.pic_large}?timestamp=${new Date().getTime()}`);
      setIsLoaded(true);
    }
  }, [mode, view.pic_large]);

  return (
    <Element style={style}>
      <FileUploader
        hoverTitle=" "
        dropMessageStyle={{ backgroundColor: 'transparent' }}
        maxSize={10}
        onDraggingStateChange={(dragging: boolean) => {
          setDraggingState(dragging);
        }}
        onSizeError={() => {
          setImageUploadErrorState('size');
        }}
        onTypeError={() => {
          setImageUploadErrorState('type');
        }}
        name="file"
        handleChange={handleFileChange}
        types={types ?? ['zip', 'ZIP']}
        onDrop={(file: File) => {
          if (onChange) {
            onChange(file);
          }
        }}
        onSelect={(file: File) => {
          if (onChange) {
            onChange(file);
          }
        }}
        onChange={(file: File) => {
          if (onChange) {
            onChange(file);
          }
        }}>
        {draggingState ? (
          <DropHere>
            <DropHereTitle>{t('common.fileUploader.dropHere')}</DropHereTitle>
          </DropHere>
        ) : (
          <UploadFileArea $error={imageUploadErrorState}>
            {imageUploadErrorState !== '' && (
              <WarningContainer>
                <Image src={icons.warningTriangle.image.default} style={{ width: 16, height: 16 }} />
                <WarningText>
                  {imageUploadErrorState === 'type'
                    ? t('common.fileUploader.typeError')
                    : t('common.fileUploader.sizeError')}
                </WarningText>
              </WarningContainer>
            )}
            <Image src={icon_upload} style={{ width: 44, height: 44, marginBottom: 8 }} />
            <H5 style={{ height: 24, marginBottom: 4 }} color={COLOR.GRAY1}>
              {title ?? t('common.fileUploader.attachFirmwareFile')}
            </H5>
            <Text style={{ height: 24 }} color={COLOR.GRAY2} className="body7">
              {message ?? t('common.fileUploader.fileTypeDescription', { type: 'zip' })}
            </Text>
            <Button label={t('common.fileUploader.upload')} style={{ height: 28 }} />
          </UploadFileArea>
        )}
      </FileUploader>
      {isLoaded && (
        <ThumbnailContainer>
          <Image src={imagePreviewUrl} alt="Preview" style={{ width: 80, height: 60 }} />
          <ThumbnailTextContainer>
            <FileInfo>
              <FileName>{isModify && !file ? t('common.fileUploader.currentUploadedImage') : file?.name}</FileName>
              <ImageButton onClick={removeImage} source={icon_close_black} style={{ width: 16, height: 16 }} />
            </FileInfo>
          </ThumbnailTextContainer>
        </ThumbnailContainer>
      )}
    </Element>
  );
}

const Element = styled.div``;
const UploadFileArea = styled.div<{ $error: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  border: 2px dashed ${COLOR.GRAY4};
  border-radius: 8px;
  ${props =>
    props.$error !== '' &&
    css`
      border: 2px dashed ${COLOR.RED1};
    `}
`;

const ThumbnailContainer = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
const ThumbnailTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
const FileName = styled.div`
  ${typography.body8};
  color: ${COLOR.BLACK3};
`;
const FileInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const DropHere = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  border: 2px dashed ${COLOR.BLUE2};
  border-radius: 8px;
  min-height: 196px;
  background-color: ${COLOR.BLUE9};
`;
const DropHereTitle = styled.p`
  ${typography.headline5};
  color: ${COLOR.GRAY1};
`;

const WarningContainer = styled.div`
  position: absolute;
  top: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%), ${COLOR.RED2};
`;

const WarningText = styled.span`
  ${typography.caption1};
  color: ${COLOR.RED1};
`;

/** ******************************************************
[사용법]

  <FormFileUploader
    types={['zip', 'ZIP']}
    title="업로드할 펌웨어파일을 첨부해주세요."
    message="파일형식은 zip만 업로드 가능합니다."
    onChange={(file: any) => {
      dispatch(_action.setPayload({firmware_file: file}))
    }}
  />
******************************************************** */
