/**
 * @name 제조사
 * @description
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SCREEN_MODE } from '@/types/mode';
import { IVendorDetail } from '@/types/infra';
// 모드변경 _mode
export const MODE = {
  DEFAULT: 'DEFAULT',
  LIST: 'LIST',
  VIEW: 'VIEW',
  MODIFY: 'MODIFY',
  REGISTER: 'REGISTER',
  EMPTY: 'EMPTY',
};
// 팝업 _popup
export const POPUP = {
  SEND_MAIL_SUCCESS: 'SEND_MAIL_SUCCESS', // 메일발송
  SEND_MAIL_FAIL: 'SEND_MAIL_FAIL', // 메일발송실패
  VENDOR_REGISTER_SUCCESS: 'VENDOR_REGISTER_SUCCESS', // ----------------- 제조사등록성공
  VENDOR_REGISTER_FAIL: 'VENDOR_REGISTER_FAIL', // ----------------------- 제조사등록실패
  VENDOR_REMOVE_CONFIRM: 'VENDOR_REMOVE_CONFIRM', // ----------------------제조사삭제확인
};
// 이벤트 _event
export const EVENT = {
  // submit
  SUBMIT: 'SUBMIT',
  UPDATE: 'UPDATE',
  // 레이어팝업
  POPUP_SHOW: 'POPUP_SHOW',
  POPUP_HIDE: 'POPUP_HIDE',
  POPUP_SET: 'POPUP_SET',
  // CRUD
  SUBMIT_CREATE: 'SUBMIT_CREATE', // 등록하기
  FETCH_LIST: 'FETCH_LIST', // 리스트데이터요청
  FETCH_VIEW: 'FETCH_VIEW', // 상세데이터요청
  DELETE: 'DELETE', // 삭제하기
  MODIFY: 'MODIFY', // 수정하기
};
//* --------------------------------------------------*
/** @defineType */
type StateType = {
  mode: string;
  duplicatedCheck: {
    name: boolean;
    vendor_id: boolean;
  };
  view: IVendorDetail;
  payload: {
    name: string;
    country_code_2: string;
    post_code: string;
    address: string;
    address_detail: string;
    phone: string;
    fax: string;
    vendor_id: string;
  };
  list: any;
  totalCount: number;
  data: {};
};
//* --------------------------------------------------*
export const sliceName = 'vendor'; // 변경필요
/** @initialState */
const initialState: StateType = {
  mode: SCREEN_MODE.EMPTY,
  duplicatedCheck: {
    name: true,
    vendor_id: true,
  },
  view: {
    name: '',
    vendor_id: '',
    address: '',
    address_detail: '',
    country_code2: '',
    created_at: '',
    fax: '',
    last_modified_at: '',
    model_cps: [],
    ocpp_vendor_id: '',
    phone: '',
    post_code: '',
    seq: 0,
    status: '',
    suspended_at: '',
  },
  payload: {
    name: '',
    country_code_2: '',
    post_code: '',
    address: '',
    address_detail: '',
    phone: '',
    fax: '',
    vendor_id: '',
  },
  list: [],
  totalCount: 0,
  data: {},
};
//* --------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //* --------------------------------------------------*
    // 초기화
    setInit(state: StateType) {
      state.mode = SCREEN_MODE.EMPTY;
    },
    setDuplicatedCheck: (state: StateType, action: PayloadAction<{ name?: boolean; vendor_id?: boolean }>) => {
      state.duplicatedCheck = { ...state.duplicatedCheck, ...action.payload };
    },
    // setMode
    setMode(state: StateType, action: PayloadAction<SCREEN_MODE>) {
      state.mode = action.payload;
    },
    // fetch 리스트
    setList(state: StateType, action: PayloadAction<any>) {
      state.list = action.payload.list;
      state.totalCount = action.payload.totalCount;
    },
    initView: (state: StateType) => {
      state.view = initialState.view;
    },
    initPayload: (state: StateType) => {
      state.payload = initialState.payload;
    },
    // fetch view
    setView(state: StateType, action: PayloadAction<IVendorDetail>) {
      state.view = action.payload;
    },
    // create Payload업데이트
    setPayload(state: StateType, action: PayloadAction<any>) {
      state.payload = Object.assign(state.payload, action.payload);
    },
  },
});
//* --------------------------------------------------*
export default slice.reducer;
export const nameSpace = sliceName;
export const { actions } = slice;
