import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Image, Row, useClickOutside, uuid } from 'lib';
import { useAppSelector } from 'model';
import { COLOR } from 'styles';
import { icon_arrow_down_black } from 'assets/images';
import { ICountry } from '@/types/location';

/**
 * @name 국가검색
 * @description
 */
type Props = {
  style?: React.CSSProperties;
  popupStyle?: React.CSSProperties;
  onChange?: (value: ICountry | string) => void;
  value?: string;
  disabled?: boolean;
};
export default function CountriesSearch({ onChange, style, popupStyle, disabled, value }: Props) {
  const eleRef = useRef(null);
  const [search, setSearch] = useState('');
  const [flag, setFlag] = useState('');
  const [visible, setVisible] = useState(false);
  const { countries } = useAppSelector(state => state.location);

  // outside click
  useClickOutside(eleRef, () => {
    setVisible(false);
  });

  useEffect(() => {
    if (!value) return;
    if (countries) {
      const filteredCountry = countries.find((element: ICountry) => element.code2 === value);
      if (filteredCountry) {
        setSearch(filteredCountry.name);
        setFlag(filteredCountry.flag_path);
      }
    }
  }, [value]);

  return (
    <Content ref={eleRef} style={style} $disabled={disabled}>
      <SelectButton
        $disabled={disabled}
        type="button"
        className="select"
        onClick={() => {
          if (!!disabled && disabled) return;
          setVisible(!visible);
        }}>
        <Selected>
          {!!flag && <Image src={flag} style={{ width: 20, height: 20, marginRight: 10, borderRadius: 5 }} />}
          <SelectedCountryText $disabled={disabled}>{search || `제조국가`}</SelectedCountryText>
        </Selected>
        <Image src={icon_arrow_down_black} style={{ width: 20, height: 20 }} />
      </SelectButton>
      {/* 레이어팝업 */}
      {visible && (
        <section className="popup" style={popupStyle}>
          <button
            type="button"
            className="item"
            key={uuid()}
            onClick={() => {
              if (onChange) {
                onChange('');
              }
              setSearch('');
              setFlag('');
              setVisible(false);
            }}>
            전체
          </button>
          {countries?.map((item: ICountry) => {
            return (
              <button
                type="button"
                className="item"
                key={uuid()}
                onClick={() => {
                  if (onChange) {
                    onChange(item);
                  }
                  setSearch(item.name);
                  setFlag(item.flag_path);
                  setVisible(false);
                }}>
                <Image src={item?.flag_path} style={{ width: 20, height: 20, marginRight: 10, borderRadius: 5 }} />
                {item?.name}
              </button>
            );
          })}
        </section>
      )}
    </Content>
  );
}

const Content = styled.div<{ $disabled?: boolean }>`
  position: relative;
  section.popup {
    position: absolute;
    z-index: 10;
    background-color: ${COLOR.WHITE};
    width: 100%;
    height: 300px;
    box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.07);
    border-radius: 6px;
    overflow-y: scroll;

    button.item {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
      height: 40px;
      padding: 0 16px;
      color: ${COLOR.BLACK1};
      &:hover {
        background-color: ${COLOR.GRAY6};
      }
    }
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${COLOR.GRAY6};
    `}
`;

const SelectButton = styled.button<{ $disabled?: boolean }>`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  border: 1px solid ${COLOR.GRAY4};
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: default;
    `}
`;

const Selected = styled.div`
  display: flex;
  align-items: center;
`;

const SelectedCountryText = styled.span<{ $disabled?: boolean }>`
  color: ${COLOR.GRAY2};
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${COLOR.GRAY3};
    `}
`;
