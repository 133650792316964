/**
 * @name 리스트상단제목검색
 * @description
 */
import React from 'react';
import { TextFiledSearch } from 'components/form';
import { Row } from 'lib';
import { CSS } from 'types';
import ListTitle from './list-title';

type Props = {
  title?: string;
  total?: number;
  placeholder?: string;
  searchStyle?: CSS;
  insert?: React.ReactNode;
  onSubmit?: (str: string) => void;
  onChange?: (str: string) => void;
};
export default function ListTitleSearch({ title, insert, total, placeholder, onChange, searchStyle, onSubmit }: Props) {
  return (
    <Row>
      {/* 타이틀및갯수보여주기 */}
      <ListTitle title={title} total={total} />
      {/* 국가같은 중간에 값필요한상태시 입력 */}
      {insert && insert}
      <div className="search" style={{ marginLeft: 20 }}>
        <TextFiledSearch
          style={searchStyle}
          placeholder={placeholder}
          onChange={str => {
            if (onChange) {
              onChange(str);
            }
          }}
          onSubmit={str => {
            if (onSubmit) {
              onSubmit(str);
            }
          }}
        />
      </div>
    </Row>
  );
}
/** ******************************************************
[사용법]
  <ListTitleSearch
    title="충전소 등록 관리"
    total={list?.meta?.paging?.total_count}
    placeholder="placeholder"
    onChange={str => {
      console.log(str)
    }}
    onSubmit={str => {
      alert(str)
    }}
  />
******************************************************** */
