import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ModelDetailPresenter from './ModelDetail.presenter';
import { actions } from '@/model/modules/model';
import { SCREEN_MODE } from '@/types/mode';
import { MENU } from '@/pages/router';
import { useAppDispatch } from '@/model/index';
import { getModel, getVendorSeq, removeModel } from '@/lib/apis/infra';
import { IResponse } from '@/lib/apis/api/type';
import { IChargePointModelDetail } from '@/types/infra';

export default function ModelDetailContainer() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [deletePopup, setDeletePopup] = useState(false);

  const onInit = () => {
    fetchVendorDetail();
  };

  const fetchVendorDetail = async () => {
    if (id) {
      const { isSuccess, data }: IResponse<IChargePointModelDetail[]> = await getModel(id);
      if (isSuccess) {
        const vendorResponse = await getVendorSeq(data[0].vendor.seq);
        if (vendorResponse.isSuccess) {
          const chargePointModelData: IChargePointModelDetail = {
            ...data[0],
            vendor: vendorResponse.data,
          };
          dispatch(actions.setView(chargePointModelData));
        }
      }
    }
  };

  const handleDeletePopup = (value: boolean) => {
    setDeletePopup(value);
  };

  const handleBackToList = () => {
    navigate(`${MENU.INFRA.MODEL_MANAGEMENT}`);
  };

  const onEdit = () => {
    dispatch(actions.setMode(SCREEN_MODE.MODIFY));
    navigate(`${MENU.INFRA.MODEL_MANAGEMENT_UPDATE}/${id}`);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (id) {
        const removeResponse = await removeModel(id);
        if (removeResponse.isSuccess) {
          handleDeletePopup(false);
          dispatch(actions.initView());
          navigate(MENU.INFRA.MODEL_MANAGEMENT);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    onInit();
  }, [id]);

  return (
    <ModelDetailPresenter
      onEdit={onEdit}
      handleDeletePopup={handleDeletePopup}
      handleBackToList={handleBackToList}
      deletePopupVisible={deletePopup}
      onDeleteConfirm={handleDeleteConfirm}
    />
  );
}
