/**
 * @name Header
 * @description
 */
import {Div, Events, H3, Row} from 'lib'
import {useAppDispatch} from 'model'
import {ListTitleSearch, ButtonsModifyDel, BackButton} from 'components'
import {Store, _action, _event, _mode} from '../../index'

export default function Contents() {
  // hooks
  const dispatch = useAppDispatch()
  const {mode, list, view, search_payload} = Store()
  //*--------------------------------------------------*
  const setContents = () => {
    switch (mode) {
      case _mode.DEFAULT: //---------------------------기본
      case _mode.LIST: //------------------------------리스트
        return (
          <div style={{width: '100%'}}>
            <Div style={{width: '100%'}}>
              <Row>
                <ListTitleSearch
                  title="충전소별 충전단가"
                  total={list?.meta?.paging?.total_count ?? 0}
                  placeholder="충전소 ID, 또는 이름으로 검색해 주세요."
                  onChange={str => {
                    // 빈값을때 리스트를 다시 불러온다.
                    if (str === '') Events.emit(_event.FETCH_LIST, {search: str})
                  }}
                  onSubmit={str => {
                    Events.emit(_event.FETCH_LIST, {search: str})
                  }}
                />
              </Row>
            </Div>
          </div>
        )
      case _mode.VIEW: //------------------------------상세
        return (
          <Div style={{width: '100%'}}>
            <BackButton label={view?.data?.station?.name} />
          </Div>
        )
      default:
        return <div>없음</div>
    }
  }
  return <header className="inc_header">{setContents()}</header>
}
