import { App } from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { H3, Row, Text, addComma } from 'lib';
import { useAppSelector } from '@/model/index';
import { COLOR } from '@/styles/index';
import { TextFiledSearch } from '@/components/form';
import DownloadButton from '@/components/atoms/Buttons/DownloadButton';
import TroubleReports from '@/components/organisms/Tables/TroubleReports';

/**
 * @name 원격제어 리스트
 * @description
 */
export default function TroubleReportsListPresenter({
  itemsPerPage,
  currentPage,
  pageHandler,
  handleExcelDownload,
  search,
  setSearch,
  handleSearch,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  handleExcelDownload: () => void;
  search: string;
  setSearch: (str: string) => void;
  handleSearch: () => void;
}) {
  const { t } = useTranslation('common');
  const { totalCount } = useAppSelector(state => state.troubleReports);

  return (
    <App>
      <Header style={{ width: '100%' }}>
        <HeaderFuncWrapper>
          <Row>
            <div style={{ marginRight: 20 }}>
              <H3 color={COLOR.BLACK3}>{t('pages.operation.troubleReports.list.title')}</H3>
              <Text color={COLOR.BLACK3} className="body6">
                {`${t('common.unit.total')} ${addComma(totalCount)}${t('common.unit.cases')}`}
              </Text>
            </div>
            <div className="search" style={{ marginLeft: 20 }}>
              <TextFiledSearch
                defaultValue={search}
                placeholder={t('pages.operation.troubleReports.list.searchPlaceholder')}
                onChange={str => {
                  setSearch(str);
                }}
                onSubmit={handleSearch}
              />
            </div>
          </Row>
          <ButtonWrapper>
            <DownloadButton onClick={handleExcelDownload} />
          </ButtonWrapper>
        </HeaderFuncWrapper>
      </Header>
      <TroubleReports itemsPerPage={itemsPerPage} currentPage={currentPage} pageHandler={pageHandler} />
    </App>
  );
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${COLOR.GRAY5};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderFuncWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 16px 16px 16px 24px;
  align-items: center;
  justify-content: space-between;
`;
const FilterWrapper = styled.div`
  padding: 8px 32px;
`;
