/**
 * @name Test
 * @description 가이드페이지
 */
import styled from 'styled-components'
import {COLOR, layout} from 'styles'
import {H1, H3, H5, addComma} from 'lib'

type Props = {
  children?: React.ReactNode //-------------------Children
  className?: string //----------------------- 클래스네임
  total?: number | string | undefined | any //---------------------------- 카운트
  suffix?: string //--------------------------- 프리픽스
  style?: any
  mainTitle?: string
  [key: string]: any
}
export default function Panel({children, className, style, mainTitle, total, suffix}: Props) {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content className={layout.panel}>
      <section style={style} className={`${className}`}>
        {!!mainTitle && <H3>{mainTitle}</H3>}
        {/* 헤더 */}
        {/* <header>
          {!!total && <H1>{typeof total === 'number' ? addComma(total) : total}</H1>}
          {!!suffix && <H5>{suffix ?? '원'}</H5>}
        </header> */}
        <main>{children}</main>
      </section>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  height: 316px;
  padding: 24px;
  h3 {
    color: ${COLOR.GRAY1};
    text-align: center;
  }
  .title {
    display: flex;
    height: 48px;
    margin-top: 20px;
    border-radius: 12px;
    self-align: center;
    align-items: center;
    justify-content: center;
    color: ${COLOR.BLUE3};
    background: ${COLOR.BLUE9};
  }
  /* 공통 */
  header {
    display: flex;
    margin-top: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    h1 {
      color: ${COLOR.BLUE3};
    }
    h5 {
      margin-left: 4px;
      color: ${COLOR.GRAY2};
      font-weight: normal;
    }
  }
  /* main */
  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* max-width: 200px; */
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }
`
