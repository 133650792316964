import styled, { css } from 'styled-components';
import typography from '@/styles/typography';
import { COLOR } from '@/styles/index';

export const Wrapper = styled.div``;

export const FilterRow = styled.div<{ $borderNone?: boolean }>`
  padding: 8px 0;
  display: flex;
  align-items: center;
  gap: 16px;
  ${({ $borderNone }) =>
    !$borderNone &&
    css`
      border-bottom: 1px solid ${COLOR.GRAY4};
    `};
`;
export const FilterTitle = styled.div`
  position: relative;
  ${typography.headline5};
  color: ${COLOR.GRAY1};
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 178px;
`;

export const LimitDescription = styled.span`
  position: absolute;
  bottom: -8px;
  flex: 1;
  ${typography.caption2};
  color: ${COLOR.GRAY2};
`;

export const DeleteTerms = styled.button`
  ${typography.body6};
  color: ${COLOR.GRAY4};
  padding: 4px;
`;

export const Terms = styled.div``;
