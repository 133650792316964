import styled, { css } from 'styled-components';
import { ButtonSize, ButtonStyleProps } from '@/components/atoms/Buttons/Buttons.types';
import { COLOR } from '@/styles/index';
import { icons } from '@/assets/images';

const chevronLeftImage = {
  default: icons.chevronLeft.image.default,
  hover: icons.chevronLeftBlue.image.default,
  focus: icons.chevronLeftBlack.image.default,
  disabled: icons.chevronLeftWhite.image.default,
};
const chevronRightImage = {
  default: icons.chevronRight.image.default,
  hover: icons.chevronRightBlue.image.default,
  focus: icons.chevronRightBlack.image.default,
  disabled: icons.chevronRightWhite.image.default,
};

export const ChevronLeftImageBox = styled.div`
  background-image: url('${chevronLeftImage.default}');
  background-repeat: no-repeat;
  background-position: center center;
  width: 16px;
  height: 16px;
  background-size: 16px;
`;

export const ChevronRightImageBox = styled.div`
  background-image: url('${chevronRightImage.default}');
  background-repeat: no-repeat;
  background-position: center center;
  width: 16px;
  height: 16px;
  background-size: 16px;
`;

export const Button = styled.button<ButtonStyleProps>`
  display: flex;
  align-items: center;
  height: 40px;
  gap: 4px;
  ${(props: ButtonStyleProps) => {
    switch (props.$size) {
      case ButtonSize.L:
        return css`
          padding: ${props.$direction === 'left' ? '8px 16px 8px 8px' : '8px 8px 8px 16px'};
        `;
      case ButtonSize.M:
        return css`
          padding: ${props.$direction === 'left' ? '8px 16px 8px 8px' : '8px 8px 8px 16px'};
        `;
      case ButtonSize.S:
      default:
        return css`
          padding: ${props.$direction === 'left' ? '8px 16px 8px 8px' : '8px 8px 8px 16px'};
        `;
    }
  }};
  border-radius: 8px;

  color: ${COLOR.GRAY1};
  background-color: ${COLOR.WHITE};
  &:hover {
    color: ${COLOR.BLUE4};
  }
  &:focus {
    color: ${COLOR.BLUE1};
  }
  &:disabled {
    cursor: default;
    color: ${COLOR.GRAY4};
    background-color: ${COLOR.GRAY5};
  }
  &:disabled:hover {
  }

  &:hover ${ChevronLeftImageBox} {
    background-image: url('${chevronLeftImage.hover}');
  }
  &:focus ${ChevronLeftImageBox} {
    background-image: url('${chevronLeftImage.focus}');
  }
  &:disabled ${ChevronLeftImageBox} {
    background-image: url('${chevronLeftImage.disabled}');
  }

  &:hover ${ChevronRightImageBox} {
    background-image: url('${chevronRightImage.hover}');
  }
  &:focus ${ChevronRightImageBox} {
    background-image: url('${chevronRightImage.focus}');
  }
  &:disabled ${ChevronRightImageBox} {
    background-image: url('${chevronRightImage.disabled}');
  }
`;
