/**
 * @name Aside
 * @description
 */
import styled from 'styled-components'
// contents
import Ranking from './ranking'

export default function Contents() {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      {/* 실시간장애현황 */}
      <Ranking />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  position: absolute;
  top: 16px;
  right: 24px;
  z-index: 1;
`
