import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TroubleReport } from '@/lib/apis/operation/trouble-reports/type';

type StateType = {
  list: TroubleReport[];
  totalCount: number;
};

export const sliceName = 'troubleReports';
const initialState: StateType = {
  list: [],
  totalCount: 0,
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setList(state: StateType, action: PayloadAction<{ list: TroubleReport[]; totalCount: number }>) {
      state.list = action.payload.list;
      state.totalCount = action.payload.totalCount;
    },
  },
});

export default slice.reducer;
export const nameSpace = sliceName;
export const { actions } = slice;
