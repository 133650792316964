/**
 * @name Context
 * @description
 */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
/** @initialState */
export const sliceName = 'context' //변경필요
//*--------------------------------------------------*
/** @defineType */
type StateType = {
  countries: any[] | null
  [key: string]: any
}
//*--------------------------------------------------*
/** @initialState */
const initialState: StateType = {
  countries: null,
}
//*--------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //*--------------------------------------------------*
    // 국가코드추가
    setCountries(state: StateType, action: PayloadAction<any>) {
      state.countries = action.payload
    },
  },
})
//*--------------------------------------------------*
// export
export const EVENT = {
  SUBMIT: 'SUBMIT',
  UPDATE: 'UPDATE',
}
export default slice.reducer
export const nameSpace = sliceName
export const actions = slice.actions
