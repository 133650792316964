/**
 * @name List
 * @description
 * @todo 상세정보에서 충전소명 디자인에 없는 속성이 있음. 재천님 요청
 */
import {useState} from 'react'
import styled from 'styled-components'
import {Tab, debug} from 'lib'
// contents
import Device from './device'
import Connectors from './connectors'
import Infra from './infra'
import {Store} from '../..'

export default function Contents() {
  // const
  const {view} = Store()
  const tabMenu = [{label: '충전기 기본&상태 정보'}, {label: '커넥터 상태 정보'}, {label: '인프라 정보'}]
  const [selected, setSelected] = useState(0)
  //*--------------------------------------------------*

  // setContents
  const setContents = () => {
    switch (selected) {
      case 0:
        return <Device />
      case 1:
        return <Connectors />
      case 2:
        return <Infra />
    }
  }
  //*--------------------------------------------------*
  return (
    <Content>
      <header className="auto">
        <Tab
          selected={selected}
          style={{marginBottom: 32}}
          info={tabMenu}
          onChange={({index}) => {
            setSelected(index)
          }}
        />
      </header>
      {/* {debug(view)} */}
      <main>{setContents()}</main>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  header {
    width: 674px;
    margin: 24px auto;
  }
  main {
    width: 674px;
    margin: 24px auto;
  }
`
