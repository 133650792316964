/**
 * @name 매출관리(충전현황,결제현황,정산현황,목표관리,실적분석리포트)
 * @description
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPaymentStatusRes } from '@/lib/apis/sales/type';
import { IChargingStatusDetail } from '@/types/chargingStatus';
// 모드변경 _mode
export const MODE = {
  DEFAULT: 'DEFAULT',
  LIST: 'LIST',
  VIEW: 'VIEW',
  MODIFY: 'MODIFY',
  REGISTER: 'REGISTER',
  EMPTY: 'EMPTY',
} as const;
// 팝업 _popup
export const POPUP = {
  REGISTER_SUCCESS: 'REGISTER_SUCCESS', // ----------- 등록성공
  REGISTER_FAIL: 'REGISTER_FAIL', // ----------------- 등록실패
  CANCEL_CONFIRM: 'CANCEL_CONFIRM', // --------------- 취소확인
  DELETE_CONFIRM: 'DELETE_CONFIRM', // --------------- 삭제확인
};
// 이벤트 _event
export const EVENT = {
  // submit
  SUBMIT: 'SUBMIT',
  UPDATE: 'UPDATE',
  // 레이어팝업
  POPUP_SHOW: 'POPUP_SHOW',
  POPUP_HIDE: 'POPUP_HIDE',
  POPUP_SET: 'POPUP_SET',
  // CRUD
  SUBMIT_CREATE: 'SUBMIT_CREATE', // 등록하기
  FETCH_LIST: 'FETCH_LIST', // 리스트데이터요청
  FETCH_VIEW: 'FETCH_VIEW', // 상세데이터요청
  DELETE: 'DELETE', // 삭제하기
  MODIFY: 'MODIFY', // 수정하기
};
//*  --------------------------------------------------*
/** @defineType */
type StateType = {
  mode: string;
  payload: {};
  data: {};
  view: IChargingStatusDetail;
  list: IPaymentStatusRes[];
  totalCount: number;
  search_payload: {
    isOpen: boolean;
  }; //  ---------------------검색조건 및 상세검색조건
  [key: string]: unknown;
};
export const sliceName = 'sales'; // 변경필요
/** @initialState */
const initialState: StateType = {
  mode: MODE.DEFAULT,
  payload: {},
  view: {
    transaction_id: 0,
    id_tag: '',
    start_types: '',
    end_types: '',
    meter_start: 0,
    start_soc: '',
    meter_last: 0,
    end_soc: '',
    status: '',
    stop_reason: '',
    created_at: '',
    completed_at: '',
    connector: {
      seq: 0,
      code: '',
      types: '',
      chargepoint: {
        seq: 0,
        code: '',
        name: '',
        station: {
          seq: 0,
          code: '',
          name: '',
        },
      },
    },
    purchases: [],
    average_watt: 0,
  },
  list: [],
  totalCount: 0,
  data: {},
  search_payload: {
    isOpen: false,
  },
};
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInit(state: StateType) {
      Object.assign(state, initialState);
      Object.assign(state, initialState);
    },
    setMode(state: StateType, action: PayloadAction<(typeof MODE)[keyof typeof MODE]>) {
      state.mode = action.payload;
    },
    // fetch 리스트
    setList(state: StateType, action: PayloadAction<any>) {
      state.list = action.payload.list;
      state.totalCount = action.payload.totalCount;
    },
    // fetch view
    setView(state: StateType, action: PayloadAction<IChargingStatusDetail>) {
      state.view = action.payload;
    },
    // create Payload업데이트
    setPayload(state: StateType, action: PayloadAction<unknown>) {
      state.payload = Object.assign(state.payload, action.payload);
      state.payload = Object.assign(state.payload, action.payload);
    },
    // 검색및 상세검색조건 업데이트
    setSearchPayload(state: StateType, action: PayloadAction<unknown>) {
      state.search_payload = Object.assign(state.search_payload, action.payload);
      state.search_payload = Object.assign(state.search_payload, action.payload);
    },
  },
});
export default slice.reducer;
export const nameSpace = sliceName;
export const { actions } = slice;
