/**
 * @name Container
 */
import { useEffect } from 'react';
import { useAppDispatch } from 'model';
import { useAxios, useRoutes } from 'lib';
import { API_HOST } from 'config';
import { MENU } from '@/pages/router';
import { gnbAction } from 'model/modules';
import { signOut } from 'pages/common';
// contents
import Presenter from './presenter';

export default function SignOutContainer() {
  // hooks
  const { post } = useAxios();
  const dispatch = useAppDispatch();
  const { navigate } = useRoutes();
  // 로그아웃,초기화
  async function onLogout() {
    const res = await post(`${API_HOST}/auth/logout`);
    if (res?.isSuccess) {
      signOut();
      // GNB 초기화
      dispatch(gnbAction.setInit());
      navigate(MENU.HOME);
    }
  }

  useEffect(() => {
    // 초기화
    onLogout();
    return () => {};
  }, []);

  return <Presenter />;
}
