/**
 * @name 충전소등록관리
 * @description
 * @mode 1.충전소등록관리, 2.충전소등록, 3.충전소수정, 4.충전소상세
 */
import {App} from 'components'
import {Store, _mode} from '.'
// contents
import {Header, Popup} from './common'
import {List, View, Modify, Template} from './contents'
import {debug, useContext} from 'lib'

export default function Presenter() {
  // hooks
  const {menu} = useContext()
  const {mode} = Store()
  //*--------------------------------------------------*
  /**
   * @name 리스트,상세,수정,등록컨텐츠분기
   */
  const setContents = () => {
    switch (mode) {
      case _mode.REGISTER: //-------------------------등록
        return <Template />
      case _mode.MODIFY: //---------------------------수정
        return <Modify />
      case _mode.VIEW: //-----------------------------상세
        return <View />
      case _mode.DEFAULT: //--------------------------기본값
      case _mode.LIST: //-----------------------------리스트
      default:
        return <List />
    }
  }
  //*--------------------------------------------------*
  return (
    <App>
      {/* {debug(menu)} */}
      {/* 헤더 */}
      <Header />
      {/* 컨텐츠 */}
      {setContents()}
      {/* 팝업 */}
      <Popup />
    </App>
  )
}
