import { Dayjs } from 'lib';

/**
 * @name API관련
 * @description
 */
/*= ==================================================================[로그인관련] */

/**
 * @name 달력날짜계산
 * @description Dayjs로 달력계산하여 포멧처리해서 보여준다.
 */
export function formatDay(str: string) {
  if (!str) return '';
  return Dayjs(str).format('YYYY.MM.DD HH.mm.ss');
}

/**
 * @name 달력날짜계산
 * @description Dayjs로 달력계산하여 포멧처리해서 보여준다.
 */
export function formatDateString(data: any) {
  if (!data) return '';
  return `${data?.year}-${data?.month}-${data?.date}`;
}

/*= ==================================================================[포멧정의] */
/**
 * @name 커넥터포멧정리
 * @description
 */
export function formatConnector(str: string) {
  if (str === 'CCS1' || str === 'CCS2') return 'DC콤보';
  if (str === 'CHADEMO') return 'DC차데모';
  if (str === '3PHASE') return 'AC상';
  if (str === 'GBT') return 'GBT';
  if (str === 'J1772') return 'AC완속';
  return '미정';
}
