/**
 * @name 아니요,입력취소하기버튼
 * @description
 */

import styled from 'styled-components'
import {CSS} from 'types'
import {Button} from 'lib'
import {COLOR} from 'styles'

type Props = {
  cancelLabel?: string
  onCancel?: () => void
  confirmLabel?: string
  onConfirm?: () => void
  style?: CSS
}
export default function SubmitCancel({cancelLabel, confirmLabel, onCancel, onConfirm, style}: Props) {
  return (
    <Content style={style}>
      <Button
        full
        label={cancelLabel ?? '취소'}
        bg="transparent"
        borderColor={COLOR.GRAY2}
        color={COLOR.GRAY1}
        style={{height: 48, borderRadius: 8, fontSize: 15}}
        onClick={() => {
          !!onCancel && onCancel()
        }}
      />
      <Button
        full
        label={confirmLabel ?? '등록 취소하기'}
        bg={COLOR.RED2}
        style={{height: 48, borderRadius: 8, fontSize: 15}}
        onClick={() => {
          !!onConfirm && onConfirm()
        }}
      />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  > * {
    margin-left: 8px;
    margin-right: 8px;
  }
`
/********************************************************
[사용법]
  <SubmitCancel
    cancelLabel="아니요"
    onCancel={onRemovePopup}
    onConfirm={() => {
      alert('등록')
    }}
  />
*********************************************************/
