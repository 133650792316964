import styled, { css } from 'styled-components';
import { COLOR } from '@/styles/index';
import typography from '@/styles/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Table = styled.table``;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody``;
export const TR = styled.tr`
  border-bottom: 1px solid ${COLOR.GRAY5};
`;

export const TH = styled.th`
  text-align: center;
  padding: 4px 8px;
  background-color: ${COLOR.BLUE9};
  ${typography.headline6};
  color: ${COLOR.GRAY1};
  height: 48px;
  vertical-align: middle;
`;