import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { Text } from 'lib';
import typography from '@/styles/typography';
import Pagination from '@/components/organisms/Paginations/Pagination';
import { Table, TableBody, TableHead, TD, TH, TR, Wrapper } from './CalculateDetailListTable.style';
import { useAppSelector } from '@/model/index';
import { ICalculate } from '@/lib/apis/sales/type';
import { COLOR } from '@/styles/index';

export default function CalculateDetailListTable({
  itemsPerPage,
  currentPage,
  pageHandler,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
}) {
  const { t } = useTranslation('common');
  const {
    list: { detailsList: list },
    totalCount,
  } = useAppSelector(state => state.sales);

  return (
    <Wrapper>
      <Text color={COLOR.BLACK3} className="body6">
        {`${t('pages.sales.calculateStatusDetail.listTitle')}`}
      </Text>
      <Table>
        <colgroup>
          <col style={{ width: 56 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 61 }} />
          <col style={{ minWidth: 61 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 61 }} />
        </colgroup>
        <TableHead>
          <TR>
            <TH>No.</TH>
            <TH>{t('pages.sales.calculateStatusDetail.table.stations')}</TH>
            <TH>{t('pages.sales.calculateStatusDetail.table.chargers')}</TH>
            <TH>{t('pages.sales.calculateStatusDetail.table.totalChargeAmount')}</TH>
            <TH>{t('pages.sales.calculateStatusDetail.table.totalChargePrice')}</TH>
          </TR>
        </TableHead>
        <TableBody>
          {list &&
            Array.isArray(list) &&
            list.length > 0 &&
            list.map((item: ICalculate, idx: number) => (
              <TR key={nanoid()}>
                <TD $customFont={typography.body4}>{(currentPage - 1) * 10 + (idx + 1)}</TD>
                <TD $customFont={typography.body4}>{item.stations}</TD>
                <TD $customFont={typography.body4}>{item.chargepoints}</TD>
                <TD $customFont={typography.body5}>{item.kw}</TD>
                <TD $customFont={typography.body5}>{item.amount}</TD>
              </TR>
            ))}
        </TableBody>
      </Table>
      {list && Array.isArray(list) && list.length > 0 && (
        <Pagination
          listLength={totalCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onClick={pageHandler}
        />
      )}
    </Wrapper>
  );
}
