import React, { useState } from 'react';
import { App, BackButton, ButtonsModifyDel } from 'components';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { RootState, useAppSelector } from '@/model/index';
import { COLOR } from '@/styles/index';
import { Tab } from '@/lib/index';
import { MENU } from '@/pages/router';
import StationRegisterForm from '@/components/organisms/Forms/StationRegisterForm';
import { PopupContainer, PopupCreate, SubmitCancel, TextFiledLabel } from '@/components/form';
import OpensTable from '@/components/organisms/Tables/OpensTable';
import StationStatusTable from '@/components/organisms/Tables/StationStatusTable';

/**
 * @name 충전소등록관리 상세
 * @description
 */
export default function StationDetailPresenter({
  onEdit,
  deletePopupVisible,
  handleDeletePopup,
  onConfirm,
}: {
  onEdit: () => void;
  deletePopupVisible: boolean;
  handleDeletePopup: (value: boolean) => void;
  onConfirm: () => void;
}) {
  const { t } = useTranslation('common');
  const {
    station: { view },
  } = useAppSelector((state: RootState) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const tabMenu = [{ label: t('forms.station.summaryInfo') }, { label: t('forms.station.chargersInfo') }];
  const [selected, setSelected] = useState(0);
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const searchKeyword = searchParams.get('search') || '';
  const locationKeyword = searchParams.get('location') || '';

  const queryParamsURL = () => {
    const queryParams = [];

    if (searchKeyword) {
      queryParams.push(`search=${encodeURIComponent(searchKeyword)}`);
    }
    if (locationKeyword) {
      queryParams.push(`location=${encodeURIComponent(locationKeyword)}`);
    }
    queryParams.push(`page=${page}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const handleBackToList = () => {
    navigate(`${MENU.INFRA.STATION_MANAGEMENT}?${queryParamsURL()}`);
  };

  return (
    <App>
      <HeaderWrapper>
        <BackButton label={`${view.name} / ${view.code}`} onClick={handleBackToList} />
        <ButtonsModifyDel onModify={onEdit} onDelete={() => handleDeletePopup(true)} />
      </HeaderWrapper>
      <Wrapper>
        <header className="auto">
          <Tab
            selected={selected}
            style={{ marginBottom: 32 }}
            info={tabMenu}
            onChange={({ index }) => {
              setSelected(index);
            }}
          />
        </header>
        <main>
          <Content $tabSelected={selected === 0}>
            <StationRegisterForm />
            <OperationTimeWrapper>
              <TextFiledLabel label={t('forms.station.openSetting')} />
              <OpensTable isModify={false} />
            </OperationTimeWrapper>
          </Content>
          <StationStatusTable tabSelected={selected === 1} />
        </main>
      </Wrapper>
      <PopupContainer onClose={() => handleDeletePopup(false)} visible={deletePopupVisible}>
        <PopupCreate
          warning
          title={t('common.toast.removeStation')}
          message={t('common.toast.askRemoveStation')}
          description={t('common.toast.removeStationDesc')}
          footer={
            <SubmitCancel
              confirmLabel={t('common.buttons.removeAction')}
              onCancel={() => handleDeletePopup(false)}
              onConfirm={onConfirm}
            />
          }
        />
      </PopupContainer>
    </App>
  );
}

const HeaderWrapper = styled.div`
  // styles
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  header {
    width: 674px;
    margin: 24px auto;
  }
  main {
    width: 900px;
    margin: 24px auto;
  }
`;
const Content = styled.div<{ $tabSelected: boolean }>`
  ${props =>
    props.$tabSelected
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
  padding-bottom: 30px;
`;
const OperationTimeWrapper = styled.div`
  width: 900px;
  margin: 0 auto 32px;
`;
