import React from 'react';
import { App } from 'components';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/model/index';
import { BadgesContainer, Container, Content, HeaderContainer, SubmitWrapper } from './AccountManagementModify.styles';
import { Button, Div, H3, ImageButton, Row, Text } from '@/lib/index';
import { icon_arrow_prev_black, icon_edit_white } from '@/assets/images';
import { COLOR } from '@/styles/index';
import Badge from '@/components/atoms/Badge';
import AccountAuthorityForm from '@/components/organisms/Forms/AccountAuthorityForm';
import AccountInfoForm from '@/components/organisms/Forms/AccountInfoForm';
import { AccountRoleCodeType, AdminAccountsStatusType } from '@/types/admin';
import { PopupContainer, PopupCreate, SubmitConfirm } from '@/components/form';

// @name 계정관리 상세
export default function AccountManagementModifyPresenter({
  handleBackToList,
  popupSuccessVisible,
  popupFailedVisible,
  handleDone,
  handleUpdate,
  handleCloseFailedPopup,
}: {
  handleBackToList: () => void;
  popupSuccessVisible: boolean;
  popupFailedVisible: boolean;
  handleDone: () => void;
  handleUpdate: () => void;
  handleCloseFailedPopup: () => void;
}) {
  const { accountDetail } = useAppSelector(state => state.admin);
  const { t } = useTranslation('common');

  const renderRoleBadge = (role: { code: AccountRoleCodeType; name: string }) => {
    if (role.code === AccountRoleCodeType.ADMIN) return <Badge mode="Primary" label={role.name} />;
    if (role.code === AccountRoleCodeType.MANAGER) return <Badge mode="Primary" label={role.name} />;
    if (role.code === AccountRoleCodeType.USER) return <Badge mode="Admin" label={role.name} />;
    if (role.code === AccountRoleCodeType.CPO) return <Badge mode="Dark1" label={role.name} />;
    return '';
  };
  const renderStatusBadge = (status: AdminAccountsStatusType) => {
    if (status === AdminAccountsStatusType.ACTIVE)
      return <Badge mode="AccountStatActive" label={`${t(`pages.admin.account.status.${status}`)}`} />;
    if (status === AdminAccountsStatusType.INVITED)
      return <Badge mode="AccountStatInvited" label={`${t(`pages.admin.account.status.${status}`)}`} />;
    if (status === AdminAccountsStatusType.SUSPEND)
      return <Badge mode="AccountStatSuspend" label={`${t(`pages.admin.account.status.${status}`)}`} />;
    if (status === AdminAccountsStatusType.WITHDRAW)
      return <Badge mode="AccountStatWithdraw" label={`${t(`pages.admin.account.status.${status}`)}`} />;
    return '';
  };
  return (
    <App>
      <HeaderContainer>
        <Div style={{ width: '100%' }}>
          <Row>
            <ImageButton
              onClick={handleBackToList}
              source={icon_arrow_prev_black}
              style={{ width: 24, height: 24, marginRight: 15 }}
            />
            <H3 color={COLOR.BLACK3}>
              {accountDetail.email} {t('pages.admin.account.detail.updateAccountAuthority')}
            </H3>
            <BadgesContainer>
              {renderRoleBadge(accountDetail.role)}
              {renderStatusBadge(accountDetail.status)}
            </BadgesContainer>
          </Row>
        </Div>
      </HeaderContainer>
      <Content>
        <Container>
          <AccountInfoForm />
          <AccountAuthorityForm />
          <SubmitWrapper>
            <Div>
              <Button
                full
                label={t('common.buttons.cancel')}
                color={COLOR.GRAY1}
                borderColor={COLOR.GRAY2}
                bg="transparent"
                onClick={handleDone}
              />
              <div style={{ width: 32 }} />
              <Button full label={t('common.buttons.updateAction')} onClick={handleUpdate} />
            </Div>
          </SubmitWrapper>
        </Container>
      </Content>
      <PopupContainer visible={popupSuccessVisible} onClose={handleDone}>
        <PopupCreate
          title={t('common.toast.successToModifyAccountAuthority')}
          message={t('common.toast.successToModifyAccountAuthorityMessage')}
          onConfirm={handleDone}
        />
      </PopupContainer>
      <PopupContainer visible={popupFailedVisible} onClose={handleCloseFailedPopup}>
        <PopupCreate
          warning
          title={t('common.toast.failedToModifyAccountAuthority')}
          message={t('common.toast.failedToModifyAccountAuthorityMessage')}
          onConfirm={handleCloseFailedPopup}
        />
      </PopupContainer>
    </App>
  );
}
