import { App, BackButton } from 'components';
import { H3, Row } from 'lib';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Header, ButtonWrapper, HeaderFuncWrapper } from './CalculateDetail.styles';
import { CalculateDetailListTable, CalculateDetailSummaryTable } from '@/components/organisms/Tables/CalculateDetail';
import { COLOR } from '@/styles/index';
import DownloadButton from '@/components/atoms/Buttons/DownloadButton';
import { MENU } from '@/pages/router';

export default function CalculateDetailPresenter({
  itemsPerPage,
  currentPage,
  pageHandler,
  queryString,
  handleExcelDownload,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  handleExcelDownload: () => void;
  queryString: string;
}) {
  const { t } = useTranslation('common');
  const location = useLocation();
  const navigate = useNavigate();
  const { year, month } = useParams();

  const navigateBack = () => {
    const canGoBack = location.key !== 'default';
    if (canGoBack) navigate(-1);
    else navigate(MENU.CALCULATE_STATUS);
  };
  return (
    <App>
      <Header style={{ width: '100%' }}>
        <HeaderFuncWrapper>
          <Row>
            <div style={{ marginRight: 20, display: 'flex' }}>
              <BackButton onClick={navigateBack} />
              <H3 color={COLOR.BLACK3}>
                {year}
                {t('common.unit.year')} {month}
                {t('common.unit.month')} {t('pages.sales.calculateStatusDetail.title')}
              </H3>
            </div>
          </Row>
          <ButtonWrapper>
            <DownloadButton onClick={handleExcelDownload} />
          </ButtonWrapper>
        </HeaderFuncWrapper>
      </Header>
      <CalculateDetailSummaryTable />
      <CalculateDetailListTable itemsPerPage={itemsPerPage} currentPage={currentPage} pageHandler={pageHandler} />
    </App>
  );
}
