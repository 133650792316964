/**
 * @name ButtonAddModels
 * @description
 */
import {icon_plus_white} from 'assets/images'
import {Button, Text} from 'lib'
import React from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'

type Props = {
  onClick?: () => void //--------------------------onClick
  style?: React.CSSProperties //------------------ StyleSheet
  title?: string //--------------------------------Title
  label?: string //------------------------------Message
  [key: string]: any
}
export default function ButtonAddModels({style, onClick, title, label}: Props) {
  return (
    <Element style={style}>
      <div className="center">
        <Text color={COLOR.GRAY1}>{title ?? `펌웨어의 모델을 선택해 주세요.`} </Text>
      </div>
      <div className="center" style={{marginTop: 16}}>
        <Button
          icon={icon_plus_white}
          label={label ?? '모델 선택하기'}
          style={{marginRight: 16}}
          onClick={() => {
            !!onClick && onClick()
          }}
        />
      </div>
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.div`
  padding: 24px 0 24px;
  border-left: 1px solid ${COLOR.BLUE8};
  border-right: 1px solid ${COLOR.BLUE8};
  border-bottom: 1px solid ${COLOR.BLUE8};
`
/********************************************************
[사용법]

  <ButtonAddModels
    title="펌웨어의 모델을 선택해 주세요."
    label="모델 선택하기"
    onClick={() => {
      // Events.emit(_event.POPUP_SHOW, _popup.SELECT_FIRMWARE_MODEL)
    }}
  />
*********************************************************/
