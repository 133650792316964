import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { Table, TableBody, TableHead, TD, TH, TR, Wrapper } from './ErrorCodeTable.style';
import Pagination from '@/components/organisms/Paginations/Pagination';
import { useAppSelector } from '@/model/index';
import { IErrorCode } from '@/lib/apis/infra/type';
import typography from '@/styles/typography';
import { formatDay } from '@/pages/common';

export default function ErrorCodeTable({
  itemsPerPage,
  currentPage,
  pageHandler,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
}) {
  const { t } = useTranslation('common');
  const { list, totalCount } = useAppSelector(state => state.errorcode);

  return (
    <Wrapper>
      <Table>
        <colgroup>
          <col style={{ width: 44 }} />
          <col style={{ minWidth: 220 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 220 }} />
          <col style={{ minWidth: 80 }} />
        </colgroup>
        <TableHead>
          <TR>
            <TH>No.</TH>
            <TH>{t('forms.model.manufacturerName')}</TH>
            <TH>{t('forms.model.chargerManufacturerId')}</TH>
            <TH>{t('forms.model.model')}</TH>
            <TH>{t('forms.history.failedName')}</TH>
            <TH>{t('forms.history.description')}</TH>
            <TH>{t('forms.model.updateDate')}</TH>
          </TR>
        </TableHead>
        <TableBody>
          {list?.length > 0 &&
            list.map((item: IErrorCode, index: number) => (
              <TR key={nanoid()}>
                <TD $customFont={typography.body4}>{totalCount - (currentPage - 1) * itemsPerPage - index}</TD>
                <TD $customFont={typography.body5}>{item.model.vendor.name}</TD>
                <TD $customFont={typography.body5}>{item.model.vendor.vendor_id}</TD>
                <TD $customFont={typography.body5}>{item.model.name}</TD>
                <TD $customFont={typography.body5}>{item.name}</TD>
                <TD $customFont={typography.body5}>{item.description}</TD>
                <TD $customFont={typography.body5}>{formatDay(item.created_at) ?? '-'}</TD>
              </TR>
            ))}
        </TableBody>
      </Table>
      {list.length > 0 && (
        <Pagination
          listLength={totalCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onClick={pageHandler}
        />
      )}
    </Wrapper>
  );
}
