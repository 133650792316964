/**
 * @name Guide
 * @description 가이드페이지
 */
import styled from 'styled-components'

export default function Wave() {
  // hooks
  //*--------------------------------------------------*
  return (
    <div id="container">
      <WaveContent>
        <div className="wave"></div>
      </WaveContent>
    </div>
  )
}
//*--------------------------------------------------*
const WaveContent = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 150px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('https://demo.plantpot.works/1128/bg.png');
  background-size: cover;

  .wave {
    position: absolute;
    width: 100%;
    padding-top: 12%;
    top: -5px;
    left: 0;
    background-image: url('https://demo.plantpot.works/1128/wave.png');
    background-repeat: repeat-x;
    background-size: 100%;
    animation: wave 8s linear infinite;
  }
  .wave::before {
    content: '';
    position: absolute;
    width: 100%;
    padding-top: 12%;
    top: -5px;
    left: 0;
    background-image: url('https://demo.plantpot.works/1128/wave.png');
    background-repeat: repeat-x;
    background-size: 100%;
    opacity: 0.2;
    animation: wave-reverse 8s linear infinite;
  }
  .wave::after {
    content: '';
    position: absolute;
    width: 100%;
    padding-top: 12%;
    top: -5px;
    left: 0;
    background-image: url('https://demo.plantpot.works/1128/wave.png');
    background-repeat: repeat-x;
    background-size: 100%;
    opacity: 0.8;
    animation-delay: -4s;
    animation: wave 16s linear infinite;
  }
  @keyframes wave {
    0% {
      background-position: 0;
    }
    100% {
      background-position: 100vw;
    }
  }
  @keyframes wave-reverse {
    0% {
      background-position: 100vw;
    }
    100% {
      background-position: 0;
    }
  }
`
