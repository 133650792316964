import React from 'react';
import styled from 'styled-components';

type Props = {
  [key: string]: any;
};
/**
 * @name Image
 * @description 레티나,디스플레이대응 2배수이미지
 */
export default function Image({ ...props }: Props) {
  return <Element style={{ width: '50%', height: 'auto', verticalAlign: 'top' }} {...props} />;
}
//* --------------------------------------------------*
const Element = styled.img``;

/** ******************************************************
[사용법]

<Image src={common_logo} />

******************************************************** */
