import React from 'react';
import { Row, Text, TextButton, useRoutes } from 'lib';
import styled from 'styled-components';
import { COLOR } from 'styles';
import { MENU } from '@/pages/router';
import { useTranslation } from 'react-i18next';
/**
 * @name Footer
 * @description
 */
export default function Contents() {
  const { t } = useTranslation('common');
  const { navigate } = useRoutes();
  return (
    <Content>
      <div className="space_between">
        <Text color={COLOR.GRAY2} className="body6">
          {t('layout.footer.rights')}
        </Text>
        <Row>
          <TextButton
            label={t('layout.footer.terms')}
            style={{ marginRight: 20, color: COLOR.GRAY2 }}
            className="body6"
            onClick={() => {
              navigate(MENU.POLICY_PRIVACY, { state: { path: MENU.POLICY_PRIVACY, active: 0 } });
            }}
          />
          <TextButton
            label={t('layout.footer.privacyPolicy')}
            style={{ color: COLOR.GRAY2 }}
            className="body6"
            onClick={() => {
              navigate(MENU.POLICY_PRIVACY, { state: { path: MENU.POLICY_PRIVACY, active: 1 } });
            }}
          />
        </Row>
      </div>
    </Content>
  );
}
const Content = styled.div`
  // styles
  display: flex;
  position: relative;
  width: 100%;
  height: 48px;
  /* margin-left: 24px; */
  /* margin-right: 24px; */
  /* margin-bottom: 30px; */
`;
