import React from 'react';
import { App } from 'components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/model/index';
import { COLOR } from '@/styles/index';
import { addComma, Button, H3, Row, Text } from '@/lib/index';
import { PopupContainer, PopupCreate, SubmitCancel, TextFiledSearch } from '@/components/form';
import DownloadButton from '@/components/atoms/Buttons/DownloadButton';
import PaymentStatusTable from '@/components/organisms/Tables/PaymentStatusTable/PaymentStatusTable';

export default function PaymentStatusPresenter({
  currentPage,
  itemsPerPage,
  searchKeyword,
  queryString,
  pageHandler,
  handleSearchKeyword,
  handleSearch,
  handleExcelDownload,
}: {
  currentPage: number;
  itemsPerPage: number;
  searchKeyword: string;
  queryString: string;
  pageHandler: (page: number) => void;
  handleSearchKeyword: (str: string) => void;
  handleSearch: () => void;
  handleExcelDownload: () => void;
}) {
  const { list, totalCount } = useAppSelector(state => state.sales);
  const { t } = useTranslation('common');
  return (
    <App>
      <Header>
        <Row>
          <div style={{ marginRight: 20 }}>
            <H3 color={COLOR.BLACK3}>{t('forms.sales.payment')}</H3>
            <Text color={COLOR.BLACK3} className="body6">
              {`${t('common.unit.total')} ${addComma(totalCount)}${t('common.unit.cases')}`}
            </Text>
          </div>
          <div className="search" style={{ marginLeft: 20 }}>
            <TextFiledSearch
              placeholder={t('pages.infra.chargerManage.stationSearch.searchStationPlaceholder')}
              defaultValue={searchKeyword}
              onChange={str => {
                handleSearchKeyword(str);
              }}
              onSubmit={() => {
                handleSearch();
              }}
            />
          </div>
        </Row>
        <ButtonWrapper>
          <DownloadButton onClick={handleExcelDownload} />
        </ButtonWrapper>
      </Header>
      <PaymentStatusTable
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        pageHandler={pageHandler}
        queryString={queryString}
      />
    </App>
  );
}

const Header = styled.div`
  // styles
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  align-items: center;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
