/**
 * @name PDF_Document
 * @description PDF 문서
 * @refer https://react-pdf.org/ , https://github.com/diegomura/react-pdf
 */
import {Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer'

export default function Contents() {
  // hooks
  //*--------------------------------------------------*
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>Section #1</Text>
          <Text>Section #22221</Text>
        </View>
        <View style={styles.section}>
          <Text>Section #2</Text>
        </View>
        {/* 이미지 */}
        <Image src="https://react-pdf.org/images/logo.png" style={{width: 400, height: 200}} />
        <View style={styles.section}>
          <Text>Section #2</Text>
        </View>
        {/* 이미지 */}
        <Image src="https://react-pdf.org/images/logo.png" style={{width: 400, height: 200}} />
        <View style={styles.section}>
          <Text>Section #2</Text>
        </View>
        {/* 이미지 */}
        <Image src="https://react-pdf.org/images/logo.png" style={{width: 400, height: 200}} />
        <View style={styles.section}>
          <Text>Section #2</Text>
        </View>
        {/* 이미지 */}
        <Image src="https://react-pdf.org/images/logo.png" style={{width: 400, height: 200}} />
        {/* ======= 푸터 */}
        <View style={styles.footer}>
          <Text style={styles.pager} render={({pageNumber, totalPages}) => `Page ${pageNumber} of ${totalPages}`} fixed />
        </View>
      </Page>
    </Document>
  )
}
//*--------------------------------------------------*
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  // <!--푸터-->
  footer: {
    display: 'flex',
    alignItems: 'center',
    margin: 10,
  },
  pager: {
    fontSize: 10,
    textAlign: 'center',
  },
})
