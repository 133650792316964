import React, { useState } from 'react';
import styled from 'styled-components';
import { icon_checkbox_off, icon_checkbox_on, icon_arrow_down_black, icon_arrow_up_black } from 'assets/images';
import { COLOR } from 'styles';
import { Image, makeArray, uuid } from 'lib';

type Props = {
  item: any;
  index: number;
  onChange?: (data: any) => void;
};

/**
 * @name 충전소선택아이템
 */
export default function Contents({ item, onChange, index }: Props) {
  const [visible, setVisible] = useState(false);
  const [subVisible, setSubVisible] = useState<any>(makeArray(item?.stations?.length, { checked: true }));

  return (
    <Content key={uuid()} className={item?.isOpen ? 'open' : ''}>
      <dt>
        <button
          onClick={() => {
            setVisible(!visible);
            !!onChange && onChange({ item, visible: !visible });
          }}>
          <Image src={visible ? icon_checkbox_on : icon_checkbox_off} style={{ width: 18, height: 18 }} />
          <label>{item?.name}</label>
        </button>
        <Image src={visible ? icon_arrow_up_black : icon_arrow_down_black} style={{ width: 18, height: 18 }} />
      </dt>
      {/* 추가컨텐츠 */}
      {visible && !!item?.stations && (
        <dd>
          {item?.stations.map((sub: any, idx: number) => {
            const _checked = subVisible[idx]?.checked;
            return (
              <dl className="sub" key={uuid()}>
                <dt>
                  <button
                    onClick={() => {
                      const _temp = subVisible.map((item: any, i: number) => {
                        return idx === i ? { ...item, checked: !item.checked } : item;
                      });
                      setSubVisible(_temp);
                    }}>
                    <Image src={_checked ? icon_checkbox_on : icon_checkbox_off} style={{ width: 18, height: 18 }} />
                    <label>{sub?.name}</label>
                  </button>
                  <Image
                    src={_checked ? icon_arrow_up_black : icon_arrow_down_black}
                    style={{ width: 18, height: 18 }}
                  />
                </dt>
              </dl>
            );
          })}
        </dd>
      )}
    </Content>
  );
}

const Content = styled.dl`
  dl.sub {
    padding-left: 30px;
  }
  dt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      display: flex;
      /* flex: 1; */
      width: 100%;
      height: 40px;
      margin-bottom: 2px;
      align-items: center;
      /* background-color: ${COLOR.GRAY2}; */
      label {
        margin-left: 8px;
        color: ${COLOR.BLACK1};
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.3px;
      }
    }
  }
`;
