import React from 'react';
import styled from 'styled-components';
import { COLOR } from 'styles';
import { Image, Text } from 'lib';
import { error_401_img, error_500_img } from 'assets/images';
import { FullPage } from 'components';
import { useTranslation } from 'react-i18next';

/**
 * @name Error
 * @description 에러페이지
 */
export default function Presenter() {
  const { t } = useTranslation('common');
  return (
    <FullPage>
      <Content>
        <header>
          <Image src={error_500_img} style={{ width: 144, height: 113, marginBottom: 88 }} />
          <Text center className="body1" color={COLOR.GRAY1}>
            {t('pages.error.500.sentence1')}
          </Text>
          <Text center className="body1" color={COLOR.GRAY1}>
            {t('pages.error.500.sentence2')}
          </Text>
          <Text color={COLOR.GRAY2} className="body3" style={{ marginTop: 8 }}>
            {t('pages.error.code', { code: 500 })}
          </Text>
          {/* <Button
            icon={icon_home_white}
            iconStyle={{width: 24, height: 24}}
            style={{height: 48, fontSize: 15}}
            label="홈으로 가기"
            onClick={() => {
              navigate(MENU.DASHBOARD)
            }}
          /> */}
        </header>
      </Content>
    </FullPage>
  );
}
const Content = styled.div`
  display: flex;
  self-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* header */
  header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
