import React from 'react';
import styled from 'styled-components';
import { COLOR } from 'styles';
import { VscChromeClose } from 'react-icons/vsc';
import { nanoid } from 'nanoid';
import typography from '@/styles/typography';

type Props = {
  keywords: string[];
  onRemoveKeyword: (keyword: string) => void;
};
export default function SearchKeywordButton({ keywords, onRemoveKeyword }: Props) {
  return (
    <Element>
      {keywords.map((item: string) => {
        return (
          <RemoveButton type="button" key={nanoid()} onClick={() => onRemoveKeyword(item)}>
            <VscChromeClose size={16} />
            <Text>{item}</Text>
          </RemoveButton>
        );
      })}
    </Element>
  );
}
const Element = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
`;

const RemoveButton = styled.button`
  display: flex;
  padding: 5px 10px;
  border-radius: 40px;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  color: ${COLOR.WHITE};
  background: ${COLOR.BLUE3};
`;

const Text = styled.span`
  ${typography.caption2};
  color: ${COLOR.WHITE};
`;
