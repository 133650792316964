import React from 'react';
import { Link } from 'react-router-dom';
import { Events, ImageButton, Table, uuid } from 'lib';
import { ListRenderItem } from 'types';
import { ChargeType, PossibleImpossible, ChargeStatus, ConnectorsStatus, ConnectorsTypeStatus } from 'components';
import { Pagination } from 'components/form';
import { MENU } from '@/pages/router';
import { button_remote } from 'assets/images';
import { useTranslation } from 'react-i18next';
import { Store, _event, _mode } from '../../index';

/**
 * @name List
 * @description 가이드페이지
 */
export default function Contents() {
  const { t } = useTranslation('common');
  const { list } = Store();
  return (
    <div className="table_list">
      <Table
        info={list?.data}
        renderHeader={() => {
          return (
            <>
              <thead>
                <tr key={uuid()}>
                  <th className="inner">No.</th>
                  <th className="inner l">{t('forms.model.chargerName')}</th>
                  <th className="inner l">{t('forms.charger.name')}</th>
                  <th className="inner c">{t('forms.model.type')}</th>
                  <th className="inner c">{t('forms.model.simultaneousCharging')}</th>
                  <th className="inner c">{t('forms.model.firmware')}</th>
                  <th className="inner c">{t('forms.charger.failStatus')}</th>
                  <th className="inner l">{t('forms.charger.chargerStatus')}</th>
                  <th className="inner">{t('forms.charger.connectorStatus')}</th>
                  <th className="inner l">{t('common.buttons.remoteControl')}</th>
                </tr>
              </thead>
              <colgroup>
                <col style={{ width: 50 }} />
                <col style={{ width: '*' }} />
                <col style={{ width: '*' }} />
                <col style={{ width: 80 }} />
                <col style={{ width: 80 }} />
                <col style={{ width: 200 }} />
                <col style={{ width: 80 }} />
                <col style={{ width: 100 }} />
                <col style={{ width: 180 }} />
                <col style={{ width: 100 }} />
                {/* <col span={2} /> 필요없는 col합치기 */}
              </colgroup>
            </>
          );
        }}
        renderItem={({ item, index }: ListRenderItem) => {
          // console.log(item?.connectors)
          return (
            <tr key={uuid()}>
              <td>
                <Link to={`${MENU.DEVICE_STATUS}/${item?.seq}`}>{item?.seq}</Link>
              </td>
              <td className="l">
                <Link to={`${MENU.DEVICE_STATUS}/${item?.seq}`}>{item?.name}</Link>
              </td>
              <td className="l">{item?.station?.name}</td>
              <td className="c">
                <ChargeType type={item?.model?.charge_type} />
              </td>
              <td className="l">
                <PossibleImpossible type={item?.is_concurrency} />
              </td>
              <td className="c">{item?.firmware_version ?? '-'}</td>
              <td>
                <ChargeStatus type={item?.status} />
              </td>
              <td>
                {`${item?.device_status?.status}`}
                {/* <ConnectorsStatus type={item?.device_status?.status} /> */}
              </td>
              <td>
                <ConnectorsTypeStatus info={item?.connectors} />
              </td>
              <td className="l">
                <ImageButton source={button_remote} buttonStyle={{ height: 28 }} style={{ width: 95, height: 28 }} />
              </td>
            </tr>
          );
        }}
      />
      <Pagination
        style={{ marginTop: 30 }}
        info={list?.meta?.paging}
        onChange={(num: any) => {
          Events.emit(_event.FETCH_LIST, { page_num: num });
        }}
      />
    </div>
  );
}
