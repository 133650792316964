import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { App, BackButton, ButtonsModifyDel } from 'components';
import styled, { css } from 'styled-components';
import { Tab } from 'lib';
import { RootState, useAppSelector } from '@/model/index';
import { COLOR } from '@/styles/index';
import VendorRegisterForm from '@/components/organisms/Forms/VendorRegisterForm';
import VendorModelInfo from '@/components/organisms/TabTable/VendorModelInfo';
import { PopupContainer, PopupCreate, SubmitCancel } from '@/components/form';

/**
 * @name 제조사관리 상세
 */
export default function VendorDetailPresenter({
  onEdit,
  deletePopupVisible,
  handleDeletePopup,
  handleBackToList,
  onDeleteConfirm,
}: {
  onEdit: () => void;
  deletePopupVisible: boolean;
  handleDeletePopup: (value: boolean) => void;
  handleBackToList: () => void;
  onDeleteConfirm: () => void;
}) {
  const { t } = useTranslation('common');
  const {
    vendor: { view },
  } = useAppSelector((state: RootState) => state);
  const [selected, setSelected] = useState(0);
  const tabMenu = [
    { label: t('pages.infra.vendorManage.detail.summaryInfo') },
    { label: t('pages.infra.vendorManage.detail.vendorModelInfo.title') },
  ];

  return (
    <App>
      <HeaderWrapper>
        <BackButton label={`${view.name} / ${view.vendor_id}`} onClick={handleBackToList} />
        <ButtonsModifyDel onModify={onEdit} onDelete={() => handleDeletePopup(true)} />
      </HeaderWrapper>
      <Wrapper>
        <header className="auto">
          <Tab
            selected={selected}
            style={{ marginBottom: 32 }}
            info={tabMenu}
            onChange={({ index }) => {
              setSelected(index);
            }}
          />
        </header>
        <main>
          <Content $tabSelected={selected === 0}>
            <VendorRegisterForm />
          </Content>
          <VendorModelInfo tabSelected={selected === 1} />
        </main>
      </Wrapper>
      <PopupContainer onClose={() => handleDeletePopup(false)} visible={deletePopupVisible}>
        <PopupCreate
          warning
          title={t('common.toast.removeVendor')}
          message={t('common.toast.askRemoveVendor')}
          footer={
            <SubmitCancel
              confirmLabel={t('common.buttons.removeAction')}
              onCancel={() => handleDeletePopup(false)}
              onConfirm={onDeleteConfirm}
            />
          }
        />
      </PopupContainer>
    </App>
  );
}

const HeaderWrapper = styled.div`
  // styles
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  align-items: center;
  justify-content: space-between;
`;
const Wrapper = styled.div`
  header {
    width: 674px;
    margin: 24px auto;
  }
  main {
    width: 900px;
    margin: 24px auto;
  }
`;
const Content = styled.div<{ $tabSelected: boolean }>`
  ${props =>
    props.$tabSelected
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
  padding-bottom: 30px;
`;
