import styled, { css } from 'styled-components';
import { COLOR } from 'styles';
import typography from '@/styles/typography';
import { icons } from '@/assets/images';

const chevronUpUrl = icons.chevronUpBlack.image;
const chevronDownUrl = icons.chevronDownBlack.image;
const chevronDownGrayUrl = icons.chevronDownGray.image;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DropdownContainer = styled.div`
  position: relative;
`;
export const ArrowBox = styled.div`
  background-color: ${COLOR.WHITE};
  background-image: url('${chevronDownUrl}');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  user-select: none;
  width: 16px;
  height: 16px;
  background-size: 16px;
`;

export const Select = styled.div<{
  $isBorderExist: boolean;
  $isExpanded: boolean;
  $isSelected?: boolean;
  $isDisabled?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  gap: 16px;
  ${({ $isBorderExist, $isExpanded, $isSelected }) =>
    $isBorderExist
      ? css`
          justify-content: space-between;
          ${typography.body4};
          padding: 8px 16px;
          border-radius: 8px;
          border: 1px solid ${COLOR.GRAY4};
          color: ${COLOR.GRAY2};
          ${$isSelected &&
          css`
            color: ${COLOR.BLACK1};
          `}
          &:hover {
            color: ${COLOR.BLACK1};
            border: 1px solid ${COLOR.GRAY3};
          }

          &:focus {
            color: ${COLOR.BLACK1};
            border: 1px solid ${COLOR.BLUE7};
            ${$isExpanded &&
            css`
              color: ${COLOR.GRAY2};
              border: 1px solid ${COLOR.BLUE4};
              ${ArrowBox} {
                background-image: url('${chevronUpUrl}');
              }
            `}
          }

          &:focus ${ArrowBox} {
            //background-color: ${COLOR.GRAY3};
          }
        `
      : css`
          justify-content: center;
          gap: 16px;
          ${typography.body6};
          padding: 16px 8px;
          color: ${COLOR.BLACK};
          &:hover {
            background-color: ${COLOR.GRAY3};
          }
          &:hover ${ArrowBox} {
            background-color: ${COLOR.GRAY3};
          }

          &:focus {
            background-color: ${COLOR.GRAY3};
            color: ${COLOR.GRAY3};
          }
          &:focus ${ArrowBox} {
            background-color: ${COLOR.GRAY3};
            color: ${COLOR.GRAY3};
          }
        `};
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      justify-content: space-between;
      ${typography.body4};
      padding: 8px 16px;
      border-radius: 8px;
      border: 1px solid ${COLOR.GRAY4};
      background-color: ${COLOR.GRAY6};
      color: ${COLOR.GRAY4};
      cursor: not-allowed;
      ${ArrowBox} {
        background-color: ${COLOR.GRAY6};
        background-image: url('${chevronDownGrayUrl}');
      }
      &:hover {
        color: ${COLOR.GRAY4};
        border: 1px solid ${COLOR.GRAY4};
        background-color: ${COLOR.GRAY6};
      }
      &:focus {
        color: ${COLOR.GRAY4};
        border: 1px solid ${COLOR.GRAY4};
        background-color: ${COLOR.GRAY6};
      }
      &:hover ${ArrowBox} {
        cursor: not-allowed;
      }
      &:hover ${ArrowBox} {
        background-color: ${COLOR.GRAY6};
      }
    `};
`;

export const OptionContainer = styled.div<{ $expanded: boolean; $customWidth?: number }>`
  max-height: 250px;
  overflow: auto;

  z-index: 1;
  display: ${({ $expanded }) => ($expanded ? 'flex' : 'none')};
  position: absolute;
  border-radius: 8px;
  background-color: ${COLOR.WHITE};
  width: ${({ $customWidth }) => `${$customWidth}px` || '100%'};
  padding: 8px;
  flex-direction: column;
  gap: 2px;
  left: 0;
  box-sizing: border-box;
  box-shadow: 0px 6px 10px 0px rgba(6, 25, 56, 0.07);
`;

export const Option = styled.div<{ $selected: boolean }>`
  ${typography.body4};
  color: ${COLOR.BLACK1};
  background-color: ${COLOR.WHITE};
  ${({ $selected }) =>
    $selected &&
    css`
      background-color: ${COLOR.BLUE7};
      color: ${COLOR.BLUE4};
    `}
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background-color: ${COLOR.GRAY6};
    color: ${COLOR.BLACK1};
  }
  &:focus {
    background-color: ${COLOR.BLUE_GRAY9};
    color: ${COLOR.BLACK1};
  }
`;
