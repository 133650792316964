import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Image, useClickOutside } from 'lib';
import { COLOR } from 'styles';
import { icon_arrow_down_black, icon_arrow_down_gray, icon_arrow_up_black } from 'assets/images';
import { getStationTypes } from '@/lib/apis/infra';
import { IResponse } from '@/lib/apis/api/type';
import { StationType } from '@/types/infra';

type Props = {
  onChange?: (value: StationType) => void;
  station_type_group?: string;
  station_type_detail?: string;
  style?: React.CSSProperties;
  popupStyle?: React.CSSProperties;
  editMode?: boolean;
};

interface GroupedItem {
  seq: string;
  type_detail: string;
}
interface GroupedData {
  index: number;
  name: string;
  items: GroupedItem[];
}

/**
 * @name 충전소설치유형목록
 * @description
 */
export default function DropDownStationsTypes({
  onChange,
  station_type_group,
  station_type_detail,
  style,
  popupStyle,
  editMode,
}: Props) {
  const eleRef = useRef(null);
  const [originTypeGroup, setOriginTypeGroup] = useState<StationType[]>([]);
  const [info, setInfo] = useState<GroupedData[]>([]);
  const [typeGroupVisible, setTypeGroupVisible] = useState(false);
  const [detailTypeGroupVisible, setDetailTypeGroupVisible] = useState(false);
  const [selectedMainType, setSelectedMainType] = useState<string | null>(station_type_group || null);
  const [selectedDetailType, setSelectedDetailType] = useState<{ seq: string; type_detail: string } | null>(
    station_type_detail
      ? {
          seq: '',
          type_detail: station_type_detail,
        }
      : null,
  );

  // outside click
  useClickOutside(eleRef, () => {
    setTypeGroupVisible(false);
    setDetailTypeGroupVisible(false);
  });

  const groupDataByTypeGroup = (data: StationType[]): GroupedData[] => {
    const groupMap = new Map<string, GroupedItem[]>();

    data.forEach(({ type_group, seq, type_detail }) => {
      if (!groupMap.has(type_group)) {
        groupMap.set(type_group, []);
      }
      groupMap.get(type_group)!.push({ seq: seq ?? 'none', type_detail });
    });

    return Array.from(groupMap).map(([name, items], index) => ({
      index, // 인덱스를 문자열로 변환
      name,
      items,
    }));
  };

  const getIcon = (isDisabled: boolean) => {
    if (isDisabled) {
      return icon_arrow_down_gray;
    }
    if (detailTypeGroupVisible) {
      return icon_arrow_up_black;
    }
    return icon_arrow_down_black;
  };

  const findGroupByName = (name: string | null): GroupedData | undefined => {
    return info.find(group => group.name === name);
  };

  // 공통fetch
  async function fetch() {
    const stationTypesResponse: IResponse<StationType[]> = await getStationTypes();
    if (stationTypesResponse.isSuccess) {
      const { data } = stationTypesResponse;
      setOriginTypeGroup(data);
      const groupedData = groupDataByTypeGroup(data);
      setInfo(groupedData);
    }
  }

  const changeSelectedType = (seq: string) => {
    const foundObj = originTypeGroup.find(obj => obj.seq === seq);
    if (onChange && foundObj) {
      onChange(foundObj);
    }
  };

  const foundGroup = findGroupByName(selectedMainType);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    setSelectedMainType(station_type_group || null);
    if (station_type_detail) {
      setSelectedDetailType({ seq: '', type_detail: station_type_detail });
    } else {
      setSelectedDetailType(null);
    }
  }, [station_type_group, station_type_detail]);

  return (
    <Content ref={eleRef} style={style}>
      <SelectorContainer>
        <SelectorButton
          disabled={!editMode}
          onClick={() => {
            setTypeGroupVisible(!typeGroupVisible);
            setDetailTypeGroupVisible(false);
          }}>
          {selectedMainType === null ? `분류` : selectedMainType}
          <Image
            src={typeGroupVisible ? icon_arrow_up_black : icon_arrow_down_black}
            style={{ width: 20, height: 20 }}
          />
        </SelectorButton>
        {/* 레이어팝업 */}
        {typeGroupVisible && (
          <section className="popup" style={popupStyle}>
            {info?.map((item: GroupedData) => {
              return (
                <button
                  type="button"
                  className="item"
                  key={item.index}
                  onClick={() => {
                    if (item.name !== selectedMainType) {
                      setSelectedDetailType(null);
                    }
                    setSelectedMainType(item.name);
                    setTypeGroupVisible(false);
                  }}>
                  {item.name}
                </button>
              );
            })}
          </section>
        )}
      </SelectorContainer>

      <SelectorContainer>
        <SelectorButton
          onClick={() => {
            setTypeGroupVisible(false);
            setDetailTypeGroupVisible(!detailTypeGroupVisible);
          }}
          disabled={!editMode || selectedMainType === null}>
          {selectedDetailType === null ? `상세 분류` : selectedDetailType.type_detail}
          <Image src={getIcon(selectedMainType === null)} style={{ width: 20, height: 20 }} />
        </SelectorButton>
        {/* 레이어팝업 */}
        {detailTypeGroupVisible && (
          <section className="popup" style={popupStyle}>
            {foundGroup &&
              foundGroup.items.map((item: GroupedItem) => {
                return (
                  <button
                    type="button"
                    className="item"
                    key={item.seq}
                    onClick={() => {
                      setSelectedDetailType({ seq: item.seq, type_detail: item.type_detail });
                      setDetailTypeGroupVisible(false);
                      changeSelectedType(item.seq);
                    }}>
                    {item.type_detail}
                  </button>
                );
              })}
          </section>
        )}
      </SelectorContainer>
    </Content>
  );
}
const Content = styled.div`
  display: flex;
  gap: 20px;
  /* 레이어팝업 */
  section.popup {
    position: absolute;
    top: 40px;
    z-index: 10;
    background-color: ${COLOR.WHITE};
    width: 100%;
    height: 200px;
    box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.07);
    border-radius: 6px;
    overflow-y: scroll;

    button.item {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
      height: 40px;
      padding: 0 16px;
      color: ${COLOR.BLACK1};
      &:hover {
        background-color: ${COLOR.GRAY6};
      }
    }
  }
`;

const SelectorContainer = styled.div`
  position: relative;
  flex: 1;
`;

const SelectorButton = styled.button`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  border: 1px solid ${COLOR.GRAY4};
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${COLOR.GRAY6};
    `}
`;
