import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRoutes } from 'lib';
import ForgotPassword from './presenter';
import { postResetEmail } from '@/lib/apis/auth';
import { MENU } from '@/pages/router';

export default function ForgotPasswordContainer() {
  const { t } = useTranslation('common');
  const { navigate } = useRoutes();
  const [popupVisible, setPopupVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');

  const onChangeHandler = (value: string) => {
    // dispatch(actions.setLogin({ key: value }));
    setEmail(value);
  };

  const fetchResetEmail = async () => {
    try {
      const emailRes = await postResetEmail(email);
      if (emailRes.isSuccess) {
        setIsSuccess(true);
      }
    } catch (e: unknown) {
      // if (e.error.errors[0].code === 403) {
      //   setMessage('이미 발송된 상태입니다.');
      // }
      setMessage(t('common.toast.failedEmail'));
      setIsSuccess(false);
    }
  };

  const sendEmail = () => {
    fetchResetEmail();
    setPopupVisible(true);
  };

  useEffect(() => {
    if (isSuccess && !popupVisible) {
      navigate(MENU.AUTH.SIGN_IN);
    }
  }, [isSuccess, popupVisible]);

  return (
    <ForgotPassword
      popupVisible={popupVisible}
      setPopupVisible={setPopupVisible}
      sendEmail={sendEmail}
      email={email}
      isSuccess={isSuccess}
      message={message}
      onChangeHandler={onChangeHandler}
    />
  );
}
