/**
 * @name Test
 * @description 가이드페이지
 */

import React, {useEffect} from 'react'
import styled from 'styled-components'
import {App, GoogleMap, Page} from 'components'
import {Button, debug, useAxios, useContext, useFetch} from 'lib'
import {useLocation, useParams} from 'react-router-dom'
import {API_HOST} from 'config'

export default function Index() {
  // hooks
  const {post} = useAxios()
  const {navi, params, user} = useContext()
  const {state} = useLocation();
  useEffect(() => {
    return () => {}
  }, [])
  //*--------------------------------------------------*
  return (
    <App>
      <Content>
        <Button
          label="API"
          onClick={async () => {
            const res = await post(`${API_HOST}/auth/login`, {
              email: 'jgkim@crocus.ai',
              password: '1234',
            })
            // console.log(res?.data)
          }}
        />
        {/* 헤더 */}
        {debug(navi)}
        {debug(state)}
        {debug(user)}
        {/* {isLoaded ? <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={18} onLoad={onLoad} onUnmount={onUnmount}></GoogleMap> : <div>1111...</div>} */}
        {/* <Button onClick={() => {}} /> */}
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div``
/********************************************************
[사용법]


*********************************************************/
