/**
 * @name 취소,확인버튼
 * @description
 */

import {CSS} from 'types'
import {Button} from 'lib'
import {COLOR} from 'styles'

type Props = {
  label?: string
  warning?: boolean
  onConfirm?: () => void
  style?: CSS
}
export default function SubmitButton({label, warning, onConfirm, style}: Props) {
  const _isWarning = warning ? {backgroundColor: COLOR.RED2, borderColor: COLOR.RED2} : {}
  const _style: CSS = Object.assign({}, {height: 48, borderRadius: 8, fontSize: 15}, _isWarning, style)
  //*--------------------------------------------------*
  return (
    <Button
      full
      label={label ?? '확인'}
      style={_style}
      onClick={() => {
        !!onConfirm && onConfirm()
      }}
    />
  )
}
/********************************************************
[사용법]
  <SubmitButton
    warning
    onConfirm={() => {
      onRemovePopup()
    }}
  />
*********************************************************/
