/**
 * @name Guide
 * @description
 */
import {useEffect} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {App, Page} from 'components'
// contents
import {Header, Popup} from './common'
import {LayerPopup, Timer, Pdf, Ui, FramerMotion, Axios, Calendar, Text, Link, Date, Table} from './contents'

export default function Presenter() {
  useEffect(() => {}, [])
  //*--------------------------------------------------*
  return (
    <App>
      <Content>
        {/* 헤더 */}
        <Header />
        <main>
          {/* 테이블 */}
          <Table />
          {/* UI */}
          <Ui />
          {/* Date */}
          <Date />
          {/* 링크 */}
          <Link />
          {/* 레이어팝업 */}
          <LayerPopup />
          {/* 텍스트 */}
          <Text />
          {/* PDF */}
          <Pdf />
          {/* Calendar */}
          <Calendar />
          {/* Axios */}
          <Axios />
          {/* 타이머 */}
          <Timer />
          {/* FramerMotion */}
          <FramerMotion />
        </main>
      </Content>
      {/* 레이어팝업 */}
      <Popup />
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  padding: 20px;
  main {
    margin: 20px auto;
    padding: 20px;
    background-color: ${COLOR.GRAY6};
    > * {
      padding: 20px 0px;
      border-bottom: 1px dashed ${COLOR.GRAY2};
    }
  }
`
