import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICountry, ILocation } from '@/types/location';

type LocationStateType = {
  locations: ILocation[];
  countries: ICountry[];
};
const initialState: LocationStateType = {
  locations: [],
  countries: [],
};

/** @createSlice */
const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    // 모드변경
    setLocations(state: LocationStateType, action: PayloadAction<ILocation[]>) {
      state.locations = action.payload;
    },
    setCountries(state: LocationStateType, action: PayloadAction<ICountry[]>) {
      state.countries = action.payload;
    },
  },
});
export const { setLocations, setCountries } = locationSlice.actions;
export default locationSlice.reducer;
