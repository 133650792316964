/**
 * @name 좌우버튼
 * @description
 */

import styled from 'styled-components';
import { CSS } from 'types';
import { COLOR } from 'styles';
import React, { useEffect, useState } from 'react';

type Props = {
  leftLabel?: string;
  onLeft?: () => void;
  rightLabel?: string;
  onRight?: () => void;
  onChange?: (num: number) => void;
  value?: number;
  style?: CSS;
  disabled?: boolean;
};
export default function SelectButton({
  leftLabel,
  onLeft,
  rightLabel,
  onRight,
  onChange,
  value,
  style,
  disabled,
}: Props) {
  // hooks
  const [selected, setSelected] = useState(value ?? 0);
  useEffect(() => {
    if (!value) return;
    setSelected(value);
  }, [value]);
  return (
    <Content style={style} $disabled={disabled}>
      {/* {selected + ''} */}
      <button
        type="button"
        className={selected === 1 ? 'active' : ''}
        style={{ marginRight: 4 }}
        onClick={() => {
          if (!disabled) {
            if (onLeft) {
              onLeft();
            }
            if (onChange) {
              onChange(1);
            }
            setSelected(1);
          }
        }}>
        <span className="flex button4">{leftLabel ?? '가능'}</span>
      </button>
      <button
        type="button"
        className={selected === 2 ? 'active' : ''}
        style={{ marginLeft: 4 }}
        onClick={() => {
          if (!disabled) {
            if (onRight) {
              onRight();
            }
            if (onChange) {
              onChange(2);
            }
            setSelected(2);
          }
        }}>
        <span className="flex button4">{rightLabel ?? '불가능'}</span>
      </button>
    </Content>
  );
}
//* --------------------------------------------------*
const Content = styled.div<{ $disabled?: boolean }>`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  /* 버튼 */
  > button {
    cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
    width: 100%;
    border: 1px solid ${COLOR.GRAY2};
    border-radius: 8px;
    height: 40px;
    color: ${COLOR.GRAY1};
    &.active {
      color: ${({ $disabled }) => ($disabled ? COLOR.BLACK3 : COLOR.WHITE)};
      background-color: ${({ $disabled }) => ($disabled ? COLOR.GRAY5 : COLOR.BLACK3)};
      border: ${({ $disabled }) => ($disabled ? `1px solid ${COLOR.GRAY5}` : `1px solid ${COLOR.GRAY2}`)};
    }
    span {
      cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
    }
  }
`;
/** ******************************************************
[사용법]
  <SelectButton
    leftLabel="급속"
    rightLabel="완속"
    onChange={val => {
      console.log(val)
    }}
  />
******************************************************** */
