import React, { useState } from 'react';
import styled from 'styled-components';
import { COLOR } from 'styles';
import { ImageButton, useRoutes } from 'lib';
import { MENU } from '@/pages/router';
import { alertIcon, icon_location_avatar, languageIconKO } from 'assets/images';
import Location from './location';
import typography from '@/styles/typography';

/**
 * @name Top
 */
type Props = {
  mode?: 'auth' | 'app' | 'page';
  children?: React.ReactNode | JSX.Element[] | undefined;
  [key: string]: any;
};
export default function Top({ mode = 'app' }: Props) {
  // hooks
  const { navigate } = useRoutes();

  const handleAlertButton = () => {
    // TODO Alert 버튼 핸들링
  };

  const handleLanguageChange = () => {
    // TODO Language button 핸들링
  };
  const [isUserInfoVisible, setUserInfoVisible] = useState<boolean>(false);

  return (
    <>
      <Content>
        {/* 사이트맵 */}
        <Location />
        {/* <Button
            icon={icon_money_white}
            label="목표금액 설정"
            onClick={() => {
              navigate(MENU.GUIDE)
            }}
          /> */}
        <RightSideWrapper>
          <LanguageButtonContainer onClick={handleLanguageChange}>
            <ImageButton source={languageIconKO} style={{ width: 26, height: 26 }} />
          </LanguageButtonContainer>
          {/* TODO: 추후 알림 생겼을 떄 추가 */}
          {/* <AlertButtonContainer onClick={handleAlertButton}>
            <ImageButton source={alertIcon} style={{ width: 26, height: 26 }} />
            <AlertCountBox>
              <AlertCount>13</AlertCount>
            </AlertCountBox>
          </AlertButtonContainer> */}
          <ImageButton
            source={icon_location_avatar}
            style={{ width: 38, height: 38 }}
            onClick={() => {
              setUserInfoVisible(prev => (prev === false ? true : false));
            }}
          />
        </RightSideWrapper>
      </Content>
      {isUserInfoVisible && (
        <DropdownMenu>
          <MenuItem onClick={arg => console.log(arg)}>정보 수정(준비중)</MenuItem>
          <MenuItem onClick={arg => navigate(MENU.AUTH.SIGN_OUT)}>로그아웃</MenuItem>
        </DropdownMenu>
      )}
    </>
  );
}
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  height: 72px;
  background-color: ${COLOR.WHITE};
  box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.07);
`;

const LanguageButtonContainer = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 20px;
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const AlertButtonContainer = styled.div`
  padding: 6px 9px 6px 6px;
  min-width: 53px;
  position: relative;
`;

const AlertCountBox = styled.div`
  border-radius: 20px;
  background-color: ${COLOR.RED2};
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0;
  right: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const AlertCount = styled.span`
  ${typography.caption1};
  color: ${COLOR.WHITE};
`;

const RightSideWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 72px; // adjust this based on your header height
  right: 24px; // align with the right padding of the Content
  background-color: ${COLOR.WHITE};
  box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.1);
  border-radius: 8px;
  overflow: hidden;
  z-index: 1000;
`;

const MenuItem = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  &:hover {
    background-color: ${COLOR.BLUE9};
  }
`;
