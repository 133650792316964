import React, { CSSProperties } from 'react';
import styled from 'styled-components';

type Props = {
  source: any;
  className?: string; // ----------------------- 클래스네임
  buttonStyle?: CSSProperties; // -------------- 스타일시트
  style?: CSSProperties; // -------------------- 스타일시트
  onClick?: (event: React.MouseEvent<HTMLElement>) => void; // ------------------------ onClick 이벤트
};

/**
 * @name ImageButton
 * @description 버튼
 */
export default function ImageButton({ source, className, buttonStyle = {}, style = {}, onClick }: Props) {
  const combinedStyle = { ...(!!style && style) };

  return (
    <Element
      style={buttonStyle}
      className={className}
      onClick={event => {
        if (onClick) {
          onClick(event);
        }
      }}>
      <img src={source} style={combinedStyle} alt="" />
    </Element>
  );
}
const Element = styled.button`
  font-size: 0;
`;

/** ******************************************************
[사용법]
<ImageButton
  buttonStyle={{display: 'block', border: '1px solid red'}}
  style={{backgroundColor: 'red'}}
  source={icon_alert}
  onClick={() => {
    alert('test')
  }}
/>
******************************************************** */
