export const API_HOST = process.env.REACT_APP_API_URL;

/** ---------------------------------- @name API_KEY */
export const GOOGLE_MAP_KEY = 'AIzaSyArzPXNGE4Xwx7mpmSkjIQNIbhHZv0yK_A';

/** ---------------------------------- @name KAKAO_API_KEY */
export const KAKAO_REST_API = '7a85d96399f6b2a83f1db466b5a3f76d';

/** ******************************************************
[사용법]
구글 크로커스계정

//----카카오
네이티브 앱 키	e88439cecc9147a5b5f18ea95a4cec3c
REST API 키	7a85d96399f6b2a83f1db466b5a3f76d
JavaScript 키	5db1144bcadeb9c51c24ad6316126d75
Admin 키	93b82a08a924699758be8228548b62e4

******************************************************** */
