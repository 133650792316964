import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Image, useClickOutside } from 'lib';
import { COLOR } from 'styles';
import { icon_arrow_down_black, icon_arrow_up_black } from 'assets/images';
import { useAppSelector } from 'model';
import typography from '@/styles/typography';
import { ILocation } from '@/types/location';

type Props = {
  style?: React.CSSProperties;
  popupStyle?: React.CSSProperties;
  onChange?: (data: { name: string; value: number }) => void;
  value: string;
};
/**
 * @name 지역 Dropdown
 */
export default function DropDownLocations({ onChange, style, popupStyle, value }: Props) {
  const eleRef = useRef(null);
  const { locations } = useAppSelector(state => state.location);
  const [visible, setVisible] = useState(false);

  useClickOutside(eleRef, () => {
    setVisible(false);
  });

  return (
    <Content ref={eleRef} style={style}>
      <SelectorButton type="button" onClick={() => setVisible(!visible)} $isVisible={visible}>
        {value}
        <Image src={visible ? icon_arrow_up_black : icon_arrow_down_black} style={{ width: 20, height: 20 }} />
      </SelectorButton>
      {visible && (
        <Section style={popupStyle}>
          <ItemButton
            type="button"
            $isSelected={value === '전체지역'}
            key={0}
            onClick={() => {
              if (onChange) {
                onChange({ name: '전체지역', value: 0 });
              }
              setVisible(false);
            }}>
            전체지역
          </ItemButton>
          {locations?.map((item: ILocation) => {
            return (
              <ItemButton
                type="button"
                key={item.seq}
                $isSelected={value === item.name}
                onClick={() => {
                  if (onChange) {
                    onChange({ name: item.name, value: item.seq });
                  }
                  setVisible(false);
                }}>
                {item?.name}
              </ItemButton>
            );
          })}
        </Section>
      )}
    </Content>
  );
}

const SelectorButton = styled.button<{ $isVisible: boolean }>`
  ${typography.body4};
  display: flex;
  width: 100%;
  min-width: 120px;
  height: 40px;
  padding: 0 16px;
  border: 1px solid ${COLOR.GRAY4};
  color: ${COLOR.GRAY2};
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  ${props =>
    props.$isVisible
      ? css`
          border: 1px solid ${COLOR.BLUE4};
        `
      : css`
          &:hover {
            color: ${COLOR.BLACK1};
            border: 1px solid ${COLOR.GRAY3};
          }
          &:focus {
            color: ${COLOR.BLACK1};
            border: 1px solid ${COLOR.BLUE7};
          }
        `}}
`;
const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: absolute;
  z-index: 10;
  background-color: ${COLOR.WHITE};
  width: 100%;
  height: 200px;
  box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.07);
  border-radius: 8px;
  padding: 8px;
  overflow-y: scroll;
`;

const ItemButton = styled.button<{ $isSelected: boolean }>`
  ${typography.body4};
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  border-radius: 6px;
  padding: 8px 16px;
  color: ${COLOR.BLACK1};
  ${props =>
    props.$isSelected
      ? css`
          color: ${COLOR.BLUE4};
          background-color: ${COLOR.BLUE8};
        `
      : css`
          &:hover {
            background-color: ${COLOR.GRAY6};
          }
          &:focus {
            background-color: ${COLOR.BLUE_GRAY9};
          }
        `}
`;

const Content = styled.div`
  position: relative;
`;
