/**
 * @name Content
 * @description
 */
import styled from "styled-components";
import { Dayjs, debug, setTimezone } from "lib";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
export default function Contents() {
  // hooks
  const time = dayjs();

  // console.log(dayjs().format()) // 2022-06-11T18:24:42+09:00

  let day = Dayjs();
  const newDay = day.add(7, "day");
  //*--------------------------------------------------*
  return (
    <Content>
      <p>{setTimezone(time)}</p>
      <p>{day.format("YYYY/MM/DD HH:mm")}</p>
      <p>{Dayjs().format()}</p>
      <div>{day.format("YYYY-MM-DD")}</div>
      <div>{day.format("HH-MM.ss")}</div>
      <div>{newDay.format("YYYY/MM/DD HH-mm.ss")}</div>
      {/* <p>{dayjs().set('date', 1) as any}</p> */}
      <div className="wrap">wrap</div>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  /**-------본문--*/
  .wrap {
  }
  /* <!--media query --> */
  @media all and (min-width: 0) and (max-width: 480px) {
    /* background-color: red; */
  }
  @media all and (min-width: 480px) and (max-width: 1000px) {
    /* background-color: green; */
  }
  @media all and (min-width: 1000px) {
    /* background-color: blue; */
  }
`;
