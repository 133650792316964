/**
 * @name useCalendar
 * @description 달력함수
 */

import {useState} from 'react'

export const useCalendar = () => {
  // hooks
  const [today, setToday] = useState({
    year: new Date().getFullYear(), //---------------오늘 연도
    month: new Date().getMonth() + 1, //-------------오늘 월
    date: new Date().getDate(), //-------------------오늘 날짜
    day: new Date().getDay(), //---------------------오늘 요일
  })
  const [selectYear, setSelectYear] = useState(today.year)
  const [selectMonth, setSelectMonth] = useState(today.month)
  // *-------------------------------------------------------------------*
  /**
   * @name 캘린더생성
   * @returns
   */
  const generateCalendar = () => {
    const startDay = new Date(selectYear, selectMonth - 1, 1).getDay() // 월의 시작 요일 ( 0 ~ 6, 일요일 = 0)
    const endDate = new Date(selectYear, selectMonth, 0).getDate() // 월의 마지막 날짜
    const weekNumber = Math.ceil((startDay + endDate) / 7)
    const calendar: number[][] = []
    let nowDate = 0
    let nowDay = 0
    for (let i = 0; i < weekNumber; i++) {
      const nowWeek = [] as any
      for (let j = 0; j < 7; j++) {
        if (startDay <= nowDay && nowDate < endDate) {
          nowDate++
          nowWeek.push(nowDate)
        } else {
          nowWeek.push('')
        }
        nowDay++
      }
      calendar.push(nowWeek)
    }
    return calendar
  }
  /**
   * @name 외부에서캘린더배열로Return
   * @returns Array
   */
  const calendar = generateCalendar()
  /**
   * @name 이전_월변경
   */
  const prevMonth = () => {
    if (selectMonth === 1) {
      setSelectMonth(12)
      setSelectYear(selectYear - 1)
    } else {
      setSelectMonth(val => val - 1)
    }
  }
  /**
   * @name 다음_월변경
   */
  const nextMonth = () => {
    if (selectMonth === 12) {
      setSelectMonth(1)
      setSelectYear(selectYear + 1)
    } else {
      setSelectMonth(val => val + 1)
    }
  }
  /**
   * @name 특정_월변경
   */
  const changeMonth = (num: number) => {
    if (num < 0) return
    if (num > 13) return
    setSelectMonth(num)
  }
  /**
   * @name 특정_년도변경
   */
  const changeYear = (num: number) => {
    setSelectYear(num)
  }
  /**
   * @name 다음_년도변경
   */
  const nextYear = () => {
    setSelectYear(val => val + 1)
  }
  /**
   * @name 이전_년도변경
   */
  const prevYear = () => {
    setSelectYear(val => val - 1)
  }
  // *-------------------------------------------------------------------*
  return {today, setToday, calendar, setSelectYear, changeMonth, prevMonth, nextMonth, changeYear, prevYear, nextYear, selectYear, selectMonth}
}
/********************************************************
[사용법]

const [year, setYear] = useState(new Date().getFullYear())
const [month, setMonth] = useState(new Date().getMonth() + 1)
const {today, calendar, selectYear, selectMonth, prevMonth, nextMonth} = useCalendar({year: year, month: month})

*********************************************************/
