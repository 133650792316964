import React from 'react';
import styled from 'styled-components';
import { TextProps } from 'types';
import Text from './text';

/**
 * @name H2
 * @description H2
 */
export default function H2({ children, style, label, className, ...props }: TextProps) {
  return (
    <Element style={style} className={className}>
      <Text {...props}>
        {!!label && label}
        {children}
      </Text>
    </Element>
  );
}
//* --------------------------------------------------*
const Element = styled.h2`
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.3px;
`;
