import React, { useState } from 'react';
import styled from 'styled-components';
import { icon_calendar_black } from 'assets/images';
import { Calendar } from 'components';
import { COLOR } from 'styles';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import InputButton from './input-button';
import Panel from './panel';

type Props = {
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
};
/**
 * @name 통계유형
 */
export default function Contents({ value, placeholder, onChange }: Props) {
  const { t } = useTranslation('common');
  const [visible, setVisible] = useState(false);
  return (
    <Content>
      <InputButton
        icon={icon_calendar_black}
        value={value ? dayjs(value).format('YYYY.MM.DD') : placeholder}
        onClick={() => {
          setVisible(true);
        }}
      />
      {/* 레이어팝업 */}
      <Panel
        visible={visible}
        setVisible={setVisible}
        title={t('common.dateSelector.selectStartDateCompareStatistics')}
        desc={t('common.dateSelector.selectStartDate')}>
        <Calendar
          selectedDate={dayjs(value).format('YYYY.MM.DD')}
          onChange={date => {
            if (onChange) {
              onChange(date);
              setVisible(false);
            }
          }}
        />
      </Panel>
    </Content>
  );
}
const Content = styled.div`
  // styles
  position: relative;
  // 날짜선택
  label.label {
    color: ${COLOR.BLUE2};
  }
`;
