import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { Dayjs, Image } from 'lib';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { useAppSelector } from '@/model/index';
import {
  Wrapper,
  TableBody,
  Table,
  TableHead,
  TH,
  TD,
  TR,
  InnerCellTextButton,
  InnerCellContainer,
  NoResultDescription,
  NoResultText,
  NoResultTextWrapper,
  NoSearchResultTextWrapper,
  NoSearchResultWrapper,
} from './RemoteTable.style';
import { IGetRemoteRes } from '@/types/operation';
import typography from '@/styles/typography';
import Pagination from '@/components/organisms/Paginations/Pagination';
import { MENU } from '@/pages/router';
import Badge from '@/components/atoms/Badge';
import { COLOR } from '@/styles/index';
import { icons } from '@/assets/images';

export default function RemoteTable({
  itemsPerPage,
  currentPage,
  pageHandler,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
}) {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { list, totalCount } = useAppSelector(state => state.remote);

  const handleNavigateToDetail = (seq: number) => {
    navigate(`${MENU.OPERATION.REMOTE_HISTORY}/${seq}`);
  };

  const renderActionResult = (type: string, responseAt: string) => {
    if (type === 'CALL_ERROR' || responseAt === null) {
      return <Badge mode="RemoteActionFailed" label={t('pages.operation.remote.status.failed')} />;
    }
    if (type === 'CALL_RESULT') {
      return <Badge mode="RemoteActionSuccess" label={t('pages.operation.remote.status.success')} />;
    }
    return <Badge mode="RemoteActionWaiting" label={t('pages.operation.remote.status.waiting')} />;
  };

  return (
    <Wrapper>
      <Table>
        <colgroup>
          <col style={{ width: 44 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 180 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 220 }} />
          <col style={{ minWidth: 150 }} />
        </colgroup>
        <TableHead>
          <TR>
            <TH>No.</TH>
            <TH>{t('pages.operation.remote.targetDevice')}</TH>
            <TH>{t('pages.operation.remote.targetDeviceID')}</TH>
            <TH>{t('pages.operation.remote.connectorID')}</TH>
            <TH>{t('pages.operation.remote.type')}</TH>
            <TH>{t('pages.operation.remote.actionType')}</TH>
            <TH>{t('pages.operation.remote.result')}</TH>
            <TH>{t('pages.operation.remote.createdAt')}</TH>
            {/* <TH>{t('pages.operation.remote.account')}</TH> */}
            <TH>{t('pages.operation.remote.stationName')}</TH>
          </TR>
        </TableHead>
        <TableBody>
          {list?.length > 0 &&
            list.map((item: IGetRemoteRes, index: number) => (
              <TR key={nanoid()}>
                <TD $customFont={typography.body4}>{totalCount - (currentPage - 1) * itemsPerPage - index}</TD>
                <TD $customFont={typography.body5}>{item.connector_number === 0 ? '충전기' : '커넥터'}</TD>
                <TD $customFont={typography.body5} color={COLOR.BLUE4}>
                  <InnerCellContainer>
                    <InnerCellTextButton onClick={() => handleNavigateToDetail(item.seq)}>
                      {item.chargepoint.code}
                    </InnerCellTextButton>
                  </InnerCellContainer>
                </TD>
                <TD $customFont={typography.body5}>{item.connector_number}</TD>
                <TD $customFont={typography.body5}>{item.remote.types === 'SINGLE' ? '원격제어' : '다중제어'}</TD>
                <TD $customFont={typography.body5}>{item.remote.remote_action.control_name}</TD>
                <TD $customFont={typography.body5}>
                  <InnerCellContainer>{renderActionResult(item.response_type, item.response_at)}</InnerCellContainer>
                </TD>
                <TD $customFont={typography.body5}>{Dayjs(item.remote.created_at).format('YYYY.MM.DD HH:mm:ss')}</TD>
                {/* <TD $customFont={typography.body5}>{item.remote.created_by?.email}</TD> */}
                <TD $customFont={typography.body5}>{item.chargepoint.station.name}</TD>
              </TR>
            ))}
        </TableBody>
      </Table>
      {list.length > 0 && (
        <Pagination
          listLength={totalCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onClick={pageHandler}
        />
      )}
      {list.length === 0 && (
        <NoSearchResultWrapper>
          <Image src={icons.noSalesData.image.default} style={{ width: 192, height: 192 }} />
          <NoSearchResultTextWrapper>
            <NoResultTextWrapper>
              <NoResultText>{t('pages.operation.remote.noDataTitle')}</NoResultText>
            </NoResultTextWrapper>
          </NoSearchResultTextWrapper>
        </NoSearchResultWrapper>
      )}
    </Wrapper>
  );
}
