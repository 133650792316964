import { useAppSelector } from 'model';
import { actions, EVENT, MODE, POPUP } from 'model/modules/auth';
import Container from './container';

export default Container;

/**
 * @name config
 */
export const Store = () => useAppSelector(state => state.auth);
export const _action = actions;
export const _mode = MODE;
export const _event = EVENT;
export const _popup = POPUP;

/** ******************************************************
[사용법]
/common       : 공통적인 속성들 ex) header, footer, menu , button , popup, actionSheet
/contents     : 개볉컨텐츠 속성들 list, view
container.tsx : method , fetch , binding
presenter.tsx : view적인부분,스타일링 화면영역
index.ts      : index
******************************************************** */
