/**
 * @name 실시간랭킹
 * @description
 */
import styled from 'styled-components';
import { COLOR, layout } from 'styles';
import { Events, H5, ImageButton, Text, useRoutes, uuid } from 'lib';
import { useAppSelector } from 'model';
import { icon_dashboard_button } from 'assets/images';
import { MENU } from '@/pages/router';
// contents
import { ListRanking } from 'components';
import { _event } from '../../';

export default function Contents() {
  // hooks
  const { navigate } = useRoutes();
  const { rank } = useAppSelector(state => state.dashboard);
  //*--------------------------------------------------*
  return (
    <Content className={layout.panel}>
      {/* {debug(rank)} */}
      <header className="space_between">
        <div>
          <H5>충전소 매출랭킹</H5>
          <Text color={COLOR.GRAY1} className="body7">
            2023.04.23
          </Text>
        </div>
        <ImageButton
          onClick={() => {
            navigate(MENU.DASHBOARD);
          }}
          source={icon_dashboard_button}
          style={{ width: 48, height: 48 }}
        />
      </header>
      <section>
        {!!rank?.data &&
          rank?.data.map((item: any, index: number) => {
            return (
              <ListRanking
                key={uuid()}
                item={item}
                index={index}
                onClick={(data: any) => {
                  Events.emit(_event.MAP_SELECT_STATION, data);
                }}
              />
            );
          })}
      </section>
    </Content>
  );
}

//*--------------------------------------------------*
const Content = styled.div`
  /* height: 100px; */
  /* padding-bottom: 100px; */
  width: 312px;
  height: calc(100vh - 350px);
  margin-top: 24px;
  overflow-y: auto;
  background: ${COLOR.WHITE};
  header {
    height: 59px;
    margin-top: 10px;
    padding-left: 16px;
    padding-right: 5px;
  }
  section {
  }
`;
