import React, { useEffect } from 'react';
import { useRoutes } from 'lib';
import { contextAction } from 'model/modules';
import { useAppDispatch } from 'model';
import { MENU } from '@/pages/router';
import { getCountries } from '@/lib/apis/infra';

/**
 * @name HOME
 * @description 로그인되어있는지 체크이후 대시보드로 이동
 */
export default function Index() {
  const dispatch = useAppDispatch();
  const { navigate } = useRoutes();

  async function fetch_common() {
    const countriesResponse = await getCountries();
    if (countriesResponse?.isSuccess) {
      dispatch(contextAction.setCountries(countriesResponse.data));
    } else {
      navigate(MENU.AUTH.SIGN_IN);
    }
    return true;
  }

  async function onRoutes() {
    navigate(MENU.AUTH.SIGN_IN);
    return true;
  }

  useEffect(() => {
    async function onInit() {
      await fetch_common();
      await onRoutes();
    }
    onInit();
    return () => {};
  }, []);

  return <div>_</div>;
}
