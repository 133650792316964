import React from 'react';
import { COLOR } from 'styles';
import styled from 'styled-components';
import { debug } from 'lib';
import { ClassName, CSS, RenderItem } from 'types';

type Props = {
  info: any[];
  renderHeader?: () => React.ReactNode;
  renderItem: RenderItem;
  style?: CSS;
  itemStyle?: CSS;
  className?: ClassName; // 클래스적용
  empty?: React.ReactNode; // 데이터가 없을때
  disabled?: boolean; // hover시 배경색
};

/**
 * @name Table
 * @description 리스트 나열
 */
export default function Table({ style, disabled, renderHeader, renderItem, info, empty }: Props) {
  const _style = { ...style };
  const createThead = () => {
    return (
      <thead>
        <tr>
          <th>No.</th>
          <th>고객유형</th>
          <th>커넥터ID</th>
          <th>결제유형</th>
          <th>카드사</th>
          <th>카드번호</th>
          <th>최종승인금액(원)</th>
        </tr>
      </thead>
    );
  };
  return (
    <>
      <Element style={_style} className={disabled ? 'disabled' : 'hover'}>
        {renderHeader === undefined ? createThead() : renderHeader()}
        {/* renderItem없을경우디버그 */}
        {renderItem === undefined && debug(info)}
        <tbody>
          {!!info && info.length > 0 && info.map((item, index) => !!renderItem && renderItem({ item, index }))}
        </tbody>
      </Element>
      {/* 빈컨텐츠일때 */}
      {empty !== undefined && empty}
    </>
  );
}

const Element = styled.table`
  width: 100%;
  /* <!--common--> */
  .l {
    text-align: left;
  }
  .r {
    text-align: right;
  }
  .c {
    text-align: center;
  }
  .auto {
    width: auto;
  }
  /* 마우스호버기능추가 */
  &.hover {
    tr {
      &:hover {
        background-color: ${COLOR.GRAY6};
      }
      &:active {
        background-color: ${COLOR.GRAY4};
      }
    }
  }
  tr {
    border-bottom: 1px solid ${COLOR.GRAY5};
  }
  th,
  td {
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
  }
  th {
    height: 48px;
    /* padding: 0px 8px; */
    /* border-top: 1px solid ${COLOR.GRAY5}; */
    border-bottom: 1px solid ${COLOR.BLUE_GRAY8};
    text-align: center;
    vertical-align: middle;
    color: ${COLOR.GRAY1};
    font-size: 15px;
    line-height: 24px;
    background-color: ${COLOR.BLUE9};
    // 양쪽에 여백
    &.inner > * {
      padding: 0px 10px;
    }
  }
  td {
    height: 64px;
    vertical-align: middle;
    /* padding-top: 18px;
    padding-bottom: 18px;
     */
    color: ${COLOR.BLACK3};
    font-size: 15px;
    line-height: 24px;
    a {
      color: ${COLOR.BLUE3};
    }
  }
`;
/** ******************************************************
[사용법]
const info = [
  {no: 1, type: '-', idTag: '-', station: '-', connector: '-', payment: '-', preAmount: '-', card: '-', cardNo: '-', finalAmount: '140,000원'},
  {no: 1, type: '-', idTag: '-', station: '-', connector: '-', payment: '-', preAmount: '-', card: '-', cardNo: '-', finalAmount: '140,000원'},
  {no: 1, type: '-', idTag: '-', station: '-', connector: '-', payment: '-', preAmount: '-', card: '-', cardNo: '-', finalAmount: '140,000원'},
  {no: 1, type: '-', idTag: '-', station: '-', connector: '-', payment: '-', preAmount: '-', card: '-', cardNo: '-', finalAmount: '140,000원'},
  {no: 1, type: '-', idTag: '-', station: '-', connector: '-', payment: '-', preAmount: '-', card: '-', cardNo: '-', finalAmount: '140,000원'},
]
 <colgroup>
  <col className="col1" />
  <col className="auto" />
  <col className="auto" />
  <col className="auto" />
  <col className="auto" />
  <col className="auto" />
  <col className="auto" />
  <col className="auto" />
  <col className="auto" />
</colgroup>
<div style={{width:500,marginBottom: 100}}>
  <Table
    info={info}
    renderHeader={() => {
      return (
        <tr>
          <th>No.</th>
          <th>고객유형</th>
          <th>ID Tag</th>
          <th>충전소</th>
          <th>커넥터ID</th>
          <th>결제유형</th>
          <th>사전승인금액(원)</th>
          <th>카드사</th>
          <th>카드번호</th>
          <th>최종승인금액(원)</th>
        </tr>
      )
    }}
    renderItem={({item, index}: ListRenderItem) => {
      return (
        <tr key={uuid()}>
          <td>{item?.no}</td>
          <td>{item?.type}</td>
          <td>{item?.idTag}</td>
          <td>{item?.station}</td>
          <td>{item?.connector}</td>
          <td>{item?.payment}</td>
          <td>{item?.preAmount}</td>
          <td>{item?.card}</td>
          <td>{item?.cardNo}</td>
          <td className="right">{item?.finalAmount}</td>
        </tr>
      )
    }}
  />
</div>
******************************************************** */
