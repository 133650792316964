/**
 * @name H5
 * @description
 */
import styled from 'styled-components'
import {TextProps} from 'types'
import Text from './text'

export default function H5({children, style, label, ...props}: TextProps) {
  return (
    <Element style={style}>
      <Text {...props}>
        {!!label && label}
        {children}
      </Text>
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.h5`
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.375px;
`
