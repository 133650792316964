import { CSSProperties } from 'react';

export enum ButtonSize {
  L = 'large',
  M = 'medium',
  S = 'small',
}

export enum ButtonStyle {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}
export type ButtonArrowDirectionStyleType = 'left' | 'right';

export interface ButtonStyleProps {
  $size?: ButtonSize;
  $stretch?: boolean;
  $buttonStyle?: ButtonStyle;
  $direction?: ButtonArrowDirectionStyleType;
}

export interface PaginationFunctionButtonProps {
  onClick?: () => void;
  title: string;
  disabled?: boolean;
  size?: ButtonSize;
  direction: ArrowDirectionType;
}

type ArrowDirectionType = 'left' | 'right';

export interface ImageButtonProps {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  style?: CSSProperties;
  ariaLabel?: string;
  imagePaths: StatusImagesPath;
  imageStyle?: CSSProperties;
}

export interface StatusImagesPath {
  $default: any;
  $hover: any;
  $focus: any;
}
