/**
 * @name CheckBox
 * @description
 */
import styled from 'styled-components'
import {Image} from 'lib'
import {icon_checkbox_off, icon_checkbox_on} from 'assets/images'

type Props = {
  value?: boolean
  style?: React.CSSProperties //----------------- StyleSheet
  onChange?: (value: boolean) => void
  [key: string]: any
}
export default function CheckBox({onChange, value, style}: Props) {
  //*--------------------------------------------------*
  return (
    <Content
      style={style}
      className={`row ${value ? 'active' : ''}`}
      onClick={() => {
        !!onChange && onChange(!value)
      }}>
      <Image src={value ? icon_checkbox_on : icon_checkbox_off} style={{width: 18, height: 18}} />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.button`
  position: relative;
  height: 18px;
`
/********************************************************
<FormCheckbox
  value={isAgree}
  onChange={val => {
    setIsAgree(val)
  }}
/>
{debug(select)}
*********************************************************/
