import React from 'react';
import { InputComponent } from './Input.style';
import { FontType } from '@/types/font';

type Props = {
  defaultValue?: string | number;
  style?: React.CSSProperties;
  placeholder?: string;
  onSubmit?: (str: string) => void;
  onChange?: (event: string) => void;
  customFont?: FontType;
  disabled?: boolean;
};
export default function Input({ disabled, defaultValue, style, placeholder, onChange, onSubmit, customFont }: Props) {
  return (
    <InputComponent
      disabled={disabled}
      value={defaultValue}
      style={style}
      placeholder={placeholder}
      $customFont={customFont}
      onChange={event => {
        if (onChange) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={event => {
        if (event.key === 'Enter' && onSubmit) {
          onSubmit(event.currentTarget.value);
        }
      }}
    />
  );
}
