import { App } from 'components';
import { addComma, H3, Row, Text } from 'lib';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'model';
import { Header, ButtonWrapper, HeaderFuncWrapper } from './CalculateList.styles';
import CalculateTable from '@/components/organisms/Tables/CalculateTable';
import { DropDownMonth, DropDownYear } from '@/components/form';
import { COLOR } from '@/styles/index';
import DownloadButton from '@/components/atoms/Buttons/DownloadButton';

export default function CalculateListPresenter({
  itemsPerPage,
  currentPage,
  pageHandler,
  queryString,
  handleExcelDownload,
  handleSelectYear,
  handleSelectMonth,
  selectYear,
  selectMonth,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  handleExcelDownload: () => void;
  queryString: string;
  handleSelectYear: (val: { label: string; value: number }) => void;
  handleSelectMonth: (val: { label: string; value: number }) => void;
  selectYear: string;
  selectMonth: string;
}) {
  const { t } = useTranslation('common');
  const totalCount = useAppSelector(state => state.sales.totalCount);

  return (
    <App>
      <Header style={{ width: '100%' }}>
        <HeaderFuncWrapper>
          <Row>
            <div style={{ marginRight: 20 }}>
              <H3 color={COLOR.BLACK3}>{t('pages.sales.calculateStatus.title')}</H3>
              <Text color={COLOR.BLACK3} className="body6">
                {`${t('common.unit.total')} ${addComma(totalCount)}${t('common.unit.cases')}`}
              </Text>
            </div>
            <div className="search" style={{ marginLeft: 20 }}>
              <DropDownYear value={String(selectYear)} onChange={handleSelectYear} />
            </div>
            <div className="search" style={{ marginLeft: 20 }}>
              <DropDownMonth value={String(selectMonth)} onChange={handleSelectMonth} />
            </div>
          </Row>
          <ButtonWrapper>
            <DownloadButton onClick={handleExcelDownload} />
          </ButtonWrapper>
        </HeaderFuncWrapper>
      </Header>
      <CalculateTable
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        pageHandler={pageHandler}
        queryString={queryString}
      />
    </App>
  );
}
