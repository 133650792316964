import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { SelectButton, TextFiledGroup, TextFiledLabel, TextInput } from 'components/form';
import { useAppDispatch, useAppSelector } from 'model';
import { useTranslation } from 'react-i18next';
import { SCREEN_MODE } from '@/types/mode';
import { actions } from '@/model/modules/admin';
import { Button } from '@/lib/index';
import { icon_email_white } from '@/assets/images';
import { AdminAccountsStatusType } from '@/types/admin';
import { reInvite } from '@/lib/apis/admin';

/**
 * @name 계정이메일,계정유형
 * @description
 */
export default function AccountInfoForm({ handleReInvite }: { handleReInvite?: () => void }) {
  const { t } = useTranslation('common');
  const { mode, permissions, accountDetail } = useAppSelector(state => state.admin);
  const dispatch = useAppDispatch();
  const isModify = mode === SCREEN_MODE.MODIFY;

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    // dispatch(actions.setPayload({[name]: value}))
  };

  return (
    <Content>
      <TextFiledLabel required label={t('pages.admin.account.detail.accountEmail')} />
      <TextInput
        disabled
        style={{ marginBottom: accountDetail.status === AdminAccountsStatusType.INVITED ? 8 : 32 }}
        name="email"
        placeholder={t('pages.admin.account.detail.accountEmailPlaceholder')}
        defaultValue={accountDetail.email}
        onChange={onChangeHandler}
      />

      {accountDetail.status === AdminAccountsStatusType.INVITED && !isModify && (
        <ButtonWrapper>
          <Button
            icon={icon_email_white.default}
            style={{ width: '100%' }}
            label={t('pages.admin.account.detail.resendInviteEmail')}
            onClick={handleReInvite}
          />
        </ButtonWrapper>
      )}

      <TextFiledGroup
        label={t('pages.admin.account.detail.accountType')}
        required
        component={
          <SelectButton
            disabled
            value={accountDetail.role.code === 'ADMIN' ? 1 : 2}
            leftLabel={t('pages.admin.account.detail.admin')}
            rightLabel={t('pages.admin.account.detail.operator')}
            onChange={val => {
              if (val === 1) {
                // dispatch(actions.setInvitePayload({ ...invitePayload, role_code: 'ADMIN' }));
              } else {
                // dispatch(actions.setInvitePayload({ ...invitePayload, role_code: 'CPO' }));
              }
            }}
          />
        }
      />

      {/* <TextFiledLabel label={t('pages.admin.account.detail.name')} /> */}
      {/* <TextInput */}
      {/*  disabled={disabled} */}
      {/*  style={{ marginBottom: 16 }} */}
      {/*  defaultValue={permissions?.name} */}
      {/*  name="name" */}
      {/*  onChange={onChangeHandler} */}
      {/* /> */}

      {/* <TextFiledLabel label={t('pages.admin.account.detail.number')} /> */}
      {/* <TextInput */}
      {/*  disabled={disabled} */}
      {/*  style={{ marginBottom: 16 }} */}
      {/*  defaultValue={permissions?.phone} */}
      {/*  name="phone" */}
      {/*  onChange={onChangeHandler} */}
      {/* /> */}

      {/* <TextFiledLabel label={t('pages.admin.account.detail.company')} /> */}
      {/* <TextInput */}
      {/*  disabled={disabled} */}
      {/*  style={{ marginBottom: 16 }} */}
      {/*  defaultValue={permissions?.business_info?.name} */}
      {/*  name="business_info_name" */}
      {/*  onChange={onChangeHandler} */}
      {/* /> */}

      {/* <TextFiledLabel label={t('pages.admin.account.detail.companyNumber')} /> */}
      {/* <TextInput */}
      {/*  disabled={disabled} */}
      {/*  style={{ marginBottom: 16 }} */}
      {/*  defaultValue={permissions?.business_info?.phone} */}
      {/*  name="business_info_phone" */}
      {/*  onChange={onChangeHandler} */}
      {/* /> */}

      {/* <TextFiledLabel label={t('pages.admin.account.detail.companyContactNumber')} /> */}
      {/* <TextInput */}
      {/*  disabled={disabled} */}
      {/*  style={{ marginBottom: 16 }} */}
      {/*  defaultValue={permissions?.business_info?.cs_phone} */}
      {/*  name="business_info_cs_phone" */}
      {/*  onChange={onChangeHandler} */}
      {/* /> */}
    </Content>
  );
}
//* --------------------------------------------------*
const Content = styled.div`
  width: 400px;
  margin: 24px auto 56px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 32px;
`;
