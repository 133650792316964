import { COLOR } from 'styles/color';
import React from 'react';
import styled from 'styled-components';
import { CSS } from 'types';
import Image from '../image/image';
import typography from '@/styles/typography';

type Props = {
  params?: {};
  children?: React.ReactNode;
  icon?: any; // 이미지 아이콘
  iconStyle?: CSS; // ----------------------------- 아이콘스타일
  color?: string; // ------------------------------ 텍스트색상
  bg?: string; // --------------------------------- 배경색상
  radius?: number; // ----------------------------- 라운드처리
  borderColor?: string; // ------------------------ 테두리컬러
  className?: string; // -------------------------- 클래스네임
  disabled?: boolean; // -------------------------- 비활성화
  full?: boolean; // ------------------------------ full width
  label?: string; // -------------------------------버튼명
  style?: CSS; // --------------------------------- 스타일시트
  onClick?: (event: React.MouseEvent<HTMLElement>) => void; // ------------------------ onClick 이벤트
};

/**
 * @name Button
 * @description 버튼
 */
export default function Button({
  params,
  icon,
  iconStyle,
  children,
  borderColor,
  radius,
  bg,
  color,
  full,
  className,
  disabled,
  label,
  style,
  onClick,
  ...props
}: Props) {
  const fullStyle = full && { width: '100%' };
  const backgroundStyle = bg && { backgroundColor: bg };
  const colorStyle = color && { color };
  const borderColorStyle = borderColor && { borderColor, borderWidth: 1, borderStyle: 'solid', boxShadow: 'none' };
  const borderRadiusStyle = radius && { borderRadius: radius };
  const combinedStyle = {
    ...fullStyle,
    ...borderRadiusStyle,
    ...borderColorStyle,
    ...colorStyle,
    ...backgroundStyle,
    ...(!!style && style),
  };
  const combinedIconStyle = { width: 20, height: 20, marginRight: 6, ...(!!iconStyle && iconStyle) };
  return (
    <Element
      {...props}
      className={className}
      disabled={disabled}
      style={combinedStyle}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        if (onClick) {
          onClick({ ...params, ...event });
        }
      }}>
      {children}
      {!!icon && <Image src={icon} style={combinedIconStyle} />}
      {!!label && label}
    </Element>
  );
}

const Element = styled.button`
  /* display: inline-block; */
  display: flex;
  border: 0;
  border-radius: 8px;
  border-color: ${COLOR.BLUE4};
  padding: 8px 20px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: ${COLOR.WHITE};
  ${typography.body3};
  box-shadow: 1px 1px 3px rgba(56, 65, 74, 0.4);
  background-color: ${COLOR.BLUE4};
  transition: all 0.2s cubic-bezier(0.8, 0.07, 0.31, 1);
  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
    /* background-color: ${COLOR.BLUE2}; */
  }
  &:active {
    filter: brightness(0.9);
    /* filter: contrast(150%); */
    /* background-color: ${COLOR.BLUE0}; */
  }
  &:disabled {
    cursor: not-allowed;
    color: ${COLOR.BLUE7};
    background-color: ${COLOR.BLUE8};
    box-shadow: 1px 1px 3px rgba(56, 65, 74, 0.1);
  }
  label {
    color: ${COLOR.WHITE};
  }
`;
/** ******************************************************
[사용법]
  <Button bg={'orange'} color="red" />
  <Button
    full
    style={{backgroundColor: COLOR.RED1, borderColor: COLOR.BLUE4}}
    label="로그인"
    onClick={() => {
      push('/auth/sign-up')
    }}
  />

  다른예제
  <Button full label="취소" bg={'transparent'} color={'#ff0000'} radius={8} borderColor={'black'} style={{height: 30, boxShadow: 'none', border: 1, marginRight: 10}} />

******************************************************** */
