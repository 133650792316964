/**
 * @name Object
 * @description object 관련유틸리티
 */

export const isJson = (obj: any) => {
  try {
    const json = JSON.parse(obj);
    return typeof json === 'object';
  } catch (e) {
    return false;
  }
};
// 빈오브젝트체크
export const isEmptyObject = (obj: any): boolean => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const objectToQueryString = (obj: unknown): string => {
  const parts: string[] = [];
  Object.entries(obj as Record<string, unknown>).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => {
        parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
      });
    } else if (value !== undefined) {
      parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value as string | number | boolean)}`);
    }
  });
  return parts.join('&');
};
