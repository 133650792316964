import styled, { css } from 'styled-components';
import { COLOR } from '@/styles/index';
import typography from '@/styles/typography';
import { FontType } from '@/types/font';

export const RadioButton = styled.button<{ $editMode: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ $editMode }) => ($editMode ? 'pointer' : 'default')};
  padding: 8px;
  gap: 8px;
`;

export const InnerCellContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
export const TR = styled.tr`
  border-bottom: 1px solid ${COLOR.GRAY5};
`;

export const TD = styled.td<{ $index?: number; $customFont?: FontType; color?: string }>`
  padding: 8px;
  height: 56px;
  color: ${props => props.color ?? COLOR.BLACK2};
  vertical-align: middle;
  text-align: center;
  ${({ $customFont }) =>
    $customFont &&
    css`
      font-family: ${$customFont.fontFamily};
      letter-spacing: ${$customFont.letterSpacing};
      font-size: ${$customFont.fontSize};
      line-height: ${$customFont.lineHeight};
      font-weight: ${$customFont.fontWeight};
    `};
`;

export const ButtonText = styled.span`
  ${typography.body4};
`;

export const Text = styled.span`
  ${typography.caption1};
  min-width: 48px;
  color: ${COLOR.GRAY3};
`;
