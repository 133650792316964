import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'model';
import { useNavigate, useParams } from 'react-router-dom';
import ChargingRateRoamingDetailPresenter from './ChargingRateRoamingDetail.presenter';
import { getRoamingTariff, getTariff, removeRoamingTariff } from '@/lib/apis/tariff';
import { actions } from '@/model/modules/price';
import { SCREEN_MODE } from '@/types/mode';
import { MENU } from '@/pages/router';

export default function ChargingRateRoamingDetailContainer() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { mode, payload } = useAppSelector(state => state.price);
  const { cpo_seq } = useAppSelector(state => state.auth);
  const isModify = mode === SCREEN_MODE.MODIFY;
  const [deletePopup, setDeletePopup] = useState(false);

  const onInit = () => {
    fetchData(); // TODO Roaming 상세보기 데이터 값 함수 호출
    if (!isModify) {
      dispatch(actions.setMode(SCREEN_MODE.REGISTER));
    }
  };

  const fetchData = async () => {
    const { isSuccess, data } = await getRoamingTariff(String(id));
    if (isSuccess) {
      dispatch(actions.setRoamingView(data)); // TODO Roaming 상세보기 데이터 값
      dispatch(actions.setRoamingPayload(data)); // TODO Roaming 페이로드 데이터 값
    }
  };
  const handleBackToList = () => {
    navigate(MENU.RATE.CHARGING_RATE_MANAGEMENT);
  };

  const handleUpdateRoaming = () => {
    dispatch(actions.setMode(SCREEN_MODE.MODIFY));
    navigate(`${MENU.RATE.CHARGING_RATE_ROAMING_UPDATE}/${id}`);
  };

  const handleDeletePopup = (value: boolean) => {
    setDeletePopup(value);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (id) {
        const removeResponse = await removeRoamingTariff(id);
        if (removeResponse.isSuccess) {
          handleDeletePopup(false);
          dispatch(actions.initRoamingView());
          navigate(MENU.RATE.CHARGING_RATE_MANAGEMENT);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    onInit();
  }, []);

  return (
    <ChargingRateRoamingDetailPresenter
      handleBackToList={handleBackToList}
      handleToUpdate={handleUpdateRoaming}
      deletePopupVisible={deletePopup}
      handleDeletePopup={handleDeletePopup}
      onDeleteConfirm={handleDeleteConfirm}
    />
  );
}
