import React from 'react';
import { App, BackButton } from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RootState, useAppSelector } from 'model';
import { COLOR } from '@/styles/index';
import { Button } from '@/lib/index';
import { PopupContainer, PopupCreate, SubmitCancel } from '@/components/form';
import { VendorRegisterViewProps } from './VendorRegister.types';
import { SCREEN_MODE } from '@/types/mode';
import VendorRegisterForm from '@/components/organisms/Forms/VendorRegisterForm';

/**
 * @name 제조사 등록 - 등록/수정
 * @description
 */
export default function VendorRegisterPresenter({
  onSubmit,
  onCancelPopup,
  popupSuccessVisible,
  popupCancelVisible,
  handleCloseCancelPopup,
  handleDone,
  requiredFieldsWarning,
  popupFailedVisible,
  handleCloseFailedPopup,
}: VendorRegisterViewProps) {
  const {
    vendor: { mode, view },
  } = useAppSelector((state: RootState) => state);
  const { t } = useTranslation('common');
  const isModify = mode === SCREEN_MODE.MODIFY;

  return (
    <App>
      <HeaderWrapper>
        <BackButton
          label={isModify ? `${view.name} / ${view.vendor_id}` : t('pages.infra.vendorManage.register')}
          onClick={onCancelPopup}
        />
      </HeaderWrapper>
      <Wrapper>
        <MainContents>
          <Content>
            <VendorRegisterForm requiredFieldsWarning={requiredFieldsWarning} />
            <SubmitButtonsWrapper>
              <ButtonWrapper>
                <Button
                  full
                  label={t('common.buttons.cancel')}
                  color={COLOR.GRAY2}
                  borderColor={COLOR.GRAY2}
                  bg="transparent"
                  onClick={onCancelPopup}
                />
                <Button
                  full
                  label={isModify ? t('common.buttons.updateAction') : t('common.buttons.registerAction')}
                  onClick={onSubmit}
                />
              </ButtonWrapper>
            </SubmitButtonsWrapper>
          </Content>
        </MainContents>
      </Wrapper>
      <PopupContainer visible={popupSuccessVisible} onClose={handleDone}>
        <PopupCreate
          title={isModify ? t('common.toast.successToModifyVendor') : t('common.toast.successToRegisterVendor')}
          message={isModify ? t('common.toast.successToModifyVendor2') : t('common.toast.successToRegisterVendor2')}
          onConfirm={handleDone}
        />
      </PopupContainer>
      <PopupContainer visible={popupCancelVisible}>
        <PopupCreate
          warning
          title={isModify ? t('common.toast.cancelUpdateVendor') : t('common.toast.cancelRegisterVendor')}
          message={isModify ? t('common.toast.askCancel') : t('common.toast.askCancelWithoutSave')}
          footer={
            <SubmitCancel
              confirmLabel={
                isModify ? t('common.buttons.cancelUpdateAction') : t('common.buttons.cancelRegisterAction')
              }
              onCancel={handleCloseCancelPopup}
              onConfirm={handleDone}
            />
          }
        />
      </PopupContainer>
      <PopupContainer visible={popupFailedVisible} onClose={handleCloseFailedPopup}>
        <PopupCreate
          warning
          title={isModify ? t('common.toast.failedUpdateVendorTitle') : t('common.toast.failedRegisterVendorTitle')}
          message={
            isModify ? t('common.toast.failedUpdateVendorMessage') : t('common.toast.failedRegisterVendorMessage')
          }
          onConfirm={handleCloseFailedPopup}
        />
      </PopupContainer>
    </App>
  );
}

const HeaderWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  header {
    width: 674px;
    margin: 24px auto;
  }
`;

const MainContents = styled.div`
  width: 900px;
  margin: 24px auto;
`;

const Content = styled.div`
  padding-bottom: 30px;
`;
const SubmitButtonsWrapper = styled.div`
  width: 400px;
  margin: 32px auto 20px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
`;
