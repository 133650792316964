import styled, { css } from 'styled-components';
import { COLOR } from '@/styles/index';
import typography from '@/styles/typography';

export const Wrapper = styled.div<{ $tabSelected?: boolean }>`
  min-width: 680px;
  ${props =>
    props.$tabSelected
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Header = styled.div`
  flex: 1;
  padding: 14px 8px;
  background-color: ${COLOR.BLUE8};
  border-bottom: 1px solid ${COLOR.BLUE_GRAY9};
  text-align: center;
  ${typography.headline6};
  color: ${COLOR.GRAY1};
`;

export const Cell = styled.div`
  padding: 16px 8px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  flex: 1;
  text-align: center;
  ${typography.body4};
  color: ${COLOR.BLACK3};
`;
