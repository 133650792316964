/**
 * @name 보유충전기및적용된충전단가
 * @description
 */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Row, addComma, useAxios, useContext, uuid } from 'lib';
import { COLOR } from 'styles';
import { ListKeyValue, TextFiledLabel } from 'components/form';
import { API_HOST } from 'config';
import { MemberType } from 'components';

// TODO Tariff 이후(충전기 등록 이후 다시 구현)
export default function StationStatusTable({ tabSelected }: { tabSelected?: boolean }) {
  // const
  const [info, setInfo] = useState<any>(null);
  const { get } = useAxios();
  const { cpo_seq } = useContext();

  // 상세보기
  async function fetch_view(seq: number) {
    const res = await get(`${API_HOST}/business/${cpo_seq}/tariff/stations/${seq}`);
    if (res?.isSuccess) {
      setInfo(res);
    } else {
    }
  }
  useEffect(() => {
    // fetch_view(view?.data?.seq);
  }, []);
  //* --------------------------------------------------*
  return (
    <Content $tabSelected={tabSelected}>
      <div className="top">
        {!!info &&
          info?.data?.current_tariff?.map((item: any, index: number) => {
            return (
              <section className="section" key={uuid()}>
                <MemberType type={item?.is_user} />
                <Row style={{ marginTop: 30, justifyContent: 'center' }}>
                  <div style={{ marginRight: 10 }}>
                    <p className="body3">급속단가</p>
                    <p className="price">
                      <b>{addComma(item?.fast_amount)}</b>
                      원/kwh
                    </p>
                  </div>
                  <div style={{ marginLeft: 10 }}>
                    <p className="body3">완속단가</p>
                    <p className="price">
                      <b>{addComma(item?.fast_amount)}</b>
                      원/kwh
                    </p>
                  </div>
                </Row>
              </section>
            );
          })}
      </div>
      <div style={{ marginBottom: 40 }}>
        <TextFiledLabel label="보유 충전기 및 적용된 충전 단가" />
        <ListKeyValue title="충전소명" value={info?.data?.station?.name} />
        <ListKeyValue title="충전소 ID" value={info?.data?.station?.code} />
        <ListKeyValue title="보유충전기 수" value={addComma(info?.data?.station?.chargepoint_count)} />
      </div>
    </Content>
  );
}
//* --------------------------------------------------*
const Content = styled.div<{ $tabSelected?: boolean }>`
  ${props =>
    props.$tabSelected
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 920px;
    height: 180px;
    margin: 24px auto;
    /* padding: 30px 30px; */
    border: 1px solid ${COLOR.GRAY5};
    border-radius: 8px;
    /* 구분 */
    section.section {
      text-align: center;
      width: 200px;
      margin: 20px 20px;
      padding: 37px 0;
      .body3 {
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.45px;
      }
      p.price {
        font-size: 13px;
        line-height: 24px;
        letter-spacing: -0.45px;
        color: ${COLOR.GRAY2};
        b {
          display: inline-block;
          margin-right: 2px;
          color: ${COLOR.BLUE3};
          font-weight: bold;
        }
      }
    }
  }
`;
