import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'model';
import ChargepointDetailPresenter from './ChargepointDetail.presenter';
import { MENU } from '@/pages/router';
import { actions } from '@/model/modules/chargepoint';
import { getCharger } from '@/lib/apis/infra';

export default function ChargepointDetailOperationContainer() {
  const { cpo_seq } = useAppSelector(state => state.auth);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const searchKeyword = searchParams.get('search') || '';
  const locationKeyword = searchParams.get('location') || '';
  const filterYn = searchParams.get('filter') || '';

  const fetchData = async () => {
    if (id && cpo_seq) {
      const { isSuccess, data } = await getCharger(id, cpo_seq);
      if (isSuccess) {
        dispatch(actions.setView(data));
      }
    }
  };

  const queryParamsURL = () => {
    const queryParams = [];

    if (searchKeyword) {
      queryParams.push(`search=${encodeURIComponent(searchKeyword)}`);
    }
    if (locationKeyword) {
      queryParams.push(`location=${encodeURIComponent(locationKeyword)}`);
    }
    if (filterYn) {
      queryParams.push(`filter=${encodeURIComponent(filterYn)}`);
    }
    queryParams.push(`page=${page}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const handleBackToList = () => {
    navigate(`${MENU.OPERATION.CHARGEPOINT_OPERATION}?${queryParamsURL()}`);
  };

  useEffect(() => {
    fetchData();
  }, [cpo_seq]);

  return <ChargepointDetailPresenter handleBackToList={handleBackToList} />;
}
