import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'model';
import dayjs from 'dayjs';
import CalculateDetailPresenter from './CalculateDetail.presenter';
import { getCalculateDetails, getCalculateDetailsExcel, getCalculates } from '@/lib/apis/sales';
import { actions } from '@/model/modules/sales';

export default function CalculateDetailContainer() {
  const { cpo_seq } = useAppSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { year, month } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(page || '1');

  const pageHandler = (p: number) => {
    const page = String(p);
    if (page !== currentPage) {
      setCurrentPage(page);
      navigate(`?${queryParamsURL({ page })}`);
    }
  };

  const fetchList = async (payload: { size: number; page: string; year: string; month: string; type?: 1 | 2 }) => {
    const getCalcualtesResponse = await getCalculates(payload);
    const getCalcualteDetailsResponse = await getCalculateDetails(payload);
    if (getCalcualtesResponse.isSuccess && getCalcualteDetailsResponse.isSuccess) {
      dispatch(
        actions.setList({
          list: { summary: getCalcualtesResponse.data, detailsList: getCalcualteDetailsResponse.data },
          totalCount: getCalcualtesResponse.meta.paging?.total_count ?? 0,
        }),
      );
    }
  };

  const queryParamsURL = (args: { page?: string; year?: string; month?: string } = {}) => {
    const { page } = args;
    const queryParams = [];
    queryParams.push(`page=${page ?? currentPage}`);
    queryParams.push(`size=${itemsPerPage}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const handleExcelDownload = async () => {
    const payload = { cpo_seq, year: year as string, month: month as string };
    if (cpo_seq) {
      await getCalculateDetailsExcel({ payload, fileName: `정산현황상세_${dayjs().format('YYYY_MM_DD')}.xlsx` });
    }
  };

  useEffect(() => {
    const payload = {
      size: itemsPerPage,
      page: currentPage,
      year: year as string,
      month: month as string,
    };
    fetchList(payload);
  }, []);

  return (
    <CalculateDetailPresenter
      itemsPerPage={itemsPerPage}
      currentPage={+currentPage}
      pageHandler={pageHandler}
      queryString={queryParamsURL()}
      handleExcelDownload={handleExcelDownload}
    />
  );
}
