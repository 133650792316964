import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { Button, Image } from 'lib';
import {
  InnerCell,
  InnerCellContainer,
  InnerCellTextButton,
  NoResultDescription,
  NoResultText,
  NoResultTextWrapper,
  NoSearchResultTextWrapper,
  NoSearchResultWrapper,
  SearchKeyword,
  Table,
  TableBody,
  TableHead,
  TD,
  TH,
  TR,
  Wrapper,
} from './StationsTable.style';
import typography from '@/styles/typography';
import { useAppDispatch, useAppSelector } from '@/model/index';
import { MENU } from '@/pages/router';
import { IStation } from '@/types/infra';
import { COLOR } from '@/styles/color';
import { formatDate } from '@/lib/util';
import Pagination from '@/components/organisms/Paginations/Pagination';
import { SortElementType, SortType } from '@/lib/apis/infra/type';
import IconButtonsModifyDel from '@/components/molecules/ButtonGroups/ModifyDel';
import { actions } from '@/model/modules/station';
import { SCREEN_MODE } from '@/types/mode';
import { icon_plus_white, icons } from '@/assets/images';

function StationsTable({
  itemsPerPage,
  currentPage,
  pageHandler,
  sortingHandler,
  handleDelete,
  queryString,
  onRegister,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  sortingHandler?: ({ sort_element, sort_type }: { sort_element: SortElementType; sort_type: SortType }) => void;
  handleDelete: {
    popup: (value: boolean) => void;
    setId: (value: string) => void;
  };
  queryString: string;
  onRegister: () => void;
}) {
  const { t } = useTranslation('common');
  const { list, totalCount } = useAppSelector(state => state.station);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchKeyword = searchParams.get('search');
  const searchLocation = searchParams.get('location');

  const handleEdit = (id: string) => {
    dispatch(actions.setStationMode(SCREEN_MODE.MODIFY));
    navigate(`${MENU.INFRA.STATION_MANAGEMENT_UPDATE}/${id}?${queryString}`);
  };

  const handleNavigateToDetail = (seq: string) => {
    navigate(`${MENU.INFRA.STATION_MANAGEMENT}/${seq}?${queryString}`);
  };

  return (
    <Wrapper>
      <Table>
        <colgroup>
          <col style={{ width: 56 }} />
          <col style={{ minWidth: 312 }} />
          <col style={{ minWidth: 118 }} />
          <col style={{ minWidth: 136 }} />
          <col style={{ minWidth: 60 }} />
          <col style={{ minWidth: 322 }} />
          <col style={{ minWidth: 120 }} />
          <col style={{ minWidth: 76 }} />
          <col style={{ minWidth: 88 }} />
        </colgroup>
        <TableHead>
          <TR>
            <TH>No.</TH>
            <TH>
              <InnerCell>
                {t('forms.charger.name')}
                {/* TODO Sort 버튼 일단 기능 뺌 */}
                {/* <SortButton */}
                {/*  onChange={data => { */}
                {/*    sortingHandler({ sort_element: 'name', sort_type: data }); */}
                {/*  }} */}
                {/* /> */}
              </InnerCell>
            </TH>
            <TH>
              <InnerCell>{t('forms.charger.stationId')}</InnerCell>
            </TH>
            <TH>
              <InnerCell>Type</InnerCell>
            </TH>
            <TH>{t('pages.accountManage.station.manageStation.location')}</TH>
            <TH>
              <InnerCell>{t('pages.accountManage.station.manageStation.address')}</InnerCell>
            </TH>
            <TH>
              <InnerCell>{t('pages.accountManage.station.manageStation.registerDate')}</InnerCell>
            </TH>
            <TH>{t('pages.accountManage.station.manageStation.ea')}</TH>
            <TH>{t('common.buttons.updateDelete')}</TH>
          </TR>
        </TableHead>
        <TableBody>
          {list.length > 0 &&
            list.map((item: IStation, index: number) => (
              <TR key={nanoid()}>
                <TD $customFont={typography.body4}>{totalCount - (currentPage - 1) * itemsPerPage - index}</TD>
                <TD $customFont={typography.body5} color={COLOR.BLUE4}>
                  <InnerCellContainer>
                    <InnerCellTextButton onClick={() => handleNavigateToDetail(item.seq)}>
                      {item?.name}
                    </InnerCellTextButton>
                  </InnerCellContainer>
                </TD>
                <TD $customFont={typography.body4} color={COLOR.BLUE4}>
                  <InnerCellContainer>
                    <InnerCellTextButton onClick={() => handleNavigateToDetail(item.seq)}>
                      {item?.code}
                    </InnerCellTextButton>
                  </InnerCellContainer>
                </TD>
                <TD $customFont={typography.body6}>
                  <InnerCellContainer>
                    {item?.station_type?.type_group} {`>`} {item?.station_type?.type_detail}
                  </InnerCellContainer>
                </TD>
                <TD $customFont={typography.body4}>{item?.kr_location?.name ?? ''}</TD>
                <TD $customFont={typography.caption1} color={COLOR.GRAY2}>
                  <InnerCellContainer>{`${item?.address} ${item?.address_detail}`}</InnerCellContainer>
                </TD>
                <TD $customFont={typography.body4}>{formatDate(item?.created_at)}</TD>
                <TD $customFont={typography.body4}>{item?.chargepoint_count}</TD>
                <TD>
                  <IconButtonsModifyDel
                    onModify={() => handleEdit(item?.seq)}
                    onDelete={() => {
                      handleDelete.popup(true);
                      handleDelete.setId(`${item?.seq}`);
                    }}
                  />
                </TD>
              </TR>
            ))}
        </TableBody>
      </Table>
      {list.length === 0 && (searchLocation || searchKeyword) && (
        <NoSearchResultWrapper>
          <Image src={icons.noSearchResult.image} style={{ width: 192, height: 192 }} />
          <NoSearchResultTextWrapper>
            <NoResultTextWrapper>
              <SearchKeyword>
                {searchLocation} {searchKeyword}
              </SearchKeyword>
              <NoResultText>{t('pages.infra.stationManage.noSearchResult')}</NoResultText>
            </NoResultTextWrapper>
            <NoResultDescription>{t('pages.infra.stationManage.noSearchResultDescription')}</NoResultDescription>
          </NoSearchResultTextWrapper>
        </NoSearchResultWrapper>
      )}
      {list.length === 0 && !searchLocation && !searchKeyword && (
        <NoSearchResultWrapper>
          <Image src={icons.noStations.image} style={{ width: 192, height: 192 }} />
          <NoSearchResultTextWrapper>
            <NoResultTextWrapper>
              <NoResultText>{t('pages.infra.stationManage.noStations')}</NoResultText>
            </NoResultTextWrapper>
            <NoResultDescription>{t('pages.infra.stationManage.noStationsDescription')}</NoResultDescription>
          </NoSearchResultTextWrapper>
          <Button
            icon={icon_plus_white}
            label={t('pages.infra.stationManage.register')}
            style={{ marginRight: 16 }}
            onClick={onRegister}
          />
        </NoSearchResultWrapper>
      )}
      {list.length > 0 && (
        <Pagination
          listLength={totalCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onClick={pageHandler}
        />
      )}
    </Wrapper>
  );
}

export default StationsTable;
