import { useAppDispatch } from 'model';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IResponse } from '@/lib/apis/api/type';
import { getRemoteHistory } from '@/lib/apis/run';
import { IRemoteDetails } from '@/lib/apis/run/type';
import remote, { actions } from '@/model/modules/remote';

import RemoteHistoryDetailPresenter from './RemoteHistoryDetail.presenter';
import { MENU } from '@/pages/router';

export default function RemoteHistoryDetailContainer() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fetchRemoteDetail = async () => {
    if (id) {
      const { isSuccess, data } = await getRemoteHistory(id);

      if (isSuccess) {
        dispatch(actions.setView(data));
      }
    }
  };

  const handleBackToList = () => {
    navigate(MENU.OPERATION.REMOTE_HISTORY);
  };

  useEffect(() => {
    fetchRemoteDetail();
  }, []);

  return <RemoteHistoryDetailPresenter handleBackToList={handleBackToList} />;
}
