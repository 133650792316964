import { API_HOST } from '@/config/index';
import { objectToQueryString } from '@/lib/index';
import api from '@/lib/apis/api';
import { ICalculate, ICalculateDetail, IPaymentStatusRes } from './type';
import { Paging } from '../infra/type';
import { IResponse } from '@/lib/apis/api/type';
import { IChargingStatus, IChargingStatusDetail } from '@/types/chargingStatus';
import { IPurchaseDetail } from '@/types/sales';

export const getPurchases = (payload: Paging) => {
  const param = objectToQueryString(payload);
  const url = `${API_HOST}/calculate/purchases?${param}`;
  return api.get({ url }) as unknown as IResponse<IPaymentStatusRes[]>;
};

export const getPurchase = async (seq: string): Promise<IResponse<IPurchaseDetail>> => {
  const url = `${API_HOST}/calculate/purchase/${seq}`;
  return api.get({ url });
};

export const getPurchasesExcel = (search: string, fileName: string) => {
  const url = `${API_HOST}/calculate/purchases/excel?search=${search}`;
  return api.get({ url, responseType: 'blob', fileName });
};

export const getTransactions = async (payload: Paging): Promise<IResponse<IChargingStatus[]>> => {
  const temp = { ...payload, size: 10 };
  const param = objectToQueryString(temp);
  const url = `${API_HOST}/transactions?${param}`;
  return api.get({ url });
};

export const getTransactionsExcelFile = async ({ payload, fileName }: { payload?: Paging; fileName: string }) => {
  const temp = { ...payload, size: 10 };
  const param = objectToQueryString(temp);
  const url = `${API_HOST}/transactions/list/excel?${param}`;
  return api.get({ url, responseType: 'blob', fileName });
};

export const getTransactionDetail = async (seq: string): Promise<IResponse<IChargingStatusDetail>> => {
  const url = `${API_HOST}/transactions/${seq}`;
  return api.get({ url });
};

interface IGetCalcualtes {
  size: number;
  page: string;
  year: string;
  month: string;
}
export const getCalculates = async (payload: IGetCalcualtes): Promise<IResponse<ICalculate[]>> => {
  const temp = { ...payload, size: 10 };
  const param = objectToQueryString(temp);
  const url = `${API_HOST}/calculate?${param}`;
  return api.get({ url });
};

interface IGetCalculateDetail extends IGetCalcualtes {
  type?: 1 | 2;
}

export const getCalculateDetails = async (payload: IGetCalculateDetail) => {
  const temp = { ...payload, size: 10 };
  const param = objectToQueryString(temp);
  const url = `${API_HOST}/calculate/day?${param}`;
  return api.get({ url }) as unknown as IResponse<ICalculateDetail[]>;
};
interface IGetCalculateDetailExcel {
  fileName: string;
  payload: Omit<IGetCalculateDetail, 'size' | 'page'>;
}
export const getCalculateDetailsExcel = async (args: IGetCalculateDetailExcel) => {
  const temp = { ...args.payload };
  const param = objectToQueryString(temp);
  const url = `${API_HOST}/calculate/excel?${param}`;
  return api.get({ url, responseType: 'blob', fileName: args.fileName });
};
interface IGetExcelFile {
  payload: Omit<IGetCalcualtes, 'size' | 'page'>;
  fileName: string;
}
export const getExcelFile = async (args: IGetExcelFile) => {
  const temp = { ...args.payload };
  const param = objectToQueryString(temp);
  const url = `${API_HOST}/calculate/excel?${param}`;
  return api.get({ url, responseType: 'blob', fileName: args.fileName });
};
