import styled from 'styled-components';
import { COLOR } from 'styles';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${COLOR.GRAY5};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderFuncWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 16px 16px 16px 24px;
  align-items: center;
  justify-content: space-between;
`;
export const FilterWrapper = styled.div`
  padding: 8px 32px;
`;
