/**
 * @name 급속타입
 * @description
 */
import {Text, uuid} from 'lib'
import {formatConnector} from 'pages/common'
import styled from 'styled-components'

type Props = {
  info: any[]
  onClick?: () => void
}
export default function ConnectorsType({info}: Props) {
  if (!info) return null
  // hooks
  //*--------------------------------------------------*
  return (
    <Element>
      {info.map((item, index) => {
        return (
          <p key={uuid()}>
            <Text className={'body4'}>{formatConnector(item?.types)}</Text>
          </p>
        )
      })}
    </Element>
  )
}

//*--------------------------------------------------*
const Element = styled.div`
  padding-left: 10px;
  text-align: left;
  /* padding: 24px 0 24px; */
`

/********************************************************
[사용법]

*********************************************************/
