/**
 * @name List
 * @description 리스트 나열
 */
import {uuid} from 'lib'
import React, {useState} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {CSS, RenderItem} from 'types'

type Props = {
  info: any[]
  selected?: number
  onChange?: (obj: any) => void
  renderItem?: RenderItem
  style?: CSS
}
export default function List({selected, style, onChange, renderItem, info}: Props) {
  // const
  const _style = Object.assign({}, style)
  const [select, setSelect] = useState(selected ?? 0)
  //*--------------------------------------------------*
  const makeElement = <T extends string>(value?: T) => {
    return info.map((item, index) => {
      return (
        <button
          className={`item ${select === index ? 'active' : ''}`}
          key={uuid()}
          onClick={() => {
            setSelect(index)
            !!onChange && onChange({item: item, index: index})
          }}>
          <label className="body4">{item?.label}</label>
        </button>
      )
    })
  }
  //*--------------------------------------------------*
  return (
    <Element style={_style}>
      {/* renderItem없을경우디버그 */}
      {renderItem === undefined && makeElement()}
      {!!renderItem &&
        !!info &&
        info.map((item, index) => {
          return (
            <button
              type="button"
              key={uuid()}
              className={`${select === index ? 'active' : ''}`}
              onClick={() => {
                setSelect(index)
                !!onChange && onChange({item: item, index: index})
              }}>
              {!!renderItem && renderItem({item: item, index: index})}
            </button>
          )
        })}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  /* renderItem없을경우기본디자인 */
  button {
    cursor: pointer;
  }
  .item {
    position: relative;
    display: flex;
    flex: 1;
    /* margin-left: 1px;
    margin-right: 1px; */
    padding: 8px 0;
    align-items: center;
    justify-content: center;
    justify-content: 'center';
    background-color: ${COLOR.WHITE};
    border-bottom: 1px solid ${COLOR.GRAY5};
    label {
      color: ${COLOR.GRAY3};
    }
    &.active label {
      color: ${COLOR.BLUE3};
    }
    &.active:after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      border-bottom: 2px solid ${COLOR.BLUE3};

      /* background-color: ${COLOR.RED1}; */
    }
  }
`
/********************************************************
[사용법]
  <Tab
    style={{ marginBottom: 30 }}
    info={ui_info}
    onChange={({ item, index }) => {
      console.log(index);
    }}
    renderItem={({ item, index }) => <span>{item?.label}</span>}
  />
  // 스타일적용
  button.active {
    color: red;
  }

<Tab selected={2} style={{ marginBottom: 30 }} info={ui_info}
    onChange={({ index }) => {
    console.log("index", index);
    }}
/>
*********************************************************/
