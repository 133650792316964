/**
 * @name Search
 * @description
 */
import React from 'react'
import {COLOR} from 'styles'
import styled from 'styled-components'
import {Text} from 'lib'
import {TextInput} from 'components/form'

export default function Contents() {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      <dl>
        <dt>장애 발생 일자</dt>
        <dd>
          <TextInput style={{width: 130}} placeholder="시작일자" defaultValue={''} name="name" />
          <Text label="-" className="center" style={{marginLeft: 10, marginRight: 10}} />
          <TextInput style={{width: 130}} placeholder="종료일자" defaultValue={''} name="name" />
        </dd>
      </dl>
      {/* --------- */}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  // styles
  dl {
    display: flex;
    flex: 1;
    height: 56px;
    border-bottom: 1px solid ${COLOR.GRAY5};
    align-items: center;
    /* 리스트타이틀 */
    dt {
      width: 200px;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.38px;
      color: ${COLOR.GRAY1};
    }
    dd {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      span.group {
        display: flex;
        padding: 0 8px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 24px;
      }
      label {
        display: inline-block;
        margin-left: 6px;
        color: ${COLOR.GRAY1};
      }
    }
  }
`
