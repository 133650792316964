import styled, { css } from 'styled-components';
import typography from '@/styles/typography';
import { COLOR } from '@/styles/index';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const PageButton = styled.button<{ $active: boolean }>`
  padding: 8px 0;
  width: 44px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${typography.body4};
  color: ${COLOR.GRAY1};
  background-color: ${COLOR.WHITE};

  &:hover {
    color: ${COLOR.BLUE4};
    background-color: ${COLOR.BLUE9};
  }
  &:focus {
    color: ${COLOR.BLUE1};
    background-color: ${COLOR.BLUE_GRAY9};
  }
  ${({ $active }) =>
    $active &&
    css`
      color: ${COLOR.WHITE};
      background-color: ${COLOR.BLUE4};
    `}
`;
