import { Panel } from 'components';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLOR } from 'styles';
import { useTranslation } from 'react-i18next';
import { ResponsivePie } from '@nivo/pie';
import TotalCount from '@/components/atoms/Labels/TotalCount';
import { actions } from '@/model/modules/dashboard';
import { useAppDispatch, useAppSelector } from '@/model/index';
import { getTroubles } from '@/lib/apis/dashborad';

/**
 * @name 장애 / 고장 / 통신이상 차트
 */
export default function FaultCountStats() {
  const { fault } = useAppSelector(state => state.dashboard);
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const [rate, setRate] = useState([
    { id: t('pages.dashboard.troubleChart.trouble'), value: 0 },
    { id: t('pages.dashboard.troubleChart.report'), value: 0 },
    { id: t('pages.dashboard.troubleChart.device'), value: 0 },
  ]);
  const [totalCases, setTotalCases] = useState(0);

  const fetchData = async () => {
    const { isSuccess, data } = await getTroubles();
    if (isSuccess) {
      dispatch(actions.setFault(data));
    }
  };

  const setPieData = () => {
    const { trouble_count, device_count, report_count } = fault;
    const total = trouble_count + device_count + report_count;
    setTotalCases(total);
    setRate([
      { id: t('pages.dashboard.troubleChart.trouble'), value: trouble_count },
      { id: t('pages.dashboard.troubleChart.report'), value: report_count },
      { id: t('pages.dashboard.troubleChart.device'), value: device_count },
    ]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setPieData();
  }, [fault]);

  return (
    <Panel mainTitle={t('pages.dashboard.troubleChart.title')}>
      <Content>
        <main>
          <TotalCount comma value={String(totalCases)} suffix={t('common.unit.cases')} />
          <div style={{ width: 250, height: 180, marginTop: 24 }}>
            <ResponsivePie
              data={rate}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              isInteractive
              margin={{ top: 0, right: 10, bottom: 60, left: 10 }}
              sortByValue
              innerRadius={0.8}
              padAngle={2}
              cornerRadius={10}
              colors={[COLOR.GREEN3, COLOR.BLUE4, COLOR.YELLOW3]}
              fit={false}
              activeOuterRadiusOffset={2}
              legends={[
                {
                  anchor: 'bottom',
                  direction: 'row',
                  justify: false,
                  translateX: 0,
                  translateY: 40,
                  itemsSpacing: 0,
                  itemWidth: 60,
                  itemHeight: 14,
                  itemTextColor: COLOR.BLACK3,
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: 10,
                  symbolShape: 'circle',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000',
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        </main>
      </Content>
    </Panel>
  );
}
const Content = styled.div`
  main {
    padding-top: 10px;
    /* overflow: hidden; */
  }
`;
