type DayCode = 'ALL' | 'SUN' | 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT';

type Status = 'ACTIVE' | 'SUSPEND';

export enum TariffUserType {
  NONE,
  USER,
  ROAMING,
}

export interface UpsertTariff {
  seq?: number; // patch , put에서만 사용
  type: TariffUserType; // 0 : 비회원 | 1 : 회원 | 2 : 로밍
  fast_amount: number; // decimal
  slow_amount: number; // decimal
  super_fast_amount: number; // decimal
  currency: string;
  day_code: DayCode;
  status: Status;
  apply_start_at?: string;
  apply_end_at?: string;
  roaming_code?: string; // default null
  roaming_name?: string; // default null
  start_kw?: number; // default null
  end_kw?: number; // default null
  price?: number; // default null
}

export interface GetTariffRes {
  seq: number; // 31,
  day_code: string; // "ALL",
  type: number; // 0 : 비회원 | 1 : 회원 | 2 : 로밍,
  super_fast_amount: string; // "0.0000",
  fast_amount: string; // "0.0000",
  slow_amount: string; // "0.0000",
  currency: string; // "KRW",
  apply_start_at: string; // null,
  apply_end_at: string; // null,
  status: string; // "ACTIVE",
  created_at: string; // "2024-03-31T21:19:27.000Z",
  created_by: number; // 33,
  suspended_at: string; // "1999-12-31T15:00:00.000Z",
  last_modified_at: string; // "2024-04-01T23:07:11.000Z",
  last_modified_by: number; // 33
  roaming_code?: string; // default null
  roaming_name?: string; // default null
  start_kw?: number; // default null
  end_kw?: number; // default null
  price?: number; // default null
}
