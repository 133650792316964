import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Image } from 'lib';
import { icons } from 'assets/images';
import { COLOR } from '@/styles/index';

type Props = {
  value?: boolean;
  style?: React.CSSProperties;
  onChange?: (value: boolean) => void;
};
export default function FilterButton({ onChange, value, style }: Props) {
  const [checked, setChecked] = useState<boolean>(value ?? false);
  useEffect(() => {
    if (value) {
      setChecked(value);
    }
  }, [value]);
  return (
    <Content
      $isActive={checked}
      style={style}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        if (onChange) {
          onChange(!checked);
        }
        setChecked(val => !val);
        event.currentTarget.blur();
      }}>
      <Image
        src={checked ? icons.filterActive.image.default : icons.filterDefault.image.default}
        style={{ width: 20, height: 20 }}
      />
    </Content>
  );
}
const Content = styled.button<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${COLOR.BLUE4};
  box-sizing: border-box;

  ${({ $isActive }) =>
    $isActive
      ? css`
          background-color: ${COLOR.BLUE4};
        `
      : css`
          &:hover {
            background-color: ${COLOR.BLUE8};
          }
          &:focus {
            background-color: ${COLOR.BLUE7};
          }
        `}
`;
