/**
 * @name Search
 * @description
 */
import React from 'react';
import styled from 'styled-components';
import { Text, debug } from 'lib';
import {
  CheckboxGroup,
  FilterButtonGroup,
  ListKeyComponent,
  RadioGroup,
  TextFiledSearch,
  TextInput,
} from 'components/form';
import { Store, _action } from '../../';
import { useAppDispatch } from 'model';

export default function Contents() {
  // hooks
  const dispatch = useAppDispatch();
  const { search_payload } = Store();
  //*--------------------------------------------------*
  return (
    <Content>
      {/* {debug(search_payload)} */}
      <ListKeyComponent
        title="동시 충전"
        component={
          <CheckboxGroup
            info={[
              { label: '동시충전', value: true, checked: false },
              { label: '단독충전', value: false, checked: false },
            ]}
            onValue={data => {
              // const _payload = {is_concurrency: data}
              // Events.emit(_event.FETCH_LIST, Object.assign({}, search_payload, _payload))
              // dispatch(_action.setSearchPayload(_payload))
            }}
          />
        }
      />
      <ListKeyComponent
        title="충전기 구분"
        component={
          <RadioGroup
            info={[
              { label: '급속', value: 'FAST', checked: false },
              { label: '완속', value: 'SLOW', checked: false },
            ]}
            onValue={data => {
              const _payload = { charger_type: data[0] };
              // Events.emit(_event.FETCH_LIST, Object.assign({}, search_payload, _payload))
              // dispatch(_action.setSearchPayload(_payload))
            }}
          />
        }
      />
      <ListKeyComponent
        title="커넥터 타입"
        component={
          <CheckboxGroup
            info={[
              { label: 'DC콤보', value: 'CCS1', checked: false },
              { label: 'AC상', value: '3PHASE', checked: false },
              { label: 'GBT', value: 'GBT', checked: false },
              { label: 'AC완속', value: 'J1772', checked: false },
            ]}
            onValue={data => {
              // const _payload = {connector_type: data}
              // Events.emit(_event.FETCH_LIST, Object.assign({}, search_payload, _payload))
              // dispatch(_action.setSearchPayload(_payload))
            }}
          />
        }
      />
      <ListKeyComponent
        title="장애 여부"
        component={
          <CheckboxGroup
            info={[
              { label: '장애', value: 'Y', checked: false },
              { label: '정상', value: 'N', checked: false },
            ]}
            onValue={data => {
              // const _payload = {is_error: data}
              // Events.emit(_event.FETCH_LIST, Object.assign({}, search_payload, _payload))
              // dispatch(_action.setSearchPayload(_payload))
            }}
          />
        }
      />
      <ListKeyComponent
        title="커넥터 타입"
        component={
          <React.Fragment>
            <TextInput style={{ width: 130 }} placeholder="최소금액" defaultValue={''} name="name" />
            <Text label="-" className="center" style={{ marginLeft: 10, marginRight: 10 }} />
            <TextInput style={{ width: 130 }} placeholder="최대금액" defaultValue={''} name="name" />
          </React.Fragment>
        }
      />
      <ListKeyComponent
        title="ID Tag 로 검색"
        component={
          <TextFiledSearch
            style={{ width: 430 }}
            onChange={data => {
              // console.log(data)
            }}
            placeholder="ID Tag로 검색해 주세요.."
          />
        }
      />
      <ListKeyComponent
        title="결과내 검색 조건"
        component={
          <FilterButtonGroup
            onChange={data => {
              // console.log(data)
            }}
            info={['검색어테스트', 'TEST_TEST', '1111']}
          />
        }
      />
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div``;
