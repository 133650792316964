/**
 * @name Header
 * @description
 */
import {arrow_next} from 'assets/svg'
import styled from 'styled-components'
import {COLOR} from 'styles'
export default function Contents() {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      <div className="wrap">
        <h1>Headedsdsdr</h1>
        <img src={arrow_next} />
      </div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  // styles
  padding: 20px 20px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  background-color: ${COLOR.WHITE};
`
