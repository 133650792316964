import React from 'react';
import { ButtonSize, PaginationFunctionButtonProps } from '@/components/atoms/Buttons/Buttons.types';
import { Button, ChevronLeftImageBox, ChevronRightImageBox } from './PaginationFunctionButton.style';

function PaginationFunctionButton({
  onClick,
  size = ButtonSize.L,
  title,
  disabled = false,
  direction,
}: PaginationFunctionButtonProps) {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick();
    }
    event.currentTarget.blur();
  };

  return (
    <Button $size={size} onClick={handleClick} disabled={disabled} $direction={direction}>
      {direction === 'left' && <ChevronLeftImageBox />}
      {title}
      {direction === 'right' && <ChevronRightImageBox />}
    </Button>
  );
}
export default PaginationFunctionButton;
