import React from 'react';
import styled from 'styled-components';
import { H5, Text } from 'lib';
import { COLOR, layout } from 'styles';
import { Layer } from 'components';

type Props = {
  title?: string;
  desc?: string;
  visible?: boolean;
  isContent?: boolean;
  setVisible?: (bool: boolean) => void | undefined;
  children: React.ReactNode;
};

/**
 * @name 패널
 * @description
 */
export default function Panel({ title, desc, children, isContent = true, visible, setVisible }: Props) {
  return (
    <Layer visible={visible} setVisible={setVisible}>
      <Content>
        <article className={`${layout.panel}`}>
          <div className="wrap">
            {!!title && <H5>{title}</H5>}
            {!!desc && <Text className="caption1">{desc}</Text>}
            <section className={isContent ? 'content' : ''}>{children}</section>
          </div>
        </article>
      </Content>
    </Layer>
  );
}

const Content = styled.div`
  // styles
  position: relative;
  /* article */
  > article {
    position: absolute;
    z-index: 5;
    top: 10px;
    left: 0;
    min-width: 290px;
    /* wrap */
    > .wrap {
      display: flex;
      flex-direction: column;
      padding: 16px;
      border-radius: 8px;
      color: ${COLOR.WHITE};
      background-color: ${COLOR.GRAY1};
      p.title {
        display: block;
      }
      > .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 16px;
        padding: 16px;
        border-radius: 8px;
        background-color: ${COLOR.WHITE};
      }
    }
  }
`;
