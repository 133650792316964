import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLOR } from 'styles';
import { Events, Table, uuid } from 'lib';
import { ListRenderItem } from 'types';
import { Pagination } from 'components/form';
import { MENU } from '@/pages/router';
import { formatDay } from 'pages/common';
import { useTranslation } from 'react-i18next';
import { Store, _event, _mode } from '../../index';
/**
 * @name List
 * @description 가이드페이지
 */
export default function Contents() {
  const { t } = useTranslation('common');
  const { list } = Store();

  return (
    <Content>
      {/* {debug(list)} */}
      <Table
        style={{ marginBottom: 30 }}
        info={list?.data}
        renderHeader={() => {
          return (
            <>
              <thead>
                <tr key={uuid()}>
                  <th className="inner l">No.</th>
                  <th className="inner l">{t('forms.model.chargerName')}</th>
                  <th className="inner l">{t('forms.history.failedTarget')}</th>
                  <th className="inner l">ID</th>
                  <th className="inner l">{t('forms.history.status')}</th>
                  <th className="inner l">{t('forms.history.level')}</th>
                  <th className="inner l">{t('forms.history.failedNameOCPP')}</th>
                  <th className="inner l">{t('forms.history.failedCodeManufacturer')}</th>
                  <th className="inner l">{t('forms.history.failedType')}</th>
                  <th className="inner l">{t('forms.history.failedNameManufacturer')}</th>
                  <th className="inner l">{t('forms.history.failedDate')}</th>
                </tr>
              </thead>
              <colgroup>
                <col style={{ width: 30 }} />
                <col style={{ width: '*' }} />
                <col style={{ width: 150 }} />
                <col style={{ width: 100 }} />
                <col style={{ width: 100 }} />
                <col style={{ width: 100 }} />
                <col style={{ width: 140 }} />
                <col style={{ width: 180 }} />
                <col style={{ width: 100 }} />
                <col style={{ width: 100 }} />
                <col style={{ width: 100 }} />
              </colgroup>
            </>
          );
        }}
        renderItem={({ item, index }: ListRenderItem) => {
          return (
            <tr key={uuid()}>
              <td>{item?.seq}</td>
              <td className="l">
                <Link to={`${MENU.DISORDER_HISTORY}/${item?.seq}`}>{item?.chargepoint?.name}</Link>
              </td>
              <td className="l">
                <Link to={`${MENU.DISORDER_HISTORY}/${item?.seq}`}>{item?.chargepoint_code}</Link>
              </td>
              <td className="l">{item?.chargepoint?.code}</td>
              <td className="l">{item?.chargepoint?.status}</td>
              <td className="l">{item?.remote_seq}</td>
              <td className="l">{item?.request_message?.type}</td>
              <td className="l">{item?.response_type ?? '-'}</td>
              <td className="l">{item?.remote?.action?.action_name}</td>
              <td className="l">{formatDay(item?.chargepoint?.activated_at)}</td>
              <td className="l">{formatDay(item?.chargepoint?.created_at)}</td>
            </tr>
          );
        }}
      />
      <Pagination
        info={list?.meta?.paging}
        onChange={(num: any) => {
          Events.emit(_event.FETCH_LIST, { page_num: num });
        }}
      />
    </Content>
  );
}

const Content = styled.div`
  padding: 16px;
  /* 테이블 */
  table {
    td:nth-child(2) a,
    td:nth-child(3) a {
      color: ${COLOR.BLUE3};
    }
  }
`;
