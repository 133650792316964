/**
 * @name Popup
 * @todo
 */
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useAppDispatch} from 'model'
import {Events, useRoutes} from 'lib'
import {PopupContainer, PopupCreate, SubmitCancel} from 'components/form'

// contents
import {_action, _event, _popup} from '../../index'

export default function Contents() {
  // hooks
  const {navigate} = useRoutes()
  const dispatch = useAppDispatch()
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('')
  //*--------------------------------------------------*
  // 레이어팝업실행,설정
  const onSetPopup = (type: string, data?: any) => {
    setMode(type)
    setVisible(true)
  }

  // 레이어팝업닫기
  const onRemovePopup = () => {
    setVisible(false)
  }
  // 초기화
  const onInit = () => {
    onRemovePopup()
    dispatch(_action.setInit())
  }
  // 컨텐츠분기
  const setContents = () => {
    switch (mode) {
      case _popup.CANCEL_CONFIRM: //-----------------------------------펌웨어 파일 등록 취소
        return PopupCreate({
          warning: true,
          title: '펌웨어 파일 등록 취소',
          message: '펌웨어 파일을 등록하지 않고 취소하시겠습니까?',
          footer: (
            <SubmitCancel
              onCancel={() => {
                onRemovePopup()
              }}
              onConfirm={() => {
                onInit()
              }}
            />
          ),
        })
      case _popup.REGISTER_SUCCESS: //-----------------------------------충전소등록취소
        return PopupCreate({title: '충전소 등록', message: '충전소 등록을 성공했습니다.'})
      case _popup.REGISTER_FAIL: //-----------------------------------충전소등록취소
        return PopupCreate({title: '충전소 등록 실패', message: '충전소 등록을 성공했습니다.'})
    }
  }
  //*--------------------------------------------------*
  useEffect(() => {
    Events.addListener(_event.POPUP_SHOW, onSetPopup)
    Events.addListener(_event.POPUP_HIDE, onRemovePopup)
    return () => {
      Events.removeListener(_event.POPUP_SHOW, onSetPopup)
      Events.removeListener(_event.POPUP_HIDE, onRemovePopup)
    }
  }, [])
  //*--------------------------------------------------*
  return (
    <Content>
      <PopupContainer visible={visible} onClose={() => onRemovePopup()}>
        {setContents()}
      </PopupContainer>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div``
