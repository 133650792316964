import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'model';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { actions } from '@/model/modules/admin';
import { getAdminAccountDetail, getAdminAccounts, inviteAccount, modifyPermissions, reInvite } from '@/lib/apis/admin';
import { AdminAccountsRequest } from '@/lib/apis/admin/type';
import { AdminAccountsStatusType, IAssignMenu } from '@/types/admin';
import AccountManagementModifyPresenter from './AccountManagementModify.presenter';
import { SCREEN_MODE } from '@/types/mode';
import { MENU } from '@/pages/router';

/**
 * @name AccountManagementModifyContainer
 * @description 계정별 권한관리 수정
 */
export default function AccountManagementModifyContainer() {
  const { accountDetail, invitePayload } = useAppSelector(state => state.admin);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);
  const searchQueryString = searchParams.get('search') || '';
  const [searchKeyword, setSearchKeyword] = useState(searchQueryString);
  const [appliedSearchKeyword, setAppliedSearchKeyword] = useState(searchQueryString);

  const [popupSuccessVisible, setPopupSuccessVisible] = useState(false);
  const [popupFailedVisible, setPopupFailedVisible] = useState(false);

  // 초기화
  const onInit = () => {
    dispatch(actions.setMode(SCREEN_MODE.MODIFY));
    fetchData();
  };

  const fetchData = async () => {
    if (id) {
      const { isSuccess, data } = await getAdminAccountDetail(id);
      if (isSuccess) {
        dispatch(actions.setAccountDetailData(data));
      }
    }
  };

  const handleBackToList = () => {
    // navigate(`${MENU.SALES.CHARGING_STATUS}?${queryParamsURL()}`);
    navigate(MENU.ADMIN.ACCOUNT_MANAGEMENT);
  };

  const handleUpdateSubmit = async () => {
    const payloadAssignUpdate = invitePayload.assign_menu.map((item: IAssignMenu) => {
      return {
        ...item,
        cpo_seq: id,
        admin_seq: id,
      };
    });
    try {
      const submitResponse = await modifyPermissions(payloadAssignUpdate);
      if (submitResponse.isSuccess) {
        setPopupSuccessVisible(true);
      }
    } catch (e) {
      setPopupFailedVisible(true);
    }
  };

  const handleDone = () => {
    // dispatch(actions.initPayload());
    setPopupSuccessVisible(false);
    navigate(`${MENU.ADMIN.ACCOUNT_MANAGEMENT_DETAIL}/${id}`);
  };

  const handleCloseFailedPopup = () => {
    setPopupFailedVisible(false);
  };

  useEffect(() => {
    onInit();
  }, []);

  return (
    <AccountManagementModifyPresenter
      handleBackToList={handleBackToList}
      popupSuccessVisible={popupSuccessVisible}
      handleDone={handleDone}
      handleUpdate={handleUpdateSubmit}
      handleCloseFailedPopup={handleCloseFailedPopup}
      popupFailedVisible={popupFailedVisible}
    />
  );
}
