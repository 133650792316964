import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'model';
import { useNavigate, useLocation } from 'react-router-dom';
import PaymentStatusPresenter from './PaymentStatus.presenter';
import { Paging } from '@/lib/apis/infra/type';
import { getPurchases, getPurchasesExcel } from '@/lib/apis/sales';
import { actions } from '@/model/modules/sales';

export default function PaymentStatusContainer() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const searchQueryString = searchParams.get('search') || '';

  const [currentPage, setCurrentPage] = useState(Number(page) || 1);
  const [searchKeyword, setSearchKeyword] = useState(searchQueryString);

  const itemsPerPage = 10;

  const queryParamsURL = (page?: number, search?: string) => {
    const word = search ?? searchKeyword;
    const queryParams = [];
    if (searchKeyword) {
      queryParams.push(`search=${encodeURIComponent(word)}`);
    }
    queryParams.push(`page=${page ?? currentPage}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const fetchList = async (payload: Paging) => {
    const response = await getPurchases(payload);
    if (response.isSuccess) {
      dispatch(
        actions.setList({
          list: response.data,
          totalCount: response.meta.paging?.total_count ?? NaN,
        }),
      );
    }
  };

  const pageHandler = (page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      navigate(`?${queryParamsURL(page)}`);
      fetchList({
        size: itemsPerPage,
        page,
        search: searchKeyword,
      });
    }
  };

  const handleSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const handleSearch = () => {
    navigate(`?${queryParamsURL(1, searchKeyword)}`);
    fetchList({
      size: itemsPerPage,
      page: 1,
      search: searchKeyword,
    });
  };
  const handleExcelDownload = async () => {
    await getPurchasesExcel(searchKeyword, `결제 현황_${dayjs().format('YYYY_MM_DD')}.xlsx`);
  };

  useEffect(() => {
    fetchList({
      size: itemsPerPage,
      page: currentPage,
    });
  }, []);

  useEffect(() => {
    setCurrentPage(Number(page));
  }, [page]);
  return (
    <PaymentStatusPresenter
      currentPage={currentPage}
      itemsPerPage={itemsPerPage}
      pageHandler={pageHandler}
      queryString={queryParamsURL()}
      handleExcelDownload={handleExcelDownload}
      handleSearch={handleSearch}
      handleSearchKeyword={handleSearchKeyword}
      searchKeyword={searchKeyword}
    />
  );
}
