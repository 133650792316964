import { useAppDispatch } from 'model';
import ErrorCodeListPresenter from './ErrorCodeList.presenter';
import { useEffect, useState } from 'react';
import { getErrorCodeList } from '@/lib/apis/infra';
import { Paging } from '@/lib/apis/infra/type';
import { actions } from '@/model/modules/errorcode';
import { useLocation, useNavigate } from 'react-router-dom';
import { getExcel } from '@/pages/common';
import { API_HOST } from '@/config/index';

export default function ErrorCodeListContainer() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);
  const searchQueryString = searchParams.get('search') || '';
  const [searchKeyword, setSearchKeyword] = useState(searchQueryString);
  const [appliedSearchKeyword, setAppliedSearchKeyword] = useState(searchQueryString);
  const fetchList = async (paging: Paging) => {
    if (!paging.search) delete paging.search;
    const { isSuccess, data, meta } = await getErrorCodeList(paging);
    if (isSuccess)
      dispatch(
        actions.setList({
          list: data,
          totalCount: meta.paging?.total_count ?? NaN,
        }),
      );
  };
  const pageHandler = (page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      navigate(`?page=${page}`);
    }
  };

  const handleExcelDownload = async () => {
    await getExcel(`${API_HOST}/trouble-code/cpo/excel?search=${appliedSearchKeyword}`);
  };

  const handleSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };
  const handleSearch = () => {
    setAppliedSearchKeyword(searchKeyword);
    fetchList({
      size: itemsPerPage,
      search: searchKeyword,
      page: currentPage,
    });
  };
  useEffect(() => {
    fetchList({
      size: itemsPerPage,
      page: currentPage,
      search: searchKeyword,
    });
  }, [page]);
  return (
    <ErrorCodeListPresenter
      itemsPerPage={itemsPerPage}
      currentPage={currentPage}
      pageHandler={pageHandler}
      handleExcelDownload={handleExcelDownload}
      searchKeyword={searchKeyword}
      handleSearchKeyword={handleSearchKeyword}
      handleSearch={handleSearch}
    />
  );
}
