import React from 'react';
import styled from 'styled-components';
import { COLOR } from 'styles';
import { Button, H1, H3, Image, Text, useRoutes } from 'lib';
import { MENU } from '@/pages/router';
import { error_404_img, icon_home_white } from 'assets/images';
import { FullPage } from 'components';
import { useTranslation } from 'react-i18next';

/**
 * @name Error
 * @description 에러페이지
 */
export default function Presenter() {
  const { t } = useTranslation('common');
  const { navigate } = useRoutes();
  return (
    <FullPage>
      <Content>
        <header>
          <Image src={error_404_img} style={{ width: 144, height: 108, marginBottom: 88 }} />
          <H1 color={COLOR.GRAY1}>{t('pages.error.404.sentence1')}</H1>
          <H1 style={{ marginBottom: 8 }} color={COLOR.GRAY1}>
            {t('pages.error.404.sentence2')}
          </H1>
          <Text center className="body3" color={COLOR.GRAY2}>
            {t('pages.error.404.sentence3')}
            <br />
            {t('pages.error.404.sentence4')}
          </Text>
          <Text className="body3" color={COLOR.GRAY2} style={{ marginTop: 5, marginBottom: 48 }}>
            {t('pages.error.code', { code: 404 })}
          </Text>
          <Button
            icon={icon_home_white}
            iconStyle={{ width: 24, height: 24 }}
            style={{ height: 48, fontSize: 15 }}
            label={t('common.buttons.home')}
            onClick={() => {
              navigate(MENU.DASHBOARD);
            }}
          />
        </header>
      </Content>
    </FullPage>
  );
}
const Content = styled.div`
  display: flex;
  self-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* header */
  header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
