/**
 * @name RadioGroup
 * @description 체크박스그룹
 */
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {CSS} from 'types'
import {Image, uuid} from 'lib'
import {icon_radio_off, icon_radio_on} from 'assets/images'
import {COLOR} from 'styles'

type Props = {
  info?: any
  label?: string
  style?: CSS
  onChange?: (value: any) => void
  onValue?: (value: any) => void
  component?: React.ReactNode //------------------ children
}
export default function RadioGroup({info, onChange, style, onValue}: Props) {
  // hooks
  const [state, setState] = useState(info) as any
  //*--------------------------------------------------*
  useEffect(() => {
    !!onChange && onChange(state)
    if (!!onValue) {
      const _temp = [] as any
      state?.map((item: any) => {
        if (item?.checked === true) _temp.push(item?.value)
      })
      // 체크된게없으면 리턴
      if (!_temp[0]) return
      onValue(_temp)
    }
  }, [state])
  //*--------------------------------------------------*
  return (
    <Element style={style}>
      {state &&
        state.map((item: any, index: number) => {
          return (
            <button
              className="item"
              key={uuid()}
              onClick={() => {
                const _update = state?.map((ele: any, idx: number) => {
                  if (ele?.label === item?.label) {
                    return {...ele, checked: true}
                  } else {
                    return {...ele, checked: false}
                  }
                })
                setState(_update)
              }}>
              {/* {disabled && <Image src={icon_radio_disabled} style={{width: 18, height: 18}} />} */}
              <Image src={item?.checked ? icon_radio_on : icon_radio_off} style={{width: 18, height: 18}} />
              <label>{item?.label ?? '라디오박스'}</label>
            </button>
          )
        })}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  /* 아이템 */
  button.item {
    display: flex;
    min-width: 120px;
    /* margin-right: 36px; */
    align-items: center;
    flex-direction: row;
  }
  /* 라벨 */
  label {
    display: inline-block;
    margin-left: 6px;
    color: ${COLOR.GRAY1};
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.53px;
  }
`
/********************************************************
[사용법]

  <ListKeyComponent
    title="충전기 구분"
    component={
      <RadioGroup
        info={[
          {label: '급속', value: 'a', checked: true},
          {label: '완속', value: 'b', checked: false},
        ]}
        onChange={data => {
          // dispatch(_action.setSearchPayload({charger_type: ['FAST', 'SLOW'][data]}))
        }}
        onValue={data => {
          console.log(data)
        }}
      />
    }
  />

*********************************************************/
