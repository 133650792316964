/**
 * @name 폼입력
 * @description
 */
import {ChangeEvent} from 'react'
import styled from 'styled-components'
import {Button, DropDown, Textarea} from 'lib'
import {TextFiledGroup, TextInput} from 'components/form'

export default function Contents() {
  // hooks
  //*--------------------------------------------------*
  // onChangeHandler
  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {}
  //*--------------------------------------------------*
  return (
    <Content className="inc_form" style={{width: 400}}>
      <TextFiledGroup required label="이름" component={<TextInput placeholder={''} defaultValue={''} name="max_power_kw" onChange={onChangeHandler} />} />
      <TextFiledGroup required label="전화번호" component={<TextInput placeholder={''} defaultValue={''} name="max_power_kw" onChange={onChangeHandler} />} />
      <TextFiledGroup required label="이메일" component={<TextInput placeholder={''} defaultValue={''} name="max_power_kw" onChange={onChangeHandler} />} />
      {/* 설치 시설 유형 */}
      <TextFiledGroup
        style={{marginBottom: 0}}
        label="설치 시설 유형"
        component={
          <DropDown
            info={[
              {label: '라벨1', value: 'num1'},
              {label: '라벨2', value: 'num2'},
            ]}
          />
        }
      />
      <Textarea value="" style={{marginTop: 5, marginBottom: 40}} />
      <Button full label="문의 하기" />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.main``
