import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'model';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { actions } from '@/model/modules/admin';
import { getAdminAccountDetail, getAdminAccounts, reInvite } from '@/lib/apis/admin';
import { AdminAccountsRequest } from '@/lib/apis/admin/type';
import { AdminAccountsStatusType } from '@/types/admin';
import AccountManagementDetailPresenter from './AccountManagementDetail.presenter';
import { SCREEN_MODE } from '@/types/mode';
import { MENU } from '@/pages/router';

/**
 * @name AccountManagementDetailContainer
 * @description 계정별 권한관리
 */
export default function AccountManagementDetailContainer() {
  const { accountDetail } = useAppSelector(state => state.admin);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);
  const searchQueryString = searchParams.get('search') || '';
  const [searchKeyword, setSearchKeyword] = useState(searchQueryString);
  const [appliedSearchKeyword, setAppliedSearchKeyword] = useState(searchQueryString);

  const [reInviteSuccessPopup, setReInviteSuccessPopup] = useState(false);
  const [reInviteFailPopup, setReInviteFailPopup] = useState(false);

  // 초기화
  const onInit = () => {
    dispatch(actions.setMode(SCREEN_MODE.VIEW));
    fetchData();
  };

  const fetchData = async () => {
    if (id) {
      const { isSuccess, data } = await getAdminAccountDetail(id);
      if (isSuccess) {
        dispatch(actions.setAccountDetailData(data));
      }
    }
  };

  const handleBackToList = () => {
    // navigate(`${MENU.SALES.CHARGING_STATUS}?${queryParamsURL()}`);
    navigate(MENU.ADMIN.ACCOUNT_MANAGEMENT);
  };

  const handleReInvite = async () => {
    try {
      const reInviteResponse = await reInvite({ invite_email: accountDetail.email });
      if (reInviteResponse.isSuccess) {
        setReInviteSuccessPopup(true);
      }
    } catch (e) {
      setReInviteFailPopup(true);
      console.error(e);
    }
  };

  const onCloseReInviteSuccessPopup = () => {
    setReInviteSuccessPopup(false);
  };
  const onCloseReInviteFailPopup = () => {
    setReInviteFailPopup(false);
  };

  const handleEditButton = () => {
    dispatch(actions.setMode(SCREEN_MODE.MODIFY));
    navigate(`${MENU.ADMIN.ACCOUNT_MANAGEMENT_UPDATE}/${id}`);
  };

  useEffect(() => {
    onInit();
  }, []);

  return (
    <AccountManagementDetailPresenter
      handleBackToList={handleBackToList}
      handleReInvite={handleReInvite}
      handleEditButton={handleEditButton}
      reInvitePopup={{
        successState: reInviteSuccessPopup,
        failState: reInviteFailPopup,
        successPopupClose: onCloseReInviteSuccessPopup,
        failPopupClose: onCloseReInviteFailPopup,
      }}
    />
  );
}
