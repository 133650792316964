import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ImageButton } from 'lib';
import { icon_arrow_down_black, icon_arrow_down_gray, icon_arrow_up_black, icon_arrow_up_gray } from 'assets/images';
import { SortType } from '@/lib/apis/infra/type';

type Props = {
  value?: 'DESC' | 'ASC' | ''; // ----------------------------- 초기값설정
  style?: React.CSSProperties; // ----------------- StyleSheet
  onChange?: (str: SortType) => void;
  [key: string]: any;
};

/**
 * @name SortButton
 */
export default function SortButton({ onChange, value, style }: Props) {
  const [_value, setValue] = useState<string>('');

  useEffect(() => {
    if (value) {
      setValue(value);
    }
  }, [value]);

  return (
    <Content style={style}>
      <ImageButton
        source={_value === 'ASC' ? icon_arrow_up_black : icon_arrow_up_gray}
        style={{ width: 18, height: 18 }}
        buttonStyle={{ width: 18, height: 18 }}
        onClick={() => {
          if (onChange) {
            onChange('ASC');
          }
          setValue('ASC');
        }}
      />
      <ImageButton
        source={_value === 'DESC' ? icon_arrow_down_black : icon_arrow_down_gray}
        style={{ width: 18, height: 18 }}
        buttonStyle={{ width: 18, height: 18 }}
        onClick={() => {
          if (onChange) {
            onChange('DESC');
          }
          setValue('DESC');
        }}
      />
    </Content>
  );
}
const Content = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

/** ******************************************************
사용법
<SortButton value={value} onChange={data => console.log(data)} />

******************************************************** */
