/**
 * @name 모델관리
 * @description
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SCREEN_MODE } from '@/types/mode';
import { IChargePointModelDetail, ReqModel } from '@/types/infra';
// 모드변경 _mode
export const MODE = {
  DEFAULT: 'DEFAULT',
  LIST: 'LIST',
  VIEW: 'VIEW',
  MODIFY: 'MODIFY',
  REGISTER: 'REGISTER',
  EMPTY: 'EMPTY',
};
// 팝업 _popup
export const POPUP = {
  SEND_MAIL_SUCCESS: 'SEND_MAIL_SUCCESS', // 메일발송
  SEND_MAIL_FAIL: 'SEND_MAIL_FAIL', // 메일발송실패
  CONFIRM_DELETE: 'CONFIRM_DELETE', // 삭제확인
};
// 이벤트 _event
export const EVENT = {
  // submit
  SUBMIT: 'SUBMIT',
  UPDATE: 'UPDATE',
  // 레이어팝업
  POPUP_SHOW: 'POPUP_SHOW',
  POPUP_HIDE: 'POPUP_HIDE',
  POPUP_SET: 'POPUP_SET',
  // CRUD
  SUBMIT_CREATE: 'SUBMIT_CREATE', // 등록하기
  FETCH_LIST: 'FETCH_LIST', // 리스트데이터요청
  FETCH_VIEW: 'FETCH_VIEW', // 상세데이터요청
  DELETE: 'DELETE', // 삭제하기
  MODIFY: 'MODIFY', // 수정하기
};
//* --------------------------------------------------*
/** @defineType */
type StateType = {
  mode: string;
  duplicatedCheck: {
    name: boolean;
  };
  payload: ReqModel;
  view: IChargePointModelDetail;
  data: {};
  search_payload: {
    isOpen: boolean;
  }; // ---------------------검색조건 및 상세검색조건
  totalCount: number;
  list: any;
  [key: string]: any;
};
//* --------------------------------------------------*
export const sliceName = 'template'; // 변경필요
/** @initialState */
const initialState: StateType = {
  mode: SCREEN_MODE.EMPTY,
  duplicatedCheck: {
    name: true,
  },
  view: {
    seq: '',
    pg: '',
    name: '',
    charger_type: 'FAST',
    is_concurrency: false,
    max_power_kw: '',
    connector_count: '',
    max_voltage: '',
    max_ampare: '',
    efficiencies: '',
    ipx_level: '',
    trouble_version: '',
    firmware_version: '',
    status: '',
    created_at: '',
    created_by: '',
    suspended_at: '',
    last_modified_at: '',
    last_modified_by: '',
    vendor: {
      seq: '',
      cpo_seq: '',
      vendor_id: '',
      ocpp_vendor_id: '',
      name: '',
      country_code2: '',
      country_code_2: '',
      post_code: '',
      address: '',
      address_detail: '',
      phone: '',
      status: '',
      created_at: '',
      created_by: '',
      suspended_at: '',
      last_modified_at: '',
      last_modified_by: '',
      fax: '',
      model_count: '',
    },
  },
  payload: {
    name: '',
    vendorSeq: '',
    chargerType: 'FAST',
    isConcurrency: false,
    maxPowerKw: '',
    connectorCount: '',
    maxVoltage: '',
    maxAmpare: '',
    efficiencies: '',
    ipxLevel: '',
    connectors: [],
  },
  search_payload: {
    isOpen: false,
  }, // ---------------------검색조건 및 상세검색조건
  data: {},
  list: [],
  totalCount: 0,
};
//* --------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //* --------------------------------------------------*
    // 초기화
    setInit(state: StateType) {
      // state.mode = 'DEFAULT'
      Object.assign(state, initialState);
    },
    // setMode
    setMode(state: StateType, action: PayloadAction<any>) {
      state.mode = action.payload;
    },
    setDuplicatedCheck: (state: StateType, action: PayloadAction<{ name?: boolean }>) => {
      state.duplicatedCheck = { ...state.duplicatedCheck, ...action.payload };
    },
    // fetch 리스트
    setList(state: StateType, action: PayloadAction<any>) {
      state.list = action.payload.list;
      state.totalCount = action.payload.totalCount;
    },
    // fetch view
    setView(state: StateType, action: PayloadAction<IChargePointModelDetail>) {
      state.view = action.payload;
    },
    initView(state: StateType) {
      state.view = initialState.view;
    },
    initPayload(state: StateType) {
      state.payload = initialState.payload;
    },
    // create Payload업데이트
    setPayload(state: StateType, action: PayloadAction<ReqModel>) {
      state.payload = Object.assign(state.payload, action.payload);
    },
    // 검색및 상세검색조건 업데이트
    setSearchPayload(state: StateType, action: PayloadAction<any>) {
      state.search_payload = Object.assign(state.search_payload, action.payload);
    },
  },
});
//* --------------------------------------------------*
export default slice.reducer;
export const nameSpace = sliceName;
export const { actions } = slice;
