/**
 * @name ListTable
 * @description 테이블형리스트
 */
import React from 'react'
import {COLOR} from 'styles'
import styled from 'styled-components'
import {ClassName, CSS} from 'types'

type Props = {
  children?: React.ReactNode
  thead?: React.ReactNode
  style?: CSS
  itemStyle?: CSS
  className?: ClassName // 클래스적용
  empty?: React.ReactNode // 데이터가 없을때
  disabled?: boolean // hover시 배경색
}
export default function ListTable({style, children, thead, empty}: Props) {
  // const
  const _style = Object.assign({}, style)
  //*--------------------------------------------------*
  const createThead = () => {
    return (
      <thead>
        <tr>
          <th>No.</th>
          <th>고객유형</th>
          <th>커넥터ID</th>
          <th>결제유형</th>
          <th>카드사</th>
          <th>카드번호</th>
          <th>최종승인금액(원)</th>
        </tr>
      </thead>
    )
  }
  return (
    <React.Fragment>
      <Element style={_style}>
        {thead === undefined ? createThead() : thead}
        {/* renderItem없을경우디버그 */}
        {!!children && children}
      </Element>
      {/* 빈컨텐츠일때 */}
      {empty !== undefined && empty}
    </React.Fragment>
  )
}
//*--------------------------------------------------*
const Element = styled.table`
  width: 100%;
  /* <!--common--> */
  .l {
    text-align: left;
  }
  .r {
    text-align: right;
  }
  .c {
    text-align: center;
  }
  .auto {
    width: auto;
  }
  tr {
    border-bottom: 1px solid ${COLOR.GRAY5};
    &:nth-child(even) {
      /* border-bottom: 1px solid ${COLOR.GRAY5}; */
    }
    &:hover {
      background-color: ${COLOR.GRAY6};
    }
    &:active {
      background-color: ${COLOR.GRAY4};
    }
  }
  th,
  td {
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
  }
  th {
    height: 48px;
    /* border-top: 1px solid ${COLOR.GRAY5}; */
    border-bottom: 1px solid ${COLOR.BLUE_GRAY8};
    text-align: center;
    vertical-align: middle;
    color: ${COLOR.BLACK3};
    font-size: 15px;
    line-height: 24px;
    background-color: ${COLOR.BLUE8};
    // 양쪽에 여백
    &.inner > * {
      padding: 0px 10px;
    }
  }
  td {
    height: 64px;
    vertical-align: middle;
    /* padding-top: 18px;
    padding-bottom: 18px;
     */
    color: ${COLOR.BLACK3};
    font-size: 15px;
    line-height: 24px;
    a {
      color: ${COLOR.BLUE3};
    }
  }
`
/********************************************************
[사용법]
  <ListTable
    thead={
      <thead>
        <tr>
          <th className="inner">고객 유형</th>
          <th className="inner">적용 시간</th>
          <th className="inner">급속 단가(원/kWh)</th>
          <th className="inner">완속 단가(원/kWh)</th>
        </tr>
        <colgroup>
          <col style={{width: 100}} />
        </colgroup>
      </thead>
    }>
    <tbody>
      <tr key={uuid()}>
        <td>
          <MemberType type="FAST" />
        </td>
        <td>00:00 ~ 24:00</td>
        <td>{addComma(280)}원</td>
        <td>{addComma(160)}원</td>
      </tr>
    </tbody>
  </ListTable>
*********************************************************/
