import React, { useEffect, useState } from 'react';
import { ImageButton } from 'lib';
import styled from 'styled-components';
import { COLOR } from 'styles';
import { icon_search_gray } from 'assets/images';
import { CSS } from 'types';
import { Wrapper } from './InputSearch.style';
import Input from '@/components/atoms/Input';

type Props = {
  containerStyle?: CSS;
  style?: CSS;
  placeholder?: string;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  onSubmit?: (str: string) => void;
  onChange?: (str: string) => void;
  onClick?: () => void;
};

export default function InputSearch({
  className,
  disabled,
  containerStyle,
  style,
  placeholder,
  onSubmit,
  onClick,
}: Props) {
  const [keyword, setKeyword] = useState('');
  const handleInputChange = (str: string) => {
    setKeyword(str);
  };

  return (
    <Wrapper
      style={containerStyle}
      className={className}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}>
      <Input
        disabled={disabled}
        defaultValue={keyword}
        style={style}
        placeholder={placeholder}
        onChange={handleInputChange}
        onSubmit={() => {
          if (onSubmit && keyword !== '') {
            onSubmit(keyword);
            setKeyword('');
          }
        }}
      />
      <ImageButton
        className="button"
        // buttonStyle={{backgroundColor: 'red'}}
        source={icon_search_gray}
        style={{ width: 20, height: 20 }}
        onClick={() => {
          if (onSubmit && keyword !== '') {
            onSubmit(keyword);
            setKeyword('');
          }
        }}
      />
    </Wrapper>
  );
}
