import React from 'react';
import styled from 'styled-components';
import Wave from 'react-wavify';
import { CSS } from 'types';

type Props = {
  style?: CSS;
};

/**
 * @name 매출총액
 */
export default function TotalSalesItemBlue({ style }: Props) {
  return (
    <Content style={style}>
      <div className="wrap">
        <Wave fill="url(#gradient)" options={{ height: 45, amplitude: 20, speed: 0.25, points: 4 }}>
          <defs>
            <linearGradient id="gradient" gradientTransform="rotate(40)">
              <stop offset="0%" stopColor="#D6E2FB" />
              <stop offset="100%" stopColor="#BED0F5" />
            </linearGradient>
          </defs>
        </Wave>
      </div>
      <div className="wrap">
        <Wave fill="url(#gradient1)" options={{ height: 50, amplitude: 10, speed: 0.35, points: 2 }}>
          <defs>
            <linearGradient id="gradient1" gradientTransform="rotate(30)">
              <stop offset="10%" stopColor="#1146CB" />
              <stop offset="100%" stopColor="#BED0F5" />
            </linearGradient>
          </defs>
        </Wave>
      </div>
      <div className="wrap">
        <Wave fill="url(#gradient2)" options={{ height: 60, amplitude: 15, speed: 0.4, points: 5 }}>
          <defs>
            <linearGradient id="gradient2" gradientTransform="rotate(10)">
              <stop offset="0%" stopColor="#1146CB" />
              <stop offset="100%" stopColor="#BED0F5" />
            </linearGradient>
          </defs>
        </Wave>
      </div>
    </Content>
  );
}

const Content = styled.div`
  position: relative;
  margin-top: 24px;
  /* margin-bottom: 25px; */
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  border-radius: 200px;
  background: #f4f4f4;
  .wrap {
    position: absolute;
    top: 150;
    left: 0;
  }
  footer {
    align-items: center;
  }
`;
