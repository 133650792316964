import React from 'react';
import { Image } from 'lib';
import styled from 'styled-components';
import { COLOR } from 'styles';

type Props = {
  value?: string;
  icon?: any;
  onClick?: () => void;
};

/**
 * @name InputButton
 */
export default function Contents({ value, icon, onClick }: Props) {
  return (
    <Content onClick={() => !!onClick && onClick()}>
      <label className="label">{value}</label>
      {!!icon && (
        <span className="icon">
          <img src={icon} alt="icon" />
        </span>
      )}
    </Content>
  );
}
const Content = styled.button`
  // styles
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  label {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid ${COLOR.GRAY4};
    padding-left: 16px;
    font-size: 15px;
    color: ${COLOR.GRAY2};
    line-height: 24px;
    /* letter-spacing: -0.53px; */
  }
  .icon {
    position: absolute;
    top: 10px;
    right: 10px;
    img {
      width: 20px;
      height: 20px;
    }
  }
`;
