/**
 * @name 운영 - 장애이력
 * @description
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChargingStatusDetail } from '@/types/chargingStatus';
import { ITrouble } from '@/types/trouble';

type StateType = {
  mode: string;
  payload: {};
  totalCount: number;
  data: {};
  list: ITrouble[];
  view: IChargingStatusDetail;
  search_payload: {
    isOpen: boolean;
  };
};
export const sliceName = 'operationTrouble';

const initialState: StateType = {
  mode: 'DEFAULT',
  payload: {},
  totalCount: 0,
  list: [],
  data: {},
  view: {
    transaction_id: 0,
    id_tag: '',
    start_types: '',
    end_types: '',
    meter_start: 0,
    start_soc: '',
    meter_last: 0,
    end_soc: '',
    status: '',
    stop_reason: '',
    created_at: '',
    completed_at: '',
    connector: {
      seq: 0,
      code: '',
      types: '',
      chargepoint: {
        seq: 0,
        code: '',
        name: '',
        station: {
          seq: 0,
          code: '',
          name: '',
        },
      },
    },
    purchases: [],
    average_watt: 0,
  },
  search_payload: {
    isOpen: false,
  },
};
const operationTroubleSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInit(state: StateType) {
      Object.assign(state, initialState);
    },
    // setMode
    setMode(state: StateType, action: PayloadAction<any>) {
      state.mode = action.payload;
    },
    // fetch 리스트
    setList(state: StateType, action: PayloadAction<{ list: ITrouble[]; totalCount: number }>) {
      state.list = action.payload.list;
      state.totalCount = action.payload.totalCount;
    },
    // fetch view
    setView(state: StateType, action: PayloadAction<IChargingStatusDetail>) {
      state.view = action.payload;
    },
    initView(state: StateType) {
      state.view = initialState.view;
    },
    // create Payload업데이트
    setPayload(state: StateType, action: PayloadAction<any>) {
      state.payload = Object.assign(state.payload, action.payload);
    },
    // 검색및 상세검색조건 업데이트
    setSearchPayload(state: StateType, action: PayloadAction<any>) {
      state.search_payload = Object.assign(state.search_payload, action.payload);
    },
  },
});
export const nameSpace = sliceName;
export const { actions } = operationTroubleSlice;
export default operationTroubleSlice.reducer;
