import styled, { css } from 'styled-components';
import { COLOR } from 'styles';
import typography from '@/styles/typography';
import { FontType } from '@/types/font';

export const Content = styled.div`
  width: 806px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  footer {
    width: 350px;
    margin: 40px auto 0px;
  }
`;
export const Header = styled.div`
  text-align: center;
`;
export const PopupTitle = styled.p`
  ${typography.headline2};
  color: ${COLOR.BLACK1};
`;
export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
export const Footer = styled.div`
  margin-top: 24px;
  padding: 0 150px;
`;

export const Table = styled.table`
  width: 100%;
`;
export const TableHead = styled.thead``;

export const TableBody = styled.tbody``;

export const TD = styled.td<{ $index?: number; $customFont?: FontType; color?: string }>`
  padding: 8px;
  height: 56px;
  word-break: keep-all;
  color: ${props => props.color ?? COLOR.BLACK2};
  vertical-align: middle;
  ${({ $customFont }) =>
    $customFont &&
    css`
      font-family: ${$customFont.fontFamily};
      letter-spacing: ${$customFont.letterSpacing};
      font-size: ${$customFont.fontSize};
      line-height: ${$customFont.lineHeight};
      font-weight: ${$customFont.fontWeight};
    `};
`;

export const TR = styled.tr<{ $isSelected?: boolean }>`
  border-bottom: 1px solid ${COLOR.GRAY5};
  &:hover {
    background-color: ${COLOR.GRAY6};
  }

  ${props =>
    props.$isSelected &&
    css`
      ${TD} {
        color: ${COLOR.BLUE4};
      }
    `}
`;

export const TH = styled.th`
  text-align: left;
  padding: 14px 8px;
  background-color: ${COLOR.BLUE8};
  ${typography.headline6};
  color: ${COLOR.GRAY1};
`;
