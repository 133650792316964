import styled from 'styled-components';
import { COLOR } from '@/styles/index';

export const Content = styled.div``;

export const Container = styled.div`
  width: 674px;
  margin: 24px auto;
`;

export const HeaderContainer = styled.div`
  display: flex;
  height: 72px;
  padding: 19px 16px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  align-items: center;
  justify-content: space-between;
`;

export const BadgesContainer = styled.div`
  margin: 0 10px;
  gap: 10px;
  display: flex;
  align-items: center;
`;

export const SubmitWrapper = styled.div`
  width: 400px;
  margin: 32px auto 20px;
`;
