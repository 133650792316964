import { useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from 'model';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import StationUpdatePresenter from './StationUpdate.presenter';
import { actions } from '@/model/modules/station';
import { getStation, modifyStation } from '@/lib/apis/infra';
import { DayCode, ReqStationUpdate, StationOpen } from '@/types/infra';
import { MENU } from '@/pages/router';

/**
 * @description 충전소등록관리 상세
 */
export default function StationUpdateContainer() {
  const {
    auth: { cpo_seq },
    station: { payload, duplicatedCheck },
  } = useAppSelector((state: RootState) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [popupSuccessVisible, setPopupSuccessVisible] = useState(false);
  const [popupCancelVisible, setPopupCancelVisible] = useState(false);
  const [popupFailedVisible, setPopupFailedVisible] = useState(false);
  const [nameInitialValue, setNameInitialValue] = useState('');
  const [requiredFieldsWarning, setRequiredFieldsWarning] = useState({
    name: false,
    requireDuplicateCheck: false,
    stationTypeGroup: false,
    address: false,
  });

  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const searchKeyword = searchParams.get('search') || '';
  const locationKeyword = searchParams.get('location') || '';

  const queryParamsURL = () => {
    const queryParams = [];

    if (searchKeyword) {
      queryParams.push(`search=${encodeURIComponent(searchKeyword)}`);
    }
    if (locationKeyword) {
      queryParams.push(`location=${encodeURIComponent(locationKeyword)}`);
    }
    queryParams.push(`page=${page}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const onInit = async () => {
    const { data } = await getStation({ stationSeq: id! });
    dispatch(actions.setView(data));
    dispatch(
      actions.setStationPayload({
        ...data,
        cpo_seq,
        station_type_group: data.station_type.type_group,
        station_type_detail: data.station_type.type_detail,
        name: data.name,
        latitude: data.latitude,
        longitude: data.longitude,
        post_code: data.post_code,
        phone: data.phone,
        address: data.address,
        address_detail: data.address_detail,
        station_open: data.opens.reduce((acc: { [key in DayCode]: StationOpen }, current: StationOpen) => {
          // eslint-disable-next-line no-param-reassign
          acc[current.day_code as DayCode] = current;
          return acc;
        }, {}),
      }),
    );
    setNameInitialValue(data.name);
  };

  const onCancelPopup = () => {
    setPopupCancelVisible(true);
  };

  const checkRequiredInputs = () => {
    setRequiredFieldsWarning({
      name: false,
      requireDuplicateCheck: false,
      stationTypeGroup: false,
      address: false,
    });
    let check = true;
    if (payload.name === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        name: true,
      }));
      check = false;
    } else if (!duplicatedCheck && payload.name !== nameInitialValue) {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        requireDuplicateCheck: true,
      }));
      check = false;
    }
    if (payload.station_type_group === null) {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        stationTypeGroup: true,
      }));
      check = false;
    }
    if (payload.address === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        address: true,
      }));
      check = false;
    }
    return check;
  };

  const onPatchSubmit = async () => {
    if (checkRequiredInputs() && id) {
      const convertToStationOpenArray = (stationOpenObject: { [key in DayCode]: StationOpen }): StationOpen[] => {
        return Object.keys(stationOpenObject).map(key => stationOpenObject[key as DayCode]);
      };
      const stationOpenArray = convertToStationOpenArray(payload.station_open);
      const reqPayload: ReqStationUpdate = {
        ...payload,
        cpo_seq,
        station_open: stationOpenArray,
      };

      try {
        const submitResponse = await modifyStation({ seq: id, payload: reqPayload });
        if (submitResponse.isSuccess) {
          setPopupSuccessVisible(true);
        }
      } catch (e) {
        setPopupFailedVisible(true);
        console.error(e);
      }
    }
  };

  const handleCloseCancelPopup = () => {
    setPopupCancelVisible(false);
  };

  const handleDone = () => {
    setPopupSuccessVisible(false);
    navigate(`${MENU.INFRA.STATION_MANAGEMENT}/${id}?${queryParamsURL()}`);
  };

  const handleCloseFailedPopup = () => {
    setPopupFailedVisible(false);
  };

  useEffect(() => {
    (async () => {
      await onInit();
    })();
  }, []);

  return (
    <StationUpdatePresenter
      onSubmit={onPatchSubmit}
      onCancelPopup={onCancelPopup}
      popupSuccessVisible={popupSuccessVisible}
      popupCancelVisible={popupCancelVisible}
      handleCloseCancelPopup={handleCloseCancelPopup}
      handleDone={handleDone}
      requiredFieldsWarning={requiredFieldsWarning}
      popupFailedVisible={popupFailedVisible}
      handleCloseFailedPopup={handleCloseFailedPopup}
    />
  );
}
