/**
 * @name 등록,및다운로드아이콘
 * @description
 */
import { MENU } from '@/pages/router';
import { Link } from 'react-router-dom';
import { Button, ImageButton, Row } from 'lib';
import { icon_download, icon_plus_white } from 'assets/images';

type Props = {
  addRoute?: string;
  addLabel?: string;
  icon?: string;
  onDownload?: () => void;
};
export default function ButtonsAddDownload({ addRoute, addLabel, icon, onDownload }: Props) {
  // hooks
  //*--------------------------------------------------*
  return (
    <Row>
      <Link to={addRoute ?? `/add`}>
        <Button icon={icon ?? icon_plus_white} label={addLabel ?? '충전소 등록'} style={{ marginRight: 16 }} />
      </Link>
      <ImageButton
        source={icon_download}
        style={{ width: 40, height: 40 }}
        onClick={() => {
          !!onDownload && onDownload();
        }}
      />
    </Row>
  );
}
/********************************************************
[사용법]
<ButtonAddDownload
  icon={icon_signal_white}
  addLabel="충전소 등록"
  onDownload={() => {
    alert('download11')
  }}
/>

*********************************************************/
