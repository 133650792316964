import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './model';
import './assets/locales/i18n';
import './styles/scss/root.scss';
import './styles/scss/init.scss';
import './styles/scss/font.scss';
import './styles/scss/text.scss';
import './styles/scss/common.scss';
import './styles/scss/gnb.scss';
import reportWebVitals from './reportWebVitals';
import Router from './pages/router';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter basename="/">
      <Router />
    </BrowserRouter>
  </Provider>,
  // </React.StrictMode>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
