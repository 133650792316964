/**
 * @name 펌웨어
 * @description
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// 모드변경 _mode
export const MODE = {
  DEFAULT: 'DEFAULT',
  LIST: 'LIST',
  REGISTER: 'REGISTER',
  EMPTY: 'EMPTY',
};
// 팝업 _popup
export const POPUP = {
  SELECT_FIRMWARE_MODEL: 'SELECT_FIRMWARE_MODEL', // 펌웨어모델선택
  CANCEL_CONFIRM: 'CANCEL_CONFIRM', // 취소확인
  DELETE_CONFIRM: 'DELETE_CONFIRM', // 삭제확인
  RESISTER_SUCCESS: 'RESISTER_SUCCESS', // 등록성공
  RESISTER_FAIL: 'RESISTER_FAIL', // 등록실패
};
// 이벤트 _event
export const EVENT = {
  // submit
  SUBMIT: 'SUBMIT',
  UPDATE: 'UPDATE',
  // 레이어팝업
  POPUP_SHOW: 'POPUP_SHOW',
  POPUP_HIDE: 'POPUP_HIDE',
  POPUP_SET: 'POPUP_SET',
  // CRUD
  SUBMIT_CREATE: 'SUBMIT_CREATE', // 등록하기
  FETCH_LIST: 'FETCH_LIST', // 리스트데이터요청
  FETCH_VIEW: 'FETCH_VIEW', // 상세데이터요청
  DELETE: 'DELETE', // 삭제하기
  MODIFY: 'MODIFY', // 수정하기
};
//* --------------------------------------------------*
/** @defineType */
type StateType = {
  mode: string;
  payload: {};
  data: {};
  select_model: any[];
  search_payload: {
    isOpen: boolean;
  }; // ---------------------검색조건 및 상세검색조건
  [key: string]: any;
};
//* --------------------------------------------------*
export const sliceName = 'firmware'; // 변경필요
/** @initialState */
const initialState: StateType = {
  mode: 'DEFAULT',
  payload: {},
  data: {},
  select_model: [],
  search_payload: {
    isOpen: false,
  }, // ---------------------검색조건 및 상세검색조건
};
//* --------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //* --------------------------------------------------*
    // 초기화
    setInit(state: StateType) {
      Object.assign(state, initialState);
    },
    // setMode
    setMode(state: StateType, action: PayloadAction<any>) {
      state.mode = action.payload;
    },
    // fetch 리스트
    setList(state: StateType, action: PayloadAction<any>) {
      state.list = action.payload;
    },
    // fetch view
    setView(state: StateType, action: PayloadAction<any>) {
      state.view = action.payload;
    },
    // create Payload업데이트
    setPayload(state: StateType, action: PayloadAction<any>) {
      state.payload = Object.assign(state.payload, action.payload);
    },
    // 펌웨어모델선택
    setSelectModel(state: StateType, action: PayloadAction<any>) {
      state.select_model = action.payload;
    },
    // 검색및 상세검색조건 업데이트
    setSearchPayload(state: StateType, action: PayloadAction<any>) {
      state.search_payload = Object.assign(state.search_payload, action.payload);
    },
  },
});
//* --------------------------------------------------*
export default slice.reducer;
export const nameSpace = sliceName;
export const { actions } = slice;
