import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Image, useClickOutside, uuid } from 'lib';
import { COLOR } from 'styles';
import { icon_arrow_down_black, icon_arrow_down_gray, icon_arrow_up_black } from 'assets/images';

type Props = {
  style?: React.CSSProperties;
  popupStyle?: React.CSSProperties;
  onChange?: (value: { name: string; value: string }) => void;
  disabled?: boolean;
  value?: string;
  [key: string]: any;
};

/**
 * @name 커넥터
 * @description
 */
export default function DropDownTimes({ onChange, style, disabled, popupStyle, value }: Props) {
  const info = [
    { name: '00:00:00', value: '00:00:00' },
    { name: '01:00:00', value: '01:00:00' },
    { name: '02:00:00', value: '02:00:00' },
    { name: '03:00:00', value: '03:00:00' },
    { name: '04:00:00', value: '04:00:00' },
    { name: '05:00:00', value: '05:00:00' },
    { name: '06:00:00', value: '06:00:00' },
    { name: '07:00:00', value: '07:00:00' },
    { name: '08:00:00', value: '08:00:00' },
    { name: '09:00:00', value: '09:00:00' },
    { name: '10:00:00', value: '10:00:00' },
    { name: '11:00:00', value: '11:00:00' },
    { name: '12:00:00', value: '12:00:00' },
    { name: '13:00:00', value: '13:00:00' },
    { name: '14:00:00', value: '14:00:00' },
    { name: '15:00:00', value: '15:00:00' },
    { name: '16:00:00', value: '16:00:00' },
    { name: '17:00:00', value: '17:00:00' },
    { name: '18:00:00', value: '18:00:00' },
    { name: '19:00:00', value: '19:00:00' },
    { name: '20:00:00', value: '20:00:00' },
    { name: '21:00:00', value: '21:00:00' },
    { name: '22:00:00', value: '22:00:00' },
    { name: '23:00:00', value: '23:00:00' },
    { name: '24:00:00', value: '24:00:00' },
  ];
  const eleRef = useRef(null);
  const [search, setSearch] = useState(value);
  const [visible, setVisible] = useState(false);
  useClickOutside(eleRef, () => {
    setVisible(false);
  });

  const getIcon = () => {
    if (disabled) {
      return icon_arrow_down_gray;
    }
    if (visible) {
      return icon_arrow_up_black;
    }
    return icon_arrow_down_black;
  };

  const icon = getIcon();

  useEffect(() => {
    setSearch(value);
  }, [value]);

  return (
    <Content ref={eleRef} style={style} $disabled={disabled}>
      <SelectButton
        type="button"
        onClick={() => {
          // 비활성화면 종료
          if (disabled) return;
          setVisible(!visible);
        }}>
        <SelectLabel $disabled={disabled}>{search ?? ``}</SelectLabel>
        <Image src={icon} style={{ width: 20, height: 20 }} />
      </SelectButton>
      {/* 레이어팝업 */}
      {visible && (
        <Section style={popupStyle}>
          {info?.map((item: any) => {
            return (
              <ItemButton
                type="button"
                $active={item?.name === search}
                key={uuid()}
                onClick={() => {
                  if (onChange) {
                    onChange(item);
                  }
                  setSearch(item?.name);
                  setVisible(false);
                }}>
                {item?.name}
              </ItemButton>
            );
          })}
        </Section>
      )}
    </Content>
  );
}

const SelectButton = styled.button`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  border: 1px solid ${COLOR.GRAY4};
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
`;

const SelectLabel = styled.label<{ $disabled?: boolean }>`
  color: ${COLOR.BLACK1};
  ${props =>
    props.$disabled &&
    css`
      color: ${COLOR.GRAY4};
    `}
`;

const Content = styled.div<{ $disabled?: boolean }>`
  position: relative;
  width: 100%;
  min-width: 120px;
  max-width: 140px;
  ${props =>
    props.$disabled &&
    css`
      background-color: ${COLOR.GRAY6};
    `}

  /* 레이어팝업 */
  section.popup {
    button.item {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
      height: 40px;
      padding: 0 16px;
      color: ${COLOR.BLACK1};
      &.active {
        background-color: ${COLOR.BLUE7};
      }
      &:hover {
        background-color: ${COLOR.GRAY6};
      }
      &:active {
        background-color: ${COLOR.BLUE_GRAY9};
      }
    }
  }
`;

const Section = styled.section`
  position: absolute;
  z-index: 10;
  background-color: ${COLOR.WHITE};
  width: 100%;
  height: 200px;
  box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.07);
  border-radius: 8px;
  overflow-y: scroll;
`;

const ItemButton = styled.button<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  color: ${COLOR.BLACK1};
  ${props =>
    props.$active &&
    css`
      background-color: ${COLOR.BLUE7};
    `}
  &:hover {
    background-color: ${COLOR.GRAY6};
  }
  &:active {
    background-color: ${COLOR.BLUE_GRAY9};
  }
`;
