import styled, { css } from 'styled-components';
import { COLOR } from '@/styles/index';
import typography from '@/styles/typography';
import { FontType } from '@/types/font';

export const Table = styled.table`
  width: 100%;
`;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody``;
export const TR = styled.tr`
  border-bottom: 1px solid ${COLOR.GRAY5};
  &:hover {
    background-color: ${COLOR.GRAY6};
  }
`;

export const TH = styled.th`
  text-align: center;
  padding: 14px 8px;
  background-color: ${COLOR.BLUE8};
  ${typography.headline6};
  color: ${COLOR.GRAY1};
`;

export const TD = styled.td<{ $index?: number; $customFont?: FontType; color?: string }>`
  padding: 8px;
  height: 56px;
  text-align: center;
  word-break: keep-all;
  color: ${props => props.color ?? COLOR.BLACK2};
  vertical-align: middle;
  ${({ $customFont }) =>
    $customFont &&
    css`
      font-family: ${$customFont.fontFamily};
      letter-spacing: ${$customFont.letterSpacing};
      font-size: ${$customFont.fontSize};
      line-height: ${$customFont.lineHeight};
      font-weight: ${$customFont.fontWeight};
    `};
  white-space: pre-line;
`;
