/**
 * @name useGoogleMap
 */

import styled from 'styled-components'
import {useCallback, useEffect, useRef} from 'react'
import {GOOGLE_MAP_KEY} from 'config'
import {MarkerClusterer} from '@googlemaps/markerclusterer'
import {icon_location, icon_location_over} from 'assets/images'
import {Events} from 'lib'
import {Store, _event} from '../../'

// window객체오류해결
declare global {
  interface Window {
    initMap: () => void
    map: any
  }
}
type Props = {
  lat?: number
  lng?: number
}
export default function GoogleMap({lat = 37.5656, lng = 126.9769}: Props) {
  // data
  const {map_stations} = Store()
  // hooks
  // let map: google.maps.Map // 지도
  const mapElement = useRef(null)
  // const
  const makerList: any[] = []
  const locationCenter = {lat: lat, lng: lng}
  const defaultZoom = 12
  //*--------------------------------------------------*
  const onSelect = (data: any) => {
    // console.log(data)
    !!window?.map && window?.map.setCenter(new google.maps.LatLng(data?.latitude ?? 37.418981288714804, data?.longitude ?? 126.92024230957031))
  }

  // loadScript
  const loadScript = useCallback((url: string) => {
    const firstScript = window.document.getElementsByTagName('script')[0]
    const script = window.document.createElement('script')
    script.src = url
    script.async = true
    script.defer = true
    firstScript?.parentNode?.insertBefore(script, firstScript)
  }, [])
  // initMap
  const initMap = useCallback(() => {
    const {google} = window
    if (!mapElement.current || !google) return
    const map = new google.maps.Map(mapElement.current, {
      zoom: defaultZoom,
      minZoom: 5,
      // maxZoom: 10,
      center: locationCenter,
      zoomControl: false, // 줌컨트롤 제거
      fullscreenControl: false, // 전체화면 제거
      streetViewControl: false, // 스트리트뷰 제거
      mapTypeControl: false, // 지도타입(위성,지도) 감추기
    })

    window.map = map
    const infoWindow = new google.maps.InfoWindow({
      content: '',
      disableAutoPan: true,
    })
    // createInfoWindow
    const createInfoWindow = (data: any) => {
      return `<section id="infoWindow">
      <h1>
        ${data?.code}
      </h1>
      <div>
        <img class="thumb" src="${data?.pic_mideum ?? 'https://www.hyundai.co.kr/image/upload/asset_library/MDA00000000000017250/ac115791b5994fb9a0aeac1ca941ee2c.jpg'}"/>
      </div>
      <p>${data?.address} ${data?.address_detail}</p>
      <p>post_code : ${data?.post_code}</p>
      </section>`
    }
    //================== marker
    // console.log(map_stations)
    const markers =
      !!map_stations &&
      map_stations?.map((data: any, index: number) => {
        const position = {lat: data.latitude, lng: data.longitude}
        const marker = new google.maps.Marker({
          position,
          icon: {
            url: icon_location,
            scaledSize: new google.maps.Size(22, 22), // size
          },
          label: {text: index.toString(), fontSize: '10px', color: '#FFF'},
        })
        makerList.push(marker)
        marker.addListener('mouseover', () => {
          marker.setIcon({
            url: icon_location_over,
            scaledSize: new google.maps.Size(36, 51), // size
            labelOrigin: new google.maps.Point(18, 18),
            // anchor: new google.maps.Point(18, 51), // anchor
          })
          marker.setLabel({
            text: index.toString(),
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#2B64F5',
          })
        })
        marker.addListener('mouseout', () => {
          marker.setIcon({
            url: icon_location,
            scaledSize: new google.maps.Size(22, 22), // size
          })
          marker.setLabel({
            text: index.toString(),
            fontSize: '10px',
            fontWeight: 'normal',
            color: '#FFF',
          })
        })
        marker.addListener('click', () => {
          console.log(data)
          infoWindow.setContent(createInfoWindow(data))
          infoWindow.open(map, marker)
          map.panTo(position) // 중앙으로이동
        })
        return marker
      })
    new MarkerClusterer({markers, map})
    //================== Events
    map.addListener('dragend', (event: any) => {
      // DRAGEND
      // console.log(event)
      // infoWindow.close()
      // console.log('dragend')
    })
    // event
    map.addListener('click', (event: any) => {
      const {lat, lng} = event.latLng
      const position = {lat: lat(), lng: lng()}
      map.panTo(position) // 중앙으로이동
      // console.log(log)
    })
    //================== marker
  }, [map_stations])
  //*--------------------------------------------------*
  useEffect(() => {
    const script = window.document.getElementsByTagName('script')[0]
    const includeCheck = script.src.startsWith('https://maps.googleapis.com/maps/api')
    if (includeCheck) return initMap() // script 중복 호출 방지
    window.initMap = initMap
    loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&callback=initMap&language=ko&region=KR`)
  }, [initMap, loadScript])

  useEffect(() => {
    !!window?.map && window?.map.setCenter(new google.maps.LatLng(lat, lng))
    // window.map?.panTo({lat, lng})
  }, [lat, lng])

  useEffect(() => {
    // addListener
    Events.addListener(_event.MAP_SELECT_STATION, onSelect)
    return () => {
      // removeListener
      Events.removeListener(_event.MAP_SELECT_STATION, onSelect)
    }
  }, [])
  return <MapContainer ref={mapElement}></MapContainer>
}
//*--------------------------------------------------*
const MapContainer = styled.div`
  position: 'relative';
  width: 100%;
  height: calc(100vh - 150px);
`

/********************************************************


*********************************************************/

/************
 구글지도
 https://developers.google.com/maps/documentation/javascript/events
 https://developers.google.com/maps/documentation/javascript/examples/map-simple
https://developers.google.com/maps/documentation/javascript/examples/control-replacement#maps_control_replacement-javascript

블로그
https://devkkiri.com/post/2a105ee2-112e-4d15-9dc0-a3a7b6f2adcf
컴포넌트가 마운트될 때, 수동으로 스크립트를 넣어줍니다.script가 실행되기 
이전에 window.initMap이 먼저 선언되어야함.

  */
