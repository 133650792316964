import styled from 'styled-components';
import React, { useCallback, useEffect, useRef } from 'react';
import { GOOGLE_MAP_KEY } from 'config';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { icon_location, icon_location_over } from 'assets/images';
import { Button, Row, uuid } from 'lib';

// window객체오류해결
declare global {
  interface Window {
    initMap: () => void;
    map: any;
  }
}
type Props = {
  lat?: number;
  lng?: number;
};
/**
 * @name useGoogleMap
 */
export default function GoogleMap({ lat = 37.5656, lng = 126.9769 }: Props) {
  // data
  const locations = [{ lat: 37.5656, lng: 126.9769, label: '서울' }];
  // hooks
  // let map: google.maps.Map // 지도
  const mapElement = useRef(null);
  // const
  const makerList: any[] = [];
  const locationCenter = { lat, lng };
  const defaultZoom = 17;
  //* --------------------------------------------------*
  // loadScript
  const loadScript = useCallback((url: string) => {
    const firstScript = window.document.getElementsByTagName('script')[0];
    const script = window.document.createElement('script');
    script.src = url;
    script.async = true;
    script.defer = true;
    firstScript?.parentNode?.insertBefore(script, firstScript);
  }, []);
  // initMap
  const initMap = useCallback(() => {
    const { google } = window;
    if (!mapElement.current || !google) return;
    const map = new google.maps.Map(mapElement.current, {
      zoom: defaultZoom,
      center: locationCenter,
      streetViewControl: false, // 스트리트뷰 제거
      mapTypeControl: false, // 지도타입(위성,지도) 감추기
    });
    window.map = map;
    const infoWindow = new google.maps.InfoWindow({
      content: '',
      disableAutoPan: true,
    });
    const element = `<div>크로커스<span>sdfsd</span></div>`;
    //= ================= marker
    const markers = locations.map((data: any, index: number) => {
      const position = { lat: data.lat, lng: data.lng };
      const label = data?.label;
      const marker = new google.maps.Marker({ position, label, icon: icon_location });
      makerList.push(marker);
      marker.addListener('mouseover', () => {
        marker.setIcon(icon_location_over);
      });
      marker.addListener('mouseout', () => {
        marker.setIcon(icon_location);
      });
      marker.addListener('click', () => {
        infoWindow.setContent(element);
        infoWindow.open(map, marker);
        map.panTo(position); // 중앙으로이동
      });
      return marker;
    });
    new MarkerClusterer({ markers, map });
    //= ================= Events
    map.addListener('dragend', (event: any) => {
      // DRAGEND
      // console.log(event)
      // console.log('dragend');
    });
    // event
    map.addListener('click', (event: any) => {
      const { lat, lng } = event.latLng;
      const position = { lat: lat(), lng: lng() };
      map.panTo(position); // 중앙으로이동
      // console.log(log)
    });
    //= ================= marker
  }, []);
  //* --------------------------------------------------*

  useEffect(() => {
    const script = window.document.getElementsByTagName('script')[0];
    const includeCheck = script.src.startsWith('https://maps.googleapis.com/maps/api');
    if (includeCheck) return initMap(); // script 중복 호출 방지
    window.initMap = initMap;
    loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&callback=initMap&language=ko&region=KR`);
  }, [initMap, loadScript]);

  useEffect(() => {
    !!window?.map && window?.map.setCenter(new google.maps.LatLng(lat, lng));

    // window.map?.panTo({lat, lng})
  }, [lat, lng]);
  return (
    <MapContainer>
      <div ref={mapElement} style={{ width: '100%', minHeight: 300 }} />
      <button
        onClick={() => {
          // map.panTo({lat: 37.5656, lng: 126.9769})
        }}>
        test
      </button>
    </MapContainer>
  );
}
//* --------------------------------------------------*
const MapContainer = styled.div`
  position: 'relative';
  width: 100%;
  min-height: 300px;
`;

/** ******************************************************


******************************************************** */

/** **********
 구글지도
 https://developers.google.com/maps/documentation/javascript/events
 https://developers.google.com/maps/documentation/javascript/examples/map-simple
https://developers.google.com/maps/documentation/javascript/examples/control-replacement#maps_control_replacement-javascript

블로그
https://devkkiri.com/post/2a105ee2-112e-4d15-9dc0-a3a7b6f2adcf
컴포넌트가 마운트될 때, 수동으로 스크립트를 넣어줍니다.script가 실행되기 
이전에 window.initMap이 먼저 선언되어야함.

  */
