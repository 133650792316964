/**
 * @name TextFiledLabel
 * @description H1
 */

import styled from 'styled-components'
import {CSS, TextProps} from 'types'
import {H5} from 'lib'
import {COLOR} from 'styles'

type Props = {
  label: string
  required?: boolean
  style?: CSS
}
export default function H1({label, required, style}: Props) {
  return (
    <Element style={style}>
      <H5>{label}</H5>
      {required && (
        <H5 className={'required'} style={{color: 'red'}}>
          *
        </H5>
      )}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.div`
  display: flex;
  margin-bottom: 4px;
  flex-direction: row;
  align-items: flex-start;
  color: ${COLOR.GRAY1};
  //required
  .required {
    color: ${COLOR.RED3};
  }
`
/********************************************************
[사용법]
  <H1 label="H1 텍스트를 입력합니다." />
  <H1 style={{fontWeight: 'normal', fontSize: 46, color: 'gold'}}>H1 텍스트를 입력합니다.</H1>
*********************************************************/
