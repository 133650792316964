import React, { useState } from 'react';
import { App } from 'components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/model/index';
import AdminAccountsTable from '@/components/organisms/Tables/AdminAccountsTable';
import {
  Count,
  HeaderContainer,
  RoleFilterButton,
} from '@/pages/admin/account-management/AccountManagementList/AccountManagementList.styles';
import { addComma, H3, IconButton, Row, Text } from '@/lib/index';
import { TextFiledSearch } from '@/components/form';
import { icon_add_member_white } from '@/assets/images';
import { MENU } from '@/pages/router';
import { RoleFilterType } from '@/types/admin';
import { COLOR } from '@/styles/index';
import { actions } from '@/model/modules/admin';
import { SCREEN_MODE } from '@/types/mode';

// 계정 권한관리 리스트
export default function AccountManagementListPresenter({
  itemsPerPage,
  currentPage,
  pageHandler,
  handleSearchKeyword,
  handleSearch,
  searchKeyword,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  handleSearchKeyword: (keyword: string) => void;
  handleSearch: () => void;
  searchKeyword: string;
}) {
  const { totalCount, cpo_count, admin_count } = useAppSelector(state => state.admin);
  const { mode } = useAppSelector(state => state.admin);
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [roleFilter, setRoleFilter] = useState<RoleFilterType>(RoleFilterType.ALL);
  const dispatch = useAppDispatch();

  const handleNavigateToRegister = () => {
    dispatch(actions.setMode(SCREEN_MODE.REGISTER));
    navigate(MENU.ADMIN.ACCOUNT_MANAGEMENT_REGISTER);
  };

  const handleRoleFilter = (role: RoleFilterType) => {
    setRoleFilter(role);
  };

  return (
    <App>
      <HeaderContainer>
        <Row>
          <div style={{ marginRight: 20 }}>
            <H3 color={COLOR.BLACK3}>{t('pages.admin.account.list.title')}</H3>
            <Text color={COLOR.BLACK3} className="body6">
              {`${t('common.unit.total')} ${addComma(totalCount)}${t('common.unit.cases')}`}
            </Text>
          </div>
          <div className="search" style={{ marginLeft: 20 }}>
            <TextFiledSearch
              style={{ width: 300, marginRight: 8 }}
              placeholder={t('pages.auth.enterEmailToFind')}
              onChange={keyword => {
                handleSearchKeyword(keyword);
              }}
              onSubmit={handleSearch}
            />
          </div>
          {/* <RoleFilterButton */}
          {/*  $isSelected={roleFilter === RoleFilterType.ALL} */}
          {/*  onClick={() => handleRoleFilter(RoleFilterType.ALL)}> */}
          {/*  {`${t('pages.auth.authority.all')} `} */}
          {/*  <Count $isSelected={roleFilter === RoleFilterType.ALL}>({totalCount})</Count> */}
          {/* </RoleFilterButton> */}
          {/* <RoleFilterButton */}
          {/*  $isSelected={roleFilter === RoleFilterType.ADMIN} */}
          {/*  onClick={() => handleRoleFilter(RoleFilterType.ADMIN)}> */}
          {/*  {`${t('pages.auth.authority.admin')} `} */}
          {/*  <Count $isSelected={roleFilter === RoleFilterType.ADMIN}>({admin_count})</Count> */}
          {/* </RoleFilterButton> */}
          {/* <RoleFilterButton */}
          {/*  $isSelected={roleFilter === RoleFilterType.OPERATOR} */}
          {/*  onClick={() => handleRoleFilter(RoleFilterType.OPERATOR)}> */}
          {/*  {`${t('pages.auth.authority.cpo')} `} */}
          {/*  <Count $isSelected={roleFilter === RoleFilterType.OPERATOR}>({cpo_count})</Count> */}
          {/* </RoleFilterButton> */}
        </Row>
        <Row>
          <IconButton icon={icon_add_member_white} onClick={() => handleNavigateToRegister()} />
        </Row>
      </HeaderContainer>
      <AdminAccountsTable itemsPerPage={itemsPerPage} currentPage={currentPage} pageHandler={pageHandler} />
    </App>
  );
}
