import React from 'react';
import styled from 'styled-components';
import { TextProps } from 'types';

/**
 * @name Text
 * @description Text
 */
export default function Text({ children, center, color, label, style, ...props }: TextProps) {
  // hooks
  const centerStyle = center ? { textAlign: 'center' } : {};
  const combinedStyle = { ...centerStyle, ...(!!style && style), color } as React.CSSProperties;
  //* --------------------------------------------------*
  return (
    <Element {...props} style={combinedStyle}>
      {!!label && label}
      {children}
    </Element>
  );
}
//* --------------------------------------------------*
const Element = styled.span`
  vertical-align: middle;
  font-family: 'Pretendard';
`;
/** ******************************************************
[사용법]

  <H1 label="H1 텍스트를 입력합니다." />
  <H1>H1 텍스트를 입력합니다.</H1>
******************************************************** */
