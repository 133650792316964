import styled from 'styled-components';
import { COLOR } from 'styles';
import typography from '@/styles/typography';

export const styleListKeyValue = { padding: '6px 0' };

export const Header = styled.div`
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  align-items: center;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SummaryWrapper = styled.div`
  min-width: 780px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ListWrapper = styled.div``;

export const CustomGap = styled.div`
  margin: 20px 0;
`;

export const TableTitle = styled.p`
  ${typography.headline5};
  color: ${COLOR.GRAY1};
`;
