/**
 * @name LayerButton
 * @description 버튼
 */
import {Layer} from 'components'
import React, {useState, CSSProperties} from 'react'
import styled from 'styled-components'
import {layout} from 'styles'

type Props = {
  visible?: boolean
  className?: string //----------------------- 클래스네임
  buttonStyle?: CSSProperties //-------------- 스타일시트
  style?: CSSProperties //-------------------- 스타일시트
  popup?: React.ReactNode | JSX.Element[] | undefined
  children?: React.ReactNode | JSX.Element[] | undefined
  onClick?: (event: React.MouseEvent<HTMLElement>) => void //------------------------ onClick 이벤트
}
export default function LayerButton({visible, children, popup, buttonStyle = {}, style = {}, onClick}: Props) {
  // hooks
  const [isBool, setIsBool] = useState(visible ?? false)
  const _style = Object.assign({}, !!style && style)
  //*--------------------------------------------------*
  return (
    <Element>
      {/* {debug(isBool)} */}
      <button
        onClick={event => {
          setIsBool(!isBool)
          onClick && onClick(event)
        }}>
        {children}
      </button>
      {/* 레이어팝업 */}
      <Layer visible={isBool} setVisible={setIsBool}>
        <article className={layout.layer}>{popup}</article>
      </Layer>
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.div`
  position: relative;
  display: flex;
  /* 레이어팝업 */
`
/********************************************************
[사용법]
<ImageButton
  buttonStyle={{display: 'block', border: '1px solid red'}}
  style={{backgroundColor: 'red'}}
  source={icon_alert}
  onClick={() => {
    alert('test')
  }}
/>
*********************************************************/
