import React, { useEffect, useRef, useState } from 'react';
import { DropdownOptions, DropdownProps } from './Dropdown.types';
import { DropdownContainer, Wrapper, Select, Option, OptionContainer, ArrowBox } from './Dropdown.style';

function Dropdown({
  selectField,
  onChangeSelectOption,
  dropdownOptions,
  selectBoxName,
  isBorderExist = true,
  wrapperClassName,
  wrapperStyle,
  ariaLabel,
  placeholder,
  disabled,
  optionContainerWidth,
  defaultValue,
}: DropdownProps) {
  const selectRef = useRef<HTMLDivElement | null>(null);
  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState<DropdownOptions | null>(null);

  const handleSelectBox = () => {
    if (!disabled) {
      setExpanded(!expanded);
    }
  };

  const handleOption = (id: string, name: string, value?: string) => {
    const selectedOption: DropdownOptions | undefined = dropdownOptions.find(value => value.id === id);
    if (selectedOption) {
      setSelected(selectedOption);
      setExpanded(false);
      if (selectField && onChangeSelectOption) {
        onChangeSelectOption({ field: selectField, value: value || selectedOption.id, name });
      }
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    const { current } = selectRef;
    if (current && !current.contains(event.target as Node)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Wrapper className={wrapperClassName} style={wrapperStyle}>
      <DropdownContainer ref={selectRef}>
        <Select
          tabIndex={0}
          onClick={handleSelectBox}
          role="combobox"
          aria-controls={`selectBox-${selectBoxName}`}
          aria-expanded={expanded}
          $isBorderExist={isBorderExist}
          aria-label={ariaLabel}
          $isExpanded={expanded}
          $isSelected={selected !== null}
          $isDisabled={disabled}>
          <div>{selected ? selected.value || selected.name : placeholder}</div>
          <ArrowBox />
        </Select>
        <OptionContainer $expanded={expanded} role="listbox" $customWidth={optionContainerWidth}>
          {dropdownOptions.map(({ id, name, value }) => (
            <Option
              key={id}
              tabIndex={0}
              $selected={selected !== null && selected.id === id}
              onClick={() => handleOption(id, name, value)}
              role="option"
              aria-selected={selected !== null && selected.id === id}>
              {name}
            </Option>
          ))}
        </OptionContainer>
      </DropdownContainer>
    </Wrapper>
  );
}

export default Dropdown;
