import React, { useState } from 'react';
import styled from 'styled-components';
import { Image, List } from 'lib';
import { COLOR } from 'styles';
import { ListRenderItem } from 'types';
import { icon_radio_off, icon_radio_on, icon_signal_black } from 'assets/images';
import { useAppDispatch } from 'model';
import { useTranslation } from 'react-i18next';
import InputButton from './input-button';
import Panel from './panel';
import { actions } from '@/model/modules/dashboard';

type Props = {
  value?: string;
  icon?: any; // 이미지 아이콘
};

/**
 * @name 통계유형
 */
export default function Contents({ icon }: Props) {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(0);
  const info = [
    {
      value: 0,
      title: t('pages.dashboard.filterController.realtime'),
      calc_type: 'REALTIME',
      desc: t('pages.dashboard.filterController.realtimeDesc'),
    },
    {
      value: 1,
      title: t('pages.dashboard.filterController.comparePeriod'),
      calc_type: 'TERMS',
      desc: t('pages.dashboard.filterController.comparePeriodDesc'),
    },
    {
      value: 2,
      title: t('pages.dashboard.filterController.compareStations'),
      calc_type: 'STATIONS',
      desc: t('pages.dashboard.filterController.compareStationsDesc'),
    },
  ];

  return (
    <Content>
      <InputButton
        value={info[selected]?.title}
        icon={icon_signal_black}
        onClick={() => {
          setVisible(true);
        }}
      />
      {/* 레이어팝업 */}
      <Panel
        visible={visible}
        setVisible={setVisible}
        title={t('pages.dashboard.filterController.selectStatisticsType')}
        desc={t('pages.dashboard.filterController.selectStatisticsTypeDesc')}>
        <List
          info={info}
          renderItem={({ item, index }: ListRenderItem) => {
            return (
              <button
                className="item"
                onClick={() => {
                  setSelected(index);
                  dispatch(actions.setPeriod({ calc_type: info[index]?.calc_type }));
                }}>
                <Image src={selected === index ? icon_radio_on : icon_radio_off} style={{ width: 18, height: 18 }} />
                <div>
                  <p className="title">{item?.title}</p>
                  <p className="desc">{item?.desc}</p>
                </div>
              </button>
            );
          }}
        />
      </Panel>
    </Content>
  );
}

const Content = styled.div`
  // styles
  position: relative;
  /* article */
  .item {
    display: flex;
    height: 58px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    color: ${COLOR.GRAY2};
    p {
      display: block;
      margin-left: 8px;
      text-align: left;
      &.title {
        color: ${COLOR.BLACK1};
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.35px;
      }
      &.desc {
        color: ${COLOR.GRAY3};
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.18px;
      }
    }
  }
`;
