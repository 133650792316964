import React, { useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from 'model';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import StationRegisterPresenter from './StationRegister.presenter';
import { actions } from '@/model/modules/station';
import { SCREEN_MODE } from '@/types/mode';
import { getStation, modifyStation, registerStation } from '@/lib/apis/infra';
import { DayCode, ReqStationRegister, StationOpen } from '@/types/infra';
import { MENU } from '@/pages/router';

/**
 * @description 충전소등록관리 상세
 */
export default function StationRegisterContainer() {
  // TODO 수정 중 새로고침시 정보가 날아가는 것을 방지하기 위해 Storage 이용 방안 고려
  const {
    auth: { cpo_seq },
    station: { mode, payload, view, duplicatedCheck },
  } = useAppSelector((state: RootState) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [popupSuccessVisible, setPopupSuccessVisible] = useState(false);
  const [popupCancelVisible, setPopupCancelVisible] = useState(false);
  const [popupFailedVisible, setPopupFailedVisible] = useState(false);
  const [requiredFieldsWarning, setRequiredFieldsWarning] = useState({
    name: false,
    requireDuplicateCheck: false,
    stationTypeGroup: false,
    address: false,
  });
  const isModify = mode === SCREEN_MODE.MODIFY;

  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const searchKeyword = searchParams.get('search') || '';
  const locationKeyword = searchParams.get('location') || '';

  const queryParamsURL = () => {
    const queryParams = [];

    if (searchKeyword) {
      queryParams.push(`search=${encodeURIComponent(searchKeyword)}`);
    }
    if (locationKeyword) {
      queryParams.push(`location=${encodeURIComponent(locationKeyword)}`);
    }
    queryParams.push(`page=${page}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const onInit = () => {
    dispatch(actions.initPayload());
    if (isModify) {
      if (!view.seq) {
        fetchStation();
      }
      dispatch(
        actions.setStationPayload({
          cpo_seq,
          station_type_group: view.station_type.type_group,
          station_type_detail: view.station_type.type_detail,
          name: view.name,
          latitude: view.latitude,
          longitude: view.longitude,
          post_code: view.post_code,
          phone: view.phone,
          address: view.address,
          address_detail: view.address_detail,
          station_open: view.opens.reduce((acc: { [key in DayCode]: StationOpen }, current: StationOpen) => {
            // eslint-disable-next-line no-param-reassign
            acc[current.day_code as DayCode] = current;
            return acc;
          }, {}),
          supplier_seq: '1',
        }),
      );
    } else {
      dispatch(actions.setStationMode(SCREEN_MODE.REGISTER));
    }
  };

  const fetchStation = async () => {
    if (id) {
      const stationResponse = await getStation({ stationSeq: id });
      if (stationResponse.isSuccess) {
        dispatch(actions.setView(stationResponse.data));
        dispatch(actions.setStationPayload(stationResponse.data));
      }
    }
  };

  const onCancelPopup = () => {
    setPopupCancelVisible(true);
  };

  const checkRequiredInputs = () => {
    setRequiredFieldsWarning({
      name: false,
      requireDuplicateCheck: false,
      stationTypeGroup: false,
      address: false,
    });
    let check = true;
    if (payload.name === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        name: true,
      }));
      check = false;
    } else if (!duplicatedCheck) {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        requireDuplicateCheck: true,
      }));
      check = false;
    }
    if (payload.station_type_group === null) {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        stationTypeGroup: true,
      }));
      check = false;
    }
    if (payload.address === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        address: true,
      }));
      check = false;
    }
    return check;
  };

  const onSubmit = async () => {
    if (checkRequiredInputs()) {
      const convertToStationOpenArray = (stationOpenObject: { [key in DayCode]: StationOpen }): StationOpen[] => {
        return Object.keys(stationOpenObject).map(key => stationOpenObject[key as DayCode]);
      };
      const stationOpenArray = convertToStationOpenArray(payload.station_open);
      const reqPayload: ReqStationRegister = {
        ...payload,
        cpo_seq,
        station_open: stationOpenArray,
      };

      try {
        const submitResponse = await registerStation(reqPayload);
        if (submitResponse.isSuccess) {
          dispatch(actions.initPayload());
          setPopupSuccessVisible(true);
        }
      } catch (e) {
        setPopupFailedVisible(true);
        console.error(e);
      }
    }
  };

  const onPatchSubmit = async () => {
    if (checkRequiredInputs() && id) {
      const convertToStationOpenArray = (stationOpenObject: { [key in DayCode]: StationOpen }): StationOpen[] => {
        return Object.keys(stationOpenObject).map(key => stationOpenObject[key as DayCode]);
      };
      const stationOpenArray = convertToStationOpenArray(payload.station_open);
      const reqPayload: ReqStationRegister = {
        ...payload,
        cpo_seq,
        station_open: stationOpenArray,
      };

      try {
        const submitResponse = await modifyStation({ seq: id, payload: reqPayload });
        if (submitResponse.isSuccess) {
          dispatch(actions.initPayload());
          dispatch(actions.initView());
          setPopupSuccessVisible(true);
        }
      } catch (e) {
        setPopupFailedVisible(true);
        console.error(e);
      }
    }
  };

  const handleCloseCancelPopup = () => {
    setPopupCancelVisible(false);
  };

  const handleDone = () => {
    dispatch(actions.initPayload());
    setPopupSuccessVisible(false);
    if (isModify) {
      navigate(`${MENU.INFRA.STATION_MANAGEMENT}/${id}?${queryParamsURL()}`);
    } else {
      navigate(MENU.INFRA.STATION_MANAGEMENT);
    }
  };

  const handleCloseFailedPopup = () => {
    setPopupFailedVisible(false);
  };

  useEffect(() => {
    onInit();
  }, []);

  return (
    <StationRegisterPresenter
      onSubmit={isModify ? onPatchSubmit : onSubmit}
      onCancelPopup={onCancelPopup}
      popupSuccessVisible={popupSuccessVisible}
      popupCancelVisible={popupCancelVisible}
      handleCloseCancelPopup={handleCloseCancelPopup}
      handleDone={handleDone}
      requiredFieldsWarning={requiredFieldsWarning}
      popupFailedVisible={popupFailedVisible}
      handleCloseFailedPopup={handleCloseFailedPopup}
    />
  );
}
