import React from 'react';
import { ImageButtonProps } from '@/components/atoms/Buttons/Buttons.types';
import { ImageBox, Button } from '@/components/atoms/Buttons/ImageButton/ImageButton.style';

function ImageButton({ className, onClick, disabled, style, ariaLabel, imagePaths, imageStyle }: ImageButtonProps) {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick();
    }
    event.currentTarget.blur();
  };
  return (
    <Button
      className={className}
      onClick={handleClick}
      disabled={disabled}
      style={style}
      aria-label={ariaLabel}
      $default={imagePaths.$default}
      $hover={imagePaths.$hover}
      $focus={imagePaths.$focus}>
      <ImageBox $default={imagePaths.$default} style={imageStyle} />
    </Button>
  );
}

export default ImageButton;
