import React from 'react';
import styled from 'styled-components';
import { TextProps } from 'types';
import Text from './text';

/**
 * @name H3
 * @description H3
 */
export default function H3({ children, style, label, ...props }: TextProps) {
  return (
    <Element style={style}>
      <Text {...props}>
        {!!label && label}
        {children}
      </Text>
    </Element>
  );
}
//* --------------------------------------------------*
const Element = styled.h3`
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.6px;
`;
