/**
 * @name 리스트상단제목
 * @description
 */
import {H3, Text, addComma} from 'lib'
import {COLOR} from 'styles'

type Props = {
  title?: string
  total?: number
}
export default function ListTitle({title, total}: Props) {
  // hooks
  //*--------------------------------------------------*
  return (
    <div>
      <H3 color={COLOR.BLACK3}>{title ?? `충전소 등록 관리`}</H3>
      <Text color={COLOR.BLACK3} className={'body6'}>
        총 {addComma(total ?? 0)}건
      </Text>
    </div>
  )
}
/********************************************************
[사용법]

  <FilterButton
    style={{marginLeft: 8}}
    onChange={(bool: boolean) => {
      dispatch(_action.setSearchPayload({isOpen: bool}))
      // console.log(bool)
    }}
  />

*********************************************************/
