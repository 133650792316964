import React from 'react';
import ImageButton from '@/components/atoms/Buttons/ImageButton';
import { Wrapper } from './ModifyDel.style';
import { icons } from '@/assets/images';

type Props = {
  onModify?: () => void;
  onDelete?: () => void;
};
/**
 * @name 수정,삭제버튼그룹
 * @description
 */
export default function IconButtonsModifyDel({ onModify, onDelete }: Props) {
  const modifyImagePaths = {
    $default: icons.editDefault.image,
    $hover: icons.editHover.image,
    $focus: icons.editFocused.image,
  };
  const deleteImagePaths = {
    $default: icons.removeDefault.image,
    $hover: icons.removeHover.image,
    $focus: icons.removeFocused.image,
  };
  return (
    <Wrapper>
      <ImageButton onClick={() => !!onModify && onModify()} imagePaths={modifyImagePaths} />
      <ImageButton onClick={() => !!onDelete && onDelete()} imagePaths={deleteImagePaths} />
    </Wrapper>
  );
}
