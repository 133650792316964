import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import {
  InnerCell,
  InnerCellContainer,
  InnerCellTextButton,
  NoResultDescription,
  NoResultText,
  NoResultTextWrapper,
  NoSearchResultTextWrapper,
  NoSearchResultWrapper,
  SearchKeyword,
  Table,
  TableBody,
  TableHead,
  TD,
  TH,
  TR,
  Wrapper,
} from './PaymentStatusTable.style';
import typography from '@/styles/typography';
import { useAppDispatch, useAppSelector } from '@/model/index';
import { actions } from '@/model/modules/sales';
import { SCREEN_MODE } from '@/types/mode';
import Badge from '@/components/atoms/Badge';
import { IPaymentStatusRes } from '@/lib/apis/sales/type';
import { COLOR } from '@/styles/index';
import { BadgeMode, BadgeType } from '@/components/atoms/Badge/Badge.type';
import { addComma, formatDate } from '@/lib/index';
import { displayPrice } from '@/lib/util/displayData';
import Pagination from '@/components/organisms/Paginations/Pagination';
import { MENU } from '@/pages/router';

export default function PaymentStatusTable({
  itemsPerPage,
  currentPage,
  pageHandler,
  queryString,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  queryString: string;
}) {
  const { t } = useTranslation('common');
  const { list, totalCount } = useAppSelector(state => state.sales);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchKeyword = searchParams.get('search');

  const getUserType = (type: 0 | 1 | 2) => {
    const obj: { [k: number]: { mode: BadgeType; label: string } } = {
      0: {
        mode: 'TariffNone',
        label: '비회원',
      },
      1: {
        mode: 'TariffUser',
        label: '회원',
      },
      2: {
        mode: 'TariffRoaming',
        label: '로밍',
      },
    };
    return obj[type];
  };

  const handleNavigateToDetail = (seq: number) => {
    navigate(`${MENU.SALES.PAYMENT_STATUS}/${seq}`);
  };

  return (
    <Wrapper>
      <Table>
        <colgroup>
          <col style={{ width: 56 }} />
          <col style={{ minWidth: 92 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 92 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 138 }} />
        </colgroup>
        <TableHead>
          <TR>
            <TH>No.</TH>
            <TH>{t('forms.sales.userType')}</TH>
            <TH>{t('forms.sales.IDTag')}</TH>
            <TH>{t('forms.sales.chargepoint')}</TH>
            <TH>{t('forms.sales.connectorID')}</TH>
            <TH>{t('forms.sales.connectorType')}</TH>
            <TH>{t('forms.sales.paymentType')}</TH>
            <TH>{t('forms.sales.approvalAmount')}</TH>
            <TH>{t('forms.sales.approvalDate')}</TH>
            <TH>{t('forms.sales.approvalNumber')}</TH>
          </TR>
        </TableHead>
        <TableBody>
          {list.length > 0 &&
            list.map((item: IPaymentStatusRes, index: number) => (
              <TR key={nanoid()}>
                <TD $customFont={typography.body5}>
                  {totalCount - (currentPage - 1) * itemsPerPage - index}
                  {/* {(currentPage - 1) * 10 + (idx + 1)} */}
                </TD>
                <TD $customFont={typography.body4}>
                  <InnerCellContainer>
                    <Badge mode={getUserType(item.user_type)?.mode} label={getUserType(item.user_type)?.label} />
                  </InnerCellContainer>
                </TD>
                <TD $customFont={typography.body4} color={COLOR.BLUE4}>
                  <InnerCellContainer>
                    <InnerCellTextButton onClick={() => handleNavigateToDetail(item.transaction_id)}>
                      {item.id_tag}
                    </InnerCellTextButton>
                  </InnerCellContainer>
                </TD>
                <TD $customFont={typography.body4}>{item.station_code}</TD>
                <TD $customFont={typography.body4}>{item.connector_code}</TD>
                <TD $customFont={typography.body4}>{t(`common.connector.${item.types}`)}</TD>
                <TD $customFont={typography.body4}>{item.pay_type}</TD>
                <TD $customFont={typography.body4}>{`${addComma(item.amount)}${t(
                  `common.currency.${item.currency.toUpperCase() === 'WON' ? 'KRW' : item.currency}`,
                )}`}</TD>
                <TD $customFont={typography.body4}>{formatDate(item.created_at)}</TD>
                <TD $customFont={typography.body4}>{item.approval_number}</TD>
              </TR>
            ))}
        </TableBody>
      </Table>
      {list.length === 0 && searchKeyword && (
        <NoSearchResultWrapper>
          <NoSearchResultTextWrapper>
            <NoResultTextWrapper>
              <SearchKeyword>{searchKeyword}</SearchKeyword>
              <NoResultText>{t('pages.infra.stationManage.noSearchResult')}</NoResultText>
            </NoResultTextWrapper>
            <NoResultDescription>{t('pages.infra.stationManage.noSearchResultDescription')}</NoResultDescription>
          </NoSearchResultTextWrapper>
        </NoSearchResultWrapper>
      )}
      {list.length === 0 && !searchKeyword && (
        <NoSearchResultWrapper>
          <NoSearchResultTextWrapper>
            <NoResultTextWrapper>
              <NoResultText>{t('pages.infra.stationManage.noStations')}</NoResultText>
            </NoResultTextWrapper>
            <NoResultDescription>{t('pages.infra.stationManage.noStationsDescription')}</NoResultDescription>
          </NoSearchResultTextWrapper>
        </NoSearchResultWrapper>
      )}
      {list.length > 0 && (
        <Pagination
          listLength={totalCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onClick={pageHandler}
        />
      )}
    </Wrapper>
  );
}
