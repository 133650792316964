/**
 * @name ListKeyComponent
 * @description @key값을, 예약어라 title로 변경
 */
import React from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'

type Props = {
  style?: React.CSSProperties //------------------------ StyleSheet
  keyStyle?: React.CSSProperties //--------------------- StyleSheet
  valueStyle?: React.CSSProperties //------------------- StyleSheet
  title?: string //------------------------------------- Title
  left?: boolean //------------------------------------- key값을 왼쪽으로 정렬
  leftValue?: boolean //-------------------------------- key값을 왼쪽으로 정렬
  children?: React.ReactNode
  component?: React.ReactNode
  [key: string]: any
}
export default function ListKeyComponent({style, keyStyle, left, leftValue, valueStyle, title, children, component}: Props) {
  return (
    <Element style={style}>
      <dt className={left ? 'left' : ''} style={keyStyle}>
        {title ?? 'KEY'}
      </dt>
      {/* children있을때 */}
      {!!children && (
        <dd style={valueStyle} className={leftValue ? 'left' : ''}>
          {children}
        </dd>
      )}
      {/* component 있을때 */}
      {!!component && (
        <dd style={valueStyle} className={leftValue ? 'left' : ''}>
          {!!component && component}
        </dd>
      )}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.dl`
  display: flex;
  flex: 1;
  height: 56px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  align-items: center;
  /* 리스트타이틀 */
  > dt {
    width: 160px;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.38px;
    color: ${COLOR.GRAY1};
  }
  dd {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`
/********************************************************
[사용법]

chargepoint_types=['완속']
동시충전여부=['가능', '불가능']
*********************************************************/
