/**
 * @name Template
 * @description 충전소등록/상세/수정
 */
import styled from 'styled-components'
import {debug} from 'lib'
// contents
import {Store, _mode} from '../../index'
import Form from './form'
import Submit from './submit'

export default function Contents() {
  // hooks
  const {mode, view, payload} = Store()
  //*--------------------------------------------------*
  return (
    <Content>
      {/* 폼 */}
      <Form />
      {/* submit */}
      {mode !== _mode.VIEW && <Submit />}
      {/* 디버그 */}
      <div className="auto none" style={{width: '60%'}}>
        {debug(mode)}
        {debug(view)}
        {/* {debug(payload)} */}
      </div>
    </Content>
  )
}

//*--------------------------------------------------*
const Content = styled.div``
