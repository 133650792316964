/**
 * @name 충전소별 충전단가
 * @description
 */
import {App} from 'components'
import {Store, _mode} from '.'
// contents
import {Header, Popup} from './common'
import {List, View} from './contents'

export default function Presenter() {
  // hooks
  const {mode} = Store()
  //*--------------------------------------------------*
  /**
   * @name 리스트,상세,수정,등록컨텐츠분기
   */
  const setContents = () => {
    switch (mode) {
      case _mode.VIEW: //-----------------------------상세
        return <View />
      case _mode.DEFAULT: //--------------------------기본값
      case _mode.LIST: //-----------------------------리스트
      default:
        return <List />
    }
  }
  //*--------------------------------------------------*
  return (
    <App>
      {/* 헤더 */}
      <Header />
      {/* 컨텐츠 */}
      {setContents()}
      {/* 팝업 */}
      <Popup />
    </App>
  )
}
