import React from 'react';
import styled from 'styled-components';
import { COLOR } from 'styles';
import { Row, uuid } from 'lib';

/**
 * @name LegendLabels
 * @description
 */
export default function LegendLabels({
  data,
  color = [COLOR.BLUE4, COLOR.GREEN3, COLOR.YELLOW3, COLOR.GRAY1, COLOR.RED3, COLOR.BLUE_GRAY5],
}: {
  data: string[];
  color?: string[];
}) {
  // const color = [COLOR.BLUE4, COLOR.GREEN3, COLOR.YELLOW3, COLOR.GRAY1, COLOR.RED3, COLOR.BLUE_GRAY5]

  return (
    <Content className={data.length < 3 ? 'grid2' : 'grid3'}>
      {/* 본문내용 */}
      {data?.map((list: string, index: number) => {
        return (
          <span className="item" key={uuid()}>
            <span className="color" style={{ background: color[index] }} />
            <label>{list}</label>
          </span>
        );
      })}
    </Content>
  );
}

const Content = styled.div`
  /* 본문 */
  display: grid;
  gap: 5px 10px;
  grid-template-columns: repeat(3, 1fr);
  // 2개일때 CSS변형
  &.grid2 {
    grid-template-columns: repeat(2, 1fr);
  }
  grid-template-rows: 1fr 1fr 1fr;
  span.item {
    display: flex;
    /* margin-top: 20px; */
    align-items: center;
    justify-content: center;
    .color {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 8px;
      height: 8px;
      border-radius: 2px;
    }
    label {
      display: flex;
      margin-left: 10px;
      color: ${COLOR.BLACK3};
      font-size: 10px;
      line-height: 14px;
    }
  }
  .row {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    label {
      display: flex;
      margin-left: 10px;
      color: ${COLOR.BLACK3};
      font-size: 10px;
      line-height: 14px;
    }
    .blue {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 8px;
      height: 8px;
      border-radius: 2px;
      background-color: ${COLOR.BLUE4};
    }
    .yellow {
      width: 8px;
      height: 8px;
      border-radius: 2px;
      background-color: ${COLOR.YELLOW3};
    }
  }
`;
/**

 <LegendLabels data={['공공기관', '주거시설', '상업시설', '도심 내 시설', '도로 상 시설', '기타시설']} />

 */
