/**
 * @name 캘린더
 * @description
 */
import {H1, ListDescription, Table, uuid} from 'lib'
import styled from 'styled-components'
import {ListRenderItem} from 'types'

export default function Contents() {
  // hooks
  const info = [
    {no: 1, type: '-', idTag: '-', station: '-', connector: '-', payment: '-', preAmount: '-', card: '-', cardNo: '-', finalAmount: '140,000원'},
    {no: 1, type: '-', idTag: '-', station: '-', connector: '-', payment: '-', preAmount: '-', card: '-', cardNo: '-', finalAmount: '140,000원'},
    {no: 1, type: '-', idTag: '-', station: '-', connector: '-', payment: '-', preAmount: '-', card: '-', cardNo: '-', finalAmount: '140,000원'},
    {no: 1, type: '-', idTag: '-', station: '-', connector: '-', payment: '-', preAmount: '-', card: '-', cardNo: '-', finalAmount: '140,000원'},
    {no: 1, type: '-', idTag: '-', station: '-', connector: '-', payment: '-', preAmount: '-', card: '-', cardNo: '-', finalAmount: '140,000원'},
  ]

  const info1 = [
    {key: 'MODE', value: 'TYPE1'},
    {key: '고객유형', value: '명사 일부[어떤], 온갖 종류 등의'},
    {key: '내용', value: '기술적인(언어에 대해 정해진 규칙을 제시하는 것이 아니라 실제 사용 현황을 보여주는),'},
    {key: '고객유형', value: '명사 일부[어떤], 온갖 종류 등의'},
    {key: '내용', value: '기술적인(언어에 대해 정해진 규칙을 제시하는 것이 아니라 실제 사용 현황을 보여주는),'},
  ]
  //*--------------------------------------------------*

  //*--------------------------------------------------*
  return (
    <Content>
      <H1>Table</H1>
      {/* <!--테이블시작--> */}
      <Table
        style={{marginBottom: 30}}
        info={info}
        renderHeader={() => {
          return (
            <tr key={uuid()}>
              <th className="c">No.</th>
              <th>고객유형</th>
              <th>ID Tag</th>
              <th>충전소</th>
              <th>커넥터ID</th>
              <th>결제유형</th>
              <th>사전승인금액(원)</th>
              <th>카드사</th>
              <th>카드번호</th>
              <th>최종승인금액(원)</th>
            </tr>
          )
        }}
        renderItem={({item, index}: ListRenderItem) => {
          return (
            <tr key={uuid()}>
              <td className="c">{item?.no}</td>
              <td>{item?.type}</td>
              <td>{item?.idTag}</td>
              <td>{item?.station}</td>
              <td>{item?.connector}</td>
              <td>{item?.payment}</td>
              <td>{item?.preAmount}</td>
              <td>{item?.card}</td>
              <td>{item?.cardNo}</td>
              <td className="r">{item?.finalAmount}</td>
            </tr>
          )
        }}
      />
      {/* <!--테이블끝--> */}
      <ListDescription
        info={info1}
        renderItem={({item, index}) => {
          return (
            <dl key={uuid()}>
              <dt>{item?.key}</dt>
              <dd>{item?.value}</dd>
            </dl>
          )
        }}
      />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div``
/********************************************************
[사용법]

*********************************************************/
