import React from 'react';
import { App } from 'components';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/model/index';
import { BadgesContainer, Container, Content, HeaderContainer } from './AccountManagementDetail.styles';
import { Button, Div, H3, ImageButton, Row, Text } from '@/lib/index';
import { icon_arrow_prev_black, icon_edit_white } from '@/assets/images';
import { COLOR } from '@/styles/index';
import Badge from '@/components/atoms/Badge';
import AccountAuthorityForm from '@/components/organisms/Forms/AccountAuthorityForm';
import AccountInfoForm from '@/components/organisms/Forms/AccountInfoForm';
import { AccountRoleCodeType, AdminAccountsStatusType } from '@/types/admin';
import { PopupContainer, PopupCreate } from '@/components/form';

// @name 계정관리 상세
export default function AccountManagementDetailPresenter({
  handleBackToList,
  handleReInvite,
  handleEditButton,
  reInvitePopup,
}: {
  handleBackToList: () => void;
  handleReInvite: () => void;
  handleEditButton: () => void;
  reInvitePopup: {
    successState: boolean;
    failState: boolean;
    successPopupClose: () => void;
    failPopupClose: () => void;
  };
}) {
  const { accountDetail } = useAppSelector(state => state.admin);
  const { t } = useTranslation('common');

  const renderRoleBadge = (role: { code: AccountRoleCodeType; name: string }) => {
    if (role.code === AccountRoleCodeType.ADMIN) return <Badge mode="Primary" label={role.name} />;
    if (role.code === AccountRoleCodeType.MANAGER) return <Badge mode="Primary" label={role.name} />;
    if (role.code === AccountRoleCodeType.USER) return <Badge mode="Admin" label={role.name} />;
    if (role.code === AccountRoleCodeType.CPO) return <Badge mode="Dark1" label={role.name} />;
    return '';
  };
  const renderStatusBadge = (status: AdminAccountsStatusType) => {
    if (status === AdminAccountsStatusType.ACTIVE)
      return <Badge mode="AccountStatActive" label={`${t(`pages.admin.account.status.${status}`)}`} />;
    if (status === AdminAccountsStatusType.INVITED)
      return <Badge mode="AccountStatInvited" label={`${t(`pages.admin.account.status.${status}`)}`} />;
    if (status === AdminAccountsStatusType.SUSPEND)
      return <Badge mode="AccountStatSuspend" label={`${t(`pages.admin.account.status.${status}`)}`} />;
    if (status === AdminAccountsStatusType.WITHDRAW)
      return <Badge mode="AccountStatWithdraw" label={`${t(`pages.admin.account.status.${status}`)}`} />;
    return '';
  };
  return (
    <App>
      <HeaderContainer>
        <Div style={{ width: '100%' }}>
          <Row>
            <ImageButton
              onClick={handleBackToList}
              source={icon_arrow_prev_black}
              style={{ width: 24, height: 24, marginRight: 15 }}
            />
            <H3 color={COLOR.BLACK3}>
              {accountDetail.email} {t('pages.auth.authority.detail')}
            </H3>
            <BadgesContainer>
              {renderRoleBadge(accountDetail.role)}
              {renderStatusBadge(accountDetail.status)}
            </BadgesContainer>
          </Row>
          <Button icon={icon_edit_white} label={t('common.buttons.update')} onClick={handleEditButton} />
        </Div>
      </HeaderContainer>
      <Content>
        <Container>
          <AccountInfoForm handleReInvite={handleReInvite} />
          {/* <AccountChargeStationForm /> */}
          <AccountAuthorityForm />
        </Container>
      </Content>
      <PopupContainer visible={reInvitePopup.successState} onClose={reInvitePopup.successPopupClose}>
        <PopupCreate title={t('common.toast.inviteSuccess')} onConfirm={reInvitePopup.successPopupClose} />
      </PopupContainer>
      <PopupContainer visible={reInvitePopup.failState} onClose={reInvitePopup.failPopupClose}>
        <PopupCreate
          warning
          title={t('common.toast.failedReInviteTitle')}
          message={t('common.toast.failedReInviteMessage')}
          onConfirm={reInvitePopup.failPopupClose}
        />
      </PopupContainer>
    </App>
  );
}
