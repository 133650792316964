import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TroubleReport } from '@/lib/apis/operation/trouble-reports/type';
import { getTroubleReportById, updateTroubleReports } from '@/lib/apis/operation/trouble-reports';
import TroubleReportsEditPresenter from './TroubleReportsEdit.presenter';
import { MENU } from '@/pages/router';

export default function TroubleReportsEditContainer() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [troubleReport, setTroubleReport] = useState<TroubleReport | null>(null);
  const [popupSuccessVisible, setPopupSuccessVisible] = useState(false);
  const [popupCancelVisible, setPopupCancelVisible] = useState(false);
  const [popupFailedVisible, setPopupFailedVisible] = useState(false);

  const fetchData = async () => {
    if (id) {
      const { data } = await getTroubleReportById(+id);
      setTroubleReport(data);
    }
  };
  const handleCancelPopup = () => {
    setPopupCancelVisible(true);
  };

  const handleCloseCancelPopup = () => {
    setPopupCancelVisible(false);
  };

  const handleCloseSuccessPopup = () => {
    setPopupSuccessVisible(false);
    navigate(`${MENU.OPERATION.TROUBLE_REPORTS}/${id}`);
  };

  const handleCloseFailedPopup = () => {
    setPopupFailedVisible(false);
  };

  const handleBackToList = () => {
    navigate(-1);
  };

  const handleSubmit = async (args: { admin_memo: string; status: string }) => {
    const { admin_memo, status } = args;
    try {
      const result = await updateTroubleReports(+id!, { admin_memo, status });
      if (!result.isSuccess) {
        setPopupFailedVisible(true);
        return;
      }
      setPopupSuccessVisible(true);
    } catch (e) {
      setPopupFailedVisible(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <TroubleReportsEditPresenter
      troubleReport={troubleReport}
      onSubmit={handleSubmit}
      onCancelPopup={handleCancelPopup}
      onCloseCancelPopup={handleCloseCancelPopup}
      onConfirmCancelPopup={handleBackToList}
      popupCancelVisible={popupCancelVisible}
      popupSuccessVisible={popupSuccessVisible}
      popupFailedVisible={popupFailedVisible}
      onCloseFailedPopup={handleCloseFailedPopup}
      onCloseSuccessPopup={handleCloseSuccessPopup}
      onBackToList={handleBackToList}
    />
  );
}
