import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { COLOR, layout } from '@/styles/index';

interface AuthScreenLayoutProps {
  visual: ReactNode;
  children: ReactNode;
}
export default function AuthScreenLayout({ visual, children }: AuthScreenLayoutProps) {
  return (
    <Wrapper className={layout.container_center}>
      <aside className="flex">
        <AsideTop />
        {visual}
        <AsideBottom />
      </aside>
      <main className="flex">{children}</main>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 32px;

  aside {
    border-radius: 20px;
    position: relative;
    height: calc(100vh - 64px);
    justify-content: center;
    align-items: center;
    background-color: ${COLOR.SIGN_IN_BG};
    img {
      width: 100% !important;
    }
  }
  main {
    width: 600px;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 64px);
  }
  /* <!--media query --> */
  @media all and (min-width: 0) and (max-width: 1000px) {
    aside {
      display: none;
    }
  }
`;

const AsideTop = styled.div`
  width: 100%;
  height: 100%;
`;
const AsideBottom = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0 0 20px 20px;
  background-color: rgba(75, 70, 92, 0.02);
`;
