import styled from 'styled-components';
import typography from '@/styles/typography';
import { COLOR } from '@/styles/index';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  /* 검색버튼 */
  .button {
    position: absolute;
    right: 16px;
  }
  /* input */
  input {
    ${typography.body4};
    display: flex;
    flex: 1;
    width: 100%;
    min-width: 300px;
    padding: 8px 36px 8px 16px;
    height: 40px;
    border: 1px solid ${COLOR.GRAY4};
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    color: ${COLOR.BLACK1};
    background-color: ${COLOR.WHITE};
    outline: none;
    overflow: hidden;
    /* 마우스호버 */
    &:hover {
      border-color: ${COLOR.GRAY1};
    }
    /* 포커스인 */
    &:focus {
      outline: 0;
      border-color: ${COLOR.BLUE3};
      outline-color: ${COLOR.BLUE3};
    }
    /* 비활성화 */
    &:disabled {
      border: 0;
      color: ${COLOR.GRAY3};
      background-color: ${COLOR.GRAY6};
    }
    /* 유효성검사 */
    &.invalid {
      border-color: ${COLOR.RED1} !important;
    }
    /* 플레이스홀더 */
    &::placeholder {
      color: ${COLOR.GRAY2};
    }
  }
`;
