import { API_HOST } from '@/config/index';
import api from '@/lib/apis/api';
import { objectToQueryString } from '@/lib/index';
import { AdminAccountsRequest } from '@/lib/apis/admin/type';
import { IResponse } from '@/lib/apis/api/type';
import { IAccountInvitePayload, IAdminAccount, IAdminAccountDetail, IAdminAccountListResponse } from '@/types/admin';

export const getAdminAccounts = async (
  reqParams?: AdminAccountsRequest,
): Promise<IResponse<IAdminAccountListResponse>> => {
  const param = objectToQueryString({ ...reqParams });
  const url = `${API_HOST}/admins?${param}`;
  return api.get({ url });
};

export const getAdminAccountDetail = async (seq: string): Promise<IResponse<IAdminAccountDetail>> => {
  const url = `${API_HOST}/admins/permissions/${seq}`;
  return api.get({ url });
};

export const getCheckDuplicatedCorpNumber = async (corpNum: string) => {
  const url = `${API_HOST}/admins/corp_number?corp_num=${corpNum}`;
  return api.get({ url });
};

export const inviteAccount = async (payload: IAccountInvitePayload) => {
  const url = `${API_HOST}/admins/invite`;
  return api.post({ url, payload });
};

export const reInvite = async (payload: { invite_email: string }) => {
  const url = `${API_HOST}/admins/invite`;
  return api.patch({ url, payload });
};

export const modifyPermissions = async (payload: any) => {
  const url = `${API_HOST}/admins/permissions`;
  return api.put({ url, payload });
};
