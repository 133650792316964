import React, { useEffect, useState } from 'react';
import ResetPassword from './presenter';
import { useRoutes } from 'lib';
import { useParams } from 'react-router-dom';
import { getResetEmail, patchPassword } from '@/lib/apis/auth';
import { MENU } from '@/pages/router';
import { useTranslation } from 'react-i18next';

export default function ResetPasswordContainer() {
  const { t } = useTranslation('common');
  const { key } = useParams();
  const { navigate } = useRoutes();
  const [validEmail, setValidEmail] = useState('');
  const [verifyKey, setVerifyKey] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setconfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [popupVisible, setPopupVisible] = useState(false);
  const [success, isSuccess] = useState(false);

  const onChangeHandler = (k: string, value: string) => {
    switch (k) {
      case 'pw':
        setPassword(value);
        break;
      case 'confirm':
        setconfirmPassword(value);
        break;
    }
  };

  const onSubmit = async () => {
    if (typeof key !== 'string') return;
    if (password !== confirmPassword) {
      setMessage('비밀번호가 일치하지 않습니다.');
      setPopupVisible(true);
      return;
    }

    if (!password || !confirmPassword) {
      setMessage('비밀번호를 입력해주세요');
      setPopupVisible(true);
      return;
    }
    const changedPasswordRes = await patchPassword(verifyKey, validEmail, {
      password,
      password_confirm: confirmPassword,
    });
    if (!changedPasswordRes.isSuccess) {
      setMessage((changedPasswordRes.error.errors?.[0]?.code as string) ?? 'none');
      setPopupVisible(true);
      return {
        status: 'fail',
        message: '비밀번호 변경 실패',
        code: changedPasswordRes.error.errors?.[0]?.code ?? 'none',
      };
    }
    setTitle(t('pages.auth.completedSetPassword'));
    setMessage(t('pages.auth.newPasswordToLogin'));
    isSuccess(true);
    setPopupVisible(true);
  };

  const fetchEmail = async (key: string) => {
    try {
      const email = await getResetEmail(key);
      if (!email.data) {
        navigate(MENU.ERROR_401);
      }
      if (typeof email.data === 'string') {
        setValidEmail(email.data);
      }
    } catch (e) {
      navigate(MENU.ERROR_401);
    }
  };

  useEffect(() => {
    if (!popupVisible && success) {
      navigate(MENU.AUTH.SIGN_IN);
    }
  }, [popupVisible]);

  useEffect(() => {
    if (key) {
      fetchEmail(key);
      setVerifyKey(key);
    }
  }, [key]);
  return (
    <ResetPassword
      validEmail={validEmail}
      onSubmit={onSubmit}
      setPopupVisible={setPopupVisible}
      message={message}
      navigate={navigate}
      onChangeHandler={onChangeHandler}
      popupVisible={popupVisible}
      success={success}
      title={title}
    />
  );
}
