/**
 * @name ButtonAddModels
 * @description 등록할 충전기의 제조사/모델을 선택해 주세요.
 */
import React, { useEffect, useState } from 'react';
import { API_HOST } from 'config';
import { Button, Text, useAxios, useContext } from 'lib';
import { useAppDispatch } from 'model';
import styled from 'styled-components';
import { COLOR } from 'styles';
import { useTranslation } from 'react-i18next';
import { getModels, getVendors } from '@/lib/apis/infra';
import { IResponse } from '@/lib/apis/api/type';
import { IChargePointModel, IVendor } from '@/types/infra';
import Dropdown from '@/components/molecules/Dropdown';
import { DropdownOptions } from '@/components/molecules/Dropdown/Dropdown.types';

type Props = {
  onChange?: (seq: string, vendor?: IVendor) => void;
  style?: React.CSSProperties;
  [key: string]: any;
};

export default function ButtonAddVendorModels({ style, onChange }: Props) {
  const { cpo_seq } = useContext();
  const { get } = useAxios();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');

  const [vendorOptionList, setVendorOptionList] = useState<DropdownOptions[]>([]);
  const [modelOptionList, setModelOptionList] = useState<DropdownOptions[]>([]);
  const [vendorList, setVendorList] = useState<IVendor[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<IVendor>();

  // TODO 벤더, 모델 리스트 마지막에 신규 등록 버튼 추가해야함
  const fetchVendorList = async () => {
    const vendorsResponse: IResponse<IVendor[]> = await getVendors();
    if (vendorsResponse.isSuccess) {
      setVendorList(vendorsResponse.data);
      const temp = vendorsResponse.data.map((item: IVendor) => {
        return {
          name: item.name,
          id: `${item.seq}`,
        };
      });
      setVendorOptionList(temp);
    }
  };

  const fetchModelListByVendor = async (vendorName: string) => {
    setModelOptionList([]);
    const modelsResponse: IResponse<IChargePointModel[]> = await getModels({ vendor: vendorName });
    if (modelsResponse.isSuccess) {
      const temp = modelsResponse.data.map((item: IChargePointModel) => {
        return {
          name: item.name,
          id: `${item.seq}`,
        };
      });
      setModelOptionList(temp);
    }
  };

  const handleSelectVendor = (name: string, seq: string) => {
    const vendor = vendorList.find(vendor => `${vendor.seq}` === `${seq}`);
    setSelectedVendor(vendor);
    fetchModelListByVendor(name);
  };

  const handleSelectModel = (seq: string) => {
    if (onChange) {
      onChange(seq, selectedVendor);
    }
  };

  useEffect(() => {
    fetchVendorList();
  }, []);

  return (
    <Element style={style}>
      <div className="center">
        <Text color={COLOR.GRAY1}>{t('common.buttons.addVendor')}</Text>
      </div>
      <div className="center" style={{ marginTop: 16 }}>
        {vendorOptionList.length > 0 && (
          <Dropdown
            wrapperStyle={{ minWidth: 190 }}
            selectBoxName="제조사선택"
            dropdownOptions={vendorOptionList}
            placeholder={t('common.buttons.selectVendor')}
            selectField="vendor"
            onChangeSelectOption={({ name, value }) => handleSelectVendor(name ?? '', value)}
          />
          // <DropDown
          //   info={vendor}
          //   onChange={(items: any) => {
          //     const model = items?.value?.map((item: any) => {
          //       return {
          //         label: item?.name,
          //         value: item?.seq,
          //       };
          //     });
          //     setModels(model);
          //   }}
          // />
        )}
        <Dropdown
          wrapperStyle={{ minWidth: 190 }}
          selectBoxName="제조사모델선택"
          dropdownOptions={modelOptionList}
          placeholder={t('common.buttons.selectModel')}
          selectField="model"
          disabled={modelOptionList.length === 0}
          onChangeSelectOption={({ value }) => handleSelectModel(value)}
        />
        {/* {modelOptionList.length > 0 && ( */}
        {/*  // <DropDown */}
        {/*  //   info={models} */}
        {/*  //   onChange={(item: any) => { */}
        {/*  //     if (onChange) { */}
        {/*  //       onChange(item?.value); */}
        {/*  //     } */}
        {/*  //   }} */}
        {/*  // /> */}
        {/* )} */}
      </div>
    </Element>
  );
}
const Element = styled.div`
  padding: 24px 0 24px;
  border-left: 1px solid ${COLOR.BLUE8};
  border-right: 1px solid ${COLOR.BLUE8};
  border-bottom: 1px solid ${COLOR.BLUE8};
  .center {
    width: 450px;
    margin: 0 auto;
    > * {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
`;
