import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'model';
import { useNavigate } from 'react-router-dom';
import { actions } from '@/model/modules/admin';
import { inviteAccount } from '@/lib/apis/admin';
import AccountManagementRegisterPresenter from './AccountManagementRegister.presenter';
import { MENU } from '@/pages/router';
import { SCREEN_MODE } from '@/types/mode';

/**
 * @name AccountManagementRegisterContainer
 * @description 계정별 권한관리
 */
export default function AccountManagementRegisterContainer() {
  const { invitePayload, duplicatedCheck } = useAppSelector(state => state.admin);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [popupInviteVisible, setPopupInviteVisible] = useState(false);
  const [popupSuccessVisible, setPopupSuccessVisible] = useState(false);
  const [popupFailedVisible, setPopupFailedVisible] = useState(false);
  const [popupCancelVisible, setPopupCancelVisible] = useState(false);
  const [requiredFieldsWarning, setRequiredFieldsWarning] = useState({
    email: false,
    roleCode: false,
    businessNumber: false,
    requireDuplicateCheck: false,
    operatorCompanyName: false,
  });

  const handleInvitePopup = (value: boolean) => {
    if (value) {
      if (checkRequiredInputs()) {
        setPopupInviteVisible(value);
      }
    } else {
      setPopupInviteVisible(value);
    }
  };

  const checkRequiredInputs = () => {
    let checkResult = true;
    setRequiredFieldsWarning({
      email: false,
      roleCode: false,
      businessNumber: false,
      requireDuplicateCheck: false,
      operatorCompanyName: false,
    });
    if (invitePayload.invite_email === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        email: true,
      }));
      checkResult = false;
    }
    // if (invitePayload.businessNumber === '') {
    //   setRequiredFieldsWarning(prevState => ({
    //     ...prevState,
    //     businessNumber: true,
    //   }));
    //   checkResult = false;
    // } else
    if (!duplicatedCheck) {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        requireDuplicateCheck: true,
      }));
      checkResult = false;
    }
    // if (invitePayload.role_code === '') {
    //   setRequiredFieldsWarning(prevState => ({
    //     ...prevState,
    //     roleCode: true,
    //   }));
    //   checkResult = false;
    // }
    // if (invitePayload.operatorCompanyName === '') {
    //   setRequiredFieldsWarning(prevState => ({
    //     ...prevState,
    //     operatorCompanyName: true,
    //   }));
    //   checkResult = false;
    // }

    return checkResult;
  };

  const handleInviteSubmit = async () => {
    // TODO 입력사항 확인

    const payload = {
      ...invitePayload,
    };
    try {
      const submitResponse = await inviteAccount(payload);
      if (submitResponse.isSuccess) {
        setPopupSuccessVisible(true);
      }
    } catch (e) {
      setPopupFailedVisible(true);
    } finally {
      handleInvitePopup(false);
    }
  };

  const handleDone = () => {
    setPopupSuccessVisible(false);
    navigate(MENU.ADMIN.ACCOUNT_MANAGEMENT);
  };

  const handleCloseCancelPopup = () => {
    setPopupCancelVisible(false);
  };

  const handleCloseFailedPopup = () => {
    setPopupFailedVisible(false);
  };

  const onCancelPopup = () => {
    setPopupCancelVisible(true);
  };

  useEffect(() => {
    return () => {
      dispatch(actions.setMode(SCREEN_MODE.DEFAULT));
      dispatch(actions.initInvitePayload());
    };
  }, []);

  return (
    <AccountManagementRegisterPresenter
      popupInviteVisible={popupInviteVisible}
      handleInvitePopup={{
        on: () => handleInvitePopup(true),
        close: () => handleInvitePopup(false),
        confirm: handleInviteSubmit,
      }}
      popupSuccessVisible={popupSuccessVisible}
      handleDone={handleDone}
      popupCancelVisible={popupCancelVisible}
      handleCloseCancelPopup={handleCloseCancelPopup}
      popupFailedVisible={popupFailedVisible}
      handleCloseFailedPopup={handleCloseFailedPopup}
      onCancelPopup={onCancelPopup}
      requiredFieldsWarning={requiredFieldsWarning}
    />
  );
}
