import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'model';
import { useLocation, useNavigate } from 'react-router-dom';
import ChargingRateListPresenter from './ChargingRateList.presenter';
import { getRoamingTariffList, getTariff } from '@/lib/apis/tariff';
import { actions } from '@/model/modules/price';
import { SCREEN_MODE } from '@/types/mode';
import { Paging } from '@/lib/apis/infra/type';

export default function ChargingRateListContainer() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);

  const onInit = () => {
    dispatch(actions.setMode(SCREEN_MODE.LIST));
    fetchData();
  };

  const queryParamsURL = (page?: number) => {
    const queryParams = [];
    queryParams.push(`page=${page ?? currentPage}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const pageHandler = (page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      navigate(`?${queryParamsURL(page)}`);
      fetchRoamingData({
        size: itemsPerPage,
        page,
      });
    }
  };
  const fetchData = async () => {
    const { isSuccess, data } = await getTariff();
    if (isSuccess) {
      // TODO Roaming 리스트 가져오기
      // data type은  GetTariffRes[]
      // data 배열 탐색, 타입으로 구분. data.map( // if type 고정 -> fixedData, if type 로밍 -> roamingData)
      // 고정단가일경우 아래처럼 actions.setList
      // 로밍단가일경우 actions.setRoamingList를 이용한다
      dispatch(actions.setList(data)); // data -> fixedData
    }
  };

  // TODO Roaming List 가져오기
  const fetchRoamingData = async (paging: Paging) => {
    const { isSuccess, data, meta } = await getRoamingTariffList(paging);
    if (isSuccess) {
      dispatch(
        actions.setRoamingList({
          dataList: data,
          totalCount: meta.paging?.total_count ?? NaN,
        }),
      );
    }
  };

  useEffect(() => {
    onInit();
    fetchRoamingData({
      size: itemsPerPage,
      page: currentPage,
    });
  }, []);

  return <ChargingRateListPresenter itemsPerPage={itemsPerPage} currentPage={currentPage} pageHandler={pageHandler} />;
}
