import styled from 'styled-components';
import { COLOR } from 'styles';
import typography from '@/styles/typography';

export const ImageIsNotExist = styled.div`
  background-color: ${COLOR.GRAY6};
  border-radius: 8px;
  padding: 24px 16px;
  border: 2px dashed ${COLOR.GRAY4};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

export const ImageIsNotExistTitle = styled.p`
  color: ${COLOR.GRAY3};
  ${typography.headline5};
`;
export const ImageIsNotExistDescription = styled.p`
  color: ${COLOR.GRAY3};
  ${typography.body7};
`;

export const NameInputContainer = styled.div`
  display: flex;
  gap: 4px;
`;

export const NumbersWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
