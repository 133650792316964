import { API_HOST } from '@/config/index';
import api from '@/lib/apis/api';
import { IResponse } from '../api/type';
import { ReqPutCompany, ReqPutPassword } from './type';
import { ResLogin } from '@/pages/common';

export const getEmail = async (key: string) => {
  const url = `${API_HOST}/admins/invites/${key}`;
  return api.get({ url }) as unknown as IResponse<string>;
};

export const putPassword = async (key: string, payload: ReqPutPassword) => {
  const url = `${API_HOST}/admins/invites/password/${key}`;
  return api.put({ url, payload }) as unknown as IResponse<ResLogin>;
};

export const putCompany = async (payload: ReqPutCompany) => {
  const url = `${API_HOST}/admins/invites/company`;
  return api.put({ url, payload });
};

export const postResetEmail = async (email: string) => {
  const url = `${API_HOST}/auth/password/reset`;
  return api.post({ url, payload: { email } });
};

export const getResetEmail = async (key: string) => {
  const url = `${API_HOST}/auth/password/reset/${key}`;
  return api.get({ url }) as unknown as IResponse<string>;
};

export const patchPassword = async (key: string, email: string, payload: ReqPutPassword) => {
  const url = `${API_HOST}/auth/password/reset/${key}`;
  return api.patch({
    url,
    payload: {
      ...payload,
      email,
    },
  }) as unknown as IResponse<ResLogin>;
};
