import React from 'react';
import { App } from 'components';
import { addComma, Button, H3, Row, Text } from 'lib';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'model';
import { Header, ButtonWrapper, HeaderFuncWrapper, FilterWrapper } from './ChargerList.styles';
import { icon_plus_white } from '@/assets/images';
import ChargersTable from '@/components/organisms/Tables/ChargersTable';
import ChargerFilter from '@/components/organisms/Filters/ChargerFilter';
import FilterButton from '@/components/atoms/Buttons/FilterButton';
import { IChargerFilter } from '@/components/organisms/Filters/ChargerFilter/ChargerFilter.type';
import { PopupContainer, PopupCreate, SubmitCancel, TextFiledSearch } from '@/components/form';
import { COLOR } from '@/styles/index';
import { actions } from '@/model/modules/device';
import DownloadButton from '@/components/atoms/Buttons/DownloadButton';

/**
 * @name List
 * @description
 */
export default function ChargerListPresenter({
  itemsPerPage,
  currentPage,
  pageHandler,
  handleDelete,
  queryString,
  onRegister,
  handleExcelDownload,
  detailSearchTerms,
  handleSetDetailSearchTerms,
  handleSearchKeyword,
  handleSearch,
  searchKeyword,
  deletePopupVisible,
  onDeleteConfirm,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  onRegister: () => void;
  handleExcelDownload: () => void;
  handleDelete: {
    popup: (value: boolean) => void;
    setId: (value: string) => void;
  };
  queryString: string;
  detailSearchTerms: IChargerFilter;
  handleSetDetailSearchTerms: (terms: IChargerFilter) => void;
  handleSearchKeyword: (keyword: string) => void;
  handleSearch: () => void;
  searchKeyword: string;
  deletePopupVisible: boolean;
  onDeleteConfirm: () => void;
}) {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const { totalCount, search_payload } = useAppSelector(state => state.device);

  return (
    <App>
      <Header style={{ width: '100%' }}>
        <HeaderFuncWrapper>
          <Row>
            <div style={{ marginRight: 20 }}>
              <H3 color={COLOR.BLACK3}>{t('pages.infra.chargerManage.registerManage')}</H3>
              <Text color={COLOR.BLACK3} className="body6">
                {`${t('common.unit.total')} ${addComma(totalCount)}${t('common.unit.cases')}`}
              </Text>
            </div>
            <div className="search" style={{ marginLeft: 20 }}>
              <TextFiledSearch
                defaultValue={searchKeyword}
                onChange={str => {
                  handleSearchKeyword(str);
                }}
                onSubmit={() => {
                  handleSearch();
                }}
              />
            </div>
            <FilterButton
              style={{ marginLeft: 8 }}
              value={search_payload.isOpen}
              onChange={() => {
                dispatch(actions.setSearchPayload({ isOpen: !search_payload.isOpen }));
                // dispatch(_action.setSearchPayload({ isOpen: bool }));
                // console.log(bool)
              }}
            />
          </Row>
          <ButtonWrapper>
            <Button
              icon={icon_plus_white}
              label={t('forms.charger.registerCharger')}
              style={{ marginRight: 16 }}
              onClick={onRegister}
            />
            <DownloadButton onClick={handleExcelDownload} />
          </ButtonWrapper>
        </HeaderFuncWrapper>
        <FilterWrapper>
          {search_payload.isOpen && (
            <ChargerFilter
              detailSearchTerms={detailSearchTerms}
              handleSetDetailSearchTerms={handleSetDetailSearchTerms}
            />
          )}
        </FilterWrapper>
      </Header>
      <ChargersTable
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        pageHandler={pageHandler}
        handleDelete={handleDelete}
        queryString={queryString}
        onRegister={onRegister}
      />
      <PopupContainer onClose={() => handleDelete.popup(false)} visible={deletePopupVisible}>
        <PopupCreate
          warning
          title={t('common.toast.removeCharger')}
          message={t('common.toast.askRemoveCharger')}
          footer={
            <SubmitCancel
              confirmLabel={t('common.buttons.removeAction')}
              onCancel={() => handleDelete.popup(false)}
              onConfirm={onDeleteConfirm}
            />
          }
        />
      </PopupContainer>
    </App>
  );
}
