import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SCREEN_MODE } from '@/types/mode';
import { IAccountInvitePayload, IAccountPayload, IAdminAccount, IAdminAccountDetail } from '@/types/admin';

/**
 * @name 계정관리
 * @description
 */
/** @defineType */
type AdminStateType = {
  mode: string;
  list: IAdminAccount[];
  cpo_count: number;
  admin_count: number;
  totalCount: number;
  permissions: null | {};
  accountDetail: IAdminAccountDetail;
  payload: IAccountPayload;
  invitePayload: IAccountInvitePayload;
  duplicatedCheck: boolean;
};

/** @initialState */
const initialState: AdminStateType = {
  mode: SCREEN_MODE.DEFAULT,
  list: [],
  cpo_count: 0,
  admin_count: 0,
  totalCount: 0,
  permissions: null,
  payload: {
    email: '',
    name: '',
    phone: '',
    cpoName: '',
    cpoPhone: '',
    cpoCustomerCenterPhone: '',
    menuPermissions: [],
  },
  invitePayload: {
    invite_email: '',
    role_code: '',
    timezone_code: 'Asia/seoul', // TODO 수정필요
    country_code2: 'KR', // TODO 수정필요
    operatorCompanyName: '',
    businessNumber: '',
    assign_menu: [],
  },
  accountDetail: {
    seq: 0,
    email: '',
    name: '',
    phone: '',
    role: {
      code: '',
      name: '',
    },
    admin_cpo_list: [],
    menu_permissions: [],
    status: '',
    language_code: '',
    last_modified_at: '',
    last_modified_by: '',
    timezone: '',
    withdrew_at: '',
    country_code2: '',
    created_at: '',
    created_by: 0,
    admin_permissions: [],
  },
  duplicatedCheck: true,
};

/** @createSlice */
const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    // setMode
    setMode: (state: AdminStateType, action: PayloadAction<any>) => {
      state.mode = action.payload;
    },
    // setAdmins
    setList: (
      state: AdminStateType,
      action: PayloadAction<{ list: IAdminAccount[]; totalCount: number; admin_count: number; cpo_count: number }>,
    ) => {
      state.list = action.payload.list;
      state.cpo_count = action.payload.cpo_count;
      state.admin_count = action.payload.admin_count;
      state.totalCount = action.payload.totalCount;
    },
    // 계정권한자세히보기
    setPermissions: (state: AdminStateType, action: PayloadAction<any>) => {
      state.permissions = action.payload;
    },
    // create Payload업데이트
    setPayload: (state: AdminStateType, action: PayloadAction<IAccountPayload>) => {
      state.payload = action.payload;
    },
    setInvitePayload: (state: AdminStateType, action: PayloadAction<IAccountInvitePayload>) => {
      state.invitePayload = action.payload;
    },
    initInvitePayload: (state: AdminStateType) => {
      state.invitePayload = initialState.invitePayload;
    },
    setDuplicatedCheck: (state: AdminStateType, action: PayloadAction<boolean>) => {
      state.duplicatedCheck = action.payload;
    },
    setAccountDetailData: (state: AdminStateType, action: PayloadAction<IAdminAccountDetail>) => {
      state.accountDetail = action.payload;
    },
  },
});

export default adminSlice.reducer;
export const {
  setMode,
  setList,
  setPermissions,
  setPayload,
  setInvitePayload,
  setDuplicatedCheck,
  setAccountDetailData,
  initInvitePayload,
} = adminSlice.actions;
export const { actions } = adminSlice;
