/**
 * @name 푸터메뉴
 * @description
 */
import styled from 'styled-components'
import {sprite_gnb_2x} from 'assets/images'
import {H6, useRoutes, uuid} from 'lib'
import {FooterNavType} from 'types'
import {COLOR} from 'styles'

type Props = {
  item: FooterNavType
  depth1: number
  isOpen: boolean
}
export default function Contents({item, depth1, isOpen}: Props) {
  // hooks
  const {navigate} = useRoutes()
  const {name, path, classnames} = item as FooterNavType
  //*--------------------------------------------------*
  return (
    <Content
      // notification disabled
      className={`gnb-menu ${classnames}`}
      onClick={() => {
        if (!!item?.state) {
          navigate(path, {state: item?.state})
        } else {
          navigate(path)
        }
      }}
      key={uuid()}>
      <figure className={`gnb ${classnames}`} /> {/* 아이콘 */}
      {isOpen && <H6>{name}</H6>}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.button`
  /**-------본문--*/
  display: flex;
  align-items: center;
  justify-content: 'center';
  align-items: center;
  height: 44px;
  margin-bottom: 4px;
  border-radius: 8px;
  h6 {
    margin-left: 4px;
    color: ${COLOR.GRAY3};
  }
  /**-------figure--*/
  figure {
    width: 44px !important;
    height: 44px !important;
    background-image: url(${sprite_gnb_2x});
    /* background-position-x: -320px; */
  }
`
