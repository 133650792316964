import React from 'react';
import { useTranslation } from 'react-i18next';
import { addComma, calculateChargeDurationInMinutes, formatDateTime, Image } from 'lib';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableHead,
  TD,
  TH,
  TR,
  Wrapper,
  NoResultDescription,
  NoResultText,
  NoResultTextWrapper,
  NoSearchResultTextWrapper,
  NoSearchResultWrapper,
  InnerCellContainer,
  InnerCellTextButton,
} from './SalesChargingStatusTable.style';
import Pagination from '@/components/organisms/Paginations/Pagination';
import { useAppSelector } from '@/model/index';
import typography from '@/styles/typography';
import { icons } from '@/assets/images';
import { IChargingStatus } from '@/types/chargingStatus';
import Badge from '@/components/atoms/Badge';
import { TariffUserType } from '@/lib/apis/tariff/type';
import { COLOR } from '@/styles/index';
import { MENU } from '@/pages/router';

export default function SalesChargingStatusTable({
  itemsPerPage,
  currentPage,
  pageHandler,
  queryString,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  queryString?: string;
}) {
  const { t } = useTranslation('common');
  const { list, totalCount } = useAppSelector(state => state.chargingStatus);
  const navigate = useNavigate();

  const renderBadgeByMemberType = (type: TariffUserType) => {
    if (type === TariffUserType.NONE) {
      return <Badge mode="TariffNone" label={t('forms.price.member.none')} />;
    }
    if (type === TariffUserType.USER) {
      return <Badge mode="TariffUser" label={t('forms.price.member.user')} />;
    }
    return <Badge mode="TariffRoaming" label={t('forms.price.member.roaming')} />;
  };

  const handleNavigateToDetail = (seq: number) => {
    // navigate(`${MENU.SALES.CHARGING_STATUS}/${seq}?${queryString}`);
    navigate(`${MENU.SALES.CHARGING_STATUS}/${seq}`);
  };

  return (
    <Wrapper>
      <Table>
        <colgroup>
          <col style={{ width: 46 }} />
          <col style={{ minWidth: 92 }} />
          <col style={{ minWidth: 92 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 92 }} />
          <col style={{ minWidth: 92 }} />
          <col style={{ minWidth: 92 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 92 }} />
          <col style={{ minWidth: 92 }} />
          <col style={{ minWidth: 92 }} />
          {/* <col style={{ minWidth: 92 }} /> */}
        </colgroup>
        <TableHead>
          <TR>
            <TH>No.</TH>
            <TH>{t('pages.sales.chargingStatus.customerType')}</TH>
            <TH>{t('pages.sales.chargingStatus.idTag')}</TH>
            <TH>{t('pages.sales.chargingStatus.station')}</TH>
            <TH>{t('pages.sales.chargingStatus.connectorId')}</TH>
            <TH>{t('pages.sales.chargingStatus.connectorType')}</TH>
            <TH>{`${t('pages.sales.chargingStatus.rate')}${t('common.unit.brackets.priceWonBykWh')}`}</TH>
            <TH>{t('pages.sales.chargingStatus.startDate')}</TH>
            <TH>{t('pages.sales.chargingStatus.endDate')}</TH>
            <TH>{`${t('pages.sales.chargingStatus.chargingTime')}${t('common.unit.brackets.minute')}`}</TH>
            <TH>{`${t('pages.sales.chargingStatus.chargingAmount')}${t('common.unit.brackets.kWh')}`}</TH>
            <TH>{`${t('pages.sales.chargingStatus.chargingCost')}${t('common.unit.brackets.priceWon')}`}</TH>
            {/* <TH>{`${t('pages.sales.chargingStatus.finalCost')}${t('common.unit.brackets.priceWon')}`}</TH> */}
          </TR>
        </TableHead>
        <TableBody>
          {list?.length > 0 &&
            list.map((item: IChargingStatus, index: number) => (
              <TR key={item.transaction_id}>
                <TD $customFont={typography.body4}>
                  {totalCount - (currentPage - 1) * itemsPerPage - index}
                  {/* {itemsPerPage * (currentPage - 1) + (index + 1)} */}
                </TD>
                <TD $customFont={typography.body5}>{renderBadgeByMemberType(item.user_type)}</TD>
                <TD $customFont={typography.body5} color={COLOR.BLUE4}>
                  <InnerCellContainer>
                    <InnerCellTextButton onClick={() => handleNavigateToDetail(item.transaction_id)}>
                      {item.id_tag}
                    </InnerCellTextButton>
                  </InnerCellContainer>
                </TD>
                <TD $customFont={typography.body5}>{item.station_name}</TD>
                <TD $customFont={typography.body5}>{item.connector_code}</TD>
                <TD $customFont={typography.body5}>{t(`common.connector.${item.connector_types}`)}</TD>
                <TD $customFont={typography.body5}>{item.price_per_kw}</TD>
                <TD $customFont={typography.body5}>{formatDateTime(item.started_at)}</TD>
                <TD $customFont={typography.body5}>{formatDateTime(item.ended_at)}</TD>
                <TD $customFont={typography.body5}>
                  {calculateChargeDurationInMinutes(item.started_at, item.ended_at)}
                </TD>
                <TD $customFont={typography.body5}>{item.kw}</TD>
                <TD $customFont={typography.body5}>{addComma(item.total_amount)}</TD>
                {/* <TD $customFont={typography.body5}>{item.amount}</TD> */}
              </TR>
            ))}
        </TableBody>
      </Table>
      {list.length > 0 && (
        <Pagination
          listLength={totalCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onClick={pageHandler}
        />
      )}
      {list.length === 0 && (
        <NoSearchResultWrapper>
          <Image src={icons.noSalesData.image.default} style={{ width: 192, height: 192 }} />
          <NoSearchResultTextWrapper>
            <NoResultTextWrapper>
              <NoResultText>{t('pages.sales.chargingStatus.noDataTitle')}</NoResultText>
            </NoResultTextWrapper>
            <NoResultDescription>{t('pages.sales.chargingStatus.description')}</NoResultDescription>
          </NoSearchResultTextWrapper>
        </NoSearchResultWrapper>
      )}
    </Wrapper>
  );
}
