import i18n from 'i18next';
import {initReactI18next} from "react-i18next";

i18n.use(initReactI18next).init({
  debug: false,
  lng: 'ko',
  fallbackLng: 'ko',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    ko: {
      common: require('./ko/common.json'),
    },
    eng: {
      common: require('./eng/common.json'),
    },
  }
});

export default i18n;