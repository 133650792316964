import React from 'react';
import styled from 'styled-components';
import { Image } from 'lib';
import { icon_radio_disabled, icon_radio_on } from 'assets/images';

type Props = {
  disabled?: boolean;
  checked?: boolean;
  style?: React.CSSProperties;
  onChange?: (value: boolean) => void;
};
/**
 * @name FormRadio
 * @description
 */
export default function FormRadio({ onChange, disabled, checked, style }: Props) {
  return (
    <Content
      style={style}
      className={`row ${checked ? 'active' : ''}`}
      onClick={() => {
        if (onChange) {
          onChange(!checked);
        }
      }}>
      {/* {checked + ''} */}
      {/* {checked === null && <Image src={icon_radio_disabled} style={{width: 18, height: 18}} />} */}
      {disabled && <Image src={icon_radio_disabled} style={{ width: 18, height: 18 }} />}
      {!disabled && <Image src={checked ? icon_radio_on : icon_radio_disabled} style={{ width: 18, height: 18 }} />}
    </Content>
  );
}
const Content = styled.button`
  position: relative;
  width: 18px;
  height: 18px;
  margin-left: auto;
  margin-right: auto;
`;
