import { IMenuItems } from '@/types/menu';

export enum AdminAccountsStatusType {
  INVITED = 'INVITED',
  ACTIVE = 'ACTIVE',
  SUSPEND = 'SUSPEND',
  WITHDRAW = 'WITHDRAW',
}

export enum AccountRoleCodeType {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  USER = 'USER',
  CPO = 'CPO',
}

export interface IAdminAccountListResponse {
  cpo_count: number;
  admin_count: number;
  rows: IAdminAccount[];
}

export interface IAdminAccount {
  admin_staions_count: number;
  created_at: string;
  email: string;
  invite: {
    created_at: string;
    reinvited_at: string;
  };
  role: {
    code: AccountRoleCodeType;
    name: string;
  };
  seq: number;
  status: AdminAccountsStatusType;
}

export interface IAdminAccountDetail {
  seq: number;
  email: string;
  name: string;
  phone: string;
  language_code: string;
  last_modified_at: string;
  last_modified_by: string;
  timezone: string;
  withdrew_at: string;
  country_code2: string;
  created_at: string;
  created_by: number;
  role: {
    code: AccountRoleCodeType | '';
    name: string;
  };
  status: AdminAccountsStatusType | '';
  admin_cpo_list: any[];
  menu_permissions: IMenuItems[];
  admin_permissions: IAdminPermission[];
}

export interface IAdminPermission {
  is_create: boolean;
  is_read: boolean;
  is_update: boolean;
  is_delete: boolean;
  menu: {
    name: string;
    seq: number;
  };
}

export enum RoleFilterType {
  ALL = 'ALL',
  ADMIN = 'ADMIN',
  OPERATOR = 'OPERATOR',
}

export interface IAccountInvitePayload {
  invite_email: string;
  role_code: RoleCode | '';
  timezone_code: string;
  country_code2: string;
  assign_menu: IAssignMenu[];
  operatorCompanyName: string;
  businessNumber: string;
}

export type RoleCode = 'ADMIN' | 'MANAGER' | 'USER' | 'CPO';

export interface IAssignMenu {
  menu_seq: number;
  is_read: number;
  is_create: number;
  is_update: number;
  is_delete: number;
}

export interface IAccountPayload {
  email: string;
  name: string;
  phone: string;
  cpoName: string;
  cpoPhone: string;
  cpoCustomerCenterPhone: string;
  menuPermissions: MenuPermissions[];
}

export interface MenuPermissions {
  menu_seq: number;
  menu_admin_permissions_seq: number;
  cpo_seq: number;
  admin_seq: number;
  is_read: number;
  is_create: number;
  is_update: number;
  is_delete: number;
}
