import React, { useEffect } from 'react';
import styled from 'styled-components';
import { COLOR } from 'styles';
import { Row, uuid } from 'lib';
import { Panel } from 'components';
import TotalCount from '@/components/atoms/Labels/TotalCount';
import TotalSalesItemBlue from './TotalSalesItemBlue';
import TotalSalesItemYellow from './TotalSalesItemYellow';
import { useAppSelector, useAppDispatch } from '@/model/index';
import LegendLabels from '@/components/atoms/Labels/LegendLabels';
import { getTotal } from '@/lib/apis/dashborad';
import { actions } from '@/model/modules/dashboard';

/**
 * @name 매출총액
 * @description
 */
export default function TotalSales() {
  const { period, total } = useAppSelector(state => state.dashboard);
  const dispatch = useAppDispatch();
  const fetchData = async () => {
    const { isSuccess, data } = await getTotal({ type: 1 });
    if (isSuccess) {
      dispatch(actions.setTotal(data));
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  // const
  //* --------------------------------------------------*
  const setContents = () => {
    switch (period?.calc_type) {
      case 'REALTIME':
      default:
        return (
          <section>
            <TotalCount comma value={total} />
            {/* <TotalCount comma value={period?.calc_type?.total_charge_amount} /> */}
            <TotalSalesItemBlue style={{ width: 135, height: 135 }} />
          </section>
        );
      case 'TERMS':
      case 'STATIONS':
        return (
          <section>
            <Row>
              <div className="group">
                <TotalSalesItemBlue style={{ width: 110, height: 110, marginBottom: 10 }} />
                <TotalCount comma value={period?.calc_type?.src_data?.total_charge_amount} />
              </div>
              <div className="group">
                <TotalSalesItemYellow style={{ width: 110, height: 110, marginBottom: 10 }} />
                <TotalCount
                  comma
                  titleColor={COLOR.YELLOW2}
                  value={period?.calc_type?.compare_data?.total_charge_amount}
                />
              </div>
            </Row>
            <LegendLabels data={['설정 기간', '비교 기간']} color={[COLOR.BLUE4, COLOR.YELLOW3]} />
          </section>
        );
    }
  };

  return (
    <Panel key={uuid()} mainTitle="매출 총액">
      <Content>{setContents()}</Content>
    </Panel>
  );
}

const Content = styled.div`
  section {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .group {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 15px;
    }
  }
`;
