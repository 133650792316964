import React from 'react';
import { PageButton, Wrapper } from './Pagination.style';
import PaginationFunctionButton from '@/components/atoms/Buttons/PaginationFunctionButton';
import { ButtonSize } from '@/components/atoms/Buttons/Buttons.types';

function Pagination({
  listLength,
  itemsPerPage,
  currentPage,
  onClick,
}: {
  listLength: number;
  itemsPerPage: number;
  currentPage: number;
  onClick: (page: number) => void;
}) {
  const pageNumbers = [];
  const maxPage = Math.ceil(listLength / itemsPerPage);
  const numPagesToShow = 5;
  let startPage;
  let endPage;

  if (currentPage <= 4) {
    // 첫 번째 페이지 근처
    startPage = 1;
    endPage = Math.min(numPagesToShow, maxPage);
  } else if (currentPage + 3 >= maxPage) {
    // 마지막 페이지 근처
    startPage = Math.max(maxPage - (numPagesToShow - 1), 1);
    endPage = maxPage;
  } else {
    // 중간 페이지
    startPage = Math.max(currentPage - 1, 1);
    endPage = Math.min(currentPage + 1, maxPage);
  }
  for (let i = startPage; i <= endPage; i += 1) {
    pageNumbers.push(i);
  }

  return (
    <Wrapper>
      <PaginationFunctionButton
        title="이전"
        size={ButtonSize.S}
        direction="left"
        disabled={currentPage === 1}
        onClick={() => onClick(currentPage - 1)}
      />
      {startPage > 1 && (
        <PageButton
          $active={currentPage === 1}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            onClick(1);
            event.currentTarget.blur();
          }}>
          1
        </PageButton>
      )}
      {startPage > 2 && <span>...</span>}
      {pageNumbers.map(number => (
        <PageButton
          key={number}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            onClick(number);
            event.currentTarget.blur();
          }}
          $active={number === currentPage}>
          {number}
        </PageButton>
      ))}
      {endPage < maxPage - 1 && <span>...</span>}
      {endPage < maxPage && (
        <PageButton
          $active={currentPage === maxPage}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            onClick(maxPage);
            event.currentTarget.blur();
          }}>
          {maxPage}
        </PageButton>
      )}
      <PaginationFunctionButton
        title="다음"
        size={ButtonSize.S}
        direction="right"
        disabled={currentPage === maxPage}
        onClick={() => onClick(currentPage + 1)}
      />
    </Wrapper>
  );
}

export default Pagination;
