/**
 * @name Switch
 * @description track,cursor
 */
import styled from 'styled-components'
import {motion} from 'framer-motion'
import {COLOR} from 'styles'
import {useRef} from 'react'

type Props = {
  isBool: boolean
  setIsBool: (isBool: boolean) => void
}
export default function Switch({isBool, setIsBool}: Props) {
  // hooks
  // const [isBool, setIsBool] = useState(false)
  //*--------------------------------------------------*
  return (
    <Element
      onClick={() => {
        !!setIsBool && setIsBool(!isBool)
      }}>
      <Track animate={{backgroundColor: isBool ? COLOR.GREEN1 : COLOR.BLUE2}} />
      <Cursor animate={{left: isBool ? 16 : 4}} />
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.button`
  position: relative;
`
const Track = styled(motion.div)`
  position: relative;
  width: 32px;
  height: 16px;
  border: 0;
  border-radius: 20px;
  background-color: black;
`
const Cursor = styled(motion.div)`
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  padding: 0;
  border-radius: 12px;
  background-color: #fff;
`
