/**
 * @name 비회원,회원
 * @description
 */
import Badge from '@/components/atoms/Badge';

type Props = {
  type: string | boolean
  onClick?: () => void
}
export default function MemberType({type}: Props) {
  // hooks
  //*--------------------------------------------------*
  return <Badge mode={`${type ? 'Info1' : 'Dark1'}`} label={`${type ? '회원' : '비회원'}`} />
}
/********************************************************
[사용법]

*********************************************************/
