import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CSS } from 'types';
import { Image, uuid } from 'lib';
import { icon_checkbox_off, icon_checkbox_on } from 'assets/images';
import { COLOR } from 'styles';

type SingleCheckType = {
  label: string;
  value: string | boolean | number | string[];
  checked: boolean;
};

type Props = {
  info: SingleCheckType[];
  style?: CSS;
  onChange?: (value: any) => void;
  onValue?: (value: any) => void;
};
export default function CheckboxGroup({ info, onChange, onValue, style }: Props) {
  const [state, setState] = useState(info);
  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
    if (onValue) {
      const temp: (string | boolean | number)[] = [];
      for (let i = 0; i < state.length; i += 1) {
        if (state[i].checked === true) {
          if (Array.isArray(state[i].value)) {
            temp.push(...(state[i].value as string[]));
          } else {
            temp.push(state[i].value as string | number | boolean);
          }
        }
      }
      onValue(temp);
    }
  }, [state]);
  return (
    <Element style={style}>
      {state &&
        state.map((item: SingleCheckType) => {
          return (
            <button
              type="button"
              className="item"
              key={uuid()}
              onClick={() => {
                const update = state.map((ele: SingleCheckType) => {
                  if (ele?.label === item?.label) {
                    return { ...ele, checked: !ele?.checked };
                  }
                  return ele;
                });
                setState(update);
              }}>
              <Image src={item?.checked ? icon_checkbox_on : icon_checkbox_off} style={{ width: 18, height: 18 }} />
              <span>{item?.label ?? '체크박스'}</span>
            </button>
          );
        })}
    </Element>
  );
}
const Element = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  /* 아이템 */
  button.item {
    display: flex;
    min-width: 120px;
    /* margin-right: 36px; */
    align-items: center;
    flex-direction: row;
  }
  /* 라벨 */
  span {
    display: inline-block;
    margin-left: 6px;
    color: ${COLOR.GRAY1};
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.53px;
  }
`;
