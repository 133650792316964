import React from 'react';
import { useTranslation } from 'react-i18next';
import { addComma, calculateChargeDurationInMinutes, formatDate, formatDateTime, Image } from 'lib';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableHead,
  TD,
  TH,
  TR,
  Wrapper,
  NoResultDescription,
  NoResultText,
  NoResultTextWrapper,
  NoSearchResultTextWrapper,
  NoSearchResultWrapper,
  InnerCellContainer,
  InnerCellTextButton,
} from './AdminAccountsTable.style';
import Pagination from '@/components/organisms/Paginations/Pagination';
import { useAppSelector } from '@/model/index';
import typography from '@/styles/typography';
import { icons } from '@/assets/images';
import Badge from '@/components/atoms/Badge';
import { TariffUserType } from '@/lib/apis/tariff/type';
import { COLOR } from '@/styles/index';
import { MENU } from '@/pages/router';
import { ITrouble } from '@/types/trouble';
import { AccountRoleCodeType, AdminAccountsStatusType, IAdminAccount } from '@/types/admin';

export default function AdminAccountsTable({
  itemsPerPage,
  currentPage,
  pageHandler,
  queryString,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  queryString?: string;
}) {
  const { t } = useTranslation('common');
  const { list, totalCount } = useAppSelector(state => state.admin);
  const navigate = useNavigate();

  const renderBadgeByMemberType = (type: TariffUserType) => {
    if (type === TariffUserType.NONE) {
      return <Badge mode="TariffNone" label={t('forms.price.member.none')} />;
    }
    if (type === TariffUserType.USER) {
      return <Badge mode="TariffUser" label={t('forms.price.member.user')} />;
    }
    return <Badge mode="TariffRoaming" label={t('forms.price.member.roaming')} />;
  };

  const handleNavigateToDetail = (seq: number) => {
    // navigate(`${MENU.ADMIN.ACCOUNT_MANAGEMENT_DETAIL}/${seq}?${queryString}`);
    navigate(`${MENU.ADMIN.ACCOUNT_MANAGEMENT_DETAIL}/${seq}`);
  };

  const renderRoleBadge = (role: { code: AccountRoleCodeType; name: string }) => {
    if (role.code === AccountRoleCodeType.ADMIN) return <Badge mode="Primary" label={role.name} />;
    if (role.code === AccountRoleCodeType.MANAGER) return <Badge mode="Primary" label={role.name} />;
    if (role.code === AccountRoleCodeType.USER) return <Badge mode="Admin" label={role.name} />;
    if (role.code === AccountRoleCodeType.CPO) return <Badge mode="Dark1" label={role.name} />;
    return '';
  };

  const renderStatusBadge = (status: AdminAccountsStatusType) => {
    if (status === AdminAccountsStatusType.ACTIVE)
      return <Badge mode="AccountStatActive" label={`${t(`pages.admin.account.status.${status}`)}`} />;
    if (status === AdminAccountsStatusType.INVITED)
      return <Badge mode="AccountStatInvited" label={`${t(`pages.admin.account.status.${status}`)}`} />;
    if (status === AdminAccountsStatusType.SUSPEND)
      return <Badge mode="AccountStatSuspend" label={`${t(`pages.admin.account.status.${status}`)}`} />;
    if (status === AdminAccountsStatusType.WITHDRAW)
      return <Badge mode="AccountStatWithdraw" label={`${t(`pages.admin.account.status.${status}`)}`} />;
    return '';
  };

  return (
    <Wrapper>
      <Table>
        <colgroup>
          <col style={{ width: 60 }} />
          <col style={{ minWidth: 440 }} />
          <col style={{ minWidth: 236 }} />
          <col style={{ minWidth: 162 }} />
          <col style={{ minWidth: 230 }} />
          <col style={{ minWidth: 160 }} />
        </colgroup>
        <TableHead>
          <TR>
            <TH>No.</TH>
            <TH>{t('pages.admin.account.list.table.account')}</TH>
            <TH>{t('pages.admin.account.list.table.type')}</TH>
            <TH>{t('pages.admin.account.list.table.managingStation')}</TH>
            <TH>{t('pages.admin.account.list.table.invitedDate')}</TH>
            <TH>{t('pages.admin.account.list.table.status')}</TH>
          </TR>
        </TableHead>
        <TableBody>
          {list?.length > 0 &&
            list.map((item: IAdminAccount, index: number) => (
              <TR key={item.seq}>
                <TD $customFont={typography.body4}>
                  {totalCount - (currentPage - 1) * itemsPerPage - index}
                  {/* {itemsPerPage * (currentPage - 1) + (index + 1)} */}
                </TD>
                <TD $customFont={typography.body5} color={COLOR.BLUE4}>
                  <InnerCellContainer>
                    <InnerCellTextButton onClick={() => handleNavigateToDetail(item.seq)}>
                      {item.email}
                    </InnerCellTextButton>
                  </InnerCellContainer>
                </TD>
                <TD $customFont={typography.body5} color={COLOR.BLUE4}>
                  <InnerCellContainer>{renderRoleBadge(item.role)}</InnerCellContainer>
                </TD>
                <TD $customFont={typography.body5}>{`${addComma(item.admin_staions_count)}${t(
                  'common.unit.locations',
                )}`}</TD>
                <TD $customFont={typography.body5}>{formatDateTime(item.invite.created_at)}</TD>
                <TD $customFont={typography.body5}>
                  <InnerCellContainer>{renderStatusBadge(item.status)}</InnerCellContainer>
                </TD>
              </TR>
            ))}
        </TableBody>
      </Table>
      {list.length > 0 && (
        <Pagination
          listLength={totalCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onClick={pageHandler}
        />
      )}
      {list.length === 0 && (
        <NoSearchResultWrapper>
          <Image src={icons.noSalesData.image.default} style={{ width: 192, height: 192 }} />
          <NoSearchResultTextWrapper>
            <NoResultTextWrapper>
              <NoResultText>{t('pages.admin.account.list.noData')}</NoResultText>
            </NoResultTextWrapper>
            <NoResultDescription />
          </NoSearchResultTextWrapper>
        </NoSearchResultWrapper>
      )}
    </Wrapper>
  );
}
