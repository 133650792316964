/**
 * @name FilterButtonGroup
 * @description 체크박스그룹
 */
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {CSS} from 'types'
import {uuid} from 'lib'
import {COLOR} from 'styles'
import {VscChromeClose} from 'react-icons/vsc'

type Props = {
  info?: any
  label?: string
  style?: CSS
  onChange?: (value: any) => void
  onValue?: (value: any) => void
  component?: React.ReactNode //------------------ children
}
export default function FilterButtonGroup({info, onChange, style, onValue}: Props) {
  // hooks
  const [state, setState] = useState(info) as any
  //*--------------------------------------------------*
  useEffect(() => {
    !!onChange && onChange(state)
  }, [state])
  //*--------------------------------------------------*
  return (
    <Element style={style}>
      {state &&
        state.map((item: any, index: number) => {
          return (
            <button
              className="item"
              key={uuid()}
              onClick={() => {
                const _filter = state?.filter((ele: any) => ele !== item)
                setState(_filter)
              }}>
              <VscChromeClose size={12} />
              <label>{item ?? '라디오박스'}</label>
            </button>
          )
        })}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  /* 아이템 */
  button.item {
    display: flex;
    height: 26px;
    margin-right: 10px;
    padding: 0 16px 0 10px;
    border-radius: 40px;
    align-items: center;
    flex-direction: row;
    color: ${COLOR.WHITE};
    background: ${COLOR.BLUE3};
  }
  /* 라벨 */
  label {
    display: inline-block;
    margin-left: 6px;
    color: ${COLOR.WHITE};
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.1px;
  }
`
/********************************************************
[사용법]

  <ListKeyComponent
    title="충전기 구분"
    component={
      <RadioGroup
        info={[
          {label: '급속', value: 'a', checked: true},
          {label: '완속', value: 'b', checked: false},
        ]}
        onChange={data => {
          // dispatch(_action.setSearchPayload({charger_type: ['FAST', 'SLOW'][data]}))
        }}
        onValue={data => {
          console.log(data)
        }}
      />
    }
  />

*********************************************************/
