import React from 'react';
import styled, { css } from 'styled-components';
import { Image } from 'lib';
import { icons } from 'assets/images';
import { COLOR } from '@/styles/index';

type Props = {
  style?: React.CSSProperties;
  onClick?: () => void;
};
export default function DownloadButton({ onClick, style }: Props) {
  return (
    <Content
      style={style}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
          onClick();
        }
        event.currentTarget.blur();
      }}>
      <Image src={icons.download.image.default} style={{ width: 20, height: 20 }} />
    </Content>
  );
}
const Content = styled.button<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${COLOR.BLUE4};
  box-sizing: border-box;

  ${({ $isActive }) =>
    $isActive
      ? css`
          background-color: ${COLOR.BLUE4};
        `
      : css`
          &:hover {
            background-color: ${COLOR.BLUE8};
          }
          &:focus {
            background-color: ${COLOR.BLUE7};
          }
        `}
`;
