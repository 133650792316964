/**
 * @name List
 * @description
 */
import {useState} from 'react'
import styled from 'styled-components'
import {Tab} from 'lib'
// contents
import {Template} from '..'
import Summary from './summary'

export default function Contents() {
  // const
  const tabMenu = [{label: '충전 상세 내역'}, {label: '충전기 기본정보'}]
  const [selected, setSelected] = useState(0)
  //*--------------------------------------------------*

  // setContents
  const setContents = () => {
    switch (selected) {
      case 0:
        return <Template />
      case 1:
        return <Summary />
    }
  }
  //*--------------------------------------------------*
  return (
    <Content>
      <header className="auto">
        <Tab
          selected={selected}
          style={{marginBottom: 32}}
          info={tabMenu}
          onChange={({index}) => {
            setSelected(index)
          }}
        />
      </header>
      <main>{setContents()}</main>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  header {
    width: 674px;
    margin: 24px auto;
  }
  main {
    width: 674px;
    margin: 24px auto;
  }
`
