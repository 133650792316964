/**
 * @name FullPage
 */
import React from 'react'
import styled from 'styled-components'
// components
import Footer from './footer'

type Props = {
  children?: React.ReactNode | JSX.Element[] | undefined
  [key: string]: any
}
export default function App({children}: Props) {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      {/* container, children */}
      <article className="article">{children}</article>
      {/* Footer */}
      <footer>
        <Footer />
      </footer>
    </Content>
  )
}
const Content = styled.main`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background-color: #dadada;
  /*---------- article -------*/
  > article {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    /* height: 400px; */
  }
  /*---------- footer -------*/
  > footer {
    display: flex;
    margin-left: 96px;
    margin-right: 28px;
  }
`
