/**
 * @name Content
 * @description
 */
import {useInterval} from 'lib'
import {useState} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'

export default function Contents() {
  // hooks
  const [count, setCount] = useState(0)
  const [delay, setDelay] = useState(1000)
  const [isRunning, setIsRunning] = useState(true)
  //*--------------------------------------------------*
  useInterval(
    () => {
      setCount(count + 1)
    },
    isRunning ? delay : null,
  )
  //*--------------------------------------------------*
  return (
    <Content>
      <div className="wrap">
        <h1>{count}</h1>
        <input type="checkbox" checked={isRunning} onChange={e => setIsRunning(e.target.checked)} /> Running
        <br />
        <input value={delay} onChange={e => setDelay(Number(e.target.value))} />
      </div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /* <!--wrap--> */
  .wrap {
    h1 {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 5px 10px;
      font-size: 12px;
      background-color: ${COLOR.GRAY3};
    }
    input[type='checkbox'] {
      /* width: 100px; */
    }
  }
`
