import React from 'react';
import styled from 'styled-components';
import { COLOR, layout } from 'styles';
import { Div, H3, H6, Text, formatDateTime, makeArray, uuid } from 'lib';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/model/index';
import { ITrouble } from '@/types/trouble';

/**
 * @name 실시간장애현황
 * @description
 */
export default function RealTimeFaultStatus() {
  const { fault } = useAppSelector(state => state.dashboard);
  const info = makeArray(5, {
    id: 1,
    vendor_id: 'EV 불광 스테이션/기기 ',
    ocpp_error_code: '도로 상 시설',
    vendor_error_info: 'error_saddeu_rfureddade_sdfiuhusd_dirfrefsrfwdd_404',
  });
  const { t } = useTranslation('common');

  return fault.troubles.length !== 0 ? (
    <Content className={layout.panel}>
      <H3 style={{ marginBottom: 12 }} color={COLOR.RED2}>
        {t('pages.dashboard.broken')}
      </H3>
      {/* 아이템 */}
      {fault.troubles.length !== 0 ? (
        fault.troubles?.map((item: ITrouble) => {
          return (
            <button type="button" className="item" key={uuid()} onClick={() => {}}>
              <Div style={{ width: '100%' }}>
                <H6 color={COLOR.RED1}>{`${item?.chargepoints[0].station.name}/${item?.chargepoints[0].code}`}</H6>
                <Text color={COLOR.GRAY3} className="caption2">
                  {formatDateTime(item?.created_at)}
                </Text>
              </Div>
              <p className="caption2" style={{ width: '100%', color: COLOR.RED2 }}>
                {item?.ocpp_error_info !== '' ? item?.ocpp_error_info : item?.ocpp_error_code}
              </p>
            </button>
          );
        })
      ) : (
        <></>
      )}
    </Content>
  ) : (
    <></>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 428px; */
  padding: 24px 16px;
  width: 350px;
  background: ${COLOR.WHITE};

  /* 아이템 */
  .item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 62px;
    margin-bottom: 8px;
    padding: 12px 14px;
    border-radius: 8px;
    background: ${COLOR.RED5};
  }
`;
