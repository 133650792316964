/**
 * @name SignOut
 * @description
 */
import React from 'react';
import styled from 'styled-components';
// contents

export default function Presenter() {
  // hooks

  return <Content />;
}
const Content = styled.div``;
