import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { Button, Image } from 'lib';
import {
  InnerCellContainer,
  InnerCellTextButton,
  NoResultDescription,
  NoResultText,
  NoResultTextWrapper,
  NoSearchResultTextWrapper,
  NoSearchResultWrapper,
  SearchKeyword,
  Table,
  TableBody,
  TableHead,
  TD,
  TH,
  TR,
  Wrapper,
} from './ChargersTable.style';
import typography from '@/styles/typography';
import { useAppDispatch, useAppSelector } from '@/model/index';
import { MENU } from '@/pages/router';
import { ICharger } from '@/types/infra';
import { COLOR } from '@/styles/color';
import Pagination from '@/components/organisms/Paginations/Pagination';
import { SortElementType, SortType } from '@/lib/apis/infra/type';
import IconButtonsModifyDel from '@/components/molecules/ButtonGroups/ModifyDel';
import { SCREEN_MODE } from '@/types/mode';
import { icon_plus_white, icons } from '@/assets/images';
import { formatDay } from '@/pages/common';
import Badge from '@/components/atoms/Badge';
import { actions } from '@/model/modules/device';

function ChargersTable({
  itemsPerPage,
  currentPage,
  pageHandler,
  sortingHandler,
  handleDelete,
  queryString,
  onRegister,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  sortingHandler?: ({ sort_element, sort_type }: { sort_element: SortElementType; sort_type: SortType }) => void;
  handleDelete: {
    popup: (value: boolean) => void;
    setId: (value: string) => void;
  };
  queryString: string;
  onRegister: () => void;
}) {
  const { t } = useTranslation('common');
  const { list, totalCount } = useAppSelector(state => state.device);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchKeyword = searchParams.get('search');
  const searchLocation = searchParams.get('location');

  const handleEdit = (id: string) => {
    dispatch(actions.setMode(SCREEN_MODE.MODIFY));
    navigate(`${MENU.INFRA.CHARGER_MANAGEMENT_UPDATE}/${id}?${queryString}`);
  };

  const handleNavigateToDetail = (seq: string) => {
    navigate(`${MENU.INFRA.CHARGER_MANAGEMENT}/${seq}?${queryString}`);
  };

  return (
    <Wrapper>
      <Table>
        <colgroup>
          <col style={{ width: 44 }} />
          <col style={{ minWidth: 60 }} />
          <col style={{ minWidth: 220 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 78 }} />
          <col style={{ minWidth: 220 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 130 }} />
          <col style={{ minWidth: 130 }} />
          <col style={{ minWidth: 78 }} />
          <col style={{ minWidth: 88 }} />
        </colgroup>
        <TableHead>
          <TR>
            <TH>No.</TH>
            <TH>{t('pages.accountManage.station.manageStation.location')}</TH>
            <TH>{t('forms.model.chargerName')}</TH>
            <TH>{t('forms.model.type')}</TH>
            <TH>{t('forms.model.simultaneousCharging')}</TH>
            <TH>{t('forms.model.typeOfConnectors')}</TH>
            <TH>{t('forms.charger.name')}</TH>
            <TH>{t('forms.model.connectionAvailable')}</TH>
            <TH>{t('forms.model.model')}</TH>
            <TH>{t('forms.model.manufacturerName')}</TH>
            <TH>{t('forms.model.updateDate')}</TH>
            <TH>{t('common.buttons.updateDelete')}</TH>
          </TR>
        </TableHead>
        <TableBody>
          {list.length > 0 &&
            list.map((item: ICharger, index: number) => (
              <TR key={nanoid()}>
                <TD $customFont={typography.body4}>{totalCount - (currentPage - 1) * itemsPerPage - index}</TD>
                <TD $customFont={typography.body4}>{item.station.kr_location.name}</TD>
                <TD $customFont={typography.body5} color={COLOR.BLUE4}>
                  <InnerCellContainer>
                    <InnerCellTextButton onClick={() => handleNavigateToDetail(item.seq)}>
                      {item?.name}
                    </InnerCellTextButton>
                  </InnerCellContainer>
                </TD>
                <TD>
                  <InnerCellContainer>
                    <Badge
                      mode={item.model?.charger_type === 'FAST' ? 'Fast' : 'Standard'}
                      label={item.model?.charger_type === 'FAST' ? '급속' : '완속'}
                    />
                  </InnerCellContainer>
                </TD>
                <TD>
                  <InnerCellContainer>
                    <Badge
                      mode={item.is_concurrency ? 'Available' : 'Unavailable'}
                      label={item.is_concurrency ? '가능' : '불가'}
                    />
                  </InnerCellContainer>
                </TD>
                <TD $customFont={typography.body6}>{t(`common.connector.${item?.connectors[0]?.types}`)}</TD>
                <TD $customFont={typography.body6}>{item?.station?.name}</TD>
                <TD>
                  <InnerCellContainer>
                    <Badge
                      mode={item.activated_at ? 'Connected' : 'Unconnected'}
                      label={item.activated_at ? '개설' : '대기중'}
                    />
                  </InnerCellContainer>
                </TD>
                <TD $customFont={typography.body4}>{item?.model?.name}</TD>
                <TD $customFont={typography.body4}>{item?.model?.vendor?.name}</TD>
                <TD $customFont={typography.body6}>{item.last_modified_at ? formatDay(item.last_modified_at) : '-'}</TD>
                <TD>
                  <IconButtonsModifyDel
                    onModify={() => handleEdit(item?.seq)}
                    onDelete={() => {
                      handleDelete.popup(true);
                      handleDelete.setId(`${item?.seq}`);
                    }}
                  />
                </TD>
              </TR>
            ))}
        </TableBody>
      </Table>
      {list.length === 0 && (searchLocation || searchKeyword) && (
        <NoSearchResultWrapper>
          <Image src={icons.noSearchResult.image} style={{ width: 192, height: 192 }} />
          <NoSearchResultTextWrapper>
            <NoResultTextWrapper>
              <SearchKeyword>
                {searchLocation} {searchKeyword}
              </SearchKeyword>
              <NoResultText>{t('pages.sales.chargingStatus.noDataTitle')}</NoResultText>
            </NoResultTextWrapper>
            <NoResultDescription>{t('pages.sales.chargingStatus.description')}</NoResultDescription>
          </NoSearchResultTextWrapper>
        </NoSearchResultWrapper>
      )}
      {list.length === 0 && !searchLocation && !searchKeyword && (
        <NoSearchResultWrapper>
          <Image src={icons.noStations.image} style={{ width: 192, height: 192 }} />
          <NoSearchResultTextWrapper>
            <NoResultTextWrapper>
              <NoResultText>{t('pages.sales.chargingStatus.noDataTitle')}</NoResultText>
            </NoResultTextWrapper>
            <NoResultDescription>{t('pages.sales.chargingStatus.description')}</NoResultDescription>
          </NoSearchResultTextWrapper>
          <Button
            icon={icon_plus_white}
            label={t('forms.charger.registerCharger')}
            style={{ marginRight: 16 }}
            onClick={onRegister}
          />
        </NoSearchResultWrapper>
      )}
      {list.length > 0 && (
        <Pagination
          listLength={totalCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onClick={pageHandler}
        />
      )}
    </Wrapper>
  );
}

export default ChargersTable;
