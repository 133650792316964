import React, { useEffect, useState } from 'react';
import { LayerPopup } from 'lib';
import { CSS } from 'types';

type Props = {
  children?: React.ReactNode; // ------------------ children
  visible?: boolean; // --------------------------- visible
  style?: CSS; // --------------------------------- StyleSheet
  onClose?: (value: boolean) => void;
};

/**
 * @name PopupContainer
 * @description
 */
export default function PopupContainer({ children, onClose, visible, style }: Props) {
  // hooks
  const [_visible, setVisible] = useState(visible || false);
  //* --------------------------------------------------*
  useEffect(() => {
    if (visible === undefined) return;
    setVisible(visible);
  }, [visible]);
  //* --------------------------------------------------*
  return (
    <LayerPopup
      style={style}
      visible={_visible}
      onClose={() => {
        setVisible(false);
        if (onClose) {
          onClose(_visible);
        }
      }}>
      {children}
    </LayerPopup>
  );
}
/** ******************************************************
[사용법]

const [visible, setVisible] = useState(false)

<PopupContainer visible={visible} onClose={() => setVisible(false)}>
  {setContents()}
</PopupContainer>

******************************************************** */
