const typography = {
  caption1: {
    fontFamily: 'Pretendard',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '-0.18px',
  },
  caption2: {
    fontFamily: 'Pretendard',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '14px',
    letterSpacing: '-0.1px',
  },
  body1: {
    fontFamily: 'Pretendard',
    fontSize: '17px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.6px',
  },
  body2: {
    fontFamily: 'Pretendard',
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.375px',
  },
  body3: {
    fontFamily: 'Pretendard',
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.45px',
  },
  body4: {
    fontFamily: 'Pretendard',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.53px',
  },
  body5: {
    fontFamily: 'Pretendard',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '-0.2px',
  },
  body6: {
    fontFamily: 'Pretendard',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.33px',
  },
  body7: {
    fontFamily: 'Pretendard',
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '-0.11px',
  },
  body8: {
    fontFamily: 'Pretendard',
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '-0.11px',
  },
  headline1: {
    fontFamily: 'Pretendard',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '36px',
    letterSpacing: '-0.48px',
  },
  headline2: {
    fontFamily: 'Pretendard',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '30px',
    letterSpacing: '-0.3px',
  },
  headline3: {
    fontFamily: 'Pretendard',
    fontSize: '17px',
    fontWeight: 600,
    lineHeight: '26px',
    letterSpacing: '-0.6px',
  },
  headline4: {
    fontFamily: 'Pretendard',
    fontSize: '17px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.68px',
  },
  headline5: {
    fontFamily: 'Pretendard',
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.375px',
  },
  headline6: {
    fontFamily: 'Pretendard',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.39px',
  },
};
export default typography;
