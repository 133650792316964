import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'model';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import ModelListPresenter from './ModelList.presenter';
import { actions } from '@/model/modules/model';
import { SCREEN_MODE } from '@/types/mode';
import { getExcelFile, getModels, getModelsExcelFile, removeModel, removeStation } from '@/lib/apis/infra';
import { Paging } from '@/lib/apis/infra/type';
import { MENU } from '@/pages/router';

/**
 * @description 모델등록관리 리스트
 */
export default function ModelListContainer() {
  const { cpo_seq } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const searchQueryString = searchParams.get('search') || '';
  const locationQueryString = searchParams.get('location') || '전체지역';

  const [currentPage, setCurrentPage] = useState(Number(page) || 1);
  const [searchKeyword, setSearchKeyword] = useState(searchQueryString);
  const [appliedSearchKeyword, setAppliedSearchKeyword] = useState(searchQueryString);
  const [selectedLocation, setSelectedLocation] = useState(locationQueryString);

  const [deletePopup, setDeletePopup] = useState(false);
  const [removeTargetId, setRemoveTargetId] = useState<null | string>(null);

  const itemsPerPage = 10;

  const queryParamsURL = (page?: number, location?: string, search?: string) => {
    const locationValue = location ?? selectedLocation;
    const locationKeyword = locationValue === '전체지역' ? '' : locationValue;
    const searchKeyword = search ?? appliedSearchKeyword;
    const queryParams = [];

    if (searchKeyword) {
      queryParams.push(`search=${encodeURIComponent(searchKeyword)}`);
    }
    if (locationKeyword) {
      queryParams.push(`location=${encodeURIComponent(locationKeyword)}`);
    }
    queryParams.push(`page=${page ?? currentPage}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const pageHandler = (page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      navigate(`?${queryParamsURL(page)}`);
      fetchList({
        size: itemsPerPage,
        page,
        location: selectedLocation === '전체지역' ? '' : selectedLocation,
        search: appliedSearchKeyword,
      });
    }
  };

  const handleSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };
  const handleSearch = () => {
    setAppliedSearchKeyword(searchKeyword);
    const locationKeyword = selectedLocation === '전체지역' ? '' : selectedLocation;
    navigate(`?${queryParamsURL(1, locationKeyword, searchKeyword)}`);
    fetchList({
      size: itemsPerPage,
      page: 1,
      location: locationKeyword,
      search: searchKeyword,
    });
  };

  const handleSelectLocation = ({ name }: { name: string }) => {
    setSelectedLocation(name);
    const locationKeyword = name === '전체지역' ? '' : name;
    navigate(`?${queryParamsURL(1, locationKeyword)}`);
    fetchList({
      size: itemsPerPage,
      page: 1,
      location: locationKeyword,
      search: appliedSearchKeyword,
      cpo_seq,
    });
  };

  const fetchList = async (payload: Paging) => {
    const { isSuccess, data, meta } = await getModels(payload);
    if (isSuccess) {
      dispatch(
        actions.setList({
          list: data.map((d: any) => {
            return {
              ...d,
              connectors: d.model_connectors,
            };
          }),
          totalCount: meta.paging?.total_count ?? NaN,
        }),
      );
    }
  };

  const handleRegister = () => {
    dispatch(actions.setMode(SCREEN_MODE.REGISTER));
    navigate(MENU.INFRA.MODEL_MANAGEMENT_REGISTER);
  };

  const handleDeletePopup = (value: boolean) => {
    setDeletePopup(value);
  };
  const handleRemoveTargetId = (id: string) => {
    setRemoveTargetId(id);
  };
  const handleDeleteConfirm = async () => {
    try {
      if (removeTargetId) {
        const removeResponse = await removeModel(removeTargetId);
        if (removeResponse.isSuccess) {
          handleDeletePopup(false);
          const locationKeyword = selectedLocation === '전체지역' ? '' : selectedLocation;
          fetchList({
            size: itemsPerPage,
            page: currentPage,
            location: locationKeyword,
            search: appliedSearchKeyword,
            cpo_seq,
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleExcelDownload = async () => {
    await getModelsExcelFile(
      {
        size: 10,
        page: 1,
        location: selectedLocation === '전체지역' ? '' : selectedLocation,
        search: appliedSearchKeyword,
      },
      `모델목록_${dayjs().format('YYYY_MM_DD')}.xlsx`,
    );
  };

  useEffect(() => {
    dispatch(actions.setInit());
    dispatch(actions.initPayload());
    dispatch(actions.initView());
    fetchList({
      size: itemsPerPage,
      page: currentPage,
      location: selectedLocation === '전체지역' ? '' : selectedLocation,
      search: appliedSearchKeyword,
    });
  }, []);

  useEffect(() => {
    setCurrentPage(Number(page));
  }, [page]);

  return (
    <ModelListPresenter
      onRegister={handleRegister}
      itemsPerPage={itemsPerPage}
      currentPage={currentPage}
      pageHandler={pageHandler}
      // sortingHandler={sortingHandler}
      deletePopupVisible={deletePopup}
      handleDelete={{ popup: handleDeletePopup, setId: handleRemoveTargetId }}
      onDeleteConfirm={handleDeleteConfirm}
      searchKeyword={searchKeyword}
      handleSearchKeyword={handleSearchKeyword}
      handleSearch={handleSearch}
      selectedLocation={selectedLocation}
      handleSelectLocation={handleSelectLocation}
      handleExcelDownload={handleExcelDownload}
      queryString={queryParamsURL()}
    />
  );
}
