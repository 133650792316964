import styled from 'styled-components';
import { COLOR } from '@/styles/index';
import typography from '@/styles/typography';

export const HeaderContainer = styled.div`
  display: flex;
  height: 72px;
  padding: 19px 16px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  align-items: center;
  justify-content: space-between;
`;

export const RoleFilterButton = styled.button<{ $isSelected: boolean }>`
  height: 44px;
  margin-right: 12px;
  color: ${({ $isSelected }) => ($isSelected ? COLOR.BLUE3 : COLOR.BLACK3)};

  ${typography.body5};
`;

export const Count = styled.span<{ $isSelected: boolean }>`
  ${typography.caption1};
`;
