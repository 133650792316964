import { useNavigate } from 'react-router-dom';
/**
 * @name useRoutes
 * @description hooks기반 라우팅정보
 */

export const useRoutes = () => {
  const navigate = useNavigate();
  return { navigate };
};
