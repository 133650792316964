/**
 * @name Page
 * @description 서브페이지 레이아웃
 */
import React, {useCallback, useEffect} from 'react'
import {useContext} from 'lib'
import styled from 'styled-components'
import {layout} from 'styles'
// components
import {Gnb, Top} from 'components'
import Footer from './footer'

type Props = {
  lnb?: any
  children?: React.ReactNode | JSX.Element[] | undefined
  [key: string]: any
}
export default function Page({lnb, children}: Props) {
  // hooks
  const {navi} = useContext()
  // 초기화
  // const onInit = useCallback(() => {
  //   // console.log(navi)
  // }, [navi])
  //*--------------------------------------------------*
  useEffect(() => {
    // onInit()
    return () => {}
  }, [])
  //*--------------------------------------------------*
  return (
    <Content>
      <main>삭제예정</main>
    </Content>
  )
}
const Content = styled.div`
  /* GNB */
  .gnb {
    width: 72px;
  }
  /* <!--footer--> */
  article > footer {
    margin-left: 24px;
    margin-right: 24px;
  }
`
