import styled, { css } from 'styled-components';
import { COLOR } from 'styles';
import typography from '@/styles/typography';
import { FontType } from '@/types/font';

export const Header = styled.div`
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  align-items: center;
  justify-content: space-between;
`;

export const Table = styled.table`
  width: 100%;
`;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody``;
export const TR = styled.tr`
  border-bottom: 1px solid ${COLOR.GRAY5};
  &:hover {
    background-color: ${COLOR.GRAY6};
  }
`;

export const TH = styled.th`
  text-align: left;
  padding: 14px 8px;
  background-color: ${COLOR.BLUE8};
  ${typography.headline6};
  color: ${COLOR.GRAY1};
`;

export const TD = styled.td<{ $index?: number; $customFont?: FontType; color?: string }>`
  padding: 8px;
  height: 56px;
  word-break: keep-all;
  color: ${props => props.color ?? COLOR.BLACK2};
  vertical-align: middle;
  ${({ $customFont }) =>
    $customFont &&
    css`
      font-family: ${$customFont.fontFamily};
      letter-spacing: ${$customFont.letterSpacing};
      font-size: ${$customFont.fontSize};
      line-height: ${$customFont.lineHeight};
      font-weight: ${$customFont.fontWeight};
    `};
  white-space: pre-line;
`;

export const SubmitWrapper = styled.div`
  width: 400px;
  margin: 32px auto 20px;
`;

export const NameInputContainer = styled.div`
  display: flex;
  gap: 4px;
`;

export const RoamingInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RoamingHidingArea = styled.div<{ $isRoamingChecked: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  display: ${({ $isRoamingChecked }) => ($isRoamingChecked ? 'block' : 'none')};
`;
export const RoamingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const RoamingTitle = styled.p`
  ${typography.headline5};
  color: ${COLOR.GRAY1};
`;

export const RoamingInputsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const RoamingInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const RoamingInputLabel = styled.p`
  ${typography.headline6};
  color: ${COLOR.GRAY1};
`;
export const RoamingInput = styled.input`
  padding: 8px 16px;
  border: 1px solid ${COLOR.GRAY4};
`;
