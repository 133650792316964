/**
 * @name 계정이메일,위임권한계정,계정유형
 * @description
 */
import React, { FormEventHandler, HTMLInputTypeAttribute, InputHTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import { SelectButton, TextFiledGroup, TextFiledLabel, TextInput } from 'components/form';
import { useAppDispatch, useAppSelector } from 'model';
import { useTranslation } from 'react-i18next';
import { actions } from '@/model/modules/admin';
import { SCREEN_MODE } from '@/types/mode';
import { NameInputContainer } from '@/components/organisms/Forms/StationRegisterForm/StationRegisterForm.style';
import { Button, validationStationName } from '@/lib/index';
import { getCheckNameDuplicated } from '@/lib/apis/infra';
import { getCheckDuplicatedCorpNumber } from '@/lib/apis/admin';

export default function AccountRegisterForm({
  requiredFieldsWarning,
}: {
  requiredFieldsWarning?: {
    email: boolean;
    roleCode: boolean;
    businessNumber: boolean;
    requireDuplicateCheck: boolean;
    operatorCompanyName: boolean;
  };
}) {
  const { t } = useTranslation('common');
  const { mode, permissions, invitePayload } = useAppSelector(state => state.admin);
  const isModify = mode === SCREEN_MODE.MODIFY || mode === SCREEN_MODE.REGISTER;
  const dispatch = useAppDispatch();
  const disabled = mode === SCREEN_MODE.VIEW;
  const [businessNumberInputDesc, setBusinessNumberInputDesc] = useState(
    t('pages.admin.account.detail.operatorBusinessNumberDesc'),
  );

  const [forms, setForms] = useState({
    businessNumber: '',
  });
  const [validationResult, setValidationResult] = useState<{ businessNumber: boolean | 'none' }>({
    businessNumber: 'none',
  });
  const [businessNumber, setBusinessNumber] = useState('');

  const onNameChangeHandler = (value: string) => {
    dispatch(
      actions.setInvitePayload({
        ...invitePayload,
        invite_email: value,
      }),
    );
  };

  const onOperatorNameChangeHandler = (value: string) => {
    dispatch(
      actions.setInvitePayload({
        ...invitePayload,
        operatorCompanyName: value,
      }),
    );
  };

  const onBusinessNumberChangeHandler = (value: string) => {
    const filteredValue = value.replace(/[^0-9]/g, '');
    setForms(prevState => ({
      ...prevState,
      businessNumber: filteredValue,
    }));
    dispatch(
      actions.setInvitePayload({
        ...invitePayload,
        businessNumber: filteredValue,
      }),
    );
    dispatch(actions.setDuplicatedCheck(false));
  };

  const onValidCheckResult = (key: string, value: boolean) => {
    setValidationResult(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };
  const isBusinessNumberDuplicated = async () => {
    if (!validationStationName(forms.businessNumber)) {
      onValidCheckResult('businessNumber', false);
      setBusinessNumberInputDesc(t('pages.admin.account.detail.operatorBusinessNumberDesc'));
      return;
    }
    const checkResult = await getCheckDuplicatedCorpNumber(forms.businessNumber);
    if (checkResult.isSuccess) {
      if (!checkResult.data) {
        onValidCheckResult('businessNumber', true);
        setBusinessNumberInputDesc(t('pages.admin.account.detail.operatorBusinessNumberAvailableDesc'));
        dispatch(
          actions.setInvitePayload({
            ...invitePayload,
            businessNumber: forms.businessNumber,
          }),
        );
        dispatch(actions.setDuplicatedCheck(true));
      } else {
        onValidCheckResult('businessNumber', false);
        setBusinessNumberInputDesc(t('pages.admin.account.detail.operatorBusinessNumberUnavailableDesc'));
      }
    }
  };

  const handleBusinessNumber: FormEventHandler<HTMLInputElement> = e => {
    const input = e.currentTarget;
    const filteredValue = input.value.replace(/[^0-9]/g, '');
    setBusinessNumber(filteredValue);
  };

  return (
    <Content>
      <TextFiledGroup
        required
        label={t('pages.admin.account.detail.accountEmail')}
        warningText={t('pages.admin.account.register.emailEmptyWarning')}
        displayWarning={requiredFieldsWarning?.email}
        component={
          <TextInput
            disabled={disabled}
            name="email"
            placeholder={t('pages.admin.account.detail.accountEmailPlaceholder')}
            defaultValue={permissions?.email}
            onChange={onNameChangeHandler}
          />
        }
      />
      <TextFiledGroup
        required
        label={t('pages.admin.account.detail.accountType')}
        warningText={t('pages.admin.account.register.roleEmptyWarning')}
        displayWarning={requiredFieldsWarning?.roleCode}
        component={
          <SelectButton
            // disabled={!isModify}
            // value={checkConcurrency(view.is_concurrency)}
            leftLabel={t('pages.admin.account.detail.admin')}
            rightLabel={'CPO'}
            onChange={val => {
              if (val === 1) {
                dispatch(actions.setInvitePayload({ ...invitePayload, role_code: 'ADMIN' }));
              } else {
                dispatch(actions.setInvitePayload({ ...invitePayload, role_code: 'CPO' }));
              }
            }}
          />
        }
      />
      {/* <TextFiledGroup
        required
        label={t('pages.admin.account.detail.operatorBusinessNumber')}
        desc={businessNumberInputDesc}
        validCheckResult={validationResult.businessNumber}
        displayWarning={requiredFieldsWarning?.businessNumber}
        warningText={t('pages.admin.account.register.businessNumberEmptyWarning')}
        displayWarning2={requiredFieldsWarning?.requireDuplicateCheck}
        warningText2={t('forms.charger.nameDuplicateCheckWarning')}
        component={
          <NameInputContainer>
            <TextInput
              disabled={!isModify}
              style={{ maxWidth: 300 }}
              placeholder={t('pages.admin.account.detail.operatorBusinessNumberPlaceholder')}
              // defaultValue={view.name}
              value={businessNumber}
              type="text"
              name="businessNumber"
              onChange={onBusinessNumberChangeHandler}
              // validFunc={validationBusinessNumber}
              maxLength={10}
              inputMode="numeric"
              pattern="[0-9]*"
              // parentValidResult={result => onValidCheckResult('name', result)}
              onInput={handleBusinessNumber}
            />
            <Button
              label={t('common.buttons.duplicateCheck')}
              disabled={!isModify || forms.businessNumber === ''}
              onClick={isBusinessNumberDuplicated}
              style={{ flex: 1 }}
            />
          </NameInputContainer>
        }
      /> */}
      {/* <TextFiledGroup
        required
        label={t('pages.admin.account.detail.operatorName')}
        warningText={t('pages.admin.account.register.companyNameEmptyWarning')}
        displayWarning={requiredFieldsWarning?.operatorCompanyName}
        component={
          <TextInput
            disabled={disabled}
            defaultValue={permissions?.business_info?.name}
            name="business_info_name"
            onChange={onOperatorNameChangeHandler}
          />
        }
      /> */}
    </Content>
  );
}
//* --------------------------------------------------*
const Content = styled.div`
  width: 400px;
  margin: 24px auto 56px;
`;
