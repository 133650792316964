import { API_HOST } from '@/config/index';
import api from '@/lib/apis/api';
import { IResponse } from '../../api/type';
import { Paging } from '@/lib/apis/infra/type';
import { objectToQueryString } from '@/lib/index';
import { TroubleReport } from './type';

export const getTroubleReportsList = async (payload: Paging): Promise<IResponse<TroubleReport[]>> => {
  const param = objectToQueryString(payload);
  const url = `${API_HOST}/trouble-reports?${param}`;
  return api.get({ url });
};

export const getTroubleReportById = async (seq: number): Promise<IResponse<TroubleReport>> => {
  const url = `${API_HOST}/trouble-reports/${seq}`;
  return api.get({ url });
};

export const getTroubleReportsExcelFile = async ({ payload, fileName }: { payload: Paging; fileName: string }) => {
  const param = objectToQueryString(payload);
  const url = `${API_HOST}/trouble-reports/list/excel?${param}`;
  return api.get({ url, responseType: 'blob', fileName });
};

export const updateTroubleReports = async (
  seq: number,
  payload: { admin_memo: string; status: string },
): Promise<IResponse<TroubleReport>> => {
  const url = `${API_HOST}/trouble-reports/${seq}`;
  return api.put({ url, payload });
};
