import React, { useEffect, useState } from 'react';
import { ImageButton, Input } from 'lib';
import styled from 'styled-components';
import { COLOR } from 'styles';
import { icon_search_gray } from 'assets/images';
import { CSS } from 'types';
import typography from '@/styles/typography';

type Props = {
  containerStyle?: CSS;
  style?: CSS;
  placeholder?: string;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  onSubmit?: (str: string) => void;
  onChange?: (str: string) => void;
  onClick?: () => void;
};

/**
 * @name TextFiledSearch
 * @description
 */
export default function TextFiledSearch({
  className,
  disabled,
  defaultValue,
  containerStyle,
  style,
  placeholder,
  onSubmit,
  onChange,
  onClick,
}: Props) {
  const [search, setSearch] = useState(defaultValue ?? '');
  useEffect(() => {
    if (defaultValue) {
      setSearch(defaultValue);
    }
  }, [defaultValue]);
  return (
    <Content
      style={containerStyle}
      className={className}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}>
      <Input
        disabled={disabled}
        defaultValue={search}
        setValue={setSearch}
        style={style}
        placeholder={placeholder ?? '충전소 ID, 또는 이름으로 검색해 주세요.'}
        onChange={str => {
          if (onChange) {
            onChange(str);
          }
        }}
        onSubmit={() => {
          if (onSubmit) {
            onSubmit(search);
          }
        }}
      />
      <ImageButton
        className="button"
        // buttonStyle={{backgroundColor: 'red'}}
        source={icon_search_gray}
        style={{ width: 20, height: 20 }}
        onClick={() => {
          if (onSubmit) {
            onSubmit(search);
          }
        }}
      />
    </Content>
  );
}
const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  /* 검색버튼 */
  .button {
    position: absolute;
    right: 16px;
  }
  /* input */
  input {
    ${typography.body4};
    display: flex;
    flex: 1;
    width: 100%;
    min-width: 300px;
    padding: 8px 36px 8px 16px;
    height: 40px;
    border: 1px solid ${COLOR.GRAY4};
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    color: ${COLOR.BLACK1};
    background-color: ${COLOR.WHITE};
    outline: none;
    overflow: hidden;
    /* 마우스호버 */
    &:hover {
      border-color: ${COLOR.GRAY1};
    }
    /* 포커스인 */
    &:focus {
      outline: 0;
      border-color: ${COLOR.BLUE3};
      outline-color: ${COLOR.BLUE3};
    }
    /* 비활성화 */
    &:disabled {
      border: 0;
      color: ${COLOR.GRAY3};
      background-color: ${COLOR.GRAY6};
    }
    /* 유효성검사 */
    &.invalid {
      border-color: ${COLOR.RED1} !important;
    }
    /* 플레이스홀더 */
    &::placeholder {
      color: ${COLOR.GRAY2};
    }
  }
`;
