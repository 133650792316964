/**
 * @name Popup
 * @todo
 */
import {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'components'
import {Events} from 'lib'
import {GUIDE_EVENT} from 'model/modules'
import {layout} from 'styles'

export default function Contents() {
  // hooks
  const [visible, setVisible] = useState(false)
  //*--------------------------------------------------*
  // 레이어팝업실행
  const onSetPopup = (mode: string) => {
    setVisible(true)
  }
  // 레이어팝업닫기
  const onRemovePopup = () => {
    setVisible(false)
  }
  //*--------------------------------------------------*
  useEffect(() => {
    Events.addListener(GUIDE_EVENT.POPUP_SET, onSetPopup)
    Events.addListener(GUIDE_EVENT.POPUP_HIDE, onRemovePopup)
    return () => {
      Events.removeListener(GUIDE_EVENT.POPUP_SET, onSetPopup)
      Events.removeListener(GUIDE_EVENT.POPUP_HIDE, onRemovePopup)
    }
  }, [])
  //*--------------------------------------------------*
  return (
    <Content>
      <Modal visible={visible} setVisible={setVisible} modalClose>
        <div className={layout.modal}></div>
      </Modal>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  /* 닫기 */
  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    /* align-items: flex-end; */
    /* background-color: red; */
  }
  main {
    p {
      margin: 40px auto 20px;
      font-size: 14px;
    }
  }
`
