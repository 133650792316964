import React, { useEffect, useState } from 'react';
import { formatDate } from 'lib';
import { RootState, useAppDispatch, useAppSelector } from 'model';
import { useTranslation } from 'react-i18next';
import { DropDownLocations, FormCheckbox, SubmitConfirm, TextFiledSearch } from '@/components/form';
import { getStations } from '@/lib/apis/infra';
import typography from '@/styles/typography';
import { IStation } from '@/types/infra';
import Pagination from '@/components/organisms/Paginations/Pagination';
import { Paging } from '@/lib/apis/infra/type';
import { actions } from '@/model/modules/device';
import {
  Content,
  Header,
  PopupTitle,
  BodyWrapper,
  FilterWrapper,
  ListWrapper,
  Table,
  TableHead,
  TR,
  TH,
  TD,
  TableBody,
  Footer,
} from './SelectStation.styles';

export default function SelectStation({ handleClosePopup }: { handleClosePopup: () => void }) {
  const { t } = useTranslation('common');
  const {
    auth: { cpo_seq },
  } = useAppSelector((state: RootState) => state);
  const dispatch = useAppDispatch();

  const [info, setInfo] = useState<IStation[]>([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('전체지역');
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [tempSelectedStations, setTempSelectedStations] = useState<IStation | null>(null);

  const itemsPerPage = 10;

  const fetchStationList = async (payload: Paging) => {
    const stationsResponse = await getStations({ payload, cpo_seq });
    if (stationsResponse.isSuccess) {
      setInfo(stationsResponse.data);
      setTotalCount(stationsResponse.meta.paging?.total_count ?? 0);
    }
  };

  const pageHandler = (page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      fetchStationList({
        size: itemsPerPage,
        page,
      });
    }
  };

  const handleSelectLocation = ({ name }: { name: string }) => {
    setSelectedLocation(name);
    const locationKeyword = name === '전체지역' ? '' : name;
    fetchStationList({
      size: itemsPerPage,
      page: 1,
      location: locationKeyword,
      search: searchKeyword,
    });
  };

  const handleSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const handleSearch = () => {
    const locationKeyword = selectedLocation === '전체지역' ? '' : selectedLocation;
    fetchStationList({
      size: itemsPerPage,
      page: 1,
      location: locationKeyword,
      search: searchKeyword,
    });
  };

  const handleCheckStation = (checked: boolean, item: IStation) => {
    if (checked) {
      setTempSelectedStations(item);
    } else {
      setTempSelectedStations(null);
    }
  };

  const handleConfirmSelectedStations = () => {
    if (tempSelectedStations) {
      dispatch(actions.setSelectStation(tempSelectedStations));
    }
    handleClosePopup();
  };

  useEffect(() => {
    fetchStationList({
      size: itemsPerPage,
      page: currentPage,
    });
  }, []);

  return (
    <Content>
      <Header>
        <PopupTitle>{t('pages.infra.chargerManage.stationSearch.selectStation')}</PopupTitle>
      </Header>
      <BodyWrapper>
        <FilterWrapper>
          <DropDownLocations value={selectedLocation} onChange={handleSelectLocation} />
          <TextFiledSearch
            style={{ width: 430 }}
            placeholder={t('pages.infra.chargerManage.stationSearch.searchStationPlaceholder')}
            defaultValue={searchKeyword}
            onChange={str => {
              handleSearchKeyword(str);
            }}
            onSubmit={() => {
              handleSearch();
            }}
          />
        </FilterWrapper>
        <ListWrapper>
          <Table>
            <colgroup>
              <col style={{ width: 44 }} />
              <col style={{ minWidth: 182 }} />
              <col style={{ minWidth: 78 }} />
              <col style={{ minWidth: 98 }} />
              <col style={{ minWidth: 50 }} />
              <col style={{ minWidth: 180 }} />
              <col style={{ minWidth: 100 }} />
            </colgroup>
            <TableHead>
              <TR>
                <TH />
                <TH>{t('pages.infra.chargerManage.stationSearch.name')}</TH>
                <TH>{t('pages.infra.chargerManage.stationSearch.id')}</TH>
                <TH>{t('pages.infra.chargerManage.stationSearch.type')}</TH>
                <TH>{t('pages.infra.chargerManage.stationSearch.location')}</TH>
                <TH>{t('pages.infra.chargerManage.stationSearch.address')}</TH>
                <TH>{t('pages.infra.chargerManage.stationSearch.registerDate')}</TH>
              </TR>
            </TableHead>
            <TableBody>
              {info.length > 0 &&
                info.map((item: IStation) => {
                  const isSelected = tempSelectedStations?.seq === item.seq;
                  return (
                    <TR key={item.seq} $isSelected={isSelected}>
                      <TD>
                        <FormCheckbox value={isSelected} onChange={checked => handleCheckStation(checked, item)} />
                      </TD>
                      <TD $customFont={typography.body5}>{item.name}</TD>
                      <TD $customFont={typography.body6}>{item.code}</TD>
                      <TD $customFont={typography.body6}>{`${item.station_type?.type_group} > 
                      ${item.station_type?.type_detail}`}</TD>
                      <TD $customFont={typography.body4}>{item.kr_location?.name}</TD>
                      <TD $customFont={typography.body6}>{`${item.address} ${item.address_detail}`}</TD>
                      <TD $customFont={typography.body4}>{formatDate(item.created_at)}</TD>
                    </TR>
                  );
                })}
            </TableBody>
          </Table>
          {info.length > 0 && (
            <Pagination
              listLength={totalCount}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onClick={pageHandler}
            />
          )}
        </ListWrapper>
        <Footer>
          <SubmitConfirm
            onCancel={handleClosePopup}
            confirmLabel={t('common.buttons.selectAction')}
            confirmDisabled={!tempSelectedStations}
            onConfirm={handleConfirmSelectedStations}
          />
        </Footer>
      </BodyWrapper>
    </Content>
  );
}
