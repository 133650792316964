import React, { ChangeEvent } from 'react';
import { icon_calendar_white } from 'assets/images';
import { useAppDispatch } from 'model';
import { Button, Div, DropDown } from 'lib';
import { TextFiledGroup, TextFiledSearch, TextInput } from 'components/form';
import { useTranslation } from 'react-i18next';
import { Store, _action, _mode } from '../../index';

/**
 * @name 폼입력
 * @description
 */
export default function Contents() {
  const { t } = useTranslation('common');
  const { mode } = Store();
  const disabled = mode === _mode.VIEW;
  const dispatch = useAppDispatch();

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(_action.setPayload({ [name]: value }));
  };

  return (
    <main className="inc_form" style={{ width: 400 }}>
      <TextFiledGroup
        label={t('forms.charger.name')}
        required
        desc={t('forms.charger.nameDesc')}
        component={
          <Div>
            <TextInput
              disabled={disabled}
              style={{ width: '77%' }}
              placeholder={t('forms.charger.namePlaceholder')}
              defaultValue=""
              name="name"
              onChange={onChangeHandler}
            />
            <Button label={t('common.buttons.duplicateCheck')} disabled={disabled} />
          </Div>
        }
      />
      <TextFiledGroup
        label={`${t('forms.charger.maximumOutput')} ${t('common.unit.brackets.V')}`}
        desc={t('forms.numberOnly')}
        component={
          <TextInput
            style={{ marginBottom: 4 }}
            placeholder=""
            defaultValue=""
            name="max_power_kw"
            onChange={onChangeHandler}
          />
        }
      />
      <TextFiledGroup
        label={t('forms.charger.installationType')}
        component={
          <Div>
            <DropDown
              style={{ flex: 1, width: 200 }}
              info={[
                { label: '라벨1', value: 'num1' },
                { label: '라벨2', value: 'num2' },
              ]}
            />
            <div style={{ width: 20 }} />
            <DropDown disabled info={[{ label: '라벨3', value: 'num3' }]} />
          </Div>
        }
      />
      <TextFiledGroup
        label={t('forms.charger.installationAddress')}
        desc={t('forms.charger.installationAddressDesc')}
        component={
          <TextFiledSearch placeholder={t('common.buttons.searchAddress')} className="gap" onClick={() => {}} />
        }
      />
      <TextFiledGroup
        label={t('forms.charger.number')}
        desc={t('forms.numberOnly')}
        component={<TextInput placeholder={t('forms.charger.numberPlaceholder')} />}
      />
      <Button full icon={icon_calendar_white} label={t('common.buttons.addRunningDate')} />
    </main>
  );
}
