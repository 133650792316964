import React from 'react';
import styled from 'styled-components';
import { H3, Image, Text, useRoutes } from 'lib';
import { icon_arrow_prev_black } from 'assets/images';
import { COLOR } from 'styles';

type Props = {
  label?: string; // ------------------------------ 텍스트라벨링
  desc?: string; // ------------------------------- 디스크립션
  style?: React.CSSProperties; // ----------------- StyleSheet
  onClick?: () => void;
};

/**
 * @name BackButton
 * @mode 라벨설정, 뒤로가기
 */
export default function BackButton({ onClick, label, desc, style }: Props) {
  const { navigate } = useRoutes();
  return (
    <Content
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          navigate(-1);
        }
      }}
      style={style}>
      <Image src={icon_arrow_prev_black} style={{ width: 24, height: 24, marginRight: 6 }} />
      {label && <H3 color={COLOR.BLACK3}>{label}</H3>}
      {desc && (
        <Text className="body6" color={COLOR.BLACK3} style={{ marginLeft: 5 }}>
          {desc}
        </Text>
      )}
    </Content>
  );
}
const Content = styled.button`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 0;
`;
