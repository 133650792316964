/**
 * @name 템플릿
 * @description
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SCREEN_MODE } from '@/types/mode';
import { IGetRemoteRes, RemoteDetail } from '@/types/operation';

type StateType = {
  mode: string;
  payload: {};
  data: {};
  list: IGetRemoteRes[];
  totalCount: number; // totalCount를 정의
  view: RemoteDetail;
  search_payload: {
    isOpen: boolean;
  };
};

export const sliceName = 'remote';
/** @initialState */
const initialState: StateType = {
  mode: SCREEN_MODE.EMPTY,
  data: {},
  list: [],
  totalCount: 0, // totalCount를 정의
  payload: {},
  view: {
    chargepoint: {
      activated_at: '',
      channel_id: '',
      code: '',
      connectors: [],
      created_at: '',
      created_by: 0,
      firmware_status: '',
      firmware_version: '',
      heartbeat_interval: 0,
      installed_at: '',
      is_concurrency: false,
      is_roaming: false,
      last_heartbeat_at: '',
      last_modified_at: '',
      last_modified_by: '',
      memo: '',
      name: '',
      password: '',
      produced_at: '',
      roaming_chargepoint_id1: '',
      roaming_chargepoint_id2: '',
      roaming_station_id: '',
      seq: 0,
      socket_id: '',
      station: {
        address: '',
        address_detail: '',
        b_code: '',
        chargepoint_count: 0,
        code: '',
        country_code2: '',
        created_at: '',
        created_by: 0,
        description: '',
        is_default: false,
        last_chargepoint_number: 0,
        last_modified_at: '',
        last_modified_by: 0,
        latitude: '',
        longitude: '',
        memo: '',
        name: '',
        old_code: '',
        phone: '',
        pic_large: '',
        pic_medium: '',
        pic_small: '',
        post_code: '',
        seq: 0,
        status: '',
        tariff_type_applied_at: '',
        tariff_types: '',
        timezone: '',
      },
      status: '',
      suspended_at: '',
    },
    connector_number: 0,
    message_id: '',
    remote: {
      action_types: '',
      created_at: '',
      created_by: {
        country_code2: '',
        created_at: '',
        created_by: 0,
        email: '',
        language_code: '',
        last_modified_at: '',
        last_modified_by: '',
        name: '',
        phone: '',
        seq: 0,
        status: '',
        timezone: '',
        withdrew_at: '',
      },
      description: '',
      remote_action: {
        action_name: '',
        api_post_url: '',
        control_description: '',
        control_name: '',
        created_at: '',
        created_by: 0,
        description: '',
        is_multiple: false,
        last_modified_at: '',
        last_modified_by: 0,
        seq: 0,
        status: '',
      },
      seq: 0,
      types: '',
    },
    request_message: {
      type: '',
    },
    response_at: '',
    response_message: {
      payload: {
        status: '',
      },
    },
    response_type: '',
    seq: 0,
  },
  search_payload: {
    isOpen: false,
  },
};

/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setList(state: StateType, action: PayloadAction<any>) {
      state.list = action.payload.list;
      state.totalCount = action.payload.totalCount;
    },

    // 검색및 상세검색조건 업데이트
    setSearchPayload(state: StateType, action: PayloadAction<any>) {
      state.search_payload = Object.assign(state.search_payload, action.payload);
    },
    // Detail
    setView(state: StateType, action: PayloadAction<RemoteDetail>) {
      state.view = action.payload;
    },
  },
});

export default slice.reducer;
export const nameSpace = sliceName;
export const { actions } = slice;
