import { API_HOST } from 'config';
import { SESSION_STORAGE } from 'lib';
import api from '@/lib/apis/api';

export const login = async (payload: object) => {
  const url = `${API_HOST}/auth/login`;
  return api.post({ url, payload });
};

export const gnbMenu = async (businessCode: string) => {
  const url = `${API_HOST}/admins/menu/business/${businessCode}`;
  return api.get({ url });
};

export const stations = async () => {
  const url = `${API_HOST}/stations`;
  return api.get({ url });
};

/**
 *
 * @name API_HOST
 * @returns
 */
export const api_fail = (params: any) => {
  // console.log(params)
};
/**
 * @name 엑셀파일다운로드
 * @description
인프라 충전기 충전소 모델 제조사 장애내역
은 기존 리스트 조회 api에 쿼리스트링으로 exports=true 로 보내주시면 됩니다.
다음은 예외케이스입니다.
충전내역
결제내역의 경우  모두 같은 api를 사용하고 있는데요.
충전내역에서의 엑셀 다운로드의 경우 charge_exports
결제내역의 경우는 payment_exports
로 쿼리스트링 해주시면 됩니다.

 */
export async function getExcel(url: string) {
  // 다운로드할 파일 URL
  const fileUrl = url ?? `${API_HOST}/chargepoints/business/1/lists?exports=true`;
  // XMLHttpRequest 객체 생성
  const xhr = new XMLHttpRequest();
  xhr.open('GET', fileUrl, true);
  xhr.setRequestHeader('Authorization', `Bearer ${sessionStorage.getItem(SESSION_STORAGE.TOKEN)}`);
  xhr.responseType = 'arraybuffer';
  // 파일 다운로드 완료 이벤트 핸들러 등록
  xhr.onload = () => {
    // 파일 다운로드가 성공한 경우
    if (xhr.status === 200) {
      const blob = new Blob([xhr.response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      // const contentDisposition = xhr.getResponseHeader('Content-Disposition') as any
      // const fileName = contentDisposition.match(/filename="(.+?)"/)[1]
      // 파일 다운로드를 위해 a 태그 생성 및 클릭 이벤트 발생
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = 'Excel.xlsx';
      downloadLink.click();
    }
  };
  // 파일 다운로드 시작
  xhr.send();
}

