/**
 * @name Template
 * @description 충전소등록
 */
import { Button, Div, Events, useRoutes } from 'lib';
import styled from 'styled-components';
import { COLOR } from 'styles';
import { MENU } from '@/pages/router';
import { Store, _event, _mode, _popup } from '../../';

export default function Contents() {
  // hooks
  const { navigate } = useRoutes();
  const { mode } = Store();
  //*--------------------------------------------------*
  return (
    <Content>
      <Div>
        <Button
          full
          label="취소"
          color={COLOR.GRAY2}
          borderColor={COLOR.GRAY2}
          bg={'transparent'}
          onClick={() => {
            // 취소확인
            if (mode === _mode.REGISTER) Events.emit(_event.POPUP_SHOW, _popup.CANCEL_CONFIRM);
          }}
        />
        <div style={{ width: 32 }} />
        <Button
          full
          label="등록하기"
          onClick={() => {
            // 초기화
            navigate(MENU.TEMPLATE);
          }}
        />
      </Div>
    </Content>
  );
}

//*--------------------------------------------------*
const Content = styled.div`
  width: 400px;
  margin: 32px auto 20px;
`;
