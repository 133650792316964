import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'model';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ChargingRateRoamingRegisterPresenter from './ChargingRateRoamingRegister.presenter';
import {
  getRoamingTariff,
  getTariff,
  patchTariff,
  postRoamingTariff,
  postTariff,
  putTariff,
  updateRoamingTariff,
} from '@/lib/apis/tariff';
import { actions } from '@/model/modules/price';
import { SCREEN_MODE } from '@/types/mode';
import { MENU } from '@/pages/router';
import { GetTariffRes } from '@/lib/apis/tariff/type';

export default function ChargingRateRoamingRegisterContainer() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { mode, roamingPayload } = useAppSelector(state => state.price);
  const { cpo_seq } = useAppSelector(state => state.auth);
  const isModify = mode === SCREEN_MODE.MODIFY;
  const { id } = useParams();

  const [popupCancelVisible, setPopupCancelVisible] = useState(false);
  const [popupSuccessVisible, setPopupSuccessVisible] = useState(false);
  const [popupFailedVisible, setPopupFailedVisible] = useState(false);
  const [requiredFieldsWarning, setRequiredFieldsWarning] = useState({
    code: false,
    name: false,
    start_kw: false,
    end_kw: false,
    price: false,
  });

  const onInit = () => {
    if (id) {
      fetchData(); // TODO Roaming 상세보기 데이터 값 함수 호출
      dispatch(actions.setMode(SCREEN_MODE.MODIFY));
    }
    if (!isModify) {
      dispatch(actions.setMode(SCREEN_MODE.REGISTER));
    }
  };

  const fetchData = async () => {
    const { isSuccess, data } = await getRoamingTariff(String(id));
    if (isSuccess) {
      dispatch(actions.setRoamingView(data)); // TODO Roaming 상세보기 데이터 값
      dispatch(actions.setRoamingPayload(data)); // TODO Roaming 페이로드 데이터 값
    }
  };
  const handleBackToList = () => {
    navigate(MENU.RATE.CHARGING_RATE_MANAGEMENT);
  };

  const checkRequiredInputs = () => {
    setRequiredFieldsWarning({
      code: false,
      name: false,
      start_kw: false,
      end_kw: false,
      price: false,
    });
    let check = true;
    if (roamingPayload.roaming_code === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        code: true,
      }));
      check = false;
    }
    if (roamingPayload.roaming_name === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        name: true,
      }));
      check = false;
    }
    if (roamingPayload.start_kw === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        start_kw: true,
      }));
      check = false;
    }
    if (roamingPayload.end_kw === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        end_kw: true,
      }));
      check = false;
    }
    if (roamingPayload.price === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        price: true,
      }));
      check = false;
    }
    return check;
  };

  const onSubmit = async () => {
    if (checkRequiredInputs()) {
      try {
        // parameter로 roamingPayload 보내기
        const submitResponse = await postRoamingTariff(cpo_seq, roamingPayload);
        if (submitResponse.isSuccess) {
          dispatch(actions.initRoamingPayload());
          setPopupSuccessVisible(true);
        }
      } catch (e) {
        setPopupFailedVisible(true);
        console.error(e);
      }
    }
  };

  const onPatchSubmit = async () => {
    if (checkRequiredInputs()) {
      try {
        const submitResponse = await patchTariff(roamingPayload);
        if (submitResponse.isSuccess) {
          dispatch(actions.initRoamingPayload());
          setPopupSuccessVisible(true);
        }
      } catch (e) {
        setPopupFailedVisible(true);
        console.error(e);
      }
    }
  };
  const onCancelPopup = () => {
    setPopupCancelVisible(true);
  };

  const handleCloseFailedPopup = () => {
    setPopupFailedVisible(false);
  };

  const handleCloseCancelPopup = () => {
    setPopupCancelVisible(false);
  };

  const handleDone = () => {
    dispatch(actions.initRoamingView());
    dispatch(actions.initRoamingPayload());
    setPopupSuccessVisible(false);
    if (isModify) {
      dispatch(actions.setMode(SCREEN_MODE.LIST));
      navigate(MENU.RATE.CHARGING_RATE_MANAGEMENT);
    } else {
      dispatch(actions.setMode(SCREEN_MODE.LIST));
      navigate(MENU.RATE.CHARGING_RATE_MANAGEMENT);
    }
  };
  useEffect(() => {
    onInit();
  }, []);

  return (
    <ChargingRateRoamingRegisterPresenter
      onSubmit={isModify ? onPatchSubmit : onSubmit}
      onCancelPopup={onCancelPopup}
      handleBackToList={handleBackToList}
      popupCancelVisible={popupCancelVisible}
      popupSuccessVisible={popupSuccessVisible}
      popupFailedVisible={popupFailedVisible}
      handleCloseFailedPopup={handleCloseFailedPopup}
      handleCloseCancelPopup={handleCloseCancelPopup}
      handleDone={handleDone}
    />
  );
}
