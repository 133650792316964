/**
 * @name useClickOutside
 * @description
 */

import {RefObject} from 'react'
import {useEventListener} from 'usehooks-ts'

type Handler = (event: MouseEvent) => void

export function useClickOutside<T extends HTMLElement = HTMLElement>(ref: RefObject<T>, handler: Handler, mouseEvent: 'mousedown' | 'mouseup' = 'mousedown'): void {
  useEventListener(mouseEvent, event => {
    const el = ref?.current
    // Do nothing if clicking ref's element or descendent elements
    if (!el || el.contains(event.target as Node)) {
      return
    }

    handler(event)
  })
}

/********************************************************
import React, { useRef } from 'react'

import { useOnClickOutside } from 'usehooks-ts'

export default function Component() {
  const ref = useRef(null)
  useClickOutside(ref, () => {
    console.log('click outside')
  })
  useClickOutside(ref, handleClickOutside)
  return (
    <button
      ref={ref}
      onClick={handleClickInside}
      style={{ width: 200, height: 200, background: 'cyan' }}
    />
  )
}
*********************************************************/
