import React from 'react';
import styled from 'styled-components';
import { CSS } from 'types';
import { COLOR } from 'styles';
import SubmitButton from './submit-button';
import typography from '@/styles/typography';

type Props = {
  header?: React.ReactNode | string; // --------------------------------- 상단메시지
  main?: React.ReactNode | string; // ----------------------------------- 본문
  footer?: React.ReactNode | string; // --------------------------------- 하단
  title?: string; // ---------------------------------------------------- 제목
  message?: string; // -------------------------------------------------- 메시지
  description?: string;
  warning?: boolean; // ------------------------------------------------- 경고유무(빨간색)
  style?: CSS; // ------------------------------------------------------- StyleSheet
  onConfirm?: () => void; // -------------------------------------------- 확인버튼
  onClose?: (value: boolean) => void;
};

/**
 * @name PopupCreate
 * @description
 */
export default function PopupCreate({
  title,
  message,
  description,
  header,
  main,
  footer,
  warning,
  style,
  onConfirm,
}: Props) {
  return (
    <Content style={style}>
      <PopupHeader>{header ?? <Title $isWarning={warning}>{title}</Title>}</PopupHeader>
      <PopupMain>
        {main ?? <Message>{message}</Message>}
        {description && <Description>{description}</Description>}
      </PopupMain>
      <PopupFooter>
        {footer ?? (
          <SubmitButton
            warning={warning}
            onConfirm={() => {
              if (onConfirm) {
                onConfirm();
              }
            }}
          />
        )}
      </PopupFooter>
    </Content>
  );
}
const Content = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;
const PopupHeader = styled.div``;
const PopupMain = styled.div`
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;
const Title = styled.p<{ $isWarning?: boolean }>`
  ${typography.headline1};
  color: ${props => (props.$isWarning ? COLOR.RED2 : COLOR.BLACK1)};
`;
const Message = styled.p`
  ${typography.headline2};
  color: ${COLOR.GRAY1};
`;
const Description = styled.p`
  ${typography.body3};
  color: ${COLOR.GRAY1};
  white-space: pre-line;
  text-align: center;
`;
const PopupFooter = styled.div`
  display: block;
  width: 100%;
  max-width: 320px;
  margin-top: 2px;
`;
