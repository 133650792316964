/**
 * @name 가능,불가분기버튼
 * @description
 */
import Badge from '@/components/atoms/Badge';

type Props = {
  type: boolean
  onClick?: () => void
}
export default function PossibleImpossible({type}: Props) {
  // hooks
  //*--------------------------------------------------*
  return <Badge mode={`${type ? 'Available' : 'Dark1'}`} label={`${type ? '가능' : '불가'}`} />
}
/********************************************************
[사용법]

<PossibleImpossible type={item?.is_concurrency} />

*********************************************************/
