/**
 * @name Header
 * @description
 */
import { COLOR } from 'styles';
import { Div, Events, H3, Row, useContext } from 'lib';
import { useAppDispatch } from 'model';
import { MENU } from '@/pages/router';
import { ListTitleSearch, ButtonAddDownload, ButtonsModifyDel, BackButton } from 'components';
import { Store, _action, _event, _mode } from '../../index';
import { API_HOST } from 'config';
import FilterButton from '@/components/atoms/Buttons/FilterButton';
//contents
import Search from './search';
import { getExcel } from 'pages/common';

export default function Contents() {
  // hooks
  const { cpo_seq } = useContext();
  const dispatch = useAppDispatch();
  const { mode, list, view, search_payload } = Store();
  //*--------------------------------------------------*
  const setContents = () => {
    switch (mode) {
      case _mode.DEFAULT: //---------------------------기본
      case _mode.LIST: //------------------------------리스트
        return (
          <div style={{ width: '100%' }}>
            <Div style={{ width: '100%' }}>
              <Row>
                <ListTitleSearch
                  title="충전소 등록 관리"
                  total={list?.meta?.paging?.total_count ?? 0}
                  placeholder="충전소 ID, 또는 이름으로 검색해 주세요."
                  onChange={str => {
                    // 빈값을때 리스트를 다시 불러온다.
                    if (str === '') Events.emit(_event.FETCH_LIST, { search: str });
                  }}
                  onSubmit={str => {
                    Events.emit(_event.FETCH_LIST, { search: str });
                  }}
                />
                <FilterButton
                  style={{ marginLeft: 8 }}
                  onChange={(bool: boolean) => {
                    dispatch(_action.setSearchPayload({ isOpen: bool }));
                    // console.log(bool)
                  }}
                />
              </Row>
              <ButtonAddDownload
                addLabel="충전소 등록"
                addRoute={`${MENU.TEMPLATE}/add`}
                onDownload={() => {
                  getExcel(`${API_HOST}/stations?cpo_seq=${cpo_seq}&sort_element=seq&exports=xlsx`);
                }}
              />
            </Div>
            {/* 검색상세화면 */}
            {search_payload.isOpen && <Search />}
          </div>
        );
      case _mode.VIEW: //------------------------------상세
        return (
          <Div style={{ width: '100%' }}>
            <BackButton label={view?.data?.name} />
            <ButtonsModifyDel
              onModify={() => {
                dispatch(_action.setMode(_mode.MODIFY));
              }}
              onDelete={() => {
                // Events.emit(_event.POPUP_SHOW, _popup.STATION_REMOVE, {title: 'test'})
              }}
            />
          </Div>
        );
      case _mode.MODIFY: //----------------------------수정
        return <BackButton label={view?.data?.name} />;
      case _mode.REGISTER: //--------------------------등록
        return <H3 color={COLOR.BLACK3}>충전소 등록</H3>;
      default:
        return <div>없음</div>;
    }
  };
  return <header className="inc_header">{setContents()}</header>;
}
