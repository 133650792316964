import React from 'react';
import styled from 'styled-components';
import { debug, uuid } from 'lib';
import { ClassName, CSS, RenderItem } from 'types';

type Props = {
  info: any[];
  row?: boolean; // 가로정렬,세로정렬
  renderItem?: RenderItem;
  style?: CSS;
  itemStyle?: CSS;
  containerClassName?: ClassName; // 클래스적용
  className?: ClassName; // 클래스적용
};

/**
 * @name List
 * @description 리스트 나열
 */
export default function List({ style, containerClassName, className, itemStyle, row, renderItem, info }: Props) {
  const rowStyle: React.CSSProperties = row ? { flexDirection: 'row' } : { flexDirection: 'column' };
  const combinedStyle = { ...rowStyle, ...style };
  const combinedItemStyle = { ...itemStyle };
  return (
    <Element style={combinedStyle} className={containerClassName}>
      {renderItem === undefined && debug(info)}
      {!!info &&
        info?.length > 0 &&
        info.map((item, index) => {
          return (
            <li className={className} style={combinedItemStyle} key={uuid()}>
              {!!renderItem && renderItem({ item, index })}
            </li>
          );
        })}
    </Element>
  );
}
const Element = styled.ul`
  width: 100%;
`;
