import React from 'react';
import { Div, H3, Row, ImageButton, Events } from 'lib';
import { useAppDispatch } from 'model';
import { icon_download } from 'assets/images';
import { ListTitleSearch, BackButton } from 'components';
import { useTranslation } from 'react-i18next';
import { Store, _action, _event, _mode } from '../../index';
import Search from './search';
import FilterButton from '@/components/atoms/Buttons/FilterButton';
/**
 * @name Header
 * @description
 */
export default function Contents() {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const { mode, list, search_payload, view } = Store();
  const setContents = () => {
    switch (mode) {
      case _mode.DEFAULT: // ---------------------------기본
      case _mode.LIST: // ------------------------------리스트
        return (
          <section style={{ width: '100%' }}>
            <Div style={{ width: '100%' }}>
              <Row>
                <ListTitleSearch
                  title={t('forms.history.failHistory')}
                  total={list?.meta?.paging?.total_count}
                  placeholder={t('pages.operation.searchPlaceholder')}
                  onChange={str => {
                    // console.log(str);
                  }}
                  onSubmit={str => {
                    Events.emit(_event.FETCH_LIST, { search: str });
                  }}
                />
                <FilterButton
                  style={{ marginLeft: 8 }}
                  onChange={(bool: boolean) => {
                    dispatch(_action.setSearchPayload({ isOpen: bool }));
                    // console.log(bool);
                  }}
                />
              </Row>
              <ImageButton
                source={icon_download}
                style={{ width: 40, height: 40 }}
                onClick={() => {
                  dispatch(_action.setInit());
                }}
              />
            </Div>
            {search_payload.isOpen && <Search />}
          </section>
        );
      case _mode.VIEW: // ------------------------------상세
        return <BackButton label={`${view?.data?.chargepoint?.name}`} />;
      default:
        return <div>{t('common.none')}</div>;
    }
  };
  return <header className="inc_header">{setContents()}</header>;
}
