/**
 * @name H6
 * @description
 */
import styled from 'styled-components'
import {TextProps} from 'types'
import Text from './text'

export default function H6({children, label, ...props}: TextProps) {
  return (
    <Element>
      <Text {...props}>
        {!!label && label}
        {children}
      </Text>
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.h6`
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.39px;
`
