import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { Image } from 'lib';
import typography from '@/styles/typography';
import Pagination from '@/components/organisms/Paginations/Pagination';
import {
  NoResultDescription,
  NoResultText,
  NoResultTextWrapper,
  NoSearchResultTextWrapper,
  NoSearchResultWrapper,
  Table,
  TableBody,
  TableHead,
  TD,
  TH,
  TR,
  Wrapper,
} from './CalculateTable.style';
import { useAppSelector } from '@/model/index';
import { icons } from '@/assets/images';
import { ICalculate } from '@/lib/apis/sales/type';
import { MENU } from '@/pages/router';

export default function CalculateTable({
  itemsPerPage,
  currentPage,
  pageHandler,
  queryString,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  queryString: string;
}) {
  const { t } = useTranslation('common');
  const { list, totalCount } = useAppSelector(state => state.sales);
  const navigate = useNavigate();

  const handleNavigateToDetail = ({ year, month }: { year: number; month: number }) => {
    navigate(`${MENU.CALCULATE_STATUS}/${year}/${month}`);
  };
  return (
    <Wrapper>
      <Table>
        <colgroup>
          <col style={{ width: 56 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 61 }} />
          <col style={{ minWidth: 61 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 61 }} />
        </colgroup>
        <TableHead>
          <TR>
            <TH>No.</TH>
            <TH>{t('pages.sales.calculateStatus.target.year')}</TH>
            <TH>{t('pages.sales.calculateStatus.target.month')}</TH>
            <TH>{t('pages.sales.calculateStatus.target.stations')}</TH>
            <TH>{t('pages.sales.calculateStatus.target.chargers')}</TH>
            <TH>{t('pages.sales.calculateStatus.total.chargeAmount')}</TH>
            <TH>{t('pages.sales.calculateStatus.total.chargePrice')}</TH>
          </TR>
        </TableHead>
        <TableBody>
          {list.length > 0 &&
            list.map((item: ICalculate, index: number) => (
              <TR key={nanoid()} onClick={() => handleNavigateToDetail({ year: item.year, month: item.month })}>
                <TD $customFont={typography.body4}>
                  {totalCount - (currentPage - 1) * itemsPerPage - index}
                  {/* {(currentPage - 1) * 10 + (idx + 1)} */}
                </TD>
                <TD $customFont={typography.body5}>
                  {item.year}
                  {t('common.unit.year')}
                </TD>
                <TD $customFont={typography.body5}>
                  {item.month}
                  {t('common.unit.month')}
                </TD>
                <TD $customFont={typography.body4}>{item.stations}</TD>
                <TD $customFont={typography.body4}>{item.chargepoints}</TD>
                <TD $customFont={typography.body5}>{item.kw}</TD>
                <TD $customFont={typography.body5}>{item.amount}</TD>
              </TR>
            ))}
        </TableBody>
      </Table>
      {list.length > 0 && (
        <Pagination
          listLength={totalCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onClick={pageHandler}
        />
      )}
      {list.length === 0 && (
        <NoSearchResultWrapper>
          <Image src={icons.noSalesData.image.default} style={{ width: 192, height: 192 }} />
          <NoSearchResultTextWrapper>
            <NoResultTextWrapper>
              <NoResultText>{t('pages.sales.calculateStatus.noData.title')}</NoResultText>
            </NoResultTextWrapper>
            <NoResultDescription>{t('pages.sales.calculateStatus.noData.description')}</NoResultDescription>
          </NoSearchResultTextWrapper>
        </NoSearchResultWrapper>
      )}
    </Wrapper>
  );
}
