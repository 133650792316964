import { COLOR } from '@/styles/index';

export type BadgeType = keyof typeof BadgeMode;
export const BadgeMode = {
  Primary: { color: COLOR.WHITE, backgroundColor: COLOR.BLUE3 },
  Available: { color: COLOR.BLUE1, backgroundColor: COLOR.BLUE8 },
  Unavailable: { color: COLOR.GRAY3, backgroundColor: `rgba(178, 178, 178, 0.15)` },
  Fast: { color: COLOR.GREEN2, backgroundColor: `rgba(64, 201, 151, 0.15)` },
  Standard: { color: COLOR.YELLOW2, backgroundColor: `rgba(246, 174, 63, 0.15)` },
  Connected: { color: COLOR.GREEN2, backgroundColor: `rgba(64, 201, 151, 0.15)` },
  Unconnected: { color: COLOR.GRAY3, backgroundColor: `rgba(178, 178, 178, 0.15)` },
  Admin: { color: COLOR.BLUE1, backgroundColor: COLOR.BLUE8 },
  Secondary: { color: COLOR.WHITE, backgroundColor: COLOR.GRAY3 },
  Secondary1: { color: COLOR.GRAY3, backgroundColor: `rgba(178, 178, 178, 0.15)` },
  Danger: { color: COLOR.WHITE, backgroundColor: COLOR.RED2 },
  Danger1: { color: COLOR.RED2, backgroundColor: `rgba(255, 91, 92, 0.15)` },
  Warning: { color: COLOR.WHITE, backgroundColor: COLOR.YELLOW2 },
  Warning1: { color: COLOR.YELLOW2, backgroundColor: `rgba(246, 174, 63, 0.15)` },
  Info: { color: COLOR.WHITE, backgroundColor: COLOR.BLUE5 },
  Info1: { color: COLOR.BLUE5, backgroundColor: `rgba(130, 165, 237, 0.15)` },
  Dark: { color: COLOR.WHITE, backgroundColor: COLOR.BLACK3 },
  Dark1: { color: COLOR.BLACK3, backgroundColor: `rgba(55, 55, 55, 0.15)` },

  TariffNone: { color: COLOR.BLACK3, backgroundColor: `rgba(55, 55, 55, 0.15)` },
  TariffUser: { color: COLOR.BLUE1, backgroundColor: COLOR.BLUE8 },
  TariffRoaming: { color: COLOR.GREEN2, backgroundColor: `rgba(64, 201, 151, 0.15)` },

  AccountStatActive: { color: COLOR.GREEN2, backgroundColor: `rgba(64, 201, 151, 0.15)` },
  AccountStatInvited: { color: COLOR.GRAY3, backgroundColor: `rgba(178, 178, 178, 0.15)` },
  AccountStatSuspend: { color: COLOR.GRAY3, backgroundColor: `rgba(178, 178, 178, 0.15)` },
  AccountStatWithdraw: { color: COLOR.GRAY3, backgroundColor: `rgba(178, 178, 178, 0.15)` },

  RemoteActionFailed: { color: COLOR.RED2, background: `rgba(255, 91, 92, 0.15)` },
  RemoteActionSuccess: { color: COLOR.GREEN2, background: `rgba(64, 201, 151, 0.15)` },
  RemoteActionWaiting: { color: COLOR.YELLOW2, background: `rgba(246, 174, 63, 0.15)` },
};
