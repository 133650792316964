import { App } from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { H3, Row, Text, addComma } from 'lib';
import React from 'react';
import { useAppDispatch, useAppSelector } from '@/model/index';
import { COLOR } from '@/styles/index';
import { TextFiledSearch } from '@/components/form';
import DownloadButton from '@/components/atoms/Buttons/DownloadButton';
import RemoteTable from '@/components/organisms/Tables/RemoteTable';
import FilterButton from '@/components/atoms/Buttons/FilterButton';
import { IRemoteHistoryFilter } from '@/components/organisms/Filters/RemoteHistoryFilter/RemoteHistoryFilter.type';
import RemoteHistoryFilter from '@/components/organisms/Filters/RemoteHistoryFilter';

/**
 * @name 원격제어 리스트
 * @description
 */
export default function RemoteHistoryListPresenter({
  itemsPerPage,
  currentPage,
  pageHandler,
  handleExcelDownload,
  searchKeyword,
  handleSearchKeyword,
  handleSearch,
  detailSearchTerms,
  handleSetDetailSearchTerms,
  handleFilterOpen,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  handleExcelDownload: () => void;
  handleSearchKeyword: (keyword: string) => void;
  handleSearch: () => void;
  searchKeyword: string;
  detailSearchTerms: IRemoteHistoryFilter;
  handleSetDetailSearchTerms: (terms: IRemoteHistoryFilter) => void;
  handleFilterOpen: (value: boolean) => void;
}) {
  const { t } = useTranslation('common');
  const { totalCount, search_payload } = useAppSelector(state => state.remote);

  return (
    <App>
      <Header style={{ width: '100%' }}>
        <HeaderFuncWrapper>
          <Row>
            <div style={{ marginRight: 20 }}>
              <H3 color={COLOR.BLACK3}>{t('pages.operation.remote.list.title')}</H3>
              <Text color={COLOR.BLACK3} className="body6">
                {`${t('common.unit.total')} ${addComma(totalCount)}${t('common.unit.cases')}`}
              </Text>
            </div>
            <div className="search" style={{ marginLeft: 20 }}>
              <TextFiledSearch
                defaultValue={searchKeyword}
                placeholder={t('pages.operation.remote.list.searchByStation')}
                onChange={str => {
                  handleSearchKeyword(str);
                }}
                onSubmit={handleSearch}
              />
            </div>
            <FilterButton style={{ marginLeft: 8 }} value={search_payload.isOpen} onChange={handleFilterOpen} />
          </Row>
          <ButtonWrapper>
            <DownloadButton onClick={handleExcelDownload} />
          </ButtonWrapper>
        </HeaderFuncWrapper>
        <FilterWrapper>
          {search_payload.isOpen && (
            <RemoteHistoryFilter
              detailSearchTerms={detailSearchTerms}
              handleSetDetailSearchTerms={handleSetDetailSearchTerms}
            />
          )}
        </FilterWrapper>
      </Header>
      <RemoteTable itemsPerPage={itemsPerPage} currentPage={currentPage} pageHandler={pageHandler} />
    </App>
  );
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${COLOR.GRAY5};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderFuncWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 16px 16px 16px 24px;
  align-items: center;
  justify-content: space-between;
`;
const FilterWrapper = styled.div`
  padding: 8px 32px;
`;
