import React from 'react';
import { App, BackButton } from 'components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useAppSelector } from '@/model/index';
import Badge from '@/components/atoms/Badge';
import {
  Header,
  ListWrapper,
  SummaryWrapper,
  TableTitle,
  styleListKeyValue,
  Wrapper,
  RowHeader,
  Cell,
  CustomGap,
  Row,
} from './RemoteHistoryDetail.styles';
import { ListKeyValue } from '@/components/form';

/**
 * @name 원격제어 상세
 * @description
 */
export default function RemoteHistoryDetailPresenter({ handleBackToList }: { handleBackToList: () => void }) {
  const { t } = useTranslation('common');
  const { view } = useAppSelector(state => state.remote);

  const renderActionResult = (type: string, responseAt: string) => {
    if (type === 'CALL_ERROR' || responseAt === null) {
      return <Badge mode="RemoteActionFailed" label={t('pages.operation.remote.status.failed')} />;
    }
    if (type === 'CALL_RESULT') {
      return <Badge mode="RemoteActionSuccess" label={t('pages.operation.remote.status.success')} />;
    }
    return <Badge mode="RemoteActionWaiting" label={t('pages.operation.remote.status.waiting')} />;
  };

  return (
    <App>
      <Header style={{ width: '100%' }}>
        <BackButton label={t('pages.operation.remote.remoteHistoryDetail')} onClick={handleBackToList} />
      </Header>
      <Wrapper>
        <SummaryWrapper>
          <TableTitle>{t('pages.operation.remote.actionSummary')}</TableTitle>
          <ListWrapper>
            <ListKeyValue
              title={t('pages.operation.remote.remoteUniqueNo')}
              value={view.seq}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.remote.remoteCreatedAt')}
              value={dayjs(view.remote.created_at).format('YYYY.MM.DD HH:mm:ss')}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.remote.remoteType')}
              value={
                view.remote.types === 'SINGLE'
                  ? t('pages.operation.remote.remoteAction')
                  : t('pages.operation.remote.multipleAction')
              }
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.remote.actionType')}
              value={view.remote.remote_action.control_name}
              valueStyle={styleListKeyValue}
            />
            {/* <ListKeyValue
              title={t('pages.operation.remote.remoteCreatedBy')}
              value={view.remote.created_by?.email}
              valueStyle={styleListKeyValue}
            /> */}
          </ListWrapper>
        </SummaryWrapper>
        <CustomGap />
        <SummaryWrapper>
          <TableTitle>{t('pages.operation.remote.result')}</TableTitle>
          <Row>
            <RowHeader>{t('pages.operation.remote.targetDevice')}</RowHeader>
            <RowHeader>{t('pages.operation.remote.targetDeviceID')}</RowHeader>
            <RowHeader>{t('pages.operation.remote.result')}</RowHeader>
            <RowHeader>{t('pages.operation.remote.chargepointBelongsTo')}</RowHeader>
            <RowHeader>{t('pages.operation.remote.stationBelongsTo')}</RowHeader>
          </Row>
          <Row>
            <Cell>
              {view.connector_number === 0
                ? t('pages.operation.remote.charger')
                : t('pages.operation.remote.connector')}
            </Cell>
            <Cell>{view.chargepoint.code}</Cell>
            <Cell>{renderActionResult(view.response_type, view.response_at)}</Cell>
            <Cell>{view.chargepoint.name}</Cell>
            <Cell>{view.chargepoint.station.name}</Cell>
          </Row>
        </SummaryWrapper>
      </Wrapper>
    </App>
  );
}
