/**
 * @name Header
 * @description
 */
import {BackButton} from 'components'

export default function Contents() {
  // hooks
  const setContents = () => {
    return <BackButton label={'이용약관 / 개인정보 처리방침'} />
  }
  return <header className="inc_header">{setContents()}</header>
}
