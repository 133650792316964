/**
 * @name 콤마찍기
 * @returns boolean
 */
export const addComma = (num: any): string => {
  if (!num) return '';
  try {
    return Intl.NumberFormat('en-US').format(num);
  } catch (e) {
    return String(num);
  }
};

export const formatFileSize = (bytes: number | undefined, decimals = 2) => {
  if (bytes === 0 || bytes === undefined) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
