import React, { useState } from 'react';
import { App, BackButton, ButtonsModifyDel } from 'components';
import { useTranslation } from 'react-i18next';
import { Button, Div, formatDate, Image, Tab } from 'lib';
import { useAppSelector } from 'model';
import {
  ListKeyValue,
  PopupContainer,
  PopupCreate,
  SubmitCancel,
  TextFiledGroup,
  TextFiledLabel,
  TextInput,
} from '@/components/form';
import {
  ChannelIdDescription,
  ChannelIdDescriptionText,
  Header,
  InstalledInfoWrapper,
  ModelInfoWrapper,
  RoamingCell,
  RoamingHeader,
  RoamingInfoWrapper,
  RoamingRow,
  SummaryWrapper,
  Wrapper,
} from './ChargerDetail.styles';
import { warningMark } from '@/assets/images';
import { displayCoordinate, displayNull, displayUnits } from '@/lib/util/displayData';
import Badge from '@/components/atoms/Badge';

export default function ChargerDetailPresenter({
  onEdit,
  handleDeletePopup,
  deletePopupVisible,
  onDeleteConfirm,
  handleBackToList,
}: {
  onEdit: () => void;
  handleDeletePopup: (value: boolean) => void;
  deletePopupVisible: boolean;
  onDeleteConfirm: () => void;
  handleBackToList: () => void;
}) {
  const { view } = useAppSelector(state => state.device);
  const { t } = useTranslation('common');
  const tabMenu = [
    { label: t('pages.infra.chargerManage.chargerDetail.tabs.default') },
    { label: t('pages.infra.chargerManage.chargerDetail.tabs.modelManufacturer') },
    { label: t('pages.infra.chargerManage.chargerDetail.tabs.installation') },
    // { label: '정보 수정 내역' },
    { label: t('pages.infra.chargerManage.chargerDetail.tabs.roaming') },
  ];
  const [selected, setSelected] = useState(0);

  return (
    <App>
      <Header>
        <BackButton label={view ? view.name : ''} onClick={handleBackToList} />
        <ButtonsModifyDel onModify={onEdit} onDelete={() => handleDeletePopup(true)} />
      </Header>
      <main className="inc_form" style={{ width: 1080 }}>
        <Tab
          selected={selected}
          style={{ marginBottom: 32 }}
          info={tabMenu}
          onChange={({ index }) => {
            setSelected(index);
          }}
        />
      </main>
      <Wrapper>
        {view && selected === 0 && (
          <SummaryWrapper>
            <TextFiledGroup
              label={t('forms.model.chargerName')}
              required
              desc={t('forms.model.nameDesc')}
              component={
                <Div>
                  <TextInput
                    defaultValue={view.name}
                    disabled
                    style={{ width: '77%' }}
                    placeholder={t('forms.model.nameEmptyWarning')}
                    name="name"
                  />
                  <Button label={t('common.buttons.duplicateCheck')} disabled />
                </Div>
              }
            />
            <TextFiledGroup
              label={t('forms.model.heartbeatInterval')}
              required
              desc={t('forms.model.heartbeatIntervalDesc')}
              component={
                <TextInput
                  defaultValue={view.heartbeat_interval}
                  disabled
                  placeholder={t('forms.model.heartbeatIntervalWarning')}
                  name="heartbeat_interval"
                />
              }
            />
            {/* <TextFiledGroup */}
            {/*  label="충전기 제조국가" */}
            {/*  component={ */}
            {/*    <CountriesSearch disabled popupStyle={{ height: 200 }} value={view.model.vendor.country_code2} /> */}
            {/*  } */}
            {/* /> */}
            <TextFiledGroup
              label={t('forms.model.date')}
              component={<TextInput disabled value={formatDate(view.produced_at)} />}
            />
            <TextFiledGroup
              label={t('forms.model.installationDate')}
              component={<TextInput disabled value={formatDate(view.installed_at)} />}
            />
            <ListKeyValue title={t('forms.charger.channelId')} value={displayNull(view.channel_id)}>
              <ChannelIdDescription>
                <Image src={warningMark} style={{ width: 16, height: 16 }} />
                <ChannelIdDescriptionText>{t('forms.charger.channelIdDesc')}</ChannelIdDescriptionText>
              </ChannelIdDescription>
            </ListKeyValue>
            <ListKeyValue title={t('forms.charger.chargerId')} value={view.code} />
            {view.connectors.length > 0 && (
              <ListKeyValue
                title={`${t('forms.charger.chargerId')} (1)`}
                value={`${view.connectors[0].code} - ${t(`common.connector.${view.connectors[0].types}`)}`}
              />
            )}
            {view.connectors.length > 1 && (
              <ListKeyValue
                title={`${t('forms.charger.chargerId')} (2)`}
                value={`${view.connectors[1].code} - ${t(`common.connector.${view.connectors[1].types}`)}`}
              />
            )}
            {/* <ListKeyValue title="충전기 S/N" value="SN 개념이 있어? " /> */}
          </SummaryWrapper>
        )}
        {view && selected === 1 && (
          <ModelInfoWrapper>
            <ListKeyValue title={t('forms.model.chargerModelName')} value={view.model.name} />
            <ListKeyValue title={t('forms.model.chargerManufacturerName')} value={view.model.vendor.name} />
            <ListKeyValue title={t('forms.model.chargerManufacturerId')} value={view.model.vendor.vendor_id} />
            <ListKeyValue
              title={t('forms.model.chargerType')}
              component={
                <Badge
                  mode={view.model.charger_type === 'FAST' ? 'Fast' : 'Standard'}
                  label={view.model.charger_type === 'FAST' ? t('common.rapid') : t('common.standard')}
                />
              }
            />
            <ListKeyValue
              title={t('forms.model.simultaneousCharging')}
              component={
                <Badge
                  mode={view.model.is_concurrency ? 'Available' : 'Unavailable'}
                  label={view.model.is_concurrency ? t('common.available') : t('common.unavailable')}
                />
              }
            />
            <ListKeyValue
              title={`${t('forms.charger.maximumOutput')}${t('common.unit.brackets.kWh')}`}
              value={displayUnits(view.model.max_power_kw, 'kWh')}
            />
            <ListKeyValue
              title={t('forms.model.chargerNumberOfConnectors')}
              value={`${view.model.connector_count}${t('common.unit.numberOf')}`}
            />
            <ListKeyValue
              title={`${t('forms.model.connectorTypeMaximumOutput')}`}
              value={
                view.connectors.length > 1
                  ? `(1) ${t(`common.connector.${view.connectors[0].types}`)} / ${displayUnits(
                      view.connectors[0].max_kw,
                      'kWh',
                    )}\n(2) ${t(`common.connector.${view.connectors[1].types}`)} / ${displayUnits(
                      view.connectors[1].max_kw,
                      'kWh',
                    )}`
                  : `(1) ${t(`common.connector.${view.connectors[0].types}`)} / ${displayUnits(
                      view.connectors[0].max_kw,
                      'kWh',
                    )}`
              }
            />
            <ListKeyValue
              title={`${t('forms.model.chargerNumberOfConnectors')} ${t('common.unit.brackets.V')}`}
              value={displayUnits(view.model.max_voltage, 'V')}
            />
            <ListKeyValue
              title={`${t('forms.model.chargerMaximumAmpere')} ${t('common.unit.brackets.A')}`}
              value={displayUnits(view.model.max_ampare, 'A')}
            />
            <ListKeyValue
              title={`${t('forms.model.chargerEfficiencies')} ${t('common.unit.brackets.percent')}`}
              value={displayUnits(view.model.efficiencies, '%')}
            />
            <ListKeyValue title={t('forms.model.date')} value={formatDate(view.produced_at)} />
          </ModelInfoWrapper>
        )}
        {view && selected === 2 && (
          <InstalledInfoWrapper>
            <ListKeyValue
              title={t('forms.model.chargerChannelingStatus')}
              component={
                <Badge
                  mode={view.station.status === 'ACTIVE' ? 'Connected' : 'Unconnected'}
                  label={view.station.status === 'ACTIVE' ? t('common.suspended') : t('common.unsuspended')}
                />
              }
            />
            <ListKeyValue title={t('forms.model.chargerSuspendedDate')} value={formatDate(view.suspended_at)} />
            <div style={{ marginBottom: 40 }} />
            <TextFiledLabel label={t('forms.model.installedLocation')} />
            <ListKeyValue title={t('forms.charger.name')} value={view.station.name} />
            <ListKeyValue title={t('forms.charger.stationId')} value={view.station.code} />
            <ListKeyValue
              title={t('pages.infra.chargerManage.stationSearch.type')}
              value={`${view.station.station_type.type_group} > ${view.station.station_type.type_detail}`}
            />
            <ListKeyValue
              title={t('pages.infra.chargerManage.stationSearch.location')}
              value={view.station.kr_location.name}
            />
            <ListKeyValue title={t('pages.infra.chargerManage.stationSearch.address')} value={view.station.address} />
            <ListKeyValue
              title={t('pages.infra.chargerManage.stationSearch.addressDetail')}
              value={view.station.address_detail}
            />
            <ListKeyValue
              title={`${t('pages.accountManage.station.manageStation.latitude')},${t(
                'pages.accountManage.station.manageStation.longitude',
              )}`}
              value={displayCoordinate(view.station.latitude, view.station.longitude)}
            />
          </InstalledInfoWrapper>
        )}
        {view && selected === 3 && (
          <RoamingInfoWrapper>
            <RoamingRow>
              <RoamingHeader>{t('forms.charger.roamingStationId')}</RoamingHeader>
              <RoamingHeader>{t('forms.charger.roamingChargerId')}</RoamingHeader>
            </RoamingRow>
            {view.roaming_chargepoint_id1 || view.roaming_chargepoint_id2 ? (
              <>
                {view.roaming_chargepoint_id1 && (
                  <RoamingRow>
                    <RoamingCell>{view.roaming_station_id}</RoamingCell>
                    <RoamingCell>{view.roaming_chargepoint_id1}</RoamingCell>
                  </RoamingRow>
                )}
                {view.roaming_chargepoint_id2 && (
                  <RoamingRow>
                    <RoamingCell>{view.roaming_station_id}</RoamingCell>
                    <RoamingCell>{view.roaming_chargepoint_id2}</RoamingCell>
                  </RoamingRow>
                )}
              </>
            ) : (
              <RoamingRow>
                <RoamingCell>{t('forms.charger.noInformation')}</RoamingCell>
              </RoamingRow>
            )}
          </RoamingInfoWrapper>
        )}
      </Wrapper>
      <PopupContainer onClose={() => handleDeletePopup(false)} visible={deletePopupVisible}>
        <PopupCreate
          warning
          title={t('common.toast.removeCharger')}
          message={t('common.toast.askRemoveCharger')}
          footer={
            <SubmitCancel
              confirmLabel={t('common.buttons.removeAction')}
              onCancel={() => handleDeletePopup(false)}
              onConfirm={onDeleteConfirm}
            />
          }
        />
      </PopupContainer>
    </App>
  );
}
