import { API_HOST } from '@/config/index';
import { GetTariffRes, UpsertTariff } from './type';
import api from '@/lib/apis/api';
import { IResponse } from '../api/type';
import { objectToQueryString } from '@/lib/util';
import { Paging } from '../infra/type';

export const getTariff = (): IResponse<GetTariffRes[]> => {
  const url = `${API_HOST}/cpo/tariff/fixed`;
  return api.get({ url }) as unknown as IResponse<GetTariffRes[]>;
};

export const getRoamingTariffList = (paging?: Paging) => {
  const param = objectToQueryString({ ...paging });
  const url = `${API_HOST}/cpo/tariff/roaming?${param}`; // TODO Roaming List API 호출 주소
  return api.get({ url });
};

export const getRoamingTariff = (id: string): IResponse<GetTariffRes> => {
  const url = `${API_HOST}/cpo/tariff/fixed/${id}`; // TODO Roaming 상세보기 API 호출 주소
  return api.get({ url }) as unknown as IResponse<GetTariffRes>;
};

// TODO Roaming 삭제 API 호출 파라미터 타입 설정
export const removeRoamingTariff = (id: string): IResponse<boolean> => {
  const url = `${API_HOST}/cpo/tariff/fixed/${id}`; // TODO Roaming 삭제 API 호출 주소
  return api.delete({ url }) as unknown as IResponse<boolean>;
};

export const postTariff = (cpoSeq: string, payload: UpsertTariff[]): IResponse<boolean> => {
  const url = `${API_HOST}/cpo/tariff/fixed/${cpoSeq}`;
  return api.post({ url, payload }) as unknown as IResponse<boolean>;
};

// TODO Roaming 등록 API 호출 파라미터 타입 설정
export const postRoamingTariff = (cpoSeq: string, payload: UpsertTariff): IResponse<boolean> => {
  const url = `${API_HOST}/cpo/tariff/fixed/${cpoSeq}`;
  const postData = {
    ...payload,
    type: 2,
    fast_amount: 0,
    slow_amount: 0,
    super_fast_amount: 0,
  };
  return api.post({ url, payload: [postData] }) as unknown as IResponse<boolean>;
};

export const patchTariff = (payload: UpsertTariff[]): IResponse<boolean> => {
  const url = `${API_HOST}/cpo/tariff/fixed`;
  return api.patch({ url, payload: [payload] }) as unknown as IResponse<boolean>;
};

export const putTariff = (cpoSeq: string, payload: UpsertTariff[]): IResponse<boolean> => {
  const url = `${API_HOST}/cpo/tariff/fixed/${cpoSeq}`;
  return api.put({ url, payload }) as unknown as IResponse<boolean>;
};

// TODO Roaming 수정 API 호출 파라미터 타입 설정
export const updateRoamingTariff = (cpoSeq: string, payload: UpsertTariff[]): IResponse<boolean> => {
  const url = `${API_HOST}/cpo/tariff/fixed/${cpoSeq}`;
  const postData = {
    ...payload,
    type: 2,
    fast_amount: 0,
    slow_amount: 0,
    super_fast_amount: 0,
  };
  return api.put({ url, payload: postData }) as unknown as IResponse<boolean>;
};
