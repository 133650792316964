import React, { useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from 'model';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ModelRegisterPresenter from './ModelRegister.presenter';
import { actions } from '@/model/modules/model';
import { SCREEN_MODE } from '@/types/mode';
import { editModel, getModel, getVendorSeq, registerModel } from '@/lib/apis/infra';
import { IChargePointModelDetail, ReqModel } from '@/types/infra';
import { MENU } from '@/pages/router';
import { IResponse } from '@/lib/apis/api/type';

/**
 * @description 모델등록관리 상세
 */
export default function ModelRegisterContainer() {
  const {
    auth: { cpo_seq },
    model: { payload, mode, view, duplicatedCheck },
  } = useAppSelector((state: RootState) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const [popupSuccessVisible, setPopupSuccessVisible] = useState(false);
  const [popupCancelVisible, setPopupCancelVisible] = useState(false);
  const [popupFailedVisible, setPopupFailedVisible] = useState(false);
  const [requiredFieldsWarning, setRequiredFieldsWarning] = useState({
    vendorSeq: false,
    name: false,
    requireNameDuplicateCheck: false,
    maxPowerKw: false,
    connectorCount: false,
    connectorOutput: false,
    maxVoltage: false,
    maxAmpare: false,
    efficiencies: false,
    ipxLevel: false,
  });
  const isModify = mode === SCREEN_MODE.MODIFY;

  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const searchKeyword = searchParams.get('search') || '';
  const locationKeyword = searchParams.get('location') || '';

  const queryParamsURL = () => {
    const queryParams = [];

    if (searchKeyword) {
      queryParams.push(`search=${encodeURIComponent(searchKeyword)}`);
    }
    if (locationKeyword) {
      queryParams.push(`location=${encodeURIComponent(locationKeyword)}`);
    }
    queryParams.push(`page=${page}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const onInit = () => {
    if (isModify) {
      fetchModel();
    } else {
      dispatch(actions.setMode(SCREEN_MODE.REGISTER));
    }
  };

  const fetchModel = async () => {
    if (id) {
      const { isSuccess, data }: IResponse<IChargePointModelDetail[]> = await getModel(id);
      if (isSuccess) {
        const vendorResponse = await getVendorSeq(data[0].vendor.seq);
        if (vendorResponse.isSuccess) {
          const chargePointModelData: IChargePointModelDetail = {
            ...data[0],
            vendor: vendorResponse.data,
          };
          dispatch(actions.setView(chargePointModelData));
          dispatch(
            actions.setPayload({
              name: chargePointModelData.name,
              vendorSeq: `${chargePointModelData.vendor.seq}`,
              chargerType: chargePointModelData.charger_type,
              isConcurrency: chargePointModelData.is_concurrency,
              maxPowerKw: `${chargePointModelData.max_power_kw}`,
              connectorCount: `${chargePointModelData.connector_count}`,
              maxVoltage: `${chargePointModelData.max_voltage}`,
              maxAmpare: `${chargePointModelData.max_ampare}`,
              efficiencies: `${chargePointModelData.efficiencies}`,
              ipxLevel: `${chargePointModelData.ipx_level}`,
              connectors: chargePointModelData.model_connectors
                ? chargePointModelData.model_connectors?.map(ele => {
                    return { types: ele.types, max_kw: `${ele.max_kw}` };
                  })
                : [],
            }),
          );
        }
      }
    }
  };

  const onCancelPopup = () => {
    setPopupCancelVisible(true);
  };

  const checkRequiredInputs = () => {
    setRequiredFieldsWarning({
      vendorSeq: false,
      name: false,
      requireNameDuplicateCheck: false,
      maxPowerKw: false,
      connectorCount: false,
      connectorOutput: false,
      maxVoltage: false,
      maxAmpare: false,
      efficiencies: false,
      ipxLevel: false,
    });
    let check = true;
    if (payload.vendorSeq === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        vendorSeq: true,
      }));
      check = false;
    }
    if (payload.name === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        name: true,
      }));
      check = false;
    } else if (!duplicatedCheck.name) {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        requireNameDuplicateCheck: true,
      }));
      check = false;
    }
    if (payload.maxPowerKw === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        maxPowerKw: true,
      }));
      check = false;
    }
    if (payload.connectorCount === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        connectorCount: true,
      }));
      check = false;
    }
    if (payload.connectorCount !== '') {
      for (let i = 0; i < payload.connectors.length; i += 1) {
        if (!payload.connectors[i].max_kw || !payload.connectors[i].types) {
          setRequiredFieldsWarning(prevState => ({
            ...prevState,
            connectorOutput: true,
          }));
          check = false;
        }
      }
    }
    if (payload.maxVoltage === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        maxVoltage: true,
      }));
      check = false;
    }
    if (payload.maxAmpare === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        maxAmpare: true,
      }));
      check = false;
    }
    if (payload.efficiencies === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        efficiencies: true,
      }));
      check = false;
    }
    if (payload.ipxLevel === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        ipxLevel: true,
      }));
      check = false;
    }
    return check;
  };

  const onSubmit = async () => {
    if (checkRequiredInputs()) {
      const reqPayload: ReqModel = {
        name: payload.name,
        vendorSeq: payload.vendorSeq,
        chargerType: payload.chargerType,
        isConcurrency: payload.isConcurrency,
        maxPowerKw: payload.maxPowerKw,
        connectorCount: payload.connectorCount,
        maxVoltage: payload.maxVoltage,
        maxAmpare: payload.maxAmpare,
        efficiencies: payload.efficiencies,
        ipxLevel: payload.ipxLevel,
        connectors: payload.connectors,
      };

      try {
        const submitResponse = await registerModel(reqPayload);
        if (submitResponse.isSuccess) {
          dispatch(actions.initPayload());
          setPopupSuccessVisible(true);
        }
      } catch (e) {
        setPopupFailedVisible(true);
      }
    }
  };

  const onPatchSubmit = async () => {
    if (checkRequiredInputs() && id) {
      const reqPayload: ReqModel = {
        name: payload.name,
        vendorSeq: payload.vendorSeq,
        chargerType: payload.chargerType,
        isConcurrency: payload.isConcurrency,
        maxPowerKw: payload.maxPowerKw,
        connectorCount: payload.connectorCount,
        maxVoltage: payload.maxVoltage,
        maxAmpare: payload.maxAmpare,
        efficiencies: payload.efficiencies,
        ipxLevel: payload.ipxLevel,
        connectors: payload.connectors,
      };

      try {
        const submitResponse = await editModel(reqPayload, id);
        if (submitResponse.isSuccess) {
          dispatch(actions.initPayload());
          setPopupSuccessVisible(true);
        }
      } catch (e) {
        setPopupFailedVisible(true);
      }
    }
  };

  const handleCloseCancelPopup = () => {
    setPopupCancelVisible(false);
  };

  const handleDone = () => {
    dispatch(actions.initView());
    dispatch(actions.initPayload());
    setPopupSuccessVisible(false);
    if (isModify) {
      dispatch(actions.setMode(SCREEN_MODE.VIEW));
      navigate(`${MENU.INFRA.MODEL_MANAGEMENT}/${id}?${queryParamsURL()}`);
    } else {
      dispatch(actions.setMode(SCREEN_MODE.VIEW));
      navigate(MENU.INFRA.MODEL_MANAGEMENT);
    }
  };

  const handleCloseFailedPopup = () => {
    setPopupFailedVisible(false);
  };

  useEffect(() => {
    onInit();
  }, []);

  return (
    <ModelRegisterPresenter
      onSubmit={isModify ? onPatchSubmit : onSubmit}
      onCancelPopup={onCancelPopup}
      popupSuccessVisible={popupSuccessVisible}
      popupCancelVisible={popupCancelVisible}
      handleCloseCancelPopup={handleCloseCancelPopup}
      handleDone={handleDone}
      requiredFieldsWarning={requiredFieldsWarning}
      popupFailedVisible={popupFailedVisible}
      handleCloseFailedPopup={handleCloseFailedPopup}
    />
  );
}
