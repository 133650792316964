/**
 * @name 취소,확인버튼
 * @description
 */
import React from 'react';
import styled from 'styled-components';
import { CSS } from 'types';
import { Button } from 'lib';
import { COLOR } from 'styles';
import { useTranslation } from 'react-i18next';

type Props = {
  cancelLabel?: string;
  onCancel?: () => void;
  confirmLabel?: string;
  onConfirm?: () => void;
  style?: CSS;
  confirmDisabled?: boolean;
};
export default function SubmitConfirm({
  cancelLabel,
  confirmLabel,
  onCancel,
  onConfirm,
  style,
  confirmDisabled,
}: Props) {
  const { t } = useTranslation('common');

  return (
    <Content style={style}>
      <Button
        full
        label={cancelLabel ?? t('common.buttons.cancel')}
        bg="transparent"
        borderColor={COLOR.BLUE3}
        color={COLOR.BLUE3}
        style={{ height: 48, borderRadius: 8, fontSize: 15 }}
        onClick={() => {
          if (onCancel) {
            onCancel();
          }
        }}
      />
      <Button
        full
        label={confirmLabel ?? t('common.buttons.submit')}
        bg={confirmDisabled ? COLOR.BLUE8 : COLOR.BLUE3}
        style={{ height: 48, borderRadius: 8, fontSize: 15 }}
        disabled={confirmDisabled}
        onClick={() => {
          if (onConfirm) {
            onConfirm();
          }
        }}
      />
    </Content>
  );
}
const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  > * {
    margin-left: 8px;
    margin-right: 8px;
  }
`;
/** ******************************************************
[사용법]
  <SubmitCancel
    cancelLabel="아니요"
    onCancel={onRemovePopup}
    onConfirm={() => {
      alert('등록')
    }}
  />
******************************************************** */
