import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'model';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import StationDetailPresenter from './StationDetail.presenter';
import { actions } from '@/model/modules/station';
import { SCREEN_MODE } from '@/types/mode';
import { getStation, removeStation } from '@/lib/apis/infra';
import { MENU } from '@/pages/router';

/**
 * @description 충전소 상세
 */
export default function StationDetailContainer() {
  // TODO 스켈레톤을 적용하여 UX 개선?
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [deletePopup, setDeletePopup] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const searchKeyword = searchParams.get('search') || '';
  const locationKeyword = searchParams.get('location') || '';
  const queryParamsURL = () => {
    const queryParams = [];

    if (searchKeyword) {
      queryParams.push(`search=${encodeURIComponent(searchKeyword)}`);
    }
    if (locationKeyword) {
      queryParams.push(`location=${encodeURIComponent(locationKeyword)}`);
    }
    queryParams.push(`page=${page}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const onInit = () => {
    dispatch(actions.setStationMode(SCREEN_MODE.VIEW));
  };

  const fetchStation = async () => {
    if (id) {
      const stationResponse = await getStation({ stationSeq: id });
      if (stationResponse.isSuccess) {
        dispatch(actions.setView(stationResponse.data));
        dispatch(actions.setStationPayload(stationResponse.data));
      }
    }
  };

  const handleEdit = () => {
    dispatch(actions.setStationMode(SCREEN_MODE.MODIFY));
    navigate(`${MENU.INFRA.STATION_MANAGEMENT_UPDATE}/${id}?${queryParamsURL()}`);
  };

  const handleDeletePopup = (value: boolean) => {
    setDeletePopup(value);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (id) {
        const removeResponse = await removeStation(id);
        if (removeResponse.isSuccess) {
          dispatch(actions.initView());
          navigate(MENU.INFRA.STATION_MANAGEMENT);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    onInit();
    fetchStation();
  }, [id]);

  return (
    <StationDetailPresenter
      onEdit={handleEdit}
      deletePopupVisible={deletePopup}
      handleDeletePopup={handleDeletePopup}
      onConfirm={handleDeleteConfirm}
    />
  );
}
