import React, { useState } from 'react';
import { Button, H2, H5, Image, Text, useRoutes, validationEmail } from 'lib';
import { useAppSelector } from 'model';
import styled from 'styled-components';
import { COLOR } from 'styles';
import { PopupContainer, PopupCreate, SubmitButton, TextInput } from 'components/form';
import { useTranslation } from 'react-i18next';
import AuthScreenLayout from '@/components/layout/auth';
import { auth_screen_sign_in } from '@/assets/images';
import { MENU } from '@/pages/router';
import { ForgotPasswordProps } from '@/pages/auth/forgot-password/type';

/**
 * @name 비밀번호 찾기
 * @description
 */
export default function ForgotPassword({
  popupVisible,
  setPopupVisible,
  sendEmail,
  onChangeHandler,
  email,
  isSuccess,
  message,
}: ForgotPasswordProps) {
  const { t } = useTranslation('common');
  const { login } = useAppSelector(state => state.auth);
  const { navigate } = useRoutes();

  const closePopup = () => {
    setPopupVisible(false);
  };

  return (
    <AuthScreenLayout visual={<Image src={auth_screen_sign_in} />}>
      <HeaderWrapper>
        <header>
          <TitleContainer>
            <H2 className="center" color={COLOR.GRAY1}>
              {t('pages.auth.findPassword')}
            </H2>
            <Text color={COLOR.GRAY2} className="body4">
              {t('pages.auth.resetPassword')}
            </Text>
          </TitleContainer>
        </header>
      </HeaderWrapper>
      <Content>
        <H5 style={{ marginBottom: 4 }} color={COLOR.GRAY1}>
          {t('pages.auth.email')}
        </H5>
        <TextInput
          style={{ marginBottom: 40 }}
          placeholder={t('pages.auth.emailPlaceholder')}
          defaultValue={login.email}
          validFunc={validationEmail}
          onChange={str => {
            onChangeHandler(str);
          }}
          onSubmit={str => {
            onChangeHandler(str);
          }}
        />
        <Button
          full
          disabled={!validationEmail(email)}
          style={{ height: 40, marginBottom: 16 }}
          onClick={() => {
            sendEmail();
          }}>
          <span className="button3">{t('pages.auth.getLinkToSetPassword')}</span>
        </Button>
        <footer>
          <p className="body6">
            <button
              type="button"
              onClick={() => {
                navigate(MENU.AUTH.SIGN_IN);
              }}>
              {t('pages.auth.backToLogin')}
            </button>
          </p>
        </footer>
      </Content>
      <PopupContainer visible={popupVisible} onClose={closePopup}>
        <PopupCreate
          title={isSuccess ? t('common.toast.sendEmail') : t('common.toast.failedEmail')}
          warning={isSuccess}
          main={
            isSuccess ? (
              <Message>
                {email}로
                <br />
                비밀번호설정 링크를 보내드렸습니다.
              </Message>
            ) : (
              <Message>{message}</Message>
            )
          }
          footer={<SubmitButton label={t('common.buttons.submit')} onConfirm={closePopup} />}
        />
      </PopupContainer>
    </AuthScreenLayout>
  );
}
const Content = styled.div`
  width: 320px;
  footer {
    p {
      display: flex;
      margin-bottom: 16px;
      justify-content: center;
      color: ${COLOR.GRAY1};
    }
    button {
      padding-left: 10px;
      color: ${COLOR.BLUE4};
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 320px;
  margin-bottom: 20px;
  flex-direction: column;

  .logo {
    display: block;
    width: 56px !important;
    height: 48px !important;
    margin: 0 auto 20px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Message = styled(H2)`
  text-align: center;
  color: ${COLOR.GRAY1};
`;
