import { IVendor } from '@/types/infra';

export enum RoamingField {
  STATION_ID = 'roaming_station_id',
  CHARGEPOINT_ID1 = 'roaming_chargepoint_id1',
  CHARGEPOINT_ID2 = 'roaming_chargepoint_id2',
}

export interface ChargerRegisterProps {
  isModify: boolean;
  popupCancelVisible: boolean;
  handleCloseCancelPopup: () => void;
  onCancelPopup: () => void;
  handleOpenStationSelectPopup: () => void;
  selectedVendor: IVendor | null;
  onSelectedModel: (seq: string, vendor?: IVendor) => void;
  nameInputDesc: string;
  validationResult: { name: boolean | 'none' };
  requiredFieldsWarning: {
    name: boolean;
    requireDuplicateCheck: boolean;
    heartbeat_interval: boolean;
  };
  onNameChangeHandler: (value: string) => void;
  forms: { name: string };
  handleIsRoamingRequired: () => void;
  isNameDuplicated: () => void;
  onHeartbeatChangeHandler: (value: number) => void;
  handleRoamingInput: ({ fieldName, value }: { fieldName: RoamingField; value: string }) => void;
  onSubmit: () => void;
  handleCancelStationSelectPopup: () => void;
  stationSelectPopupVisible: boolean;
  popupSuccessVisible: boolean;
  handleDone: () => void;
  popupFailedVisible: boolean;
  handleCloseFailedPopup: () => void;
}
