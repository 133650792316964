import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'model';
import { useNavigate } from 'react-router-dom';
import ChargingRateRegisterPresenter from './ChargingRateRegister.presenter';
import { getTariff, postTariff, putTariff } from '@/lib/apis/tariff';
import { actions } from '@/model/modules/price';
import { SCREEN_MODE } from '@/types/mode';
import { MENU } from '@/pages/router';
import { GetTariffRes } from '@/lib/apis/tariff/type';

export default function ChargingRateRegisterContainer() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { mode, payload } = useAppSelector(state => state.price);
  const { cpo_seq } = useAppSelector(state => state.auth);
  const isModify = mode === SCREEN_MODE.MODIFY;

  const [popupCancelVisible, setPopupCancelVisible] = useState(false);
  const [popupSuccessVisible, setPopupSuccessVisible] = useState(false);
  const [popupFailedVisible, setPopupFailedVisible] = useState(false);

  const onInit = () => {
    fetchData();
    if (!isModify) {
      dispatch(actions.setMode(SCREEN_MODE.REGISTER));
    }
  };

  const fetchData = async () => {
    const { isSuccess, data } = await getTariff();
    if (isSuccess) {
      let nonMembers: GetTariffRes | undefined;
      let members: GetTariffRes | undefined;
      let roamingMembers: GetTariffRes | undefined;
      data.forEach(item => {
        switch (item.type) {
          case 0:
            nonMembers = item;
            break;
          case 1:
            members = item;
            break;
          case 2:
            roamingMembers = item;
            break;
          default:
            break;
        }
      });
      dispatch(actions.setView({ nonMembers, members, roamingMembers }));
      dispatch(actions.setPayload({ nonMembers, members, roamingMembers }));
    }
  };
  const handleBackToList = () => {
    navigate(MENU.RATE.CHARGING_RATE_MANAGEMENT);
  };

  const onSubmit = async () => {
    try {
      const submitResponse = await postTariff(cpo_seq, [payload.members, payload.nonMembers, payload.roamingMembers]);
      if (submitResponse.isSuccess) {
        dispatch(actions.initPayload());
        setPopupSuccessVisible(true);
      }
    } catch (e) {
      setPopupFailedVisible(true);
      console.error(e);
    }
  };

  const onPatchSubmit = async () => {
    try {
      const submitResponse = await putTariff(cpo_seq, [payload.members, payload.nonMembers]);
      if (submitResponse.isSuccess) {
        dispatch(actions.initPayload());
        setPopupSuccessVisible(true);
      }
    } catch (e) {
      setPopupFailedVisible(true);
      console.error(e);
    }
  };

  const onCancelPopup = () => {
    setPopupCancelVisible(true);
  };

  const handleCloseFailedPopup = () => {
    setPopupFailedVisible(false);
  };

  const handleCloseCancelPopup = () => {
    setPopupCancelVisible(false);
  };

  const handleDone = () => {
    dispatch(actions.initView());
    dispatch(actions.initPayload());
    setPopupSuccessVisible(false);
    if (isModify) {
      dispatch(actions.setMode(SCREEN_MODE.LIST));
      navigate(MENU.RATE.CHARGING_RATE_MANAGEMENT);
    } else {
      dispatch(actions.setMode(SCREEN_MODE.LIST));
      navigate(MENU.RATE.CHARGING_RATE_MANAGEMENT);
    }
  };
  useEffect(() => {
    onInit();
  }, []);

  return (
    <ChargingRateRegisterPresenter
      onSubmit={isModify ? onPatchSubmit : onSubmit}
      onCancelPopup={onCancelPopup}
      handleBackToList={handleBackToList}
      popupCancelVisible={popupCancelVisible}
      popupSuccessVisible={popupSuccessVisible}
      popupFailedVisible={popupFailedVisible}
      handleCloseFailedPopup={handleCloseFailedPopup}
      handleCloseCancelPopup={handleCloseCancelPopup}
      handleDone={handleDone}
    />
  );
}
