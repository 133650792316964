import React from 'react';
import { icon_gnb_expand_black, icon_gnb_more_black, sprite_gnb_2x } from 'assets/images';
import { Image, useContext, useRoutes } from 'lib';
import { useAppDispatch } from 'model';
import { gnbAction } from 'model/modules';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { IMenu, ISitemap } from '@/types/menu';
import typography from '@/styles/typography';
import { COLOR } from '@/styles/index';

type Props = {
  isOpen: boolean; // 마우스호버되어확장상태
  // item: GlobalNavType;
  item: IMenu;
  depth1: number;
  isSelected?: boolean;
};

/**
 * @name 메인메뉴
 * @description
 */
export default function Contents({ item, depth1, isOpen, isSelected }: Props) {
  const { pathname } = useLocation();
  const { navi, sitemap } = useContext();
  const { navigate } = useRoutes();
  const { name, menu_items } = item as IMenu;
  const dispatch = useAppDispatch();
  // const isActivated = navi.depth1 === depth1;
  let isActivated = false;
  const findItem = sitemap.find((element: ISitemap) => element.path.split('/')[1] === pathname.split('/')[1]);
  if (findItem.name === item.name) {
    isActivated = true;
  }
  const activated = isActivated ? 'actived' : '';

  const imageSrc = (name: string, isActivated: boolean) => {
    const temp = name.replace(/ /gi, '');
    const foundMenu = sitemap.find((element: ISitemap) => element.name.replace(/ /gi, '') === temp);
    if (isActivated) {
      return foundMenu?.imageSrc.activated;
    }
    return foundMenu?.imageSrc.default;
  };

  return (
    <Content
      className={`gnb-menu ${activated} `}
      onClick={() => {
        // 페이지이동
        const temp = name.replace(/ /gi, '');
        const menu = sitemap.find((element: any) => element.name.replace(/ /gi, '') === temp);
        if (!menu) {
          // console.log('메뉴가 없습니다.');
          // console.log(temp);
          return;
        }

        dispatch(gnbAction.setNavi({ depth1, depth1_name: name, depth1_path: menu.path }));
        if (menu_items.length === 0) {
          navigate(menu?.path);
          dispatch(
            gnbAction.setNavi({
              depth1,
              depth1_name: name,
              depth1_path: menu.path,
              depth2: '',
              depth2_name: '',
              depth2_path: '',
            }),
          );
        }
      }}>
      <ImageBox>
        <Image src={imageSrc(name, isActivated)?.default} style={{ width: '100%' }} />
      </ImageBox>
      {/* <figure className="gnb gnb" /> /!* 아이콘 *!/ */}
      <nav className={`${isOpen ? 'opened' : ''}`}>
        <MenuTitle $isActivated={isActivated}>{name}</MenuTitle>
        {menu_items?.length !== 0 && (
          <Image src={isActivated ? icon_gnb_expand_black : icon_gnb_more_black} style={{ width: 29, height: 24 }} />
        )}
      </nav>
    </Content>
  );
}

const ImageBox = styled.div`
  padding: 8px 10px;
  width: 44px;
  height: 44px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.button`
  /**-------본문--*/
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  gap: 8px;

  nav {
    display: none;
    padding-right: 10px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    &.opened {
      display: flex;
      flex: 1;
      height: 44px;
    }
  }
  /**-------아이콘--*/
  figure {
    background-image: url(${sprite_gnb_2x});
  }
`;

const MenuTitle = styled.span<{ $isActivated: boolean }>`
  ${typography.headline4};
  color: ${COLOR.BLACK3};

  ${({ $isActivated }) =>
    $isActivated &&
    css`
      color: ${COLOR.BLUE4};
    `}
`;
