import React from 'react';
import styled from 'styled-components';
import { CSS } from 'types';
import { COLOR } from 'styles';
import typography from '@/styles/typography';
import { BadgeMode, BadgeType } from '@/components/atoms/Badge/Badge.type';

type Props = {
  bg?: string;
  color?: string;
  label: string;
  style?: React.CSSProperties;
  mode: BadgeType;
};
/**
 * @name 뱃지
 * @description
 */
export default function Badge({ bg, color, mode, label, style }: Props) {
  const theme = (mode && BadgeMode[mode]) ?? BadgeMode[mode] ?? { color: COLOR.WHITE, backgroundColor: COLOR.GRAY3 };
  const combinedBackground = bg && ({ backgroundColor: bg } as CSS);
  const combinedColor = color && ({ color } as CSS);
  const combinedStyle = { ...theme, ...combinedColor, ...combinedBackground, ...(!!style && style) };
  return (
    <Content className="badge" style={combinedStyle}>
      {label}
    </Content>
  );
}
const Content = styled.span`
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 5px 10px;
  border-radius: 4px;
  align-items: center;
  flex-direction: column;
  ${typography.body5};
`;
